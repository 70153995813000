import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconSearchStrong.module.css';

const IconSearchStrong = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path d="M19.788,17.774H18.727l-.376-.363a8.743,8.743,0,1,0-.94.94l.363.376v1.061l6.715,6.7,2-2Zm-8.058,0a6.044,6.044,0,1,1,6.044-6.044A6.036,6.036,0,0,1,11.73,17.774Z" />
    </svg>
  );
};

IconSearchStrong.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconSearchStrong.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconSearchStrong;
