import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import { IconSpinner, IconTrashBin, SecondaryButton } from '..';

import css from './AddRemoveItem.module.css';

const AddRemoveItem = props => {
  const {
    className,
    onOpenClassName,
    headerClassName,
    itemClassName,
    addButtonClassName,
    label,
    item,
    onRemoveItem,
    addItemMessage,
    initOpen,
    showPlusIcon,

    fetchInProgress,
    fetchErrorMessage,

    removingItemInProgress,
    removingItemErrorMessage,
  } = props;

  const [isOpen, setIsOpen] = useState(initOpen);

  const onOpenClasses = useMemo(() => classNames(css.openItem, onOpenClassName), [onOpenClassName]);

  const classes = useMemo(() => classNames(className, { [onOpenClasses]: isOpen }), [
    className,
    onOpenClasses,
    isOpen,
  ]);

  return (
    <div className={classes}>
      <div className={classNames(css.header, headerClassName)}>
        {label}
        <button
          type="button"
          onClick={() => {
            onRemoveItem();
            setIsOpen(false);
          }}
          className={classNames(css.removeItem, { [css.removeItemHidden]: !isOpen })}
          disabled={removingItemInProgress === item.key}
        >
          {removingItemInProgress === item.key ? (
            <IconSpinner className={css.removeItemSpinner} />
          ) : (
            <IconTrashBin />
          )}
        </button>
      </div>

      {isOpen && <div className={itemClassName}>{item.content}</div>}

      {(fetchErrorMessage || removingItemErrorMessage) && (
        <div className={css.error}> {fetchErrorMessage || removingItemErrorMessage} </div>
      )}
      {fetchInProgress && (
        <div className={css.spinner}>
          <IconSpinner />
        </div>
      )}

      {!isOpen && (
        <SecondaryButton
          type="button"
          className={classNames(css.addButton, addButtonClassName)}
          onClick={() => setIsOpen(true)}
        >
          {addItemMessage}
          {showPlusIcon && <span className={css.plus}>+</span>}
        </SecondaryButton>
      )}
    </div>
  );
};

AddRemoveItem.defaultProps = {
  items: [],
  fetchInProgress: false,
  removingItemInProgress: '',
  showPlusIcon: false,
};

AddRemoveItem.propTypes = {
  className: string,
  onOpenClassName: string,
  headerClassName: string,
  showPlusIcon: bool,
  itemClassName: string,
  addButtonClassName: string,
  initOpen: bool,
  label: oneOfType([string, node]),
  addItemMessage: node,
  items: arrayOf(shape({ key: string.isRequired, content: node.isRequired }).isRequired),
  onRemoveItem: func.isRequired,
  fetchInProgress: bool,
  removingItemInProgress: string,
  fetchErrorMessage: node,
  removingItemErrorMessage: node,
};

export default AddRemoveItem;
