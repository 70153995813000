import React, { useMemo } from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { createListingTitle } from '../../util/listingHelpers';

import css from './ListingTitle.module.css';

const ListingTitle = props => {
  const { className, rootClassName, intl, listing } = props;

  const classes = classNames(rootClassName || css.blackText, className);

  const { attributes } = listing || {};
  const { publicData, deleted } = attributes || {};
  const { discipline, duration, type, ability, groupType } = publicData || {};

  const listingTitle = createListingTitle(intl, { discipline, ability, type, duration, groupType });

  const title = useMemo(() => {
    //const durationText = duration
    //  ? intl.formatMessage({ id: 'General.days' }, { numOfDays: duration })
    //  : '';
    const disciplineText = intl.formatMessage({ id: `General.${discipline}` }).toLowerCase();

    const titleWithoutDisciplineAndDuration = listingTitle.replace(`${disciplineText}`, '');

    return {
      disciplineText,
      titleWithoutDisciplineAndDuration,
    };
  }, [intl, discipline, listingTitle]);

  if (deleted)
    return (
      <span className={css.blackText}> {intl.formatMessage({ id: 'General.deletedListing' })}</span>
    );

  return (
    <span className={classes}>
      <span className={css.emphasisedText}>{title.disciplineText} </span>
      {title.titleWithoutDisciplineAndDuration}
    </span>
  );
};

ListingTitle.defaultProps = {
  className: '',
  rootClassName: '',
  titleData: {},
};

ListingTitle.propTypes = {
  className: string,
  rootClassName: string,
  titleData: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingTitle);
