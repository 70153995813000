import httpClient from '../httpClient';

const contact = body => {
  return httpClient.post('contact-us', body);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  contact,
};
