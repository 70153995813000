import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { OrderBreakdown, FieldSessionItem } from '..';
import classNames from 'classnames';
import { arrayOf, object, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';

import {
  convertDateToMoment,
  dateIsAfter,
  dateIsBefore,
  getLocalOrderDayOfWeek2Letters,
  isSameDay,
  resetToStartOfDay,
  resetToStartOrEndOfWeek,
  stringifyDateToISO8601,
} from '../../util/dates';
import FilterPopup from '../../containers/SearchPage/FilterPopup/FilterPopup';

import css from './FieldSessionSelector.module.css';
import bus, { AuthEventTypes } from '../../modules/bus';

const FieldSessionSelectorComponent = props => {
  const {
    className,
    itemClassName,
    id,
    fields,
    options,
    onChange,
    timeZone,
    intl,
    startDate,
    endDate,
    isMultiDay,
    sessionsDiscount,
    duration,
    estimatedTx,
    currency,
    clearSessions,
    listingAuthor,
  } = props;

  const [selectedDay, setSelectedDay] = useState();

  const classes = classNames(css.root, className);

  const messages = useMemo(
    () => ({
      from: intl.formatMessage({ id: 'General.from' }),
      to: intl.formatMessage({ id: 'General.to' }),
      noDatesSelected: intl.formatMessage({ id: 'BookingTimeForm.noDatesSelected' }),
      unlockDiscount: intl.formatMessage({ id: 'BookingTimeForm.unlockDiscount' }),
    }),
    [intl]
  );
  const twoLettersDaysOfWeek = useMemo(() => getLocalOrderDayOfWeek2Letters(), []);

  const hasSelectedSessions = useMemo(() => fields.value?.length > 0, [fields.value?.length]);

  const selectedSessions = useMemo(() => {
    if (!hasSelectedSessions || !estimatedTx)
      return <span>{intl.formatMessage({ id: 'BookingTimeForm.selectAvailableSessions' })}</span>;

    return (
      <OrderBreakdown
        transaction={estimatedTx}
        timeZone={timeZone}
        showOnlySessions
        userRole="customer"
        sessionsClassName={css.selectedSessions}
      />
    );
  }, [estimatedTx, hasSelectedSessions, intl, timeZone]);

  const datesPickerButtonContent = useMemo(
    () => (
      <>
        <label> {intl.formatMessage({ id: 'General.time' })}</label>
        {selectedSessions}
      </>
    ),
    [intl, selectedSessions]
  );

  const getSessionsForSelectedDay = useCallback(
    selectedDateString => {
      if (!selectedDateString) return null;

      return options.filter(option => {
        const { value } = option;

        const { startsAt } = value ? JSON.parse(value) : {};

        const selectedDate = new Date(selectedDateString);

        const start = new Date(startsAt);

        return isSameDay(start, selectedDate);
      });
    },
    [options]
  );

  const sessionsForSelectedDay = useMemo(() => getSessionsForSelectedDay(selectedDay), [
    getSessionsForSelectedDay,
    selectedDay,
  ]);

  const getSessionDaysForDates = useCallback(() => {
    const headers = [];

    if (!startDate || !endDate) return [];

    const startOfBookingStart = resetToStartOfDay(startDate, timeZone);
    const startOfBookingEnd = resetToStartOfDay(endDate, timeZone);

    const startOfWeek = resetToStartOrEndOfWeek(startDate, timeZone);
    const endOfWeek = resetToStartOrEndOfWeek(endDate, timeZone, 0, true);

    let currentDate = startOfWeek;

    while (dateIsBefore(currentDate, endOfWeek)) {
      const dateInMoment = convertDateToMoment(currentDate, timeZone);

      const isDayInBooking =
        dateIsAfter(currentDate, startOfBookingStart) &&
        dateIsAfter(startOfBookingEnd, currentDate);

      const dateString = stringifyDateToISO8601(currentDate, timeZone);

      const sessionsForDay = getSessionsForSelectedDay(dateString);

      headers.push({
        dateString,
        day: dateInMoment.date(),
        isDayDisabled: sessionsForDay ? sessionsForDay.length === 0 : false,
        isDayHidden: !isDayInBooking,
      });

      currentDate = resetToStartOfDay(currentDate, timeZone, 1);
    }

    return headers;
  }, [endDate, getSessionsForSelectedDay, startDate, timeZone]);

  const sessionHeaders = useMemo(
    () => (
      <div className={css.sessionHeaders}>
        {twoLettersDaysOfWeek.map(dow => (
          <div key={dow} className={css.dayOfWeek}>
            {dow}
          </div>
        ))}

        {getSessionDaysForDates().map((sessionDay, index) => {
          const { dateString, day, isDayDisabled, isDayHidden } = sessionDay;

          return (
            <div key={`${dateString}.${index}`} className={css.sessionDay}>
              {isDayHidden ? (
                <div className={css.hiddenDay}>{day}</div>
              ) : (
                <button
                  type="button"
                  disabled={!!isDayDisabled || isMultiDay}
                  className={classNames(css.headerDay, {
                    [css.headerDayDisabled]: isDayDisabled,
                    [css.headerDaySelected]: isMultiDay || selectedDay === dateString,
                  })}
                  onClick={() => setSelectedDay(dateString)}
                >
                  {day}
                </button>
              )}
            </div>
          );
        })}
      </div>
    ),
    [getSessionDaysForDates, isMultiDay, selectedDay, twoLettersDaysOfWeek]
  );

  const onCancel = useCallback(() => {
    bus.broadcastEvent(AuthEventTypes.FILTER_POPUP_TOGGLE, 'BookingTimeFormSessionPopup');
  }, []);

  useEffect(() => {
    const sessionDays = getSessionDaysForDates();

    const firstSession = sessionDays.find(
      sessionDay => !sessionDay.isDayDisabled && !sessionDay.isDayHidden
    );

    // If the fields value is empty, it means that
    // either the dates in the date picker have changed
    // or its the first time the dates were selected.
    if (firstSession && !hasSelectedSessions) {
      setSelectedDay(firstSession.dateString);
    } else if (firstSession) {
      setSelectedDay(old => (old ? old : firstSession.dateString));
    } else {
      setSelectedDay();
    }
  }, [getSessionDaysForDates, hasSelectedSessions]);

  return (
    <div className={classes}>
      <FilterPopup
        className={css.filterPopup}
        clickHandlerClassName={css.filterPopupClickHandler}
        labelClassName={css.sessionPickerButton}
        labelOpenClassName={css.sessionPickerButtonSelected}
        popupOpenClassName={css.popupOpenClassName}
        label={datesPickerButtonContent}
        id="BookingTimeFormSessionPopup"
        showAsPopup
        useForm={false}
        forceRenderDirection="left"
        listenForBusToggleOpen
      >
        <fieldset className={css.fieldset}>
          {sessionHeaders}
          <ul className={css.rowList}>
            {sessionsForSelectedDay ? (
              sessionsForSelectedDay.map(option => {
                const fieldId = `${id}.${option.key}`;

                return (
                  <li key={fieldId} className={css.item}>
                    <FieldSessionItem
                      id={fieldId}
                      name={fields.name}
                      value={option.value}
                      inputClassName={itemClassName}
                      onChange={onChange}
                      intl={intl}
                      duration={duration}
                      currency={currency}
                      listingAuthor={listingAuthor}
                    />
                  </li>
                );
              })
            ) : (
              <li className={css.noDatesSelected}>{messages.noDatesSelected}</li>
            )}
            {sessionsDiscount ? (
              <li className={classNames(css.item, css.sessionsDiscount)}>
                <div>
                  <b>{messages.unlockDiscount}</b>
                  <br />
                  {intl.formatMessage(
                    { id: 'BookingTimeForm.sessionsDiscount' },
                    { fromSession: (sessionsDiscount.fromSession || 0) + 1 }
                  )}
                </div>
                <span className={css.sessionsDiscountPercent}>
                  {`-${sessionsDiscount.percent}%`}
                </span>
              </li>
            ) : null}
          </ul>
        </fieldset>
        <div className={css.selectorFooter}>
          <button type="button" onClick={clearSessions} className={css.clearButton}>
            {intl.formatMessage({ id: 'FilterForm.clear' })}
          </button>
          <button type="button" onClick={onCancel} className={css.cancelButton}>
            {intl.formatMessage({ id: 'General.cancel' })}
          </button>
          <button type="button" onClick={onCancel} className={css.submitButton}>
            {intl.formatMessage({ id: 'General.apply' })}
          </button>
        </div>
      </FilterPopup>
    </div>
  );
};

FieldSessionSelectorComponent.propTypes = {
  className: string,
  itemClassName: string,
  intl: intlShape.isRequired,
  id: string.isRequired,
  options: arrayOf(object.isRequired).isRequired,
};

const FieldSessionSelector = props => (
  <FieldArray component={FieldSessionSelectorComponent} {...props} />
);

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldSessionSelector.propTypes = {
  name: string.isRequired,
};

export default FieldSessionSelector;
