import React, { useCallback } from 'react';
import { bool, func, string } from 'prop-types';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { Modal } from '../../components';
import { useDispatch } from 'react-redux';

import css from './ModalContainer.module.css';

const ModalContainer = props => {
  const { children, isModalOpen, onClose, title, id } = props;
  const dispatch = useDispatch();

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  return (
    <Modal
      containerClassName={css.modalContainer}
      contentClassName={css.modalContent}
      id={id}
      isOpen={isModalOpen}
      onClose={() => onClose(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div>
        {title && <h2 className={css.modalTitle}>{title}</h2>}
        {children}
      </div>
    </Modal>
  );
};

ModalContainer.propTypes = {
  isModalOpen: bool.isRequired,
  onClose: func.isRequired,
  id: string.isRequired,
};

export default ModalContainer;
