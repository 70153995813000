export const LISTING_TYPE = {
  LESSON: 'lesson',
  TOUR: 'tour',
  EXPERIENCE: 'experience',
  FREE: 'free',
};

export const ALL_LISTING_TYPES = Object.values(LISTING_TYPE);

// Currently only used for tagging disciplines
export const LISTING_SEASONALITY = {
  WINTER: 'winter',
  SUMMER: 'summer',
};

export const BOOKING_STATE_TYPE = {
  ENQUIRY: 'enquiry',
  REQUESTED: 'requested',
  REVIEWED: 'reviewed',
  PENDING: 'pending',
  PENDING_PAYMENT: 'pendingPayment',
  EXPIRED: 'expired',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const ACTIVITY = {
  SKIING: 'skiing',
  SNOWBOARDING: 'snowboarding',
  HIKING: 'hiking',
  RUNNING: 'running',
  HORSE_RIDING: 'horseRiding',
  BIKING: 'biking',
  CLIMBING: 'climbing',
  SLEDGING: 'sledging',
  RIVER_SPORTS: 'riverSports',
  LAKE_AND_SEA_SPORTS: 'lakeAndSeaSports',
  AIR_SPORTS: 'airSports',
  OTHER_EXPERIENCES: 'otherExperiences',
  COURT_SPORTS: 'courtSports',
};
