import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { bool, number, string } from 'prop-types';
import classNames from 'classnames';
import { IconArrowHead } from '..';

import css from './Carousel.module.css';

const nextElement = el => {
  if (el.nextElementSibling) {
    return el.nextElementSibling;
  } else {
    return el.parentNode.firstElementChild;
  }
};

const prevElement = el => {
  if (el.previousElementSibling) {
    return el.previousElementSibling;
  } else {
    return el.parentNode.lastElementChild;
  }
};

const Carousel = props => {
  const {
    rootClassName,
    className,
    contentClassName,
    children,
    columns,
    useSlider,
    extended,
  } = props;

  const carouselRef = useRef(null);
  const isSliderEnable = useMemo(() => useSlider && (columns && children.length > columns), [
    children.length,
    columns,
    useSlider,
  ]);

  const [isReversingClass, itemClass, carouselClass] = useMemo(() => {
    return columns % 3 === 0
      ? [css.isReversing, css.item, css.carousel]
      : [css.isReversing4, css.item4, css.carousel4];
  }, [columns]);

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, { [css.extended]: extended }, className);

  const onClickNext = useCallback(() => {
    carouselRef.current.classList.add(isReversingClass);

    const carouselItems = carouselRef.current.children;

    const el = carouselRef.current.getElementsByClassName(css.isRef)[0];
    el.classList.remove(css.isRef);

    let newItem = nextElement(el);
    newItem.classList.add(css.isRef);
    newItem.style.order = 1;

    for (let i = 2; i <= carouselItems.length; i++) {
      newItem = nextElement(newItem);
      newItem.style.order = i;
    }

    carouselRef.current.classList.remove(css.isSet);

    setTimeout(() => {
      carouselRef.current.classList.add(css.isSet);
    }, 50);
  }, [isReversingClass]);

  const onClickBack = useCallback(() => {
    carouselRef.current.classList.remove(isReversingClass);

    const carouselItems = carouselRef.current.children;

    const el = carouselRef.current.getElementsByClassName(css.isRef)[0];
    el.classList.remove(css.isRef);

    let newItem = prevElement(el);
    newItem.classList.add(css.isRef);
    newItem.style.order = 1;

    for (let i = carouselItems.length; i >= 2; i--) {
      newItem = prevElement(newItem);
      newItem.style.order = i;
    }

    carouselRef.current.classList.remove(css.isSet);

    setTimeout(() => {
      carouselRef.current.classList.add(css.isSet);
    }, 50);
  }, [isReversingClass]);

  useEffect(() => {
    if (isSliderEnable) {
      carouselRef.current.classList.add(css.carousel, carouselClass, css.isSet);

      const lastElement = carouselRef.current.firstElementChild;
      lastElement.classList.add(css.isRef);
    } else {
      carouselRef.current.classList.add(css.carousel, carouselClass, css.disableCarousel);
    }
  }, [isSliderEnable, carouselClass]);

  return (
    <div className={classes} style={{ '--column-size': `calc(100% / ${columns})` }}>
      {isSliderEnable && (
        <div className={css.buttonWrapper}>
          <button
            onClick={onClickBack}
            className={classNames(css.arrowButton, { [css.extendedArrowLeft]: extended })}
          >
            <IconArrowHead direction="left" size="small" className={css.iconArrow} />
          </button>
        </div>
      )}
      <div
        className={classNames(css.wrapper, contentClassName, { [css.marginAuto]: !isSliderEnable })}
      >
        <div className={css.items} ref={carouselRef}>
          {React.Children.map(children, (child, index) => (
            <div key={index} className={itemClass}>
              {child}
            </div>
          ))}
        </div>
      </div>
      {isSliderEnable && (
        <div className={css.buttonWrapper}>
          <button
            onClick={onClickNext}
            className={classNames(css.arrowButton, { [css.extendedArrowRight]: extended })}
          >
            <IconArrowHead direction="right" size="small" className={css.iconArrow} />
          </button>
        </div>
      )}
    </div>
  );
};

Carousel.defaultProps = {
  rootClassName: null,
  className: null,
  contentClassName: null,
  columns: 3,
  useSlider: true,
};

Carousel.propTypes = {
  rootClassName: string,
  className: string,
  contentClassName: string,
  columns: number,
  useSlider: bool,
};

export default Carousel;
