import React from 'react';

import css from './IconTicinoLogo.module.css';

const IconTicinoLogo = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 205.7 60"
      style={{ enableBackground: 'new 0 0 205.7 60' }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <g>
              <g>
                <path className={css.st0} d="M90.4,46.4h-5.5V24.5h5.5V46.4z" />
                <path
                  className={css.st0}
                  d="M107.7,41.4c-3.3,0-5.9-2.6-5.9-5.9c0-3.3,2.6-5.9,5.9-5.9c2,0,3.8,1,4.9,2.5l0,0l4.3-3.6       c-2-2.6-5.4-4.4-9.2-4.4c-6.7,0-11.7,5-11.7,11.4c0,6.3,4.9,11.3,11.7,11.3c4.1,0,7.3-1.5,9.9-4.9l-4.7-3.5       C111.8,40.2,109.9,41.4,107.7,41.4z"
                />
                <path className={css.st0} d="M127.7,46.4h-5.5V24.5h5.5V46.4z" />
              </g>
              <path
                className={css.st0}
                d="M143.7,24.7c-5.9,0-10.8,4.8-10.8,10.8v10.9h5.7V35.5c0-2.8,2.3-5.1,5.1-5.1c2.8,0,5.1,2.3,5.1,5.1v10.9      h5.7V35.5C154.4,29.5,149.6,24.7,143.7,24.7z"
              />
              <path
                className={css.st0}
                d="M170.3,24.1c-6.6,0-11.6,5.1-11.6,11.5c0,6.3,5,11.4,11.6,11.4c6.6,0,11.6-5.1,11.6-11.4      C181.9,29.2,176.9,24.1,170.3,24.1z M170.3,41.5c-3.3,0-5.9-2.7-5.9-5.9c0-3.3,2.6-5.9,5.9-5.9c3.3,0,5.9,2.7,5.9,5.9      C176.2,38.8,173.5,41.5,170.3,41.5z"
              />
              <polygon
                className={css.st0}
                points="79.4,30 79.4,24.5 72.3,24.5 72.3,24.5 66.8,24.5 66.8,24.5 59.7,24.5 59.7,30 66.8,30 66.8,46.4       72.3,46.4 72.3,30     "
              />
            </g>
            <g>
              <g>
                <defs>
                  <polyline
                    id="SVGID_1_"
                    points="190.5,27.9 190.5,43 205.7,43 205.7,27.9 190.5,27.9       "
                  />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                </clipPath>
                <path className={css.st1} d="M205.7,27.9h-15.2V43h15.2V27.9" />
              </g>
              <polygon
                className={css.st2}
                points="202.8,34 199.5,34 199.5,30.7 196.6,30.7 196.6,34 193.3,34 193.3,36.9 196.6,36.9 196.6,40.2       199.5,40.2 199.5,36.9 202.8,36.9     "
              />
            </g>
          </g>
        </g>
        <g>
          <rect x="32.7" className={css.st3} width="5.5" height="5.5" />
          <rect x="10.9" y="5.5" className={css.st4} width="5.5" height="5.5" />
          <rect x="32.7" y="5.5" className={css.st5} width="5.5" height="5.5" />
          <rect x="5.5" y="10.9" className={css.st6} width="5.5" height="5.5" />
          <rect x="10.9" y="10.9" className={css.st7} width="5.5" height="5.5" />
          <rect x="16.4" y="10.9" className={css.st4} width="5.5" height="5.5" />
          <rect x="21.8" y="10.9" className={css.st8} width="5.5" height="5.5" />
          <rect x="27.3" y="10.9" className={css.st5} width="5.5" height="5.5" />
          <rect x="32.7" y="10.9" className={css.st9} width="5.5" height="5.5" />
          <rect x="38.2" y="10.9" className={css.st5} width="5.5" height="5.5" />
          <rect x="43.6" y="10.9" className={css.st10} width="5.5" height="5.5" />
          <rect x="5.5" y="16.4" className={css.st11} width="5.5" height="5.5" />
          <rect x="10.9" y="16.4" className={css.st4} width="5.5" height="5.5" />
          <rect x="16.4" y="16.4" className={css.st12} width="5.5" height="5.5" />
          <rect x="21.8" y="16.4" className={css.st9} width="5.5" height="5.5" />
          <rect x="27.3" y="16.4" className={css.st12} width="5.5" height="5.5" />
          <rect x="32.7" y="16.4" className={css.st13} width="5.5" height="5.5" />
          <rect x="38.2" y="16.4" className={css.st10} width="5.5" height="5.5" />
          <rect x="43.6" y="16.4" className={css.st14} width="5.5" height="5.5" />
          <rect y="21.8" className={css.st11} width="5.5" height="5.5" />
          <rect x="5.5" y="21.8" className={css.st15} width="5.5" height="5.5" />
          <rect x="10.9" y="21.8" className={css.st11} width="5.5" height="5.5" />
          <rect x="16.4" y="21.8" className={css.st16} width="5.5" height="5.5" />
          <rect x="21.8" y="21.8" className={css.st17} width="5.5" height="5.5" />
          <rect x="27.3" y="21.8" className={css.st18} width="5.5" height="5.5" />
          <rect x="32.7" y="21.8" className={css.st19} width="5.5" height="5.5" />
          <rect x="38.2" y="21.8" className={css.st18} width="5.5" height="5.5" />
          <rect x="43.6" y="21.8" className={css.st10} width="5.5" height="5.5" />
          <rect x="16.4" y="27.3" className={css.st20} width="5.5" height="5.5" />
          <rect x="21.8" y="27.3" className={css.st21} width="5.5" height="5.5" />
          <rect x="27.3" y="27.3" className={css.st22} width="5.5" height="5.5" />
          <rect x="32.7" y="27.3" className={css.st18} width="5.5" height="5.5" />
          <rect x="10.9" y="32.7" className={css.st23} width="5.5" height="5.5" />
          <rect x="16.4" y="32.7" className={css.st24} width="5.5" height="5.5" />
          <rect x="21.8" y="32.7" className={css.st23} width="5.5" height="5.5" />
          <rect x="27.3" y="32.7" className={css.st25} width="5.5" height="5.5" />
          <rect x="32.7" y="32.7" className={css.st14} width="5.5" height="5.5" />
          <rect x="16.4" y="38.2" className={css.st26} width="5.5" height="5.5" />
          <rect x="21.8" y="38.2" className={css.st25} width="5.5" height="5.5" />
          <rect x="27.3" y="38.2" className={css.st27} width="5.5" height="5.5" />
          <rect x="32.7" y="38.2" className={css.st25} width="5.5" height="5.5" />
          <rect x="16.4" y="43.6" className={css.st28} width="5.5" height="5.5" />
          <rect x="21.8" y="43.6" className={css.st29} width="5.5" height="5.5" />
          <rect x="27.3" y="43.6" className={css.st30} width="5.5" height="5.5" />
          <rect x="32.7" y="43.6" className={css.st31} width="5.5" height="5.5" />
          <rect x="16.4" y="49.1" className={css.st26} width="5.5" height="5.5" />
          <rect x="21.8" y="49.1" className={css.st32} width="5.5" height="5.5" />
          <rect x="27.3" y="49.1" className={css.st33} width="5.5" height="5.5" />
          <rect x="32.7" y="49.1" className={css.st34} width="5.5" height="5.5" />
          <rect x="32.7" y="54.5" className={css.st31} width="5.5" height="5.5" />
        </g>
      </g>
    </svg>
  );
};

export default IconTicinoLogo;
