import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEmail.module.css';

const IconEmail = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(-36 -313)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(36 313)" />
        <path d="M63.5 320.5h-20a2.5 2.5 0 0 0-2.487 2.5L41 338a2.507 2.507 0 0 0 2.5 2.5h20A2.507 2.507 0 0 0 66 338v-15a2.507 2.507 0 0 0-2.5-2.5Zm0 5-10 6.25-10-6.25V323l10 6.25 10-6.25Z" />
      </g>
    </svg>
  );
};

IconEmail.defaultProps = {
  rootClassName: null,
  className: null,
};

IconEmail.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEmail;
