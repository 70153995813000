import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconLink.module.css';

const IconLink = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(-36 -368)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(36 368)" />
        <path d="M56.948 382.052a7.418 7.418 0 0 1 .018 10.478l-.018.018-3.281 3.282a7.425 7.425 0 0 1-10.5-10.5l1.812-1.812a.781.781 0 0 1 1.333.518 9 9 0 0 0 .473 2.574.786.786 0 0 1-.185.811l-.639.639a3.516 3.516 0 1 0 4.94 5l3.281-3.281a3.515 3.515 0 0 0 0-4.972 3.658 3.658 0 0 0-.5-.418.783.783 0 0 1-.339-.616 1.945 1.945 0 0 1 .571-1.455l1.027-1.018a.784.784 0 0 1 1.005-.085 7.445 7.445 0 0 1 1 .84Zm6.881-6.882a7.43 7.43 0 0 0-10.5 0l-3.277 3.282-.018.018a7.423 7.423 0 0 0 1.02 11.318.784.784 0 0 0 1.005-.085l1.028-1.028a1.945 1.945 0 0 0 .571-1.455.783.783 0 0 0-.339-.616 3.658 3.658 0 0 1-.5-.418 3.515 3.515 0 0 1 0-4.972l3.281-3.281a3.516 3.516 0 1 1 4.94 5l-.639.639a.786.786 0 0 0-.185.811 9 9 0 0 1 .473 2.574.781.781 0 0 0 1.333.518l1.812-1.812a7.43 7.43 0 0 0 0-10.5Z" />
      </g>
    </svg>
  );
};

IconLink.defaultProps = {
  rootClassName: null,
  className: null,
};

IconLink.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLink;
