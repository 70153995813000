import appSettings from '../config/settings';
import { tokenStore } from './sdkLoader';
import Cookies from 'js-cookie';

const generateKey = (cookieId, namespace) => `${namespace}-${cookieId}-token`;

export const hasBrowserCookies = () =>
  typeof document === 'object' && typeof document.cookie === 'string';

export function getTokenStore() {
  const store = hasBrowserCookies()
    ? createBrowserStore({
        clientId: appSettings.sdk.clientId,
        secure: appSettings.usingSSL,
      })
    : tokenStore.memoryStore();

  return store;
}

const createBrowserStore = ({ clientId, secure }) => {
  const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
  const expiration = 30; // 30 days
  const namespace = 'st';
  const key = generateKey(clientId, namespace);

  const secureFlag = secure ? { secure: true } : {};
  const domain = COOKIE_DOMAIN ? { domain: COOKIE_DOMAIN } : {};

  const cookieOptions = {
    ...secureFlag,
    ...domain,
  };

  const getToken = () => {
    const token = Cookies.get(key);
    try {
      return token ? JSON.parse(token) : null;
    } catch (err) {
      console.error('Failed parsing token', token);
      return null;
    }
  };
  const setToken = tokenData => {
    Cookies.set(key, JSON.stringify(tokenData), { expires: expiration, ...cookieOptions });
  };
  const removeToken = () => {
    Cookies.remove(key, cookieOptions);
  };

  return {
    getToken,
    setToken,
    removeToken,
  };
};
