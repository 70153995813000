import React from 'react';
import { string } from 'prop-types';

const IconPersonOnSleigh = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 40 49">
      <path
        d="M170.557-511.794a4.826,4.826,0,0,0-3.046,2.4,5.655,5.655,0,0,0-.512,2.047,4.648,4.648,0,0,0,1.342,3.146,4.1,4.1,0,0,0,3.23,1.368,4.1,4.1,0,0,0,3.247-1.384,4.393,4.393,0,0,0,.856-5.084,3.808,3.808,0,0,0-.889-1.225A4.38,4.38,0,0,0,170.557-511.794Z"
        transform="translate(-155 511)"
      />
      <path
        d="M69.875-381.587a5.644,5.644,0,0,0-.872.456,5.053,5.053,0,0,0-1.409,1.673c-.132.294-.953,3.538-1.825,7.217-1.51,6.417-1.581,6.731-1.541,7.562a3.039,3.039,0,0,0,.355,1.52,4.175,4.175,0,0,0,1.855,1.865l.659.355H63.134c-3.953,0-3.963,0-4.409.233a2.052,2.052,0,0,0-.507,3.153,2.356,2.356,0,0,0,.568.4c.365.193.608.223,2.362.253l1.946.04v9.711H61.2c-1.642,0-1.967.03-2.311.193a2.012,2.012,0,0,0-.649,3.213c.679.7-.426.659,15.8.618,13.7-.03,14.7-.04,15.408-.213a10.9,10.9,0,0,0,8.4-9.589,4.173,4.173,0,0,0-.041-1.439A1.9,1.9,0,0,0,95.9-355.9a1.939,1.939,0,0,0-1.551.72l-.324.355L93.574-355a6.98,6.98,0,0,1-2.818-2.25l-.193-.324.223-.385a2.1,2.1,0,0,0-.537-2.575,2.866,2.866,0,0,0-.73-.355c-.426-.122-.426-.132-.6-.872a25.087,25.087,0,0,1-.558-4.815l-.061-1.835.335-.061a2.362,2.362,0,0,0,1.318-1.379,1.983,1.983,0,0,0-1.054-2.291l-.507-.253H79.859l-2.271-2.555L75.318-377.5l-.081-.74a4.064,4.064,0,0,0-2.26-3.224,3.188,3.188,0,0,0-1.571-.324A2.864,2.864,0,0,0,69.875-381.587ZM76-370.669a14.487,14.487,0,0,0,2.068,2.078c.365.193.639.2,4.6.233l4.217.03.071,1.591a30.742,30.742,0,0,0,.558,4.866c.132.608.152.862.071.892-.061.02-1,.03-2.088.02l-1.977-.03-.061-.537a4.126,4.126,0,0,0-2.078-2.889c-.274-.132-2.261-.872-4.42-1.642s-3.933-1.45-3.933-1.51c-.01-.264,1.156-5.028,1.226-5.008C74.294-372.555,75.075-371.7,76-370.669Zm13.7,14.242a12.028,12.028,0,0,0,1.237,1.379,6.314,6.314,0,0,0,2.362,1.521c.568.193.608.416.3,1.439a6.76,6.76,0,0,1-4.349,4.562,6,6,0,0,1-2.362.355l-1.5.051v-9.762l1.956-.03c1.064-.01,1.946-.03,1.956-.051S89.489-356.731,89.7-356.427ZM74.071-354.38c-.8,1.389-2.088,3.578-2.848,4.866l-1.379,2.362H65.323v-9.731H75.551Zm9.093,2.362v4.866h-3.5c-1.926,0-3.5-.03-3.5-.061s1.267-2.22,2.818-4.866l2.808-4.8h1.368Z"
        transform="translate(-57 392)"
      />
    </svg>
  );
};

IconPersonOnSleigh.defaultProps = { className: null };
IconPersonOnSleigh.propTypes = { className: string };

export default IconPersonOnSleigh;
