import React, { useMemo } from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { AspectRatioWrapper, NamedLink, ResponsiveImage, ListingTitle } from '../../components';
import SaveListingButton from '../SaveListingButton/SaveListingButton';
import UserDisplayName from '../UserDisplayName/UserDisplayName';
import ReviewRating from '../ReviewRating/ReviewRating';
import { richText } from '../../util/richText';
import { convertNumberToMoney, priceData } from '../../util/currency';
import { LISTING_TYPE } from '../../constants/listingAttributes';
import { CUSTOMER_FEE_PERCENTAGE } from '../../transactions/transactionsUtil';
import { getCustomerFee, getNormalizedFee } from '../../util/sessions';

import css from './ListingCard.module.css';
import { checkIfEntityForeignPartner, checkIfEntityPartnerApproved } from '../../util/partner';
import { useConfiguration } from '../../context/configurationContext';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const ListingImage = props => <ResponsiveImage {...props} />;
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const getPriceWithFee = (price, feeAmount) => {
  if (!price) return;

  return convertNumberToMoney(price.amount + feeAmount, price.currency);
};

const ListingCardComponent = props => {
  const {
    imageWrapperClassName,
    intl,
    listing,
    renderSizes,
    showSave,
    showOnlyAverageReview,
    currentUser,
    config,
  } = props;

  const imageWrapperClasses = useMemo(() => classNames(css.imageWrapper, imageWrapperClassName), [
    imageWrapperClassName,
  ]);

  const currentListing = useMemo(() => ensureListing(listing), [listing]);
  const { id, attributes, images, author } = currentListing;
  const { title: listingTitle = '', price: listingPrice, publicData, deleted } = attributes;
  const { city, type } = publicData || {};

  const ensuredAuthor = useMemo(() => ensureUser(author), [author]);
  const { averageRating, ratingCount = 0 } = ensuredAuthor.attributes.profile.metadata || {};

  const deletedListing = useMemo(() => intl.formatMessage({ id: 'General.deletedListing' }), [
    intl,
  ]);

  const firstImage = useMemo(() => (images?.length > 0 ? images[0] : null), [images]);

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const title = useMemo(
    () => (deleted ? deletedListing : <ListingTitle listing={currentListing} />),
    [currentListing, deleted, deletedListing]
  );

  const isCurrentUserAuthor = currentUser?.id?.uuid === ensuredAuthor.id?.uuid;

  const buyerFee = isCurrentUserAuthor ? CUSTOMER_FEE_PERCENTAGE : getCustomerFee(currentUser);

  const feeAmount =
    listingPrice?.amount * (getNormalizedFee(buyerFee, CUSTOMER_FEE_PERCENTAGE) / 100);

  const isFree = useMemo(() => type === LISTING_TYPE.FREE, [type]);

  const price = getPriceWithFee(listingPrice, feeAmount);

  const { formattedPrice, priceTitle } = useMemo(() => priceData(price, intl), [intl, price]);

  return (
    <>
      <AspectRatioWrapper className={imageWrapperClasses} width={aspectWidth} height={aspectHeight}>
        <LazyImage
          rootClassName={css.rootForImage}
          alt={listingTitle || ''}
          image={firstImage}
          variants={variants.length ? variants : ['landscape-crop', 'landscape-crop2x']}
          sizes={renderSizes}
        />
        {showSave && !deleted && (
          <SaveListingButton rootClassName={css.heartButton} listingId={id.uuid} />
        )}
      </AspectRatioWrapper>
      <div className={css.mainContent}>
        <div className={css.title}>{title}</div>
        <div className={css.info}>
          <div className={css.infoSection}>
            <div>
              {richText(city, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>

            <UserDisplayName className={css.author} user={author} intl={intl} />
          </div>
          <div className={css.priceAndRating}>
            {averageRating > 0 ? (
              <ReviewRating
                rating={averageRating}
                ratingCount={ratingCount}
                isAccented
                hideRatingCount={showOnlyAverageReview}
                showStars={!showOnlyAverageReview}
              />
            ) : (
              <div />
            )}
            {isFree ? (
              <span className={css.emphasisedText} title={priceTitle}>
                <FormattedMessage id="General.free" />
              </span>
            ) : (
              <FormattedMessage
                id="General.priceFrom"
                values={{
                  formattedPrice: (
                    <span className={css.emphasisedText} title={priceTitle}>
                      {formattedPrice}
                    </span>
                  ),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  imageWrapperClassName: null,
  renderSizes: null,
  showSave: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  imageWrapperClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  renderSizes: string,
  showSave: bool,
};

const ListingCard = props => {
  const {
    rootClassName,
    className,
    listing,
    setActiveListing,
    useForeignListingLink,
    id: htmlId,
    ...rest
  } = props;

  const config = useConfiguration();

  const ensuredListing = useMemo(() => ensureListing(listing), [listing]);
  const { id, attributes } = ensuredListing;

  const { title, deleted, publicData, metadata } = attributes || {};

  const { partner } = publicData || {};

  const isForeignListing =
    useForeignListingLink &&
    checkIfEntityForeignPartner(partner, config) &&
    !checkIfEntityPartnerApproved(metadata?.partnerApproved, config);

  const classes = classNames(rootClassName || css.root, className, {
    [css.greyedOut]: isForeignListing,
  });

  const slug = useMemo(() => (deleted ? '' : createSlug(title)), [deleted, title]);
  const namedLinkProps = useMemo(
    () => (deleted ? {} : { name: 'ListingPage', params: { id: id.uuid, slug } }),
    [deleted, id?.uuid, slug]
  );

  return deleted ? (
    <div className={classes} id={htmlId}>
      <ListingCardComponent listing={ensuredListing} {...rest} />
    </div>
  ) : (
    <NamedLink
      {...namedLinkProps}
      id={htmlId}
      className={classes}
      isForeignLink={isForeignListing}
      onMouseEnter={() => setActiveListing?.(id)}
      onMouseLeave={() => setActiveListing?.(null)}
      target="_blank"
    >
      <ListingCardComponent listing={ensuredListing} {...rest} />
    </NamedLink>
  );
};
ListingCard.displayName = 'ListingCard';
export default injectIntl(ListingCard);

// NamedLink component cannot be used outside of Router
// Some components, such as SearchMap, are global scoped
// Redux store is also not available (so we cannot use SaveListingButton)
const ListingCardWithoutLinkComponent = props => {
  const { rootClassName, className, listing, useForeignListingLink, ...rest } = props;

  const config = useConfiguration();

  const ensuredListing = useMemo(() => ensureListing(listing), [listing]);

  const { attributes } = ensuredListing || {};

  const { publicData, metadata } = attributes || {};

  const { partner } = publicData || {};

  const isForeignListing =
    useForeignListingLink &&
    checkIfEntityForeignPartner(partner, config) &&
    !checkIfEntityPartnerApproved(metadata?.partnerApproved, config);

  const classes = classNames(rootClassName || css.root, className, {
    [css.greyedOut]: isForeignListing,
  });

  return (
    <div className={classes}>
      <ListingCardComponent listing={ensuredListing} showSave={false} {...rest} />
    </div>
  );
};
ListingCardWithoutLinkComponent.displayName = 'ListingCardWithoutLinkComponent';
export const ListingCardWithoutLink = injectIntl(ListingCardWithoutLinkComponent);
