import React from 'react';

const IconFlowLogo = props => {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
      <path
        fill="#1d1d1b"
        d="M750.73,555.66A193.42,193.42,0,0,0,717,475.71a1.67,1.67,0,0,0-1.22-.73c-.53,0-1,.34-1.32,1.53l-9.93,49.67a16.94,16.94,0,0,0-.27,3,19.54,19.54,0,0,0,.79,5.77,139.73,139.73,0,0,1,5.62,17.08c.82,3.39,1.52,6.82,2.15,10.27a155.52,155.52,0,0,1-52,144.92L547.66,628.87a5.42,5.42,0,0,1-2.32-3.72,5.87,5.87,0,0,1,1-4.38,6.34,6.34,0,0,1,4.14-2.4l91.66-16.22c8.17-1.45,13.13.22,16.25,2.83s4.63,6.7,5.36,10.63l.07.65a37.08,37.08,0,0,1,.14,8.4s-.07,1.67,0,2.33a2.35,2.35,0,0,0,1.38,1.7,1.41,1.41,0,0,0,.92.1,1.72,1.72,0,0,0,1.4-1.25l17.84-89.64a2.63,2.63,0,0,0,.05-1.24,1.8,1.8,0,0,0-.42-1,1.83,1.83,0,0,0-1.81-.81l-158.49,28a64.86,64.86,0,0,0-52.79,75l.13.77a66.08,66.08,0,0,0,27.45,42L626.86,769a5.77,5.77,0,0,0,4.41.26C715.16,736.18,767,647.81,750.73,555.66M565.65,746.59a14.5,14.5,0,0,0-7.11-2.32c-31.09,0-61.31-9.81-87.36-28A155.07,155.07,0,0,1,627.46,449.53l.3.12a21.59,21.59,0,0,0,9,.67l.63-.08,44.43-7.89H682c1.67-.25,1.5-2.22.52-3.06l-.08-.07a193.51,193.51,0,0,0-155.86-40.59c-105.27,18.56-175.58,118.94-157,224.2s118.94,175.58,224.21,157c4.71-.83,7.91-1.52,12.57-2.64,1.54-.45,1.11-1.61.31-2.17-.47-.3-41-28.44-41-28.44M446.6,509.4c-7.64,8.17-13.8,21.24-11.7,40l12.73,72a2,2,0,0,0,2,1.83,2.12,2.12,0,0,1,.28,0,2,2,0,0,0,1.51-1.84A81.26,81.26,0,0,1,466,580.71,82.64,82.64,0,0,1,519.07,547h0s164.37-29,167.08-29.51a6,6,0,0,0,4.48-4.86c.33-1.74,8.93-44.57,10.88-54.21.32-1.78-.08-2.66-2.33-2.26L467.83,496.74c-4.51.84-13.48,4.41-21.23,12.66"
      />
      <path
        fill="#1d1d1b"
        d="M890.42,451.86V485.4c0,3.52-1.71,5.25-5.24,5.25h-43v54.28h37.31c3.54,0,5.31,1.78,5.31,5.29v33.54c0,3.5-1.77,5.28-5.31,5.28H842.15V705.19c0,3.48-1.71,5.21-5.18,5.21H803.4c-3.52,0-5.34-1.73-5.34-5.21V451.86c0-3.52,1.82-5.29,5.34-5.29h81.78C888.71,446.57,890.42,448.34,890.42,451.86Z"
      />
      <path
        fill="#1d1d1b"
        d="M1016.65,671.58v33.61c0,3.48-1.72,5.21-5.2,5.21H925.88c-3.51,0-5.33-1.73-5.33-5.21V451.86c0-3.52,1.82-5.29,5.33-5.29h33.53c3.53,0,5.3,1.77,5.3,5.29V666.32h46.74C1014.93,666.32,1016.65,668.06,1016.65,671.58Z"
      />
      <path
        fill="#1d1d1b"
        d="M1143,665.57a45.09,45.09,0,0,1-3.73,18.43,47.23,47.23,0,0,1-25.29,25.3,45.76,45.76,0,0,1-18.45,3.74h-14.33a46.13,46.13,0,0,1-18.47-3.74,49.16,49.16,0,0,1-15-10.17A47.75,47.75,0,0,1,1037.47,684a45.3,45.3,0,0,1-3.79-18.43V491.41a45.55,45.55,0,0,1,3.79-18.44,48.09,48.09,0,0,1,10.16-15.08,49.63,49.63,0,0,1,15-10.21,46.3,46.3,0,0,1,18.47-3.73h14.33a45.92,45.92,0,0,1,18.45,3.73A47.61,47.61,0,0,1,1139.22,473a45.34,45.34,0,0,1,3.73,18.44Zm-44.08-172.21a8.84,8.84,0,0,0-2.6-6.43,8.61,8.61,0,0,0-6.4-2.66h-3.06a8.66,8.66,0,0,0-6.43,2.66,8.88,8.88,0,0,0-2.68,6.43V663.65a9.06,9.06,0,0,0,9.11,9.06h3.06a8.93,8.93,0,0,0,9-9.06Z"
      />
      <path
        fill="#1d1d1b"
        d="M1366.47,451.86,1322,705.19c-.47,3.48-2.47,5.21-6,5.21h-33.17c-3.52,0-5.48-1.73-6.07-5.21l-13.14-93.87h-.42L1250,705.19c-.5,3.48-2.51,5.21-6.07,5.21h-33.14c-3.5,0-5.51-1.73-6-5.21l-44.53-253.33c-.46-3.52,1.06-5.29,4.57-5.29h40.72c3.3,0,5.16,1.77,5.64,5.29l17,153.78h1.54l12.79-153.78c.5-3.52,2.41-5.29,5.68-5.29h30.5c3.27,0,5.17,1.77,5.65,5.29l12.83,153.78h1.47l17-153.78c.54-3.52,2.39-5.29,5.7-5.29h40.66C1365.49,446.57,1367,448.34,1366.47,451.86Z"
      />
      <path
        fill="#1d1d1b"
        d="M812.53,782.53c-6.86,0-12.3-2.66-15.89-6.78l4.76-3A15.46,15.46,0,0,0,813,778.05c4.64,0,9.09-3.27,9.09-8,0-10.6-23.92-7-23.92-21.31,0-6.85,5.13-11.75,13.9-11.75,5.26,0,10.88,2.42,13.85,5.63l-4.89,2.72a13.64,13.64,0,0,0-9.2-3.57c-3.71,0-8.16,2.06-8.16,6,0,10.73,23.92,6.79,23.92,21.69C827.61,777.32,820.68,782.53,812.53,782.53Z"
      />
      <path
        fill="#1d1d1b"
        d="M838.36,737.59h5.13l23.67,35.13V737.59h5.13v44.33h-5.13l-23.67-35.06v35.06h-5.13Z"
      />
      <path
        fill="#1d1d1b"
        d="M901.1,781.92H886.83V737.59h13.71c9.89,0,14.47,4.42,14.47,11.51a9.43,9.43,0,0,1-6.93,9c5.26,1.76,8.78,5,8.78,11.2C916.86,776.53,911.11,781.92,901.1,781.92ZM900.54,742h-8.21v14.54h7.59c6.31,0,9.65-3,9.65-7.45C909.57,744.8,906.48,742,900.54,742Zm.87,19h-9.08V777.5h9c5.87,0,10-3.21,10-8.24S906.54,761,901.41,761Z"
      />
      <path
        fill="#1d1d1b"
        d="M960.18,781.92H945.91V737.59h13.71c9.89,0,14.47,4.42,14.47,11.51a9.43,9.43,0,0,1-6.93,9c5.26,1.76,8.78,5,8.78,11.2C975.94,776.53,970.19,781.92,960.18,781.92ZM959.62,742h-8.21v14.54H959c6.3,0,9.64-3,9.64-7.45C968.65,744.8,965.56,742,959.62,742Zm.87,19h-9.08V777.5h9c5.87,0,10-3.21,10-8.24S965.62,761,960.49,761Z"
      />
      <path fill="#1d1d1b" d="M993.34,781.92h-5.5V737.59h5.5Z" />
      <path
        fill="#1d1d1b"
        d="M1008,737.59h5.13l23.67,35.13V737.59h5.13v44.33h-5.13l-23.67-35.06v35.06H1008Z"
      />
      <path
        fill="#1d1d1b"
        d="M1069.49,781.92h-13V737.59h13c13,0,23.85,9,23.85,22.17C1093.34,772,1083,781.92,1069.49,781.92Zm-.06-39.91H1062V777.5h7.48c8.4,0,18.41-5.75,18.41-17.74C1087.84,748.07,1077.83,742,1069.43,742Z"
      />
      <path fill="#1d1d1b" d="M1111,781.92h-5.5V737.59h5.5Z" />
      <path
        fill="#1d1d1b"
        d="M1125.59,737.59h5.13l23.67,35.13V737.59h5.12v44.33h-5.12l-23.67-35.06v35.06h-5.13Z"
      />
      <path
        fill="#1d1d1b"
        d="M1193.27,737c6.86,0,12.79,3.21,15.33,6.17l-4.58,2.25a20.33,20.33,0,0,0-10.75-3.64c-10.07,0-16.19,8.18-16.19,18.05s5.37,18.17,15.45,18.17a19.76,19.76,0,0,0,11.43-3.76V764h-9v-4.42h14.21v22.34h-5v-3.33a20.1,20.1,0,0,1-12.24,3.94c-12.92,0-20.33-10.18-20.33-22.83S1180.35,737,1193.27,737Z"
      />
      <path
        fill="#1d1d1b"
        d="M1272.37,743.16l-4.57,2.25a16.35,16.35,0,0,0-10.13-3.64c-9.46,0-15.58,8.18-15.58,18.05s6.12,17.93,15.58,17.93a18.35,18.35,0,0,0,10.69-3.64l4.57,2.24c-2.54,3-8.53,6.18-15.39,6.18-12.91,0-21-9.93-21-22.71s8.16-22.83,21.08-22.83C1264.53,737,1269.84,740.2,1272.37,743.16Z"
      />
      <path
        fill="#1d1d1b"
        d="M1300.08,782.53c-12.92,0-21.7-10.06-21.7-22.71s8.78-22.83,21.7-22.83,21.75,10.11,21.75,22.83S1313.05,782.53,1300.08,782.53Zm0-41.12c-10.08,0-16.2,8.54-16.2,18.41s6.12,18.28,16.2,18.28,16.25-8.41,16.25-18.28S1310.15,741.41,1300.08,741.41Z"
      />
      <path fill="#1d1d1b" d="M1333.39,774.59a4,4,0,1,1-4,4A4,4,0,0,1,1333.39,774.59Z" />
    </svg>
  );
};

export default IconFlowLogo;
