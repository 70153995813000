import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './FieldFileUpload.module.css';

const FieldFileUpload = props => {
  const {
    accept,
    label,
    disabled,
    multiple,
    name,
    id,
    rootClassName,
    className,
    labelClassName,
    fileInputRef,
  } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    rootClassName,
    className,
  ]);

  return (
    <Field
      id={id}
      name={name}
      accept={accept}
      label={label}
      disabled={disabled}
      multiple={multiple}
      type="file"
    >
      {fieldProps => {
        const { accept, input, label, disabled: fieldDisabled } = fieldProps;
        const { name, type, multiple } = input;
        const onChange = e => {
          const { files } = e.target;
          input.onChange(files);
          props.onChange();
        };

        const inputProps = { accept, id: name, name, onChange, type, multiple, ref: fileInputRef };

        return (
          <div className={classes}>
            {fieldDisabled ? null : <input {...inputProps} className={css.input} />}
            <label
              htmlFor={name}
              tabIndex="0"
              className={classNames(css.label, labelClassName)}
              onKeyUp={e => {
                if (e.key === 'Enter') e.target.click();
              }}
            >
              {label}
              <span className={css.plus}>+</span>
            </label>
          </div>
        );
      }}
    </Field>
  );
};

FieldFileUpload.defaultProps = {
  id: null,
  labelClassName: null,
  className: null,
  rootClassName: null,
  accept: null,
  label: null,
  disabled: false,
  multiple: false,
};

FieldFileUpload.propTypes = {
  name: string.isRequired,
  className: string,
  rootClassName: string,
  id: string,
  labelClassName: string,
  accept: string,
  label: string,
  disabled: bool,
  multiple: bool,
};

export default FieldFileUpload;
