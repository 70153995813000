import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import {
  Form,
  FieldSelect,
  FieldFileUpload,
  SecondaryButton,
  IconTrashBin,
  ExternalLink,
} from '../../components';
import { required } from '../../util/validators';

import css from './AddActivityForm.module.css';

const AddActivityForm = props => {
  const {
    intl,
    abilityOptions,
    activityOptions,
    getActivityDisciplines,
    rootClassName,
    className,
    updateError,
    onClearFormErrors,
    ...restOfProps
  } = props;

  const fileInputRef = useRef();
  const [submittedValues, setSubmittedValues] = useState({});

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    rootClassName,
    className,
  ]);

  const messages = useMemo(
    () => ({
      activityPlaceholder: intl.formatMessage({
        id: 'General.selectActivity',
      }),
      disciplinePlaceholder: intl.formatMessage({
        id: 'General.selectDiscipline',
      }),
      abilityPlaceholder: intl.formatMessage({
        id: 'General.selectAbility',
      }),
      addCertificate: intl.formatMessage({
        id: 'AddActivityForm.addCertificate',
      }),
      requiredField: intl.formatMessage({
        id: 'General.requiredField',
      }),
    }),
    [intl]
  );

  const displayedCertificate = useCallback((certificate, formApi) => {
    if (!certificate) return null;

    const { name, url } = certificate;

    const className = classNames(css.uploadedFile, css.whiteBg);

    const content = (
      <>
        {name}
        <button
          type="button"
          onClick={e => {
            // If the parent element is a link,
            // we don't want to click it
            e.stopPropagation();
            e.preventDefault();
            formApi.change('certificate', null);
            // Allow a user to select the same file once again
            if (fileInputRef.current) {
              fileInputRef.current.value = null;
            }
          }}
          className={css.removeItem}
        >
          <IconTrashBin />
        </button>
      </>
    );

    return url ? (
      <ExternalLink className={className} href={url}>
        {content}
      </ExternalLink>
    ) : (
      <div className={className}>{content}</div>
    );
  }, []);

  return (
    <FinalForm
      {...restOfProps}
      render={fieldRenderProps => {
        const { updateInProgress, handleSubmit, form, invalid, values } = fieldRenderProps;

        const submitInProgress = updateInProgress;
        const submittedOnce = Object.keys(submittedValues).length > 0;
        const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);
        const submitDisabled = invalid || submitInProgress || pristineSinceLastSubmit;

        const certificate = displayedCertificate(values.certificate?.[0], form);

        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedValues(values);
              handleSubmit(e);
            }}
          >
            <FieldSelect
              onChange={() => form.change('discipline', undefined)}
              className={css.formMargins}
              selectClassName={css.whiteBg}
              name="activity"
              id="activity"
              validate={required(messages.requiredField)}
            >
              <option disabled value="">
                {messages.activityPlaceholder}
              </option>
              {activityOptions.map(a => (
                <option key={a.key} value={a.key}>
                  {a.label}
                </option>
              ))}
            </FieldSelect>

            <FieldSelect
              className={css.formMargins}
              selectClassName={css.whiteBg}
              name="discipline"
              id="discipline"
              validate={required(messages.requiredField)}
            >
              <option disabled value="">
                {messages.disciplinePlaceholder}
              </option>
              {values.activity &&
                getActivityDisciplines(values.activity).map(d => (
                  <option key={d.key} value={d.key}>
                    {intl.formatMessage({ id: d.label })}
                  </option>
                ))}
            </FieldSelect>

            <FieldSelect
              className={css.formMargins}
              selectClassName={css.whiteBg}
              name="ability"
              id="ability"
              validate={required(messages.requiredField)}
            >
              <option disabled value="">
                {messages.abilityPlaceholder}
              </option>
              {abilityOptions.map(a => (
                <option key={a.key} value={a.key}>
                  {intl.formatMessage({ id: a.label })}
                </option>
              ))}
            </FieldSelect>

            <span className={css.addCertificateInfo}>
              <FormattedMessage id="AddActivityForm.addCertificateInfo" />
            </span>

            {certificate}

            {!values.certificate && (
              <FieldFileUpload
                id="certificate"
                name="certificate"
                accept=".pdf"
                label={messages.addCertificate}
                labelClassName={css.whiteBg}
                className={css.formMargins}
                fileInputRef={fileInputRef}
                onChange={onClearFormErrors}
              />
            )}

            {updateError}

            <SecondaryButton
              type="submit"
              className={css.submitButton}
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={pristineSinceLastSubmit}
            >
              <FormattedMessage id="General.save" />
            </SecondaryButton>
          </Form>
        );
      }}
    />
  );
};

AddActivityForm.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

AddActivityForm.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

export default injectIntl(AddActivityForm);
