import httpClient from '../httpClient';

const updateSeenMessage = transactionId => {
  return httpClient.post(`transactions/${transactionId}/messages/seen`);
};

const fetchTransactions = params => {
  return httpClient.get('transactions', { params });
};

const transitionTransaction = (txId, transition) => {
  return httpClient.post(`transactions/${txId}/process-transition`, { transition });
};

const acceptTransaction = txId => {
  return httpClient.post(`transactions/${txId}/accept`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { fetchTransactions, transitionTransaction, acceptTransaction, updateSeenMessage };
