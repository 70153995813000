import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { object, string } from 'prop-types';
import { Field } from 'react-final-form';
import { filterNumber } from '../../util/sanitize';
import ValidationError from '../ValidationError/ValidationError';

import css from './FieldNumberInput.module.css';

const FieldNumberInputComponent = props => {
  const {
    className,
    rootClassName,
    inputRootClass,
    input,
    min,
    max,
    id,
    label,
    meta,
    onUnmount,
    hideValidationErrorMsg,
    ...rest
  } = props;

  const { valid, touched, error } = useMemo(() => meta, [meta]);

  const hasError = useMemo(() => !!(touched && error), [touched, error]);

  const inputClasses = useMemo(
    () =>
      inputRootClass ||
      classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
      }),
    [hasError, inputRootClass, valid]
  );

  const inputProps = useMemo(
    () => ({
      min,
      max,
      id,
      ...input,
      ...rest,
    }),
    [input, id, max, min, rest]
  );

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  useEffect(() => {
    return () => {
      onUnmount?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes}>
      {label && (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      )}
      <input type="number" {...inputProps} className={inputClasses} />
      {!hideValidationErrorMsg && <ValidationError fieldMeta={meta} />}
    </div>
  );
};

FieldNumberInputComponent.defaultProps = {
  className: null,
  rootClassName: null,
  placeholder: '0%',
  min: '0',
  max: '100',
  hideValidationErrorMsg: true,
};

FieldNumberInputComponent.propTypes = {
  className: string,
  rootClassName: string,
  label: string,
  placeholder: string,
  min: string,
  max: string,
  meta: object.isRequired,
};

const FieldNumberInput = props => {
  return (
    <Field
      component={FieldNumberInputComponent}
      type="number"
      {...props}
      onKeyPress={filterNumber}
    />
  );
};

export default FieldNumberInput;
