import React from 'react';

const IconCarvLogo = props => {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
      <path
        fill="#000"
        d="M458.31,419.26,421.45,450c-6.4-6-11.66-11.09-17.11-16-43.23-39-93.31-51.06-148-31.75-54.15,19.13-79.79,61.57-85.69,117.59-9.77,92.89,41.56,160.89,127.25,167.69,43.14,3.42,79.13-12.31,122.9-54.83l37.23,30.53c-40.91,63.16-129.89,90.88-211.46,67.56C164.6,707.33,114,637.31,113.11,546c-.93-96.48,47.19-169.26,128.24-194C323.47,327.05,414.54,354.43,458.31,419.26Z"
      />
      <path
        fill="#000"
        d="M1243.85,586.81c34.19,50.34,67.18,98.93,100.67,148.27-50.41,9.49-54,8-78.43-29.63-21.15-32.59-41.52-65.76-64.14-97.31-8.59-12-22.49-20.15-34-30.06l-6.8,5.33V532.65c18.16,0,36.49.76,54.73-.16,43.13-2.15,67.51-24.5,69-62.11,1.63-42-22.64-69.72-66.49-73.92-18.26-1.74-36.83-.29-57.13-.29V346c69.91-20.22,147.4,14.82,167.67,75.7C1354,496.87,1324.64,555.32,1243.85,586.81Z"
      />
      <path
        fill="#000"
        d="M1609.53,735.88C1637,660.19,1663.69,586.6,1690.45,513q24.65-67.71,49.49-135.35c15.06-41.14,18.83-43,67-31.79-5.31,15-10.44,29.89-15.82,44.67Q1735.27,543.83,1679.36,697C1662.77,742.54,1662.77,742.54,1609.53,735.88Z"
      />
      <path
        fill="#000"
        d="M776.34,345.35q-36.18,100.06-72.17,199.54C685.73,595.66,667,646.33,648.5,697.09c-16.81,46.11-16.75,46.13-69.83,38.6,7.47-20.74,14.68-41,22.05-61.21Q654.54,527,708.4,379.53C724.19,336.21,726.14,335.09,776.34,345.35Z"
      />
      <path fill="#000" d="M1056.66,343.17h50.69V736.8h-50.69Z" />
      <path
        fill="#000"
        d="M1458.36,346.08c48.22-11.34,52.73-9,67.44,31.4,21.86,60.11,42.67,120.65,66.28,180.08,10.9,27.43,14.8,52.5.18,79.24-5.18,9.47-7.84,20.31-14.17,37.28Z"
      />
      <path
        fill="#000"
        d="M928.84,738.58c-16.22,0-29.05,1.65-41.15-.62-7.3-1.37-17.17-7.44-19.56-13.78C839.54,648.34,808.81,573,786.41,495.32c-6.95-24.08,9.57-54.93,15.35-82.68l8.3.78C849.07,520.22,888.09,627,928.84,738.58Z"
      />
    </svg>
  );
};

export default IconCarvLogo;
