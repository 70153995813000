import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withViewport } from '../../util/uiHelpers';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  BottomNavigation,
  LimitedAccessBanner,
  ModalMissingInformation,
  TopbarContent,
} from '../../components';
import {
  clearNotRelevantDisciplines,
  parseSearchFormValuesToParams,
  parseSelectFilterOptions,
} from '../../util/search';
import appSettings from '../../config/settings';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useIntl } from 'react-intl';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';

import css from './Topbar.module.css';
import defaultLocations from '../../config/configDefaultLocationSearches';
import bus, { AuthEventTypes } from '../../modules/bus';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const checkIfScrollingIsDisabled = () => {
  if (typeof document === 'undefined') return false;

  const { overflow } = getComputedStyle(document.querySelector('#root > div:first-child')) || {};

  return overflow === 'hidden';
};

const getTopbarHeightVar = isMobileLayout => {
  if (typeof window === 'undefined') return 0;

  let stickyBreakpoint = 0;

  if (isMobileLayout)
    stickyBreakpoint = getComputedStyle(document.documentElement).getPropertyValue(
      '--topbarHeight'
    );
  else
    stickyBreakpoint = getComputedStyle(document.documentElement).getPropertyValue(
      '--topbarHeightDesktop'
    );

  return Number.parseInt(stickyBreakpoint, 10);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

const bindHeroFormToRef = heroFormRef => {
  const formEl = document?.querySelector('#SectionHeroFormContainer');

  if (!heroFormRef.current && formEl) heroFormRef.current = formEl;
  else if (formEl?.offsetHeight !== heroFormRef.current?.offsetHeight) heroFormRef.current = formEl;
};

const TopbarComponent = props => {
  const {
    className,
    rootClassName,
    isAuthenticated,
    isBottomNavHidden,
    authScopes,
    authInProgress,
    currentUser,
    currentUserHasListings,
    currentUserHasNonFreeListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    currentPage,
    notificationCount,
    messageNotificationCount,
    viewport,
    intl,
    location,
    onManageDisableScrolling,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    showGenericError,
    currentSearchParams,
    history,
    onLogout,
    routeConfiguration,
  } = props;

  const [isTopbarSticky, setIsTopbarSticky] = useState(false);
  const [isTopbarExpanded, setIsTopbarExpanded] = useState(false);

  const searchFormRef = useRef();
  const landingPageHeroForm = useRef();
  const whiteLayerRef = useRef();

  const { pub_activity, dates, address, bounds, origin, savedBounds, mapSearch } = useMemo(
    () =>
      parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      }),
    [location.search]
  );

  const selectedLocation = useMemo(
    () => ({ predictions: [], search: address, selectedPlace: { address, origin, bounds } }),
    [address, bounds, origin]
  );

  const isMobileLayout = useMemo(() => viewport.width < MAX_MOBILE_SCREEN_WIDTH, [viewport.width]);

  const isLandingPage = useMemo(() => currentPage === 'LandingPage', [currentPage]);
  const isSearchPage = useMemo(() => currentPage === 'SearchPage' && !isMobileLayout, [
    currentPage,
    isMobileLayout,
  ]);

  const stickyTopbarBreakpoint = useMemo(() => getTopbarHeightVar(isMobileLayout), [
    isMobileLayout,
  ]);

  const splitDates = useMemo(() => dates?.split(','), [dates]);
  const { startDate, endDate } = useMemo(
    () =>
      splitDates?.length > 1
        ? { startDate: new Date(splitDates[0].trim()), endDate: new Date(splitDates[1].trim()) }
        : {},
    [splitDates]
  );

  const hasInitialValues = !!(pub_activity || splitDates || address);

  const initialSearchFormValues = useMemo(
    () => ({
      activities: pub_activity ? parseSelectFilterOptions(pub_activity) : [],
      dates: splitDates ? { startDate, endDate } : null,
      location: origin || bounds ? selectedLocation : {},
    }),
    [pub_activity, splitDates, startDate, endDate, origin, bounds, selectedLocation]
  );

  const classes = useMemo(
    () =>
      classNames(rootClassName || css.root, className, {
        [css.stickyRoot]: isTopbarSticky,
        [css.landingPageRoot]: isLandingPage,
        [css.searchPageRoot]: isSearchPage,
      }),
    [className, isLandingPage, isSearchPage, isTopbarSticky, rootClassName]
  );

  const handleSubmit = useCallback(
    values => {
      const formValuesAsParams = parseSearchFormValuesToParams(values);
      const location = defaultLocations[0].predictionPlace;
      const searchParams = { ...location, ...currentSearchParams, ...formValuesAsParams };

      const hasAddressChanged = values?.location?.selectedPlace?.address !== address;
      const resetBoundsMaybe = !hasAddressChanged && mapSearch ? { bounds: savedBounds } : {};

      const cleanSearchParams = {
        ...searchParams,
        ...clearNotRelevantDisciplines(searchParams),
        ...resetBoundsMaybe,
      };

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration, {}, cleanSearchParams)
      );

      setIsTopbarExpanded(false);
    },
    [address, currentSearchParams, history, mapSearch, routeConfiguration, savedBounds]
  );

  const searchFormRefCallback = useCallback(node => (searchFormRef.current = node), []);

  const search = useMemo(
    () => (
      <TopbarSearchForm
        onSubmit={handleSubmit}
        initialValues={initialSearchFormValues}
        hasInitialValues={hasInitialValues}
        handleExpandTopbar={() => setIsTopbarExpanded(true)}
        isTopbarExpanded={isTopbarExpanded || isSearchPage}
        isMobileLayout={isMobileLayout}
        isTopbarSticky={isTopbarSticky}
        isLandingPage={isLandingPage}
        isSearchPage={isSearchPage}
        isInTopbar
        searchFormRef={searchFormRefCallback}
        onManageDisableScrolling={onManageDisableScrolling}
        formId="TopbarSearchForm"
      />
    ),
    [
      handleSubmit,
      initialSearchFormValues,
      hasInitialValues,
      isTopbarExpanded,
      isSearchPage,
      isMobileLayout,
      isTopbarSticky,
      isLandingPage,
      searchFormRefCallback,
      onManageDisableScrolling,
    ]
  );

  const handleLogout = useCallback(() => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }, [history, onLogout, routeConfiguration]);

  useEffect(() => {
    const resolveTopbarState = () => {
      const { body, documentElement } = document;

      // Because disabling scroll causes the page to scroll to the top
      // we need to check this
      if (checkIfScrollingIsDisabled()) return;

      let shouldMakeTopbarSticky =
        body.scrollTop > stickyTopbarBreakpoint ||
        documentElement.scrollTop > stickyTopbarBreakpoint;

      // If we are on the landing page, call this fn to find the
      // hero search form
      if (isLandingPage) bindHeroFormToRef(landingPageHeroForm);

      if (landingPageHeroForm.current) {
        const { top = 0 } = landingPageHeroForm.current.getBoundingClientRect() || {};

        shouldMakeTopbarSticky = top < 0;
      }

      if (shouldMakeTopbarSticky) {
        setIsTopbarSticky(true);
        // setIsTopbarExpanded(false);
      } else {
        setIsTopbarSticky(false);
        setIsTopbarExpanded(false);
      }
    };

    resolveTopbarState();

    window.addEventListener('scroll', resolveTopbarState, { passive: true });

    return () => window.removeEventListener('scroll', resolveTopbarState);
  }, [isLandingPage, stickyTopbarBreakpoint]);

  useEffect(() => {
    const handleClickOutsideTopbar = event => {
      // We don't want to close the topbar if we are clicking inside a modal (scrolling is disabled)
      if (checkIfScrollingIsDisabled()) return;

      const hasClickedInsideForm = searchFormRef.current?.contains(event.target);

      const { bottom = 0 } = whiteLayerRef.current?.getBoundingClientRect() || {};

      const hasClickedOutsideTopbar = event.clientY > bottom;

      if (!hasClickedInsideForm && !event.target.form && hasClickedOutsideTopbar)
        setIsTopbarExpanded(false);
    };

    window.addEventListener('click', handleClickOutsideTopbar, { passive: true, capture: true });

    return () => window.removeEventListener('click', handleClickOutsideTopbar, { capture: true });
  }, []);

  useEffect(() => {
    const openTopbar = () => {
      setIsTopbarExpanded(true);
      setIsTopbarSticky(true);
    };

    const remove = bus.addEventListener(AuthEventTypes.EXPAND_TOPBAR, openTopbar);

    return () => remove();
  }, []);

  return (
    <div className={classes}>
      <LimitedAccessBanner
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        currentUser={currentUser}
        onLogout={handleLogout}
        currentPage={currentPage}
      />
      <TopbarContent
        currentUserHasListings={currentUserHasListings}
        currentUserListing={currentUserListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        currentPage={currentPage}
        intl={intl}
        isAuthenticated={isAuthenticated}
        notificationCount={notificationCount}
        messageNotificationCount={messageNotificationCount}
        onLogout={handleLogout}
        search={search}
        isTopbarSticky={isTopbarSticky}
        isLandingPage={isLandingPage}
        isSearchPage={isSearchPage}
        authInProgress={authInProgress}
        location={location}
      />

      <ModalMissingInformation
        id="MissingInformationReminder"
        containerClassName={css.missingInformationModal}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasNonFreeListings={currentUserHasNonFreeListings}
        currentUserHasOrders={currentUserHasOrders}
        location={location}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
      />

      <GenericError show={showGenericError} />
      <div
        className={classNames(css.whiteLayer, {
          [css.expandedWhiteLayer]: isTopbarExpanded || isSearchPage,
          [css.searchPageExpandedWhiteLayer]: isSearchPage,
        })}
        ref={node => (whiteLayerRef.current = node)}
      />
      <BottomNavigation
        currentUserHasListings={currentUserHasListings}
        currentUserListing={currentUserListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        currentPage={currentPage}
        intl={intl}
        isAuthenticated={isAuthenticated}
        notificationCount={notificationCount}
        messageNotificationCount={messageNotificationCount}
        onLogout={handleLogout}
        search={search}
        authInProgress={authInProgress}
        isHidden={isBottomNavHidden}
        location={location}
      />
    </div>
  );
};

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  notificationCount: 0,
  messageNotificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  isBottomNavHidden: false,
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  isAuthenticated: bool.isRequired,
  isBottomNavHidden: bool,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  messageNotificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedTopbar = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  return (
    <TopbarComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  );
};

const Topbar = withViewport(EnhancedTopbar);
Topbar.displayName = 'Topbar';

export default Topbar;
