import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSavedListings } from '../../ducks/user.duck';
import { Button, IconHeart } from '..';

import css from './SaveListingButton.module.css';

const SaveListingButton = props => {
  const { className, rootClassName, listingId, children, isSmall } = props;

  const { currentUserSavedListingsInProgress, currentUser } = useSelector(state => state.user);

  const dispatch = useDispatch();

  const { savedListings = [] } = useMemo(() => currentUser?.attributes.profile?.privateData ?? {}, [
    currentUser?.attributes.profile?.privateData,
  ]);

  const isListingSaved = useMemo(() => savedListings.includes(listingId), [
    listingId,
    savedListings,
  ]);

  const changeSavedListings = useCallback(
    savedListings => dispatch(currentUserSavedListings(savedListings)),
    [dispatch]
  );

  const onClickHandler = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (isListingSaved) {
        const newSavedListings = savedListings.filter(listing => listing !== listingId);
        changeSavedListings(newSavedListings);
      } else changeSavedListings([...savedListings, listingId]);
    },
    [changeSavedListings, listingId, savedListings, isListingSaved]
  );

  const classes = useMemo(
    () => classNames(rootClassName || css.root, { [css.smallButton]: isSmall }, className),
    [className, rootClassName, isSmall]
  );

  return (
    <>
      <Button
        className={classes}
        onClick={onClickHandler}
        disabled={currentUserSavedListingsInProgress}
      >
        {children}
        <IconHeart
          className={classNames(
            css.icon,
            { [css.fillSelected]: isListingSaved },
            { [css.smallIcon]: isSmall }
          )}
        />
      </Button>
    </>
  );
};

SaveListingButton.defaultProps = {
  isSmall: false,
};

export default SaveListingButton;
