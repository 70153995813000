import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconQuestionMark.module.css';

const IconQuestionMark = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path d="M13.5 24h3v-3h-3ZM15 0a15 15 0 1 0 15 15A15.005 15.005 0 0 0 15 0Zm0 27a12 12 0 1 1 12-12 12.016 12.016 0 0 1-12 12Zm0-21a6 6 0 0 0-6 6h3a3 3 0 0 1 6 0c0 3-4.5 2.625-4.5 7.5h3c0-3.375 4.5-3.75 4.5-7.5a6 6 0 0 0-6-6Z" />
    </svg>
  );
};

IconQuestionMark.defaultProps = {
  rootClassName: null,
  className: null,
};

IconQuestionMark.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconQuestionMark;
