import React from 'react';
import { shape, string } from 'prop-types';
import { NamedLink } from '..';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { FormattedMessage } from 'react-intl';

import css from './PlaceCard.module.css';

const PlaceImage = props => {
  const { alt, ...rest } = props;
  return <img alt={alt} {...rest} />;
};

const LazyImage = lazyLoadWithDimensions(PlaceImage);

const PlaceCard = props => {
  const {
    rootClassName,
    className,
    place: { key, srcImg, queryParams },
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);

  return (
    <NamedLink name="SearchPage" to={{ search: queryParams }} className={classes}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage
            src={srcImg || '/static/imgs/laaxPlace.png'}
            alt={key}
            className={css.locationImage}
          />
        </div>
      </div>
      <div className={css.placeNameWrapper}>
        <span className={css.placeName}>
          <FormattedMessage id={`Place.${key}`} />
        </span>
      </div>
    </NamedLink>
  );
};

PlaceCard.defaultProps = {
  rootClassName: null,
  className: null,
};

PlaceCard.propTypes = {
  rootClassName: string,
  className: string,

  place: shape({
    name: string,
    key: string,
    srcImg: string,
  }),
};

export default PlaceCard;
