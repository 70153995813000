import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconDescription.module.css';

const IconDescription = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path d="M17.75,2h-11A2.674,2.674,0,0,0,4.014,4.6L4,25.4A2.674,2.674,0,0,0,6.736,28H23.25A2.686,2.686,0,0,0,26,25.4V9.8ZM20.5,22.8H9.5V20.2h11Zm0-5.2H9.5V15h11Zm-4.125-6.5V3.95l7.563,7.15Z" />
    </svg>
  );
};

IconDescription.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconDescription.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconDescription;
