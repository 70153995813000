import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';

const INBOX_PAGE_SIZE = 10;

// ================ Action types ================ //

export const FETCH_MESSAGES_REQUEST = 'app/MessagesPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/MessagesPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/MessagesPage/FETCH_MESSAGES_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchMessagesError: null,
  pagination: null,
  transactions: [],
};

export default function messagesPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        transactions:
          payload.pagination.page > 1
            ? [...state.transactions, ...payload.transactions]
            : payload.transactions,
        pagination: payload.pagination,
      };
    }
    case FETCH_MESSAGES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchMessagesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (transactions, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { transactions, pagination },
});
const fetchMessagesError = e => ({
  type: FETCH_MESSAGES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchTransactionsWithMessages = params => (dispatch, getState, sdk) => {
  dispatch(fetchMessagesRequest());
  const { page = 1 } = params || {};

  const queryParams = {
    include: [
      'messages',
      'provider',
      'customer',
      'listing',
      'provider.profileImage',
      'customer.profileImage',
    ],
    'limit.messages': 1,
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(queryParams)
    .then(response => {
      const transactions = denormalisedResponseEntities(response);
      const pagination = response.data.meta;
      dispatch(fetchMessagesSuccess(transactions, pagination));
      return response;
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const loadData = () => {
  return fetchTransactionsWithMessages();
};
