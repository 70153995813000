import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEvent.module.css';

const IconEvent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path d="M21.667,15.3H15v6.5h6.667ZM20.333,1V3.6H9.667V1H7V3.6H5.667A2.622,2.622,0,0,0,3.013,6.2L3,24.4A2.633,2.633,0,0,0,5.667,27H24.333A2.642,2.642,0,0,0,27,24.4V6.2a2.642,2.642,0,0,0-2.667-2.6H23V1Zm4,23.4H5.667V10.1H24.333Z" />
    </svg>
  );
};

IconEvent.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconEvent.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconEvent;
