/**
 * MenuContent is a immediate child of Menu component sibling to MenuLabel.
 * Clicking MenuLabel toggles visibility of MenuContent.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MenuItem } from '../../components';

import css from './MenuContent.module.css';

const MenuContent = props => {
  const { children, className, contentClassName, contentRef, isOpen, rootClassName, style } = props;

  const rootClass = rootClassName || css.root;
  const openClasses = isOpen ? css.isOpen : css.isClosed;
  const classes = classNames(rootClass, className, openClasses);
  const contentClasses = classNames(contentClassName || css.content);

  React.Children.forEach(children, child => {
    if (!child || child.type === React.Fragment) return;
    if (child.type !== MenuItem) {
      throw new Error('All children of MenuContent must be MenuItems.');
    }
    if (child.key == null) {
      throw new Error('All children of MenuContent must have a "key" prop.');
    }
  });

  return (
    <div className={classes} ref={contentRef} style={style}>
      <ul className={contentClasses}>{children}</ul>
    </div>
  );
};

MenuContent.defaultProps = {
  className: null,
  contentClassName: null,
  contentRef: null,
  isOpen: false,
  rootClassName: '',
  style: null,
};

const { bool, func, node, object, string } = PropTypes;

MenuContent.propTypes = {
  children: node.isRequired,
  className: string,
  contentClassName: string,
  contentRef: func,
  isOpen: bool,
  rootClassName: string,
  style: object,
};

export default MenuContent;
