import { LISTING_STATE_PUBLISHED } from '../../util/types';
import api from '../../api';
import { parseListingToSharetribe } from '../../util/sharetribeParser';

// ================ Action types ================ //

export const QUERY_POPULAR_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_POPULAR_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_POPULAR_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

export const QUERY_ACTIVITIES_COUNT_REQUEST = 'app/LandingPage/QUERY_ACTIVITIES_COUNT_REQUEST';
export const QUERY_ACTIVITIES_COUNT_SUCCESS = 'app/LandingPage/QUERY_ACTIVITIES_COUNT_SUCCESS';
export const QUERY_ACTIVITIES_COUNT_ERROR = 'app/LandingPage/QUERY_ACTIVITIES_COUNT_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryPopularListingsInProgress: false,
  queryPopularListingsError: null,
  popularListings: [],

  queryActivitiesCountInProgress: false,
  queryActivitiesCountError: null,
  activitiesCount: [],
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case QUERY_POPULAR_LISTINGS_REQUEST:
      return {
        ...state,
        queryPopularListingsInProgress: true,
        queryPopularListingsError: null,
      };
    case QUERY_POPULAR_LISTINGS_SUCCESS:
      return {
        ...state,
        queryPopularListingsInProgress: false,
        popularListings: payload.listings,
      };
    case QUERY_POPULAR_LISTINGS_ERROR:
      return {
        ...state,
        queryPopularListingsInProgress: false,
        queryPopularListingsError: payload,
      };
    case QUERY_ACTIVITIES_COUNT_REQUEST:
      return {
        ...state,
        queryActivitiesCountInProgress: true,
        queryActivitiesCountError: null,
      };
    case QUERY_ACTIVITIES_COUNT_SUCCESS:
      return {
        ...state,
        queryActivitiesCountInProgress: false,
        activitiesCount: payload.activitiesCount,
      };
    case QUERY_ACTIVITIES_COUNT_ERROR:
      return {
        ...state,
        queryActivitiesCountInProgress: false,
        queryActivitiesCountError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryPopularListingsRequest = () => ({
  type: QUERY_POPULAR_LISTINGS_REQUEST,
});

export const queryPopularListingsSuccess = listings => ({
  type: QUERY_POPULAR_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryPopularListingsError = e => ({
  type: QUERY_POPULAR_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryActivitiesCountRequest = () => ({
  type: QUERY_ACTIVITIES_COUNT_REQUEST,
});

export const queryActivitiesCountSuccess = activitiesCount => ({
  type: QUERY_ACTIVITIES_COUNT_SUCCESS,
  payload: { activitiesCount },
});

export const queryActivitiesCountError = e => ({
  type: QUERY_ACTIVITIES_COUNT_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryPopularListings = () => (dispatch, getState, sdk) => {
  dispatch(queryPopularListingsRequest());

  const { partner } = getState().ui;

  const publishedListings = { 'attributes.state': { $eq: LISTING_STATE_PUBLISHED } };
  const mostRecent = { $sort: '-attributes.createdAt' };

  const newParams = {
    ...publishedListings,
    ...mostRecent,
  };

  return api.listings
    .fetchListings(newParams, partner?.key)
    .then(res => {
      const { items } = res.data;
      const listings = items.map(listing => parseListingToSharetribe(listing, listing.author));

      dispatch(queryPopularListingsSuccess(listings));
    })
    .catch(e => {
      dispatch(queryPopularListingsError(e));
      throw e;
    });
};

export const queryActivitiesCount = () => (dispatch, getState, sdk) => {
  dispatch(queryActivitiesCountRequest());

  return api.listings
    .fetchActivitiesCount()
    .then(res => {
      const { data } = res;

      const activitiesCount = data.map(activity => ({
        activity: activity._id,
        count: activity.count,
      }));

      dispatch(queryActivitiesCountSuccess(activitiesCount));
    })
    .catch(e => {
      dispatch(queryActivitiesCountError(e));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(queryPopularListings()), dispatch(queryActivitiesCount())]);
};
