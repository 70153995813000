import { pick } from 'lodash';
import api from '../../api';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/PromotionsPage/SET_INITIAL_VALUES';

export const ADD_PROMO_CODES_REQUEST = 'app/PromotionsPage/ADD_PROMO_CODES_REQUEST';
export const ADD_PROMO_CODES_SUCCESS = 'app/PromotionsPage/ADD_PROMO_CODES_SUCCESS';
export const ADD_PROMO_CODES_ERROR = 'app/PromotionsPage/ADD_PROMO_CODES_ERROR';

// ================ Reducer ================ //

const initialState = {
  addPromoCodesInProgress: false,
  addPromoCodesReady: false,
  addPromoCodesError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case ADD_PROMO_CODES_REQUEST:
      return {
        ...state,
        addPromoCodesInProgress: true,
        addPromoCodesReady: false,
        addPromoCodesError: null,
      };
    case ADD_PROMO_CODES_SUCCESS:
      return { ...state, addPromoCodesInProgress: false, addPromoCodesReady: true };
    case ADD_PROMO_CODES_ERROR:
      return {
        ...state,
        addPromoCodesInProgress: false,
        addPromoCodesReady: false,
        addPromoCodesError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const addPromoCodesRequest = () => ({
  type: ADD_PROMO_CODES_REQUEST,
});
export const addPromoCodesSuccess = () => ({
  type: ADD_PROMO_CODES_SUCCESS,
});
export const addPromoCodesError = e => ({
  type: ADD_PROMO_CODES_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const requestPromoCodes = codes => dispatch => {
  dispatch(addPromoCodesRequest());

  return api.users
    .addPromoCodes(codes)
    .then(() => dispatch(addPromoCodesSuccess()))
    .catch(e => dispatch(addPromoCodesError(e)));
};

export const loadData = () => dispatch => {
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser());
};
