import React, { useMemo } from 'react';
import { UserType, ReviewRating, AvatarMedium } from '../../components';
import { ensureUser } from '../../util/data';

import css from './AuthorInfo.module.css';

const AuthorInfo = props => {
  const { author } = props;

  const ensuredAuthor = useMemo(() => ensureUser(author), [author]);

  const { averageRating, ratingCount = 0 } = ensuredAuthor.attributes.profile.metadata || {};

  return (
    <div className={css.authorInfoContainer}>
      <div className={css.authorInfo}>
        <UserType user={author} className={css.userType} showFullText />
        {averageRating > 0 ? (
          <ReviewRating rating={averageRating} ratingCount={ratingCount} />
        ) : (
          <div />
        )}
      </div>
      <AvatarMedium user={author} />
    </div>
  );
};

export default AuthorInfo;
