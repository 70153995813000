import React, { useMemo } from 'react';
import { node, oneOf, string } from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const { id, className, triggerClassName, trigger, children, ...restOfProps } = props;

  const classes = useMemo(() => classNames(css.root, className), [className]);

  const tooltipProps = useMemo(
    () => ({
      id,
      className: classes,
      ...restOfProps,
    }),
    [classes, id, restOfProps]
  );

  const tooltipTrigger = useMemo(
    () => (
      <span data-tip data-for={id} className={classNames(css.trigger, triggerClassName)}>
        ?
      </span>
    ),
    [id, triggerClassName]
  );

  return (
    <>
      {trigger || tooltipTrigger}
      <ReactTooltip {...tooltipProps}>{children}</ReactTooltip>
    </>
  );
};

Tooltip.propTypes = {
  className: string,
  triggerClassName: string,
  id: string.isRequired,
  trigger: node,
  place: oneOf(['top', 'bottom', 'left', 'right']),
  type: oneOf(['dark', 'success', 'warning', 'error', 'info', 'light']),
  effect: oneOf(['float', 'solid']),
};

export default Tooltip;
