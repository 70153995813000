export const isSSR = () => typeof window === 'undefined';

export const isIOS = () => {
  if (isSSR()) {
    return false;
  }
  // https://stackoverflow.com/a/29696509
  const ua = window.navigator.userAgent;
  return !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
};

export const isMobileSafari = () => {
  if (isSSR()) {
    return false;
  }

  // https://stackoverflow.com/a/29696509
  const ua = window.navigator.userAgent;
  const iOS = isIOS();
  const webkit = !!ua.match(/WebKit/i);

  // If iOS Chrome needs to be separated, use `!ua.match(/CriOS/i)` as
  // an extra condition.
  return iOS && webkit;
};

export const isInStandaloneMode = () => {
  if (isSSR()) return false;

  return 'standalone' in window.navigator && window.navigator.standalone;
};

export const isSamsungInternet = () => {
  if (isSSR()) return false;

  return window.navigator.userAgent.match(
    /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
  );
};

export const isFirefox = () => {
  if (isSSR()) return false;

  return (
    navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ||
    navigator.userAgent.toLowerCase().indexOf('fennec') > -1
  );
};
