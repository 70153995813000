import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, IconEmail, IconFacebook, IconLink, IconShare, IconWhatsApp, Modal } from '..';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import css from './ShareButton.module.css';
import { intlShape } from '../../util/reactIntl';

const ShareButton = props => {
  const { className, rootClassName, intl, children } = props;
  const dispatch = useDispatch();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const shareUrl = useRef(typeof window !== 'undefined' ? window.location.href : null);

  const classes = useMemo(
    () => classNames(rootClassName || css.root, { [css.smallButton]: !children }, className),
    [className, rootClassName, children]
  );

  const handleOpenShareModal = useCallback(() => setIsShareModalOpen(true), []);

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const emailMessages = {
    subject: intl.formatMessage({
      id: 'ShareButton.subject',
    }),
    body: intl.formatMessage({
      id: 'ShareButton.linkToActivityListing',
    }),
  };

  return (
    <>
      <Button className={classes} onClick={handleOpenShareModal}>
        {children}
        <IconShare className={classNames(css.icon, { [css.smallIcon]: !children })} />
      </Button>

      <Modal
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        id="ShareButton"
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div>
          <h2>
            <FormattedMessage id="ShareButton.shareActivityTitle" />
          </h2>
          <ul className={css.shareItems}>
            <li>
              <FacebookShareButton url={shareUrl.current} className={css.shareButton}>
                <IconFacebook />
                <FormattedMessage id="General.facebook" />
              </FacebookShareButton>
            </li>
            <li>
              <WhatsappShareButton url={shareUrl.current} className={css.shareButton}>
                <IconWhatsApp />
                <FormattedMessage id="General.whatsApp" />
              </WhatsappShareButton>
            </li>
            <li>
              <EmailShareButton
                subject={emailMessages.subject}
                body={emailMessages.body}
                url={shareUrl.current}
                className={css.shareButton}
              >
                <IconEmail />
                <FormattedMessage id="General.email" />
              </EmailShareButton>
            </li>
            <li
              className={css.copyLink}
              onClick={() => {
                navigator.clipboard.writeText(shareUrl.current);
              }}
            >
              <IconLink />
              <FormattedMessage id="General.copyLink" />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

ShareButton.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ShareButton);
