import pick from 'lodash/pick';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { resetToStartOfDay } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import api from '../../api';
import { LISTING_STATE_PUBLISHED } from '../../util/types';
import { getLocationQuery, locationBounds } from '../../util/maps';
import { fetchSessions } from '../../ducks/Bookings.duck';
import { parseListingToSharetribe } from '../../util/sharetribeParser';
import defaultConfig from '../../config/configDefault';
import { dayCountAvailableForBooking } from '../../config/configStripe';
import { transitions } from '../../transactions/transactionProcessBooking';
import { getPartnerDataForEntites } from '../../util/partner';
import { set } from 'lodash';

const { UUID } = sdkTypes;

const REVIEWS_PAGE_SIZE = 10;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_LISTINGS_IN_REGION_REQUEST = 'app/ListingPage/FETCH_LISTINGS_IN_REGION_REQUEST';
export const FETCH_LISTINGS_IN_REGION_SUCCESS = 'app/ListingPage/FETCH_LISTINGS_IN_REGION_SUCCESS';
export const FETCH_LISTINGS_IN_REGION_ERROR = 'app/ListingPage/FETCH_LISTINGS_IN_REGION_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  reviewsMeta: {},
  fetchReviewsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,

  listingsInRegion: [],
  fetchListingsInRegionError: null,
  fetchListingsInRegionInProgress: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: payload.concat ? [...state.reviews, ...payload.reviews] : payload.reviews,
        reviewsMeta: payload.reviewsMeta,
      };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_LISTINGS_IN_REGION_REQUEST:
      return { ...state, fetchListingsInRegionInProgress: true, fetchListingsInRegionError: null };
    case FETCH_LISTINGS_IN_REGION_SUCCESS:
      return { ...state, fetchListingsInRegionInProgress: false, listingsInRegion: payload };
    case FETCH_LISTINGS_IN_REGION_ERROR:
      return {
        ...state,
        fetchListingsInRegionInProgress: false,
        fetchListingsInRegionError: payload,
      };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const fetchListingsInRegionRequest = () => ({ type: FETCH_LISTINGS_IN_REGION_REQUEST });
export const fetchListingsInRegionSuccess = listings => ({
  type: FETCH_LISTINGS_IN_REGION_SUCCESS,
  payload: listings,
});
export const fetchListingsInRegionError = error => ({
  type: FETCH_LISTINGS_IN_REGION_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = (userId, fetchParam = {}, concat = true) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchReviewsRequest());

  return sdk.reviews
    .query({
      subject_id: userId,
      type: 'ofProvider',
      perPage: REVIEWS_PAGE_SIZE,
      ...fetchParam,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      const reviewsMeta = response.data.meta;

      dispatch(fetchReviewsSuccess({ reviews, reviewsMeta, concat }));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());

  let bodyParams = {
    transition: transitions.ENQUIRE,
    processAlias: defaultConfig.bookingProcessAlias,
    params: { listingId },
  };

  const partnerFromState = getState().ui.partner;
  if (partnerFromState) {
    const partner = getPartnerDataForEntites(partnerFromState);

    bodyParams = set(bodyParams, 'params.protectedData.partner', partner);
  }

  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const firstMonth = new Date();

    const thirdMonth = resetToStartOfDay(new Date(), tz, dayCountAvailableForBooking);

    return dispatch(fetchSessions(listing.id, firstMonth, thirdMonth));
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

export const fetchListingsInRegion = (geoParams, listingId) => (dispatch, getState, sdk) => {
  dispatch(fetchListingsInRegionRequest());

  const { partner } = getState().ui;

  const bounds = locationBounds(geoParams, 50000);

  const publishedListings = { 'attributes.state': { $eq: LISTING_STATE_PUBLISHED } };

  const geoLocationMaybe = getLocationQuery(bounds);

  const newParams = {
    ...publishedListings,
    ...geoLocationMaybe,
  };

  return api.listings
    .fetchListings(newParams, partner?.key)
    .then(res => {
      const { items } = res.data;
      const listings = items.map(listing => parseListingToSharetribe(listing, listing.author));

      // filter shown listing from listings in region
      const filterListings = listings.filter(listing => listing.id.uuid !== listingId);

      dispatch(fetchListingsInRegionSuccess(filterListings));
    })
    .catch(e => {
      dispatch(fetchListingsInRegionError(e));
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState) => {
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return dispatch(showListing(listingId)).then(result => {
    if (result?.data?.data) {
      const listing = result.data.data;

      const { id, attributes } = listing;
      const { lat, lng } = attributes.geolocation;

      dispatch(fetchListingsInRegion({ lat, lng }, id.uuid));

      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);

      const listings = getMarketplaceEntities(getState(), [{ id, type: 'listing' }]);
      if (listings && listings.length === 1 && listings[0].author) {
        dispatch(fetchReviews(listings[0].author.id.uuid, null, false));
      }
    }
    return result;
  });
};
