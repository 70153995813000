import React, { useCallback, useMemo, useState } from 'react';
import { Menu, MenuContent, MenuLabel, MenuItem } from '..';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import confirm from '../../modules/confirm';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { injectIntl } from '../../util/reactIntl';
import { CURRENCY_SYMBOL, CURRENCY_SYMBOLS } from '../../config/settingsCurrency';

import css from './CurrencySelect.module.css';

const SUPPORTED_CURRENCIES = [CURRENCY_SYMBOL.CHF];

const CurrencySelect = props => {
  const { className, rootClassName, placeBottom, embedded, intl } = props;

  const dispatch = useDispatch();

  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_SYMBOL.CHF);

  const classes = useMemo(
    () => ({
      menu: classNames(rootClassName || css.root, { [css.rootBottomNav]: placeBottom }, className),
      menuLabel: classNames(css.menuLabel, { [css.menuLabelBottomNav]: placeBottom }),
      contentLabel: classNames(css.contentLabel, { [css.contentLabelBottomNav]: placeBottom }),
      menuContent: classNames(css.menuContent, { [css.menuContentBottomNav]: placeBottom }),
      menuItemBord: classNames(css.menuItemBorder, { [css.menuItemBorderBottomNav]: placeBottom }),
      menuItemText: classNames(css.menuItemText, { [css.menuItemTextBottomNav]: placeBottom }),
    }),
    [className, placeBottom, rootClassName]
  );

  const menuContentStyle = useMemo(() => (placeBottom ? { width: '100vw', left: '0' } : {}), [
    placeBottom,
  ]);

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const handleSelectCurrency = useCallback(
    async currency => {
      if (!SUPPORTED_CURRENCIES.includes(currency)) {
        await confirm({
          title: intl.formatMessage({ id: 'CurrencySelect.curencyUnsupportedModalTitle' }),
          messages: [intl.formatMessage({ id: 'CurrencySelect.curencyUnsupportedModalMessage' })],
          showCloseButton: false,
          onManageDisableScrolling,
          contentClassName: css.modalContent,
        });
        setSelectedCurrency(SUPPORTED_CURRENCIES[0]);
      }
    },
    [intl, onManageDisableScrolling]
  );

  if (embedded) {
    return (
      <div className={css.rootEmbedded}>
        {CURRENCY_SYMBOLS.map(currency => {
          const isActive = CURRENCY_SYMBOL[currency] === selectedCurrency;
          const menuItemText = `${currency} - ${CURRENCY_SYMBOL[currency]}`;

          return (
            <div
              key={currency}
              className={classNames(css.embeddedItem, { [css.active]: isActive })}
              onClick={() => handleSelectCurrency(CURRENCY_SYMBOL[currency])}
            >
              <span className={classes.menuItemBord} />
              <span className={classes.menuItemText}>{menuItemText}</span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <Menu className={classes.menu}>
      <MenuLabel className={classes.menuLabel}>
        <span className={classes.contentLabel}>{selectedCurrency}</span>
      </MenuLabel>
      <MenuContent className={classes.menuContent} style={menuContentStyle}>
        {CURRENCY_SYMBOLS.map(currency => {
          const isActive = CURRENCY_SYMBOL[currency] === selectedCurrency;
          const menuItemText = `${currency} - ${CURRENCY_SYMBOL[currency]}`;

          return (
            <MenuItem key={currency}>
              <div
                className={classNames(css.menuItem, {
                  [css.active]: isActive,
                  [css.menuItemBottomNav]: placeBottom,
                })}
                onClick={() => handleSelectCurrency(CURRENCY_SYMBOL[currency])}
              >
                <span className={classes.menuItemBord} />
                <span className={classes.menuItemText}>{menuItemText}</span>
              </div>
            </MenuItem>
          );
        })}
      </MenuContent>
    </Menu>
  );
};

CurrencySelect.defaultProps = {
  className: null,
  rootClassName: '',

  placeBottom: false,
  embedded: false,
};

CurrencySelect.propTypes = {
  className: string,
  rootClassName: string,

  placeBottom: bool,
  embedded: bool,
};

export default injectIntl(CurrencySelect);
