import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedIn.module.css';

const IconSocialMediaLinkedIn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} height="17" viewBox="0 0 635 540">
      <g transform="translate(0, 540) scale(0.1, -0.1)">
        <path
          d="M278 5376 c-105 -38 -201 -127 -240 -224 l-23 -57 0 -2375 0 -2375
   23 -57 c31 -76 105 -156 184 -199 l63 -34 2400 0 2400 0 62 34 c77 41 146 113
   181 189 l27 57 0 2385 0 2385 -27 57 c-35 76 -109 153 -183 192 l-60 31 -2385
   2 c-1945 2 -2392 0 -2422 -11z m1051 -735 c84 -26 137 -59 202 -128 63 -66 89
   -111 114 -197 23 -77 16 -199 -14 -278 -96 -249 -387 -368 -624 -255 -294 139
   -358 521 -126 747 119 115 295 159 448 111z m2538 -1207 c45 -8 120 -28 167
   -45 285 -103 437 -316 509 -714 22 -117 22 -139 22 -980 l0 -860 -395 0 -395
   0 -6 780 c-5 710 -7 785 -23 840 -28 93 -66 162 -113 206 -65 60 -121 81 -235
   87 -223 11 -360 -58 -438 -223 -64 -137 -64 -135 -70 -950 l-5 -740 -395 0
   -395 0 -3 1278 -2 1277 385 0 385 0 0 -177 0 -178 27 40 c135 202 357 342 590
   374 84 12 280 4 390 -15z m-2269 -1321 l-3 -1278 -395 0 -395 0 -3 1278 -2
   1277 400 0 400 0 -2 -1277z"
        />
      </g>
    </svg>
  );
};

IconSocialMediaLinkedIn.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedIn.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedIn;
