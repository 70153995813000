import httpClient from '../httpClient';

const checkLocationForPartner = (locationLng, locationLat, partnerKey) => {
  const headers = partnerKey ? { headers: { 'x-partner-key': partnerKey } } : {};

  return httpClient.get('partners/check-coordinates', {
    params: {
      coordinates: [locationLng, locationLat],
    },
    ...headers,
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { checkLocationForPartner };
