import React from 'react';

const IconHellyHansenLogo = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 377.94666 296.45334" {...props}>
      <path d="M377.9533 296.4533H0V0h377.9533v296.4533" fill="#db1f26" />
      <path
        d="M298.7813 213.9893h6.5254l8.4853 15.0443h.0627v-15.0443h5.984v24.4146h-6.532l-8.4787-15.2156h-.068v15.2156h-5.9787v-24.4146M83.4792 213.9893h19.453v5.0947H89.8386v4.2761h11.9662v4.9239H89.8385v4.6453h13.4375v5.4746H83.4792v-24.4146M257.5787 230.197c.14 2.8683 1.9106 3.7949 4.6146 3.7949 1.9107 0 3.8947-.6823 3.8947-2.4916 0-2.159-3.484-2.5683-7.0093-3.5574-3.4854-.9948-7.1414-2.5656-7.1414-7.0442 0-5.336 5.3654-7.4172 9.948-7.4172 4.8547 0 9.74 2.3573 9.7814 7.8598h-6.364c.104-2.2186-1.9854-2.9401-3.9334-2.9401-1.3693 0-3.0773.4787-3.0773 2.0907 0 1.8776 3.5253 2.2213 7.0773 3.2109 3.5267.9907 7.0734 2.6333 7.0734 7.0453 0 6.1839-5.2547 8.164-10.6254 8.164-5.604 0-10.5626-2.4536-10.604-8.715h6.3654M227.2133 213.9893h6.532l8.4734 15.0443h.0733v-15.0443h5.984v24.4146h-6.5307l-8.48-15.2156h-.068v15.2156h-5.984v-24.4146M210.2547 229.3064h5.5426l-2.704-8.5437h-.0666zm-.4054-15.317h6.4534l9.1293 24.4145h-6.6293l-1.5054-4.3771h-8.5106l-1.5414 4.377h-6.5266l9.1306-24.4145M177.8147 213.9893h6.3533v8.8932h8.3733v-8.8932h6.36v24.4146h-6.36v-10.0522h-8.3733v10.0522h-6.3533v-24.4146M156.6893 229.3064v9.0975h-6.3613v-9.0975l-8.8907-15.317h7.2134l4.856 9.917 4.8546-9.917h7.2187l-8.8907 15.317M106.901 213.9893h6.3594v18.94h11.3855v5.4746H106.901v-24.4146M127.7917 213.9893h6.359v18.94h11.3853v5.4746h-17.7443v-24.4146M275.4213 213.9893H294.88v5.0947h-13.0933v4.2761h11.968v4.9239h-11.968v4.6453h13.4373v5.4746h-19.8027v-24.4146M58.0755 213.9893h6.3568v8.8932h8.3776v-8.8932h6.3594v24.4146H72.81v-10.0522h-8.3776v10.0522h-6.3568v-24.4146M347.0107 71.7027c7.5826 0 13.724-6.14 13.724-13.728 0-7.592-6.1414-13.7334-13.724-13.7334-7.584 0-13.74 6.1414-13.74 13.7334 0 7.588 6.156 13.728 13.74 13.728zM336.432 58.024c0-5.9253 4.808-10.7293 10.7453-10.7293 5.9107 0 10.7134 4.804 10.7134 10.7293 0 5.924-4.8027 10.732-10.7134 10.732-5.9373 0-10.7453-4.808-10.7453-10.732"
        fill="#fff"
      />
      <path
        d="M345.2507 57.628h2.452c1.1466 0 1.9853-.6827 1.9853-1.8027 0-1.1013-.7507-1.8093-2.3187-1.8093h-2.1186zm-2.2614-5.6013h5c2.5467 0 4.0414 1.5226 4.0414 3.6346 0 2.6827-1.796 3.3187-2.3747 3.5294l2.6773 4.8386h-2.604l-2.3226-4.42h-2.156v4.42h-2.2614V52.0267M211.0213 58.0387h17.88v48.6546h44.4427V58.0387h46.4533v139.0625h-46.62v-47.7025h-44.4266v47.7025h-44.712l26.9826-139.0625M166.8773 197.1012H148.984v-47.7025h-44.437v47.7025H58.0936V58.0387h46.612v48.6546H149.14V58.0387H194l-27.1227 139.0625"
        fill="#fff"
      />
    </svg>
  );
};

export default IconHellyHansenLogo;
