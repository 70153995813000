import React, { useCallback } from 'react';
import { func, string, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { SecondaryButton } from '../../components';

import css from './LoadMore.module.css';

export const LoadMore = props => {
  const { className, rootClassName, pagination, fetchParams, onClickLoadMore, ...rest } = props;
  const { page, totalPages, totalItems = 0 } = pagination || {};

  const classes = classNames(rootClassName || css.root, className, {
    [css.noDisplay]: page >= totalPages || totalItems === 0,
  });

  const handleClick = useCallback(() => {
    onClickLoadMore({ page: page + 1, ...fetchParams });
  }, [onClickLoadMore, page, fetchParams]);

  return (
    <SecondaryButton type="button" onClick={handleClick} className={classes} {...rest}>
      <FormattedMessage id="General.loadMore" />
    </SecondaryButton>
  );
};

LoadMore.defaultProps = {
  className: '',
  rootClassName: '',
  fetchParams: {},
  pagination: {},
};

LoadMore.propTypes = {
  className: string,
  rootClassName: string,
  onClickLoadMore: func.isRequired,
  fetchParams: object,
  pagination: object,
};

export default LoadMore;
