import defaultConfig from '../config/configDefault';
import { CUSTOM_DEFAULT_LOCATION } from '../config/configDefaultLocationSearches';
import PartnerService from '../services/PartnerService';
import { stringifyDateToISO8601 } from './dates';
import { BOUNDS_BY_PARTNER_KEY } from './partner';

/**
 * SelectMultipleFilter needs to parse values from format
 * "has_all:a,b,c,d" or "a,b,c,d"
 */
export const parseSelectFilterOptions = uriComponentValue => {
  const startsWithHasAll = uriComponentValue && uriComponentValue.indexOf('has_all:') === 0;
  const startsWithHasAny = uriComponentValue && uriComponentValue.indexOf('has_any:') === 0;

  if (startsWithHasAll) {
    return uriComponentValue.substring(8).split(',');
  } else if (startsWithHasAny) {
    return uriComponentValue.substring(8).split(',');
  } else {
    return uriComponentValue.split(',');
  }
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const formatQueryParam = (selectedOptions, queryParamName, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

/**
 * Check if any of the filters (defined by filterIds) have currently active query parameter in URL.
 */
export const isAnyFilterActive = (filterIds, urlQueryParams, filterConfigs) => {
  const getQueryParamKeysOfGivenFilters = (keys, config) => {
    const isFilterIncluded = filterIds.includes(config.id);
    const addedQueryParamNamesMaybe = isFilterIncluded ? config.queryParamNames : [];
    return [...keys, ...addedQueryParamNamesMaybe];
  };
  const queryParamKeysOfGivenFilters = filterConfigs.reduce(getQueryParamKeysOfGivenFilters, []);

  const paramEntries = Object.entries(urlQueryParams);
  const activeKey = paramEntries.find(entry => {
    const [key, value] = entry;
    return queryParamKeysOfGivenFilters.includes(key) && value != null;
  });
  return !!activeKey;
};

/**
 * Check if the filter is currently active.
 */
export const findOptionsForSelectFilter = (filterId, filters, { seasonality, type } = {}) => {
  const filter = filters.find(f => f.id === filterId);

  const options = filter && filter.config && filter.config.options ? filter.config.options : [];

  if (!seasonality && !type) {
    return options;
  }

  let filteredOptions = options;

  if (seasonality) {
    filteredOptions = filteredOptions.filter(o => o.seasonalityTags.includes(seasonality));
  }
  if (type) {
    filteredOptions = filteredOptions.filter(o => o.typeTags.includes(type));
  }

  return filteredOptions;
};

export const parseSearchFormValuesToParams = formValues => {
  const { activities = [], dates, location } = formValues;
  const { search, selectedPlace } = location || {};
  const { origin, bounds } = selectedPlace || {};

  const { key: partnerKey } = PartnerService.partner || {};

  const defaultBoundsMaybe =
    partnerKey && BOUNDS_BY_PARTNER_KEY[partnerKey]
      ? { bounds: BOUNDS_BY_PARTNER_KEY[partnerKey] }
      : CUSTOM_DEFAULT_LOCATION;

  const locationSearchParam = selectedPlace
    ? { address: search, origin, bounds }
    : {
        address: null,
        origin: null,
        ...defaultBoundsMaybe,
      };

  const activityFilter = defaultConfig.search.filters.find(f => f.id === 'activity');
  if (!activityFilter) {
    return {};
  }
  const activitiesSearchParam = formatQueryParam(
    activities,
    activityFilter.queryParamNames[0],
    activityFilter.config.searchMode
  );
  const datesParam =
    dates?.startDate && dates?.endDate
      ? {
          dates: `${stringifyDateToISO8601(dates.startDate)}, ${stringifyDateToISO8601(
            dates.endDate
          )}`,
        }
      : {
          dates: null,
        };

  const searchParams = {
    ...activitiesSearchParam,
    ...locationSearchParam,
    ...datesParam,
  };

  return searchParams;
};

export const clearNotRelevantDisciplines = queryParams => {
  const { pub_activity, pub_discipline } = queryParams;

  if (pub_activity || pub_discipline) {
    const selectedActivities = pub_activity ? pub_activity.split(':')[1]?.split(',') || [] : [];
    const selectedDisciplines = pub_discipline
      ? pub_discipline.split(':')[1]?.split(',') || []
      : [];

    const activities = defaultConfig.search.filters.filter(f =>
      selectedActivities.some(a => a === f.id)
    );
    const disciplineFilter = defaultConfig.search.filters.find(f => f.id === 'discipline');
    if (!disciplineFilter) {
      return {};
    }

    const relevantDisciplines = [];
    activities.forEach(a =>
      a.config.options.forEach(o =>
        selectedDisciplines.includes(o.key) ? relevantDisciplines.push(o.key) : null
      )
    );

    const disciplinesMaybe = formatQueryParam(
      relevantDisciplines,
      disciplineFilter.queryParamNames[0],
      disciplineFilter.config.searchMode
    );

    return disciplinesMaybe;
  }

  return {};
};
