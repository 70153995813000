import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Carousel, PlaceCard } from '..';

import css from './SectionExplore.module.css';

const placeTestData = [
  {
    key: 'CH',
    queryParams: 'address=Switzerland&bounds=49.29258977%2C10.492061%2C44.23333213%2C5.955907',
    srcImg: '/static/imgs/switzerland.jpg',
  },
  {
    key: 'IT',
    queryParams: 'address=Italy&bounds=47.13112772%2C18.5851686%2C36.59392289%2C6.53235167',
    srcImg: '/static/imgs/italy.jpg',
  },
  {
    key: 'AU',
    queryParams: 'address=Austria&bounds=49.06919995%2C17.19310416%2C46.35948885%2C9.40021601',
    srcImg: '/static/imgs/austria.jpg',
  },
  {
    key: 'FR',
    queryParams: 'address=France&bounds=51.19843811%2C8.35716924%2C42.31812829%2C-4.85644732',
    srcImg: '/static/imgs/france.jpg',
  },
  {
    key: 'DE',
    queryParams: 'address=Germany&bounds=54.96959806%2C15.1137958%2C47.24735257%2C5.77397666',
    srcImg: '/static/imgs/germany.jpg',
  },
  {
    key: 'ES',
    queryParams: 'address=Spain&bounds=43.81269472%2C3.34937864%2C36.17762728%2C-9.51581627',
    srcImg: '/static/imgs/spain.jpg',
  },
  {
    key: 'US',
    queryParams:
      'address=UnitedStates&bounds=70.88975892%2C-66.53182947%2C25.56488452%2C-165.88477669',
    srcImg: '/static/imgs/unitedstates.jpg',
  },
  {
    key: 'CA',
    queryParams: 'address=Canada&bounds=69.60156542%2C-52.25218461%2C41.86212791%2C-141.26243535',
    srcImg: '/static/imgs/canada.jpg',
  },
  {
    key: 'NO',
    queryParams: 'address=Norway&bounds=71.21230018%2C31.14882738%2C57.84269227%2C4.61621339',
    srcImg: '/static/imgs/norway.jpg',
  },
  {
    key: 'FI',
    queryParams: 'address=Finland&bounds=70.13577324%2C31.71566014%2C59.40076113%2C16.84782349',
    srcImg: '/static/imgs/finland.jpg',
  },
  {
    key: 'SW',
    queryParams: 'address=Sweden&bounds=69.11845617%2C24.31092739%2C55.28446809%2C11.06798401',
    srcImg: '/static/imgs/sweden.jpg',
  },
  {
    key: 'IC',
    queryParams: 'address=Iceland&bounds=66.55759448%2C-13.42483294%2C63.36683262%2C-24.57469578',
    srcImg: '/static/imgs/iceland.jpg',
  },
];

const SectionExplore = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionExplore.titlePlace" />
        </h2>
        <Carousel className={css.carousel} contentClassName={css.carouselContent} columns={6}>
          {placeTestData.map(place => (
            <PlaceCard key={place.key} place={place} className={css.placeCard} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

SectionExplore.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionExplore.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionExplore;
