import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getFirstLocationPart } from '../../util/maps';
import ExternalLink from '../ExternalLink/ExternalLink';

import css from './OrderBreakdown.module.css';

const BookingDetails = props => {
  const { booking, listing, savedListingPublicData } = props;
  const { seats } = booking?.attributes || {};
  const { publicData } = listing.attributes;
  const {
    activity: listingActivity,
    discipline: listingDiscipline,
    type: listingType,
    groupType: listingGroupType,
    languages: listingLanguages,
    ability: listingAbility,
    meetingLocation: listingMeetingLocation,
    meetingLocationOrigin: listingMeetingLocationOrigin,
    city: listingCity,
  } = publicData || {};

  // The saved listing data is needed because we want
  // to show a 'snapshot' of the listing data
  const {
    activity: savedActivity,
    discipline: savedDiscipline,
    type: savedType,
    groupType: savedGroupType,
    languages: savedLanguages,
    ability: savedAbility,
    meetingLocation: savedMeetingLocation,
    meetingLocationOrigin: savedMeetingLocationOrigin,
    city: savedCity,
  } = savedListingPublicData || {};

  const activity = savedActivity || listingActivity;
  const discipline = savedDiscipline || listingDiscipline;
  const type = savedType || listingType;
  const groupType = savedGroupType || listingGroupType;
  const languages = savedLanguages || listingLanguages;
  const ability = savedAbility || listingAbility;
  const meetingLocation = savedMeetingLocation || listingMeetingLocation;
  const meetingLocationOrigin = savedMeetingLocationOrigin || listingMeetingLocationOrigin;
  const meetingLocationGMapsLink =
    meetingLocationOrigin?.lat &&
    meetingLocationOrigin?.lng &&
    `https://www.google.com/maps/search/${meetingLocationOrigin.lat}+${meetingLocationOrigin.lng}`;

  const city = savedCity || listingCity;

  const detailsToShow = useMemo(
    () => [
      { labelTranslationKey: 'activity', valueTranslationKey: activity },
      { labelTranslationKey: 'discipline', valueTranslationKey: discipline },
      { labelTranslationKey: 'type', valueTranslationKey: type },
      { labelTranslationKey: 'session', valueTranslationKey: groupType },
      { labelTranslationKey: 'languages', multipleValueTranslationKeys: languages },
      { labelTranslationKey: 'ability', valueTranslationKey: ability },
      {
        labelTranslationKey: 'meetingPlace',
        rawValue: meetingLocationGMapsLink ? (
          <ExternalLink key="linkToTwitter" href={meetingLocationGMapsLink} title="Google Maps">
            {city || getFirstLocationPart(meetingLocation)}
          </ExternalLink>
        ) : (
          city || getFirstLocationPart(meetingLocation)
        ),
      },
      ...(seats ? [{ labelTranslationKey: 'people', rawValue: seats }] : []),
    ],
    [
      ability,
      activity,
      city,
      discipline,
      groupType,
      languages,
      meetingLocation,
      meetingLocationGMapsLink,
      seats,
      type,
    ]
  );

  const details = useMemo(
    () =>
      detailsToShow.map(detail => ({
        label: <FormattedMessage id={`General.${detail.labelTranslationKey}`} />,
        value:
          detail.rawValue ||
          detail.multipleValueTranslationKeys?.map((singleValue, i) => (
            <React.Fragment key={`${singleValue}.${i}`}>
              <FormattedMessage id={`General.${singleValue}`} />
              {i < detail.multipleValueTranslationKeys.length - 1 ? ', ' : ''}
            </React.Fragment>
          )) ||
          (detail.valueTranslationKey && (
            <FormattedMessage id={`General.${detail.valueTranslationKey}`} />
          )),
      })),
    [detailsToShow]
  );

  return (
    <section className={classNames({ [css.bookingBreakdownSection]: seats })}>
      <h3 className={css.breakdownTitle}>
        <FormattedMessage id="BookingBreakdown.bookingDetails" />
      </h3>
      {details.map((detail, i) => (
        <div className={css.lineItem} key={`${detail.key}.${i}`}>
          <span className={css.itemLabel}>{detail.label}</span>
          <span className={classNames(css.itemValue, css.itemGray)}>{detail.value}</span>
        </div>
      ))}
    </section>
  );
};

export default BookingDetails;
