import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconQuestionAnswer.module.css';

const IconQuestionAnswer = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path d="M26.7,7.2H24.1V18.9H7.2v2.6a1.3,1.3,0,0,0,1.3,1.3H22.8L28,28V8.5A1.3,1.3,0,0,0,26.7,7.2ZM21.5,15V3.3A1.3,1.3,0,0,0,20.2,2H3.3A1.3,1.3,0,0,0,2,3.3V21.5l5.2-5.2h13A1.3,1.3,0,0,0,21.5,15Z" />
    </svg>
  );
};

IconQuestionAnswer.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconQuestionAnswer.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconQuestionAnswer;
