import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconTicket.module.css';

const IconTicket = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(20285 11808)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(-20285 -11808)" />
        <path
          d="M4.444,7.833H15.556V14.5H4.444Zm13.889,3.333A1.667,1.667,0,0,0,20,12.833v3.333a1.667,1.667,0,0,1-1.667,1.667H1.667A1.667,1.667,0,0,1,0,16.167V12.833a1.667,1.667,0,0,0,1.667-1.667A1.667,1.667,0,0,0,0,9.5V6.167A1.667,1.667,0,0,1,1.667,4.5H18.333A1.667,1.667,0,0,1,20,6.167V9.5A1.667,1.667,0,0,0,18.333,11.167ZM16.667,7.556a.833.833,0,0,0-.833-.833H4.167a.833.833,0,0,0-.833.833v7.222a.833.833,0,0,0,.833.833H15.833a.833.833,0,0,0,.833-.833Z"
          transform="translate(-20277.5 -11801.666)"
        />
      </g>
    </svg>
  );
};

IconTicket.defaultProps = {
  rootClassName: null,
  className: null,
};

IconTicket.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconTicket;
