import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

export const PARTNER_APPROVAL_ACTION = {
  // User has joined the partner platform
  PENDING: 'pending',
  // Partner can approve or reject entites.
  APPROVED: 'approved',
  REJECTED: 'rejected',
  ALWAYS_ASK: 'always-ask',
  // Flex admin can restrict certain entites.
  RESTRICTED: 'restricted',
};

export const PARTNER_KEYS = {
  GENOVA: 'genova',
  HELLY_HANSEN: 'helly-hansen',
};

export const BOUNDS_BY_PARTNER_KEY = {
  [PARTNER_KEYS.GENOVA]: new LatLngBounds(
    new LatLng(44.676133, 10.07102),
    new LatLng(43.776034, 7.4965)
  ),
};

export const GOOGLE_FORM_BY_PARTNER_KEY = {
  [PARTNER_KEYS.GENOVA]:
    'https://docs.google.com/forms/d/e/1FAIpQLSdnSl74hDkkXfcBFxsUVP-3bGbRR7ncd4_xKfdfWXE14ay50Q/viewform',
};

export const getPartnerDataForEntites = partner => {
  if (!partner || typeof window === 'undefined') {
    return undefined;
  }

  const {
    key,
    name,
    branding: { logo, styles },
    contactDetails: { socialMediaLinks = [] },
  } = partner;

  const url = window.location.origin;

  const links = socialMediaLinks.reduce((partnerLinks, link) => {
    const { type, url } = link;

    switch (type) {
      case 'twitter':
        partnerLinks.twitter = url;
        break;
      case 'facebook':
        partnerLinks.facebook = url;
        break;
      case 'instagram':
        partnerLinks.instagram = url;
        break;
      case 'youtube':
        partnerLinks.youtube = url;
        break;

      default:
        break;
    }

    return partnerLinks;
  }, {});

  return {
    marketplace: {
      name,
      url,
      key,
    },
    logo,
    styles,
    socialMediaLinks: links,
  };
};

export const checkIfEntityForeignPartner = (partner, config) => {
  if (config.marketplaceName === 'Crion') return false;

  return !!partner && config.marketplaceName !== partner.marketplace?.name;
};

export const checkIfEntityPartnerApproved = (partnerApprovedMap, config) => {
  if (!partnerApprovedMap || config.marketplaceName === 'Crion') return true;

  return partnerApprovedMap[config.marketplaceKey] === PARTNER_APPROVAL_ACTION.APPROVED;
};

export const isProviderDisapproved = (provider, partnerKey) => {
  const { attributes } = provider || {};

  const { profile } = attributes || {};

  const { metadata } = profile || {};

  const { partnerApproved } = metadata || {};

  return (
    partnerApproved?.[partnerKey] === PARTNER_APPROVAL_ACTION.REJECTED ||
    partnerApproved?.[partnerKey] === PARTNER_APPROVAL_ACTION.RESTRICTED
  );
};
