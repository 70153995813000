import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';

import css from './ProListingUserInfo.module.css';

const ProListingUserInfo = props => {
  const { className, isWarning } = props;

  return (
    <div className={classNames(css.root, className)}>
      {isWarning && (
        <h2>
          <FormattedMessage id="ProListingUserInfo.requirementsNotFullfilled" />
        </h2>
      )}
      <FormattedMessage id="ProListingUserInfo.lessonOrTourNote" />
      <ul>
        <li>
          <FormattedMessage
            id="ProListingUserInfo.uploadCertificateInfo"
            values={{
              link: (
                <NamedLink
                  key="account-settings-profile"
                  name="ProfileSettingsPage"
                  target="_blank"
                >
                  <FormattedMessage id="ProListingUserInfo.accountSettingsProfile" />
                </NamedLink>
              ),
            }}
          />
        </li>
      </ul>

      {!isWarning && <FormattedMessage id="ProListingUserInfo.requirementsFullfilled" />}
    </div>
  );
};

ProListingUserInfo.propTypes = {
  className: string,
  isWarning: bool,
};

export default ProListingUserInfo;
