import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconWhatsApp.module.css';

const IconWhatsApp = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(-37 -203)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(37 203)" />
        <path d="M63.256 211.558a12.392 12.392 0 0 0-19.5 14.95L42 232.925l6.568-1.724a12.351 12.351 0 0 0 5.921 1.507h.006A12.508 12.508 0 0 0 67 220.319a12.437 12.437 0 0 0-3.744-8.761Zm-8.762 19.062a10.279 10.279 0 0 1-5.246-1.434l-.374-.223-3.9 1.021 1.038-3.8-.246-.391a10.317 10.317 0 1 1 19.135-5.474 10.412 10.412 0 0 1-10.407 10.301Zm5.647-7.712c-.307-.156-1.83-.9-2.115-1s-.491-.156-.7.156-.8 1-.982 1.217-.363.234-.67.078a8.426 8.426 0 0 1-4.21-3.684c-.318-.547.318-.508.91-1.691a.574.574 0 0 0-.028-.541c-.078-.156-.7-1.68-.954-2.3-.251-.6-.508-.519-.7-.53s-.385-.011-.592-.011a1.147 1.147 0 0 0-.826.385 3.478 3.478 0 0 0-1.083 2.584 6.064 6.064 0 0 0 1.261 3.2 13.832 13.832 0 0 0 5.29 4.676c1.964.848 2.734.921 3.717.776a3.171 3.171 0 0 0 2.087-1.473 2.589 2.589 0 0 0 .179-1.473c-.07-.14-.276-.218-.583-.369Z" />
      </g>
    </svg>
  );
};

IconWhatsApp.defaultProps = {
  rootClassName: null,
  className: null,
};

IconWhatsApp.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconWhatsApp;
