import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconAccount.module.css';

const IconAccount = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path
        d="M28,3A25,25,0,1,0,53,28,25.009,25.009,0,0,0,28,3Zm0,7.5A7.5,7.5,0,1,1,20.5,18,7.49,7.49,0,0,1,28,10.5ZM28,46a18,18,0,0,1-15-8.05c.075-4.975,10-7.7,15-7.7,4.975,0,14.925,2.725,15,7.7A18,18,0,0,1,28,46Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

IconAccount.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconAccount.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconAccount;
