import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewMountain.module.css';

const IconReviewMountain = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="23"
      height="18"
      viewBox="0 0 23 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.841,14.459l-9-14a1,1,0,0,0-1.682,0l-9,14A1,1,0,0,0,1,16H19a1,1,0,0,0,.841-1.541ZM10,2.849,12.668,7H10L8,9,6.81,7.811Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconReviewMountain.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewMountain.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewMountain;
