import React, { useMemo } from 'react';
import { bool } from 'prop-types';
import { Tooltip, BookedByPreview } from '../../components';

import css from './CalendarEvent.module.css';

const CalendarEvent = props => {
  const { title, showTitle, event } = props;

  const { resource } = useMemo(() => event || {}, [event]);

  const { id: tooltipId, bookings } = useMemo(() => resource || {}, [resource]);

  const tooltipTrigger = useMemo(
    () => (
      <div data-tip data-for={tooltipId} className={css.tooltipTrigger}>
        {showTitle ? title : null}
      </div>
    ),

    [showTitle, title, tooltipId]
  );

  const groupedBookingsByTx = useMemo(
    () =>
      bookings.reduce((mergedBookings, booking) => {
        const { transactionId } = booking;

        if (!mergedBookings.some(b => b.transactionId === transactionId)) {
          mergedBookings.push(booking);
        }

        return mergedBookings;
      }, []),
    [bookings]
  );

  return (
    <div className="rbc-event-content">
      <Tooltip
        id={tooltipId}
        trigger={tooltipTrigger}
        effect="solid"
        type="light"
        className={css.tooltip}
        clickable
        delayHide={100}
      >
        <BookedByPreview bookings={groupedBookingsByTx} className={css.bookingsPreview} />
      </Tooltip>
    </div>
  );
};

CalendarEvent.propTypes = {
  showTitle: bool,
};

export default CalendarEvent;
