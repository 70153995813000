import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconGroupPeople.module.css';

const IconGroupPeople = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="35" height="35">
      <g transform="translate(20285 11808)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(-20285 -11808)" />
        <path d="M-20263.364-11791.408a2.727 2.727 0 1 0-2.727-2.727 2.716 2.716 0 0 0 2.727 2.727Zm-7.273 0a2.727 2.727 0 1 0-2.727-2.727 2.716 2.716 0 0 0 2.728 2.727Zm0 1.818c-2.118 0-6.364 1.064-6.364 3.182v2.273h12.728v-2.273c0-2.119-4.245-3.182-6.363-3.182Zm7.273 0c-.264 0-.564.018-.882.045a3.836 3.836 0 0 1 1.791 3.136v2.273h5.455v-2.272c0-2.119-4.245-3.182-6.364-3.182Z" />
      </g>
    </svg>
  );
};

IconGroupPeople.defaultProps = {
  rootClassName: null,
  className: null,
};

IconGroupPeople.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconGroupPeople;
