import React, { useMemo } from 'react';
import { ACTIVITY } from '../../constants/listingAttributes';
import {
  IconSkiing,
  IconSnowboarding,
  IconHiking,
  IconBiking,
  IconClimbing,
  IconSledging,
  IconRunning,
  IconAirSports,
  IconCourtSports,
  IconHorseRiding,
  IconLakeAndSeaSports,
  IconRiverSports,
  IconOtherExperiences,
} from '../../components';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { useConfiguration } from '../../context/configurationContext';

import css from './Activity.module.css';

const Activity = props => {
  const {
    activity,
    className,
    iconClassName,
    hasIconBackground,
    iconWrapperClassName,
    hideLabel,
  } = props;

  const config = useConfiguration();

  const activityLabel = useMemo(
    () =>
      findOptionsForSelectFilter('activity', config.search.defaultFilters).find(
        act => act.key === activity
      )?.label,
    [activity, config]
  );

  const iconWrapperClasses = useMemo(
    () => classNames(css.activityIconWrapper, iconWrapperClassName),
    [iconWrapperClassName]
  );
  const iconClasses = useMemo(() => classNames(css.activityIcon, iconClassName), [iconClassName]);

  const activityIcons = useMemo(
    () => ({
      [ACTIVITY.SKIING]: <IconSkiing className={iconClasses} />,
      [ACTIVITY.SNOWBOARDING]: <IconSnowboarding className={iconClasses} />,
      [ACTIVITY.HIKING]: <IconHiking className={iconClasses} />,
      [ACTIVITY.RUNNING]: <IconRunning className={iconClasses} />,
      [ACTIVITY.HORSE_RIDING]: <IconHorseRiding className={iconClasses} />,
      [ACTIVITY.BIKING]: <IconBiking className={iconClasses} />,
      [ACTIVITY.CLIMBING]: <IconClimbing className={iconClasses} />,
      [ACTIVITY.SLEDGING]: <IconSledging className={iconClasses} />,
      [ACTIVITY.RIVER_SPORTS]: <IconRiverSports className={iconClasses} />,
      [ACTIVITY.LAKE_AND_SEA_SPORTS]: <IconLakeAndSeaSports className={iconClasses} />,
      [ACTIVITY.AIR_SPORTS]: <IconAirSports className={iconClasses} />,
      [ACTIVITY.OTHER_EXPERIENCES]: <IconOtherExperiences className={iconClasses} />,
      [ACTIVITY.COURT_SPORTS]: <IconCourtSports className={iconClasses} />,
    }),
    [iconClasses]
  );

  const icon = useMemo(
    () =>
      hasIconBackground ? (
        <span className={iconWrapperClasses}>{activityIcons[activity]}</span>
      ) : (
        activityIcons[activity]
      ),
    [activity, activityIcons, hasIconBackground, iconWrapperClasses]
  );

  if (!activity || !activityLabel) return null;

  return (
    <div className={classNames(css.root, className)}>
      {icon}
      {!hideLabel && <FormattedMessage id={activityLabel} />}
    </div>
  );
};

Activity.propTypes = {
  className: string,
  iconWrapperClassName: string,
  iconClassName: string,
  activity: string.isRequired,
  hasIconBackground: bool,
  hideLabel: bool,
};

export default Activity;
