import React, { useCallback, useMemo, useRef } from 'react';
import { string, bool, func } from 'prop-types';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, PrimaryButton, ErrorMessage } from '../../../components';
import { propTypes } from '../../../util/types';
import { isEnterPressed } from '../../../util/eventHelpers';

import css from './SendMessageForm.module.css';

const BLUR_TIMEOUT_MS = 100;

const SendMessageFormComponent = props => {
  const {
    rootClassName,
    className,
    inProgress,
    sendMessageError,
    otherUserDisplayName,
    showSendMessageForm,
    onFocus,
    onBlur,
    intl,
    ...rest
  } = props;

  const blurTimeoutId = useRef();

  const messages = useMemo(
    () => ({
      sendFailed: intl.formatMessage({ id: 'SendMessageForm.sendFailed' }),
      sendMessage: intl.formatMessage({ id: 'SendMessageForm.sendMessage' }),
      messagePlaceholder: intl.formatMessage(
        { id: 'SendMessageForm.messagePlaceholder' },
        { name: otherUserDisplayName }
      ),
      sendingMessageNotAllowed: intl.formatMessage({
        id: 'SendMessageForm.sendingMessageNotAllowed',
      }),
    }),
    [otherUserDisplayName, intl]
  );

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  const handleFocus = useCallback(() => {
    onFocus();
    window.clearTimeout(blurTimeoutId.current);
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    blurTimeoutId.current = window.setTimeout(() => onBlur(), BLUR_TIMEOUT_MS);
  }, [onBlur]);

  const handleOnKeyDown = useCallback((e, formApi, values) => {
    if (e.shiftKey) return;
    else if (isEnterPressed(e)) {
      e.preventDefault();
      formApi.submit(values);
    }
  }, []);

  return showSendMessageForm ? (
    <FinalForm
      {...rest}
      render={({ handleSubmit, form, formId, values, invalid }) => (
        <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
          <FieldTextInput
            type="textarea"
            id={formId ? `${formId}.message` : 'message'}
            name="message"
            placeholder={messages.messagePlaceholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={e => handleOnKeyDown(e, form, values)}
          />
          <div className={css.submitContainer}>
            {sendMessageError && <ErrorMessage errorMessage={messages.sendFailed} />}
            <PrimaryButton
              className={css.submitButton}
              inProgress={inProgress}
              disabled={invalid || inProgress}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              {messages.sendMessage}
            </PrimaryButton>
          </div>
        </Form>
      )}
    />
  ) : (
    <h5 className={css.sendingMessageNotAllowed}>{messages.sendingMessageNotAllowed}</h5>
  );
};

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  otherUserDisplayName: null,
  showSendMessageForm: true,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  otherUserDisplayName: string,
  showSendMessageForm: bool,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = injectIntl(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
