import React, { useMemo } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { Carousel, PrimaryButton, ProjectPartnerCard } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionPartners.module.css';

import IconLuganoregionLogo from './logos/IconLuganoregionLogo';
import IconGiorgioRoccaLogo from './logos/IconGiorgioRoccaLogo';
import IconCarvLogo from './logos/IconCarvLogo';
import IconNiedeckerLogo from './logos/IconNiedeckerLogo';
import IconFlowLogo from './logos/IconFlowLogo';
import IconYoyotennisLogo from './logos/IconYoyotennisLogo';
import IconTokoLogo from './logos/IconTokoLogo';
import IconTicinoLogo from './logos/IconTicinoLogo';
import IconBellinzonaLogo from './logos/IconBellinzonaLogo';
import IconAsconaLogo from './logos/IconAsconaLogo';
import IconHellyHansenLogo from './logos/IconHellyHansenLogo';
import IconZagSkisLogo from './logos/IconZagSkisLogo';
import IconMilanoCortinaLogo from './logos/IconMilanoCortinaLogo';
import IconElanSkisLogo from './logos/IconElanSkisLogo';
import IconWEMountainLogo from './logos/IconWEMountainLogo';

const PARTNERS_TOP = [
  {
    key: 'hellyhansen.com',
    url: 'https://www.hellyhansen.com/',
    logo: IconHellyHansenLogo,
    logoClassName: css.height70,
  },
  {
    key: 'elanskis.com',
    url: 'https://elanskis.com/',
    logo: IconElanSkisLogo,
    logoClassName: classNames(css.carv),
  },
  {
    key: 'milanocortina2026.olympics.com',
    url: 'https://milanocortina2026.olympics.com/',
    logo: IconMilanoCortinaLogo,
    logoClassName: css.height80,
  },
  {
    key: 'ticino.ch',
    url: 'https://www.ticino.ch/',
    logo: IconTicinoLogo,
    logoClassName: css.carv,
  },
  {
    key: 'bellinzonaevalli.ch',
    url: 'https://www.bellinzonaevalli.ch/',
    logo: IconBellinzonaLogo,
    logoClassName: css.height80,
  },
  {
    key: 'ascona-locarno.com',
    url: 'https://www.ascona-locarno.com/',
    logo: IconAsconaLogo,
    logoClassName: css.height70,
  },
  { key: 'luganoregion.com', url: 'https://www.luganoregion.com/en', logo: IconLuganoregionLogo },
  { key: 'w3mountain.com', url: 'https://w3mountain.com', logo: IconWEMountainLogo },
  { key: 'giorgiorocca.com', url: 'https://www.giorgiorocca.com/en/', logo: IconGiorgioRoccaLogo },
];

const PARTNERS_BOTTOM = [
  {
    key: 'nidecker.com',
    url: 'https://www.nidecker.com/en/',
    logo: IconNiedeckerLogo,
    logoClassName: css.nidecker,
  },
  {
    key: 'zagskis.com',
    url: 'https://www.zagskis.com/',
    logo: IconZagSkisLogo,
    logoClassName: classNames(css.height70, css.fillBlack),
  },
  { key: 'toko.ch', url: 'https://www.toko.ch/en/', logo: IconTokoLogo },
  {
    key: 'getcarv.com',
    url: 'https://getcarv.com/',
    logo: IconCarvLogo,
    logoClassName: css.carv,
  },
  {
    key: 'flow-bindings.com',
    url: 'https://www.flow-bindings.com/en/',
    logo: IconFlowLogo,
    logoClassName: css.flow,
  },
  { key: 'yoyo-tennis.ch', url: 'https://www.yoyo-tennis.ch/', logo: IconYoyotennisLogo },
];

const SectionPartners = props => {
  const { rootClassName, className } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return (
    <div className={classes}>
      <div className={css.content}>
        <div>
          <h2 className={css.title}>
            <FormattedMessage id="SectionPartners.ourPartnersTitle" />
          </h2>
          <Carousel className={css.carousel} contentClassName={css.carouselContent} columns={6}>
            {[...PARTNERS_TOP, ...PARTNERS_BOTTOM].map(partner => (
              <ProjectPartnerCard key={partner.key} className={css.partnerCard} partner={partner} />
            ))}
          </Carousel>
        </div>
        <div className={css.buttonWrapper}>
          <PrimaryButton
            className={css.backToTop}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <FormattedMessage id="SectionPartners.backToTopButton" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

SectionPartners.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionPartners.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPartners;
