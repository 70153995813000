import { useEffect, useMemo } from 'react';
import PartnerService from '../services/PartnerService';

const propertyMaybe = (key, property) => (property ? { [key]: property } : {});

const usePartnerStyleLoader = appConfig => {
  const { branding } = appConfig;

  const { branding: partnerBranding } = PartnerService.partner || {};

  const { styles: partnerStyles } = partnerBranding || {};

  const { borderRadius, borderRadiusSecondary } = partnerStyles || {};

  const styles = useMemo(
    () => ({
      '--marketplaceColor': branding.marketplaceColor,
      '--marketplaceColorDark': branding.marketplaceColorDark,
      '--marketplaceColorLight': branding.marketplaceColorLight,
      ...propertyMaybe('--marketplaceColor25Lighter', branding.marketplaceColor25Lighter),
      ...propertyMaybe('--marketplaceColorBright', branding.marketplaceColorBright),
      ...propertyMaybe('--borderRadius', borderRadius),
      ...propertyMaybe('--borderRadius20', borderRadiusSecondary),
      ...propertyMaybe('--borderRadius30', borderRadius),
      ...propertyMaybe('--borderRadius32', borderRadius),
      ...propertyMaybe('--borderRadiusMedium', borderRadiusSecondary),
      ...propertyMaybe('--marketplaceFilterBorderRadius', borderRadiusSecondary),
    }),
    [borderRadius, borderRadiusSecondary, branding]
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const elem = window.document.documentElement;

    Object.entries(styles).forEach(([cssVar, value]) => {
      elem.style.setProperty(cssVar, value);
    });
  }, [styles]);

  return styles;
};

export default usePartnerStyleLoader;
