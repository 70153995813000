import React from 'react';

const IconZagSkisLogo = props => {
  return (
    <svg width="103" height="52" viewBox="0 0 103.475 52.479" fill="black" {...props}>
      <g>
        <path d="M13.263 49.236v-.018a3.206 3.206 0 0 1 3.289-3.262 3.254 3.254 0 0 1 2.487.974l-.883 1.018a2.328 2.328 0 0 0-1.613-.712 1.869 1.869 0 0 0-1.828 1.964v.018a1.873 1.873 0 0 0 1.828 1.982 2.278 2.278 0 0 0 1.658-.739l.883.892a3.225 3.225 0 0 1-2.586 1.126 3.183 3.183 0 0 1-3.235-3.243" />
        <path d="M23.794 46.064h1.388v2.5h2.559v-2.5h1.384v6.307h-1.388v-2.532h-2.559v2.532H23.79z" />
        <path d="M36.406 46.019h1.279l2.7 6.351h-1.451l-.577-1.415H35.69l-.577 1.415h-1.415zm1.46 3.712-.838-2.045-.838 2.045z" />
        <path d="M44.963 46.064h1.5l1.658 2.667 1.657-2.667h1.5v6.307H49.9v-4.117l-1.775 2.694h-.036l-1.757-2.667v4.091h-1.36z" />
        <path d="M56.107 49.236v-.018a3.353 3.353 0 0 1 6.7-.018v.018a3.353 3.353 0 0 1-6.7.018m5.253 0v-.018a1.916 1.916 0 0 0-1.91-1.982 1.887 1.887 0 0 0-1.892 1.964v.018a1.916 1.916 0 0 0 1.91 1.982 1.886 1.886 0 0 0 1.892-1.964" />
        <path d="M67.647 46.064h1.28l2.955 3.884v-3.884h1.37v6.307h-1.18l-3.054-4.01v4.01h-1.37z" />
        <path d="M78.43 46.064h1.387v6.307H78.43z" />
        <path d="m90.468 46.064-2.109 3.082 2.2 3.225h-1.622l-1.415-2.162-1.423 2.162h-1.577l2.2-3.208-2.109-3.1h1.622l1.315 2.045 1.343-2.045z" />
        <path d="m50.291 15.801-2.608 7.02h2.534l1.332-3.565z" />
        <path d="m48.395 10.568-4.552 12.256h2.528l3.277-8.822z" />
        <path d="M84.112 21.132h5.316v2.371h-5.316z" />
        <path d="m17.556 29.986-1.693 2.371h17.62v-2.371z" />
        <path d="m20.135 26.382-1.7 2.371h15.044v-2.371z" />
        <path d="M99.647 4.472h.5v-1.76h.658v-.444h-1.817v.444h.659z" />
        <path d="m101.743 2.983.608.966h.043l.607-.966v1.489h.473v-2.2h-.515l-.58.93-.58-.93h-.529v2.2h.472z" />
        <path d="M35.127 35.958h2.527l10.743-28.92 6.216 17.017.012.034v-.005l4.342 11.874h2.432L48.27.374z" />
        <path d="M62.707 35.961h2.529L52.102.371h-2.523z" />
        <path d="M69.092 35.961 55.95.371h-2.53l13.136 35.59z" />
        <path d="m45.881 27.66-3.077 8.3h2.56l2.177-5.926h7.954l-.867-2.371z" />
        <path d="M5.47 7.564v2.374h13.19L0 35.958h2.932L23.251 7.564z" />
        <path d="M5.47 3.962v2.373h20.177L4.456 35.955h2.912l22.895-32z" />
        <path d="M37.255.357H5.47v2.371h27.185L8.877 35.958h24.6v-2.376h-20z" />
        <path d="m54.187 26.423-.867-2.369h-9.934l-4.42 11.9h2.526l3.539-9.529z" />
        <path d="M72.34 31.406a18.4 18.4 0 0 0 12.187 4.865h.1c9.043-.013 13.773-4.826 15.383-7.332l.232-.371V17.53l-2.379-.005V27.75a15.819 15.819 0 0 1-12.711 6.156c-.206.007-.345 0-.55 0a15.113 15.113 0 0 1-15.47-15.387v-.1c0-4.733 1.291-8.671 4.449-11.75a15.752 15.752 0 0 1 10.976-4.294 16.511 16.511 0 0 1 10.818 3.66c.082.056.212.158.3.224l1.549-1.7a26.48 26.48 0 0 0-.352-.288A18.689 18.689 0 0 0 84.557.007a17.53 17.53 0 0 0-13.081 5.476c-3.26 3.345-4.71 7.85-4.71 13.029v.094a17.418 17.418 0 0 0 5.58 12.8" />
        <path d="M94.736 7.279a14.483 14.483 0 0 0-9.937-3.68 14.7 14.7 0 0 0-10.4 4.332 14.936 14.936 0 0 0-4.043 10.6v.094a14.906 14.906 0 0 0 5.307 10.874 14.461 14.461 0 0 0 8.784 3.184h.288c6.806-.2 9.651-3.049 11.658-5.195l.239-.271v-9.678h-2.375v8.846a14.137 14.137 0 0 1-3.617 2.844 12.128 12.128 0 0 1-5.888 1.075 11.821 11.821 0 0 1-12.023-11.71v-.086a12.432 12.432 0 0 1 3.49-9.181 11.794 11.794 0 0 1 8.533-3.353 12.411 12.411 0 0 1 8.464 2.973z" />
        <path d="M77.629 26.335a11.048 11.048 0 0 0 7.172 2.736 10.647 10.647 0 0 0 7.967-3.045l.26-.234V17.53H84.11v2.372h6.547v4.88a8.839 8.839 0 0 1-14.324-6.281v-.06c-.028-2.583.479-4.846 2.182-6.545a8.906 8.906 0 0 1 6.447-2.322h.04a8.166 8.166 0 0 1 5.742 2.089l1.561-1.715a11.064 11.064 0 0 0-7.355-2.745h-.075a11.157 11.157 0 0 0-7.953 3.454 10.563 10.563 0 0 0-2.959 7.814v.07a10.968 10.968 0 0 0 3.665 7.794" />
      </g>
    </svg>
  );
};

export default IconZagSkisLogo;
