/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { useMemo } from 'react';
import { oneOf, string, bool } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes, LINE_ITEM_UNITS } from '../../util/types';

import BookingDetails from './BookingDetails';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemUnitPriceMaybe from './LineItemUnitPriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemSessions from './LineItemSessions';
import LineItemAdditionalPerson from './LineItemAdditionalPerson';
import LineItemSeason from './LineItemSeason';
import LineItemSessionsDiscount from './LineItemSessionsDiscount';
import { InfoTooltip } from '../../components';

import css from './OrderBreakdown.module.css';

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    intl,
    timeZone,
    isEstimated,
    listing,
    savedListingPublicData,
    bookings,
    sessionsDiscount,
    seasonalCharge,
    additionalPersonCharge,
    showOnlySessions,
    sessionsClassName,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const lineItems = transaction.attributes.lineItems;

  // Line-item code that matches with base unit: day, night, hour, item

  // TODO: calculate saving
  const percent = false;
  const formattedPrice = false;

  const classes = classNames(rootClassName || css.root, className);

  const feeTooltip = useMemo(
    () => (
      <InfoTooltip id="BookingBreakdown.feeTooltip" triggerClassName={css.feeTooltip}>
        <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
      </InfoTooltip>
    ),
    []
  );

  const lineItemSessions = useMemo(
    () => (
      <LineItemSessions
        transaction={transaction}
        intl={intl}
        timeZone={timeZone}
        isEstimated={isEstimated}
        bookings={bookings}
        isProvider={isProvider}
        savedListingPublicData={savedListingPublicData}
        hidePrice={showOnlySessions}
        hideTitle={showOnlySessions}
        className={sessionsClassName}
      />
    ),
    [
      bookings,
      intl,
      isEstimated,
      isProvider,
      savedListingPublicData,
      sessionsClassName,
      showOnlySessions,
      timeZone,
      transaction,
    ]
  );

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  if (showOnlySessions) {
    return lineItemSessions;
  }

  return (
    <div className={classes}>
      {listing && (
        <BookingDetails
          booking={booking}
          listing={listing}
          savedListingPublicData={savedListingPublicData}
        />
      )}

      {!isEstimated && (
        <LineItemBookingPeriod booking={booking} timeZone={timeZone} unitType={LINE_ITEM_UNITS} />
      )}

      <LineItemUnitPriceMaybe transaction={transaction} unitType={LINE_ITEM_UNITS} intl={intl} />

      {lineItemSessions}

      <LineItemBasePriceMaybe lineItems={lineItems} unitType={LINE_ITEM_UNITS} intl={intl} />
      <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />

      <LineItemAdditionalPerson
        transaction={transaction}
        intl={intl}
        isEstimated={isEstimated}
        additionalPersonCharge={additionalPersonCharge}
      />
      <LineItemSeason
        transaction={transaction}
        intl={intl}
        isEstimated={isEstimated}
        seasonalCharge={seasonalCharge}
      />
      <LineItemSessionsDiscount
        transaction={transaction}
        intl={intl}
        isEstimated={isEstimated}
        sessionsDiscount={sessionsDiscount}
      />

      {!isEstimated && (
        <LineItemSubTotalMaybe
          transaction={transaction}
          userRole={userRole}
          intl={intl}
          unitType={LINE_ITEM_UNITS}
          lineItems={lineItems}
        />
      )}

      <LineItemRefundMaybe lineItems={lineItems} intl={intl} />

      {!isEstimated && (
        <LineItemCustomerCommissionMaybe
          lineItems={lineItems}
          isCustomer={isCustomer}
          intl={intl}
          isEstimated={isEstimated}
          feeTooltip={feeTooltip}
        />
      )}
      <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        intl={intl}
      />

      <LineItemProviderCommissionMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        intl={intl}
        isEstimated={isEstimated}
        feeTooltip={feeTooltip}
      />
      <LineItemProviderCommissionRefundMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        intl={intl}
      />

      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        isEstimated={isEstimated}
      />

      {percent && formattedPrice && (
        <div className={css.detailsNote}>
          <h4 className={css.savingsNote}>
            <FormattedMessage
              id="BookingBreakdown.detailsSavingNote"
              values={{ formattedPrice, percent }}
            />
          </h4>
        </div>
      )}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  timeZone: null,
  isEstimated: false,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  timeZone: string,

  isEstimated: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
