import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconChevronUp.module.css';

const IconChevronUp = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="18.206"
      viewBox="0 0 30 18.206"
    >
      <path d="m16.166.483 13.351 13.351a1.649 1.649 0 0 1 0 2.332l-1.557 1.557a1.649 1.649 0 0 1-2.329 0L15.002 7.145 4.369 17.726a1.649 1.649 0 0 1-2.329 0l-1.557-1.56a1.649 1.649 0 0 1 0-2.332L13.834.483a1.649 1.649 0 0 1 2.332 0Z" />
    </svg>
  );
};

IconChevronUp.defaultProps = {
  rootClassName: null,
  className: null,
};

IconChevronUp.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconChevronUp;
