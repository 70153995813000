import React from 'react';
import { string } from 'prop-types';

const IconKayaking = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 53 53">
      <path d="M30.8,13.384A4.688,4.688,0,1,1,26.116,8.7,4.676,4.676,0,0,1,30.8,13.384ZM48.214,43.518H46.875a10.082,10.082,0,0,1-6.7-2.679h0a9.727,9.727,0,0,1-13.393,0,9.727,9.727,0,0,1-13.393,0,10.082,10.082,0,0,1-6.7,2.679H5.357v4.018H6.7a14.765,14.765,0,0,0,6.7-1.607,14.754,14.754,0,0,0,13.393,0,14.754,14.754,0,0,0,13.393,0,14.765,14.765,0,0,0,6.7,1.607h1.339Zm5.357-8.036a63.518,63.518,0,0,1-9.83,3.188,10.642,10.642,0,0,1-3.562-3.188c-1.634,2.25-4.018,4.018-6.7,4.018s-5.062-1.768-6.7-4.018c-1.634,2.25-4.018,4.018-6.7,4.018s-5.062-1.768-6.7-4.018A10.642,10.642,0,0,1,9.83,38.67,63.518,63.518,0,0,1,0,35.482a71.477,71.477,0,0,1,16.071-4.473L18.938,22.2A4,4,0,0,1,24,19.625a2.319,2.319,0,0,1,.643.268l5.357,3,6.536-3.482L40.1,11.375l-.991-2.652,3-6.723,6.107,2.732-3,6.723-2.652.991L34.5,30.58A72.551,72.551,0,0,1,53.571,35.482ZM33.884,25.357,29.973,27.5,26.33,25.438,24.8,30.179c.643-.027,1.313-.054,1.982-.054,1.688,0,3.321.054,4.9.188Z" />
    </svg>
  );
};

IconKayaking.defaultProps = { className: null };
IconKayaking.propTypes = { className: string };

export default IconKayaking;
