import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHeart.module.css';

const IconHeart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="32" height="28" viewBox="0 0 32 28">
      <g transform="translate(1.001 1.003)">
        <path
          transform="translate(0.001 -2.248)"
          d="M27.087,4.043a8.012,8.012,0,0,0-10.933.8L15,6.029,13.845,4.839a8.012,8.012,0,0,0-10.933-.8,8.413,8.413,0,0,0-.58,12.181L13.67,27.931a1.837,1.837,0,0,0,2.654,0L27.661,16.224a8.408,8.408,0,0,0-.574-12.181Z"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconHeart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHeart.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHeart;
