import React from 'react';
import { IntlProvider } from 'react-intl';
import useTranslations from './hooks/useTranslations';
import InstallPrompt from './components/InstallPrompt/InstallPrompt';

const TranslationsWrapper = props => {
  const { children } = props;

  const { localeMessages, locale } = useTranslations();

  return (
    <IntlProvider locale={locale} messages={localeMessages} textComponent="span">
      {children}
      <InstallPrompt />
    </IntlProvider>
  );
};

export default TranslationsWrapper;
