import React from 'react';

const IconBellinzonaLogo = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 455.05938 240.01173"
      {...props}
    >
      <path
        d="M139.6615 145.9653h-20.0222V90.8019a3.2445 3.2445 0 0 0-3.2447-3.2446 32.4908 32.4908 0 0 0-29.2048 18.3102 32.488 32.488 0 0 0-29.203-18.3102 3.246 3.246 0 0 0-3.2465 3.2446v55.1634H35.3528c-.0676 0-.1298.016-.1956.0196l-25.98-25.9799L89.485 39.6991a3.2453 3.2453 0 0 0-4.5887-4.5904L0 120.005l84.8963 84.8964a3.244 3.244 0 0 0 4.5887-4.587l-47.8582-47.8599h16.3599a3.2444 3.2444 0 0 0 3.2445-3.2446V94.2492a25.9967 25.9967 0 0 1 22.714 25.7558 3.2446 3.2446 0 1 0 6.4892 0A25.9982 25.9982 0 0 1 113.15 94.2492v54.9607a3.2445 3.2445 0 0 0 3.2446 3.2446h23.267a3.2446 3.2446 0 0 0 0-6.4892"
        fill="#f9a700"
      />
      <path
        d="M224.0835 61.1268h-.0017a54.368 54.368 0 0 1-38.7005 16.0558h-.192a54.9987 54.9987 0 0 1-38.9155-16.2602l-2.8678-2.8678 26.387-26.3869L198.4734 60.35a3.2453 3.2453 0 1 0 4.5887-4.5904l-8.0306-8.0306 28.035-28.0333a113.003 113.003 0 0 1 24.707 17.7412Zm-132.2727-23.69a113.7257 113.7257 0 0 1 11.9561-9.8422l32.7535 32.7517.0018.0036.0018.0018 5.1611 5.1593a61.4374 61.4374 0 0 0 43.4829 18.1608h.215a61.2274 61.2274 0 0 0 19.2543-3.1006l-34.8443 34.8461Zm125.1008-20.7653-26.4688 26.4687-18.3564-18.3564a3.2437 3.2437 0 0 0-4.5886 0L138.8173 53.466l-29.5658-29.5658a114.0225 114.0225 0 0 1 107.66-7.2287m37.7779 18.4381a120.1982 120.1982 0 0 0-169.7945 0l-2.2935 2.2952 87.1916 87.1898 87.1898-87.1898Z"
        fill="#00aa42"
      />
      <path
        fill="#008bc6"
        d="M169.7924 152.4401a32.3513 32.3513 0 0 1-22.945-9.4884 3.2447 3.2447 0 0 1 4.5886-4.5887 25.9914 25.9914 0 0 0 36.7127 0 3.2447 3.2447 0 0 1 4.5887 4.5887 32.3587 32.3587 0 0 1-22.945 9.4884M169.6676 181.6823a61.2967 61.2967 0 0 1-43.4705-18.0808 3.2447 3.2447 0 0 1 4.5886-4.5886 54.9188 54.9188 0 0 0 51.3766 14.7598 3.2447 3.2447 0 0 1 1.449 6.3256 62.4435 62.4435 0 0 1-13.9437 1.584M197.0628 174.9223a3.246 3.246 0 0 1-1.5183-6.1157 55.1543 55.1543 0 0 0 13.2539-9.7943 3.2447 3.2447 0 0 1 4.5886 4.5887 61.6145 61.6145 0 0 1-14.8113 10.9445 3.2264 3.2264 0 0 1-1.5129.3768M150.0068 208.6128a3.2376 3.2376 0 0 1-.7326-.0836 90.6283 90.6283 0 0 1-43.7282-24.2784 3.2447 3.2447 0 0 1 4.5905-4.5869 84.1386 84.1386 0 0 0 40.5974 22.5415 3.2453 3.2453 0 0 1-.7271 6.4074M169.7718 210.8628q-2.963 0-5.9381-.1937a3.2443 3.2443 0 1 1 .4213-6.475 84.4174 84.4174 0 0 0 65.194-24.531 3.2453 3.2453 0 0 1 4.5905 4.5887 90.9043 90.9043 0 0 1-64.2677 26.611"
      />
      <path
        fill="#008bc6"
        d="M169.7924 240.0117a119.6727 119.6727 0 0 1-84.8964-35.109l-2.2952-2.2953 84.8963-84.8963a3.2453 3.2453 0 0 1 4.5904 4.5887l-80.2756 80.2756A113.708 113.708 0 0 0 250.1 200.314a3.2447 3.2447 0 0 1 4.5887 4.5886 119.698 119.698 0 0 1-84.8963 35.1091"
      />
      <path
        d="M419.8934 35.1096a120.1961 120.1961 0 0 0-169.7927 0l-84.898 84.8963 84.898 84.8963A120.0615 120.0615 0 1 0 419.8935 35.1096"
        fill="#000"
      />
      <path
        fill="#fff"
        d="M280.2776 89.1075a5.6763 5.6763 0 0 0 6.0199-5.707c0-3.321-2.3876-5.7603-6.0199-5.7603h-7.9398v11.4673Zm-7.9398-26.2573V72.865h7.6803a4.9904 4.9904 0 0 0 5.1896-5.0847 4.899 4.899 0 0 0-5.1896-4.93Zm19.4605 20.5503a10.8655 10.8655 0 0 1-10.6388 10.845h-14.1144V57.9202H280.9a9.8335 9.8335 0 0 1 9.8084 9.8084 9.7309 9.7309 0 0 1-3.737 7.3177 9.2478 9.2478 0 0 1 4.8269 8.354M299.114 57.921h20.134v5.033h-14.841v9.911h14.374v4.879h-14.374v11.312h15.152v5.19h-20.445V57.921zM340.7314 57.9206h5.0864v20.0312h9.547v4.9815h-14.6334Zm-13.2326 0h5.2926v31.1356h15.3607v5.1896h-20.6533ZM362.6285 57.9197h5.2927v36.3252h-5.2927zM377.003 57.921h4.878l18.577 22.262V57.921h4.981v36.947l-23.455-28.696v28.074h-4.981V57.921zM267.642 138.142l14.633-31.241h-12.662v-5.084h21.121l-14.738 31.135h14.687v5.19h-23.041zM301.3706 120.0315c0 7.835 5.5523 13.5953 13.0779 13.5953 7.524 0 13.0762-5.7603 13.0762-13.5953 0-7.8883-5.5522-13.6486-13.0762-13.6486-7.5256 0-13.0779 5.7603-13.0779 13.6486m-5.5522 0c0-10.4307 7.835-18.8382 18.63-18.8382 10.7935 0 18.6303 8.4075 18.6303 18.8382a18.6308 18.6308 0 1 1-37.2603 0M339.926 101.817h4.879l18.576 22.262v-22.262h4.982v36.947l-23.455-28.696v28.074h-4.982v-36.325zM384.8636 125.7391h9.6005l-4.7736-12.2459Zm11.5206 4.722h-13.389l-3.0616 7.6804h-5.6038l15.3607-36.999 15.3607 36.999h-5.6056ZM277.1388 172.3603l-3.3727-6.434h-2.8037a5.293 5.293 0 0 0-5.2927 5.3442 5.3573 5.3573 0 0 0 5.6056 5.2944h15.6719v5.1878h-16.607a10.458 10.458 0 0 1 0-20.913h.8302l-8.0946-15.4122h20.5503v4.8269h-12.0913l11.4157 22.1059ZM320.571 182.376l-14.945-36.948h5.864l9.081 24.286 9.081-24.286h5.864l-14.945 36.948zM343.0916 169.3506h9.6005l-4.7736-12.246Zm11.5206 4.722h-13.389l-3.0616 7.6803h-5.6038l15.3607-36.999 15.3608 36.999h-5.6056ZM382.7373 145.428h5.0864v20.0312h9.547v4.9816h-14.6334Zm-13.2326 0h5.2927v31.1357h15.3606v5.1896h-20.6532ZM404.634 145.4277h5.2927v36.3252h-5.2927z"
      />
    </svg>
  );
};

export default IconBellinzonaLogo;
