import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { ensureUser } from '../../util/data';
import { USER_TYPE } from '../../constants/userAttributes';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSuccess } from '../../components';
import { getUserType } from '../../util/listingHelpers';

import css from './UserType.module.css';

const UserType = props => {
  const { className, user, showFullText, hideText } = props;

  const ensuredUser = useMemo(() => ensureUser(user), [user]);

  const userType = useMemo(() => getUserType(ensuredUser), [ensuredUser]);

  const isPlainUser = useMemo(() => userType === USER_TYPE.USER, [userType]);

  if (isPlainUser) return null;

  return (
    <div className={classNames(css.root, className)}>
      {!hideText && (
        <h5 className={css.userTypeText}>
          <FormattedMessage id={`General.${userType}${showFullText ? '' : 'Selected'}`} />
        </h5>
      )}
      <IconSuccess tagFill={userType} className={css.icon} />
    </div>
  );
};

UserType.propTypes = {
  className: string,
  user: propTypes.user.isRequired,
  showFullText: bool,
  hideText: bool,
};

export default UserType;
