/**
 * CurrencyInput renders an input field that format it's value according to currency formatting rules
 * onFocus: renders given value in unformatted manner: "9999,99"
 * onBlur: formats the given input: "9 999,99 €"
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  isSafeNumber,
  unitDivisor,
  convertUnitToSubUnit,
  convertMoneyToNumber,
  ensureDotSeparator,
  ensureSeparator,
  truncateToSubUnitPrecision,
} from '../../util/currency';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import { ValidationError } from '../../components';

import css from './FieldCurrencyInput.module.css';

const { Money } = sdkTypes;

const allowedInputProps = allProps => {
  // Strip away props that are not passed to input element (or are overwritten)
  // eslint-disable-next-line no-unused-vars
  const { currencyConfig, defaultValue, intl, input, meta, onBlur, ...inputProps } = allProps;
  return inputProps;
};

// Convert unformatted value (e.g. 10,00) to Money (or null)
const getPrice = (unformattedValue, currencyConfig) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : new Money(
          convertUnitToSubUnit(unformattedValue, unitDivisor(currencyConfig.currency)),
          currencyConfig.currency
        );
  } catch (e) {
    return null;
  }
};

const getInitialStateValues = (intl, currencyConfig, input, defaultValue) => {
  const initialValueIsMoney = input.value instanceof Money;

  const initialValue = initialValueIsMoney ? convertMoneyToNumber(input.value) : defaultValue;
  const hasInitialValue = typeof initialValue === 'number' && !isNaN(initialValue);

  // We need to handle number format - some locales use dots and some commas as decimal separator
  // TODO Figure out if this could be digged from React-Intl directly somehow
  const testSubUnitFormat = intl.formatNumber('1.1', currencyConfig);
  const usesComma = testSubUnitFormat.indexOf(',') >= 0;

  try {
    // whatever is passed as a default value, will be converted to currency string
    // Unformatted value is digits + localized sub unit separator ("9,99")
    const unformattedValue = hasInitialValue
      ? truncateToSubUnitPrecision(
          ensureSeparator(initialValue.toString(), usesComma),
          unitDivisor(currencyConfig.currency),
          usesComma
        )
      : '';
    // Formatted value fully localized currency string ("$1,000.99")
    const formattedValue = hasInitialValue
      ? intl.formatNumber(ensureDotSeparator(unformattedValue), currencyConfig)
      : '';

    return {
      formattedValue,
      unformattedValue,
      value: formattedValue,
      usesComma,
    };
  } catch (e) {
    log.error(e, 'currency-input-init-failed', { currencyConfig, defaultValue, initialValue });

    return {};
  }
};

const CurrencyInputComponent = props => {
  const { intl, className, currencyConfig, defaultValue, input, placeholder, onBlur } = props;

  const [values, setValues] = useState(
    getInitialStateValues(intl, currencyConfig, input, defaultValue)
  );

  const placeholderText = useMemo(
    () => placeholder || intl.formatNumber(defaultValue, currencyConfig),
    [currencyConfig, defaultValue, intl, placeholder]
  );

  const onInputBlur = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      const { onBlur: inputOnBlur } = input;
      setValues(prevState => {
        if (inputOnBlur || onBlur) {
          // If parent component has provided onBlur function, call it with current price.
          const price = getPrice(ensureDotSeparator(prevState.unformattedValue), currencyConfig);
          inputOnBlur?.(price);
          onBlur?.(price);
        }

        return { ...prevState, value: prevState.formattedValue };
      });
    },
    [currencyConfig, input, onBlur]
  );

  const onInputFocus = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      const { onFocus } = input;

      setValues(prevState => {
        if (onFocus) {
          // If parent component has provided onFocus function, call it with current price.
          const price = getPrice(ensureDotSeparator(prevState.unformattedValue), currencyConfig);
          onFocus(price);
        }
        return { ...prevState, value: prevState.unformattedValue };
      });
    },
    [currencyConfig, input]
  );

  const updateValues = useCallback(
    event => {
      try {
        const targetValue = event.target.value.trim();
        const isEmptyString = targetValue === '';
        const valueOrZero = isEmptyString ? '0' : targetValue;

        const targetDecimalValue = isEmptyString
          ? null
          : new Decimal(ensureDotSeparator(targetValue));

        const isSafeValue =
          isEmptyString || (targetDecimalValue.isPositive() && isSafeNumber(targetDecimalValue));
        if (!isSafeValue) {
          throw new Error(`Unsafe money value: ${targetValue}`);
        }

        // truncate decimals to subunit precision: 10000.999 => 10000.99
        const truncatedValueString = truncateToSubUnitPrecision(
          valueOrZero,
          unitDivisor(currencyConfig.currency),
          values.usesComma
        );
        const unformattedValue = !isEmptyString ? truncatedValueString : '';
        const formattedValue = !isEmptyString
          ? intl.formatNumber(ensureDotSeparator(truncatedValueString), currencyConfig)
          : '';

        setValues(oldValues => ({
          ...oldValues,
          formattedValue,
          value: unformattedValue,
          unformattedValue,
        }));

        return { formattedValue, value: unformattedValue, unformattedValue };
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        // If an error occurs while filling input field, use previous values
        // This ensures that string like '12.3r' doesn't end up to a state.
        const { formattedValue, unformattedValue, value } = values;
        return { formattedValue, unformattedValue, value };
      }
    },
    [currencyConfig, intl, values]
  );

  const onInputChange = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      // Update value strings on state
      const { unformattedValue } = updateValues(event);
      // Notify parent component about current price change
      const price = getPrice(ensureDotSeparator(unformattedValue), currencyConfig);
      input.onChange(price);
    },
    [currencyConfig, input, updateValues]
  );

  useEffect(() => {
    if (input.value && input.value instanceof Money) {
      const inputValueAsNumber = convertMoneyToNumber(input.value);

      if (inputValueAsNumber !== +values.unformattedValue) {
        const formattedValue = intl.formatNumber(
          ensureDotSeparator(`${inputValueAsNumber}`),
          currencyConfig
        );

        setValues(oldValues => ({
          ...oldValues,
          formattedValue,
          unformattedValue: `${inputValueAsNumber}`,
          value: formattedValue,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value, currencyConfig]);

  return (
    <input
      className={className}
      {...allowedInputProps(props)}
      value={values.value}
      onChange={onInputChange}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      type="text"
      placeholder={placeholderText}
    />
  );
};

CurrencyInputComponent.defaultProps = {
  className: null,
  defaultValue: null,
  input: null,
  placeholder: null,
};

CurrencyInputComponent.propTypes = {
  className: string,
  currencyConfig: propTypes.currencyConfig.isRequired,
  defaultValue: number,
  intl: intlShape.isRequired,
  input: shape({
    value: oneOfType([string, propTypes.money]),
    onBlur: func,
    onChange: func.isRequired,
    onFocus: func,
  }).isRequired,

  placeholder: string,
};

export const CurrencyInput = injectIntl(CurrencyInputComponent);

const FieldCurrencyInputComponent = props => {
  const {
    rootClassName,
    className,
    hideValidationErrorMsg,
    id,
    label,
    input,
    meta,
    inputClassName,
    ...rest
  } = props;

  const { valid, invalid, touched, error } = useMemo(() => meta, [meta]);

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = useMemo(() => touched && invalid && error, [error, invalid, touched]);

  const inputClasses = useMemo(
    () =>
      classNames(
        css.input,
        {
          [css.inputSuccess]: valid,
          [css.inputError]: hasError,
        },
        inputClassName
      ),
    [hasError, inputClassName, valid]
  );

  const inputProps = useMemo(() => ({ className: inputClasses, id, input, ...rest }), [
    id,
    input,
    inputClasses,
    rest,
  ]);
  const classes = useMemo(() => classNames(rootClassName, className), [className, rootClassName]);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <CurrencyInput {...inputProps} />
      {!hideValidationErrorMsg && <ValidationError fieldMeta={meta} />}
    </div>
  );
};

FieldCurrencyInputComponent.defaultProps = {
  rootClassName: null,
  hideValidationErrorMsg: false,
  className: null,
  id: null,
  label: null,
};

FieldCurrencyInputComponent.propTypes = {
  rootClassName: string,
  hideValidationErrorMsg: bool,
  className: string,
  inputClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
};

const FieldCurrencyInput = props => {
  return <Field component={FieldCurrencyInputComponent} {...props} autoComplete="off" />;
};

export default FieldCurrencyInput;
