import React, { useMemo, useState } from 'react';
import { arrayOf, node, object, shape, string } from 'prop-types';
import { FieldCheckbox, OutsideClickHandler, ValidationError } from '..';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './MultiSelect.module.css';

const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options } = props;

  return (
    <fieldset className={className} tabIndex="-1">
      <ul className={css.list}>
        {options.map(option => {
          const fieldId = `${id}.${option.key}`;

          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox
                id={fieldId}
                name={name}
                label={option.label}
                value={option.key}
                useMarketplaceColor
              />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const Select = props => {
  const { id, placeholder, value, className, ...rest } = props;

  return (
    <input
      id={id}
      className={className}
      value={value}
      placeholder={placeholder}
      {...rest}
      readOnly
    />
  );
};

const MultiSelectComponent = props => {
  const {
    className,
    labelClassName,
    inputContainerClassName,
    id,
    label,
    additionalLabel,
    placeholder,
    input,
    meta,
    customErrorText,

    optionsClassName,
    options,
    optionsId,
    optionsName,
    selectedOptions,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { valid, invalid, touched, error } = meta;
  const hasError = useMemo(() => touched && invalid && error, [touched, invalid, error]);

  const inputContainerClasses = useMemo(
    () =>
      classNames(
        css.inputContainer,
        { [css.selectSuccess]: selectedOptions.length && valid },
        { [css.selectError]: hasError },
        inputContainerClassName
      ),
    [inputContainerClassName, hasError, valid, selectedOptions]
  );

  const optionsClassNames = useMemo(() => classNames(css.options, optionsClassName), [
    optionsClassName,
  ]);

  const formattedOptions = useMemo(
    () => selectedOptions.map(option => options?.find(o => o.key === option)?.label).join(', '),
    [options, selectedOptions]
  );

  return (
    <OutsideClickHandler
      className={classNames(css.root, className)}
      onOutsideClick={() => setIsOpen(false)}
    >
      {label && (
        <div className={classNames(css.label, labelClassName)}>
          <label htmlFor={id}>{label}</label>
          {additionalLabel}
        </div>
      )}

      <Select
        className={inputContainerClasses}
        id={id}
        placeholder={placeholder}
        value={formattedOptions}
        {...rest}
        onClick={() => setIsOpen(oldOpen => !oldOpen)}
      />

      {isOpen && (
        <div tabIndex="-1" className={css.optionsContainer}>
          <GroupOfFieldCheckboxes
            options={options}
            id={optionsId}
            name={optionsName}
            className={optionsClassNames}
          />
        </div>
      )}
      <ValidationError fieldMeta={meta} />
    </OutsideClickHandler>
  );
};

MultiSelectComponent.defaultProps = {
  selectedOptions: [],
};

MultiSelectComponent.propTypes = {
  className: string,
  inputContainerClassName: string,
  labelClassName: string,
  optionsClassName: string,

  id: string.isRequired,
  label: string,
  placeholder: string,
  additionalLabel: node,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  options: arrayOf(shape({ key: string.isRequired, label: string.isRequired })),
  optionsId: string.isRequired,
  optionsName: string.isRequired,
  selectedOptions: arrayOf(string.isRequired),
};

const MultiSelect = props => <Field component={MultiSelectComponent} {...props} />;

export default MultiSelect;
