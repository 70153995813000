import React from 'react';
import { richText } from './richText';

// Privacy Policy and Terms Of Service helper

// Translation messages needs to follow these rules (order is important):
// section[0-n]title -> section[0-n]paragraph[1-m]

// If section has list of items use subsections
// section[0-n]subsection[1-m]paragraph[1-k] || section[0-n]subsection[1-m]item[1-k]

export const generateContentFromTranslationMessages = (
  componentName,
  intl,
  translationsWithValues
) => {
  const content = [];

  const generateKey = (
    isTitle,
    section,
    paragraph,
    subsection,
    sectionParagraph,
    item,
    subitem,
    isExtended
  ) =>
    `${componentName}.section${section}${isTitle ? 'title' : ''}${
      paragraph ? `paragraph${paragraph}` : ''
    }${subsection ? `subsection${subsection}` : ''}${
      sectionParagraph ? `paragraph${sectionParagraph}` : ''
    }${item ? `item${item}` : ''}${subitem ? `subitem${subitem}` : ''}${
      isExtended ? 'extended' : ''
    }`;

  const messageExists = key => !!intl.messages[key];

  const translate = key => {
    const keyHasValues = translationsWithValues.find(t => t.key === key);
    if (keyHasValues) {
      const { values } = keyHasValues;
      return richText(intl.formatMessage({ id: key }, values), { linkify: true });
    }
    return richText(intl.formatMessage({ id: key }), { linkify: true });
  };

  // section
  let section = 0;
  let sectionKey = generateKey(false, section, 1);
  while (messageExists(sectionKey)) {
    if (section !== 0) {
      content.push(<h3 key={sectionKey}>{translate(sectionKey)}</h3>);
    }

    // paragraph
    let paragraph = 1;
    let sectionParagraphKey = generateKey(false, section, paragraph);
    while (messageExists(sectionParagraphKey)) {
      content.push(<p key={sectionParagraphKey}>{translate(sectionParagraphKey)}</p>);
      sectionParagraphKey = generateKey(false, section, ++paragraph);
    }

    // subsection
    let subsection = 1;
    let sectionSubsectionKey = generateKey(false, section, null, subsection, 1);
    let sectionSubsectionItemTmpKey = generateKey(false, section, null, subsection, null, 1);
    while (messageExists(sectionSubsectionKey) || messageExists(sectionSubsectionItemTmpKey)) {
      sectionSubsectionItemTmpKey = null;

      // subsection paragraph
      let subsecParagraph = 1;
      let subsecParagraphKey = generateKey(false, section, null, subsection, subsecParagraph);
      while (!!intl.messages[subsecParagraphKey]) {
        content.push(<p key={subsecParagraphKey}>{translate(subsecParagraphKey)}</p>);
        subsecParagraphKey = generateKey(false, section, null, subsection, ++subsecParagraph);
      }

      // subsection item
      let subsecItem = 1;
      let subsecItemKey = generateKey(false, section, null, subsection, null, subsecItem);
      let subSecItems = [];
      while (messageExists(subsecItemKey)) {
        subSecItems.push(<li key={subsecItemKey}>{translate(subsecItemKey)}</li>);

        // subsection subitem
        let subsecSubItem = 1;
        let subsecSubItemKey = generateKey(
          false,
          section,
          null,
          subsection,
          null,
          subsecItem,
          subsecSubItem
        );
        let subSecSubItems = [];
        while (messageExists(subsecSubItemKey)) {
          subSecSubItems.push(<li key={subsecSubItemKey}>{translate(subsecSubItemKey)}</li>);
          subsecSubItemKey = generateKey(
            false,
            section,
            null,
            subsection,
            null,
            subsecItem,
            ++subsecSubItem
          );

          if (!messageExists(subsecSubItemKey)) {
            subSecItems.push(<ul key={subsecSubItemKey}>{subSecSubItems}</ul>);

            const extendedMaybe = generateKey(
              false,
              section,
              null,
              subsection,
              null,
              subsecItem,
              null,
              true
            );
            if (messageExists(extendedMaybe)) {
              subSecItems.push(
                <li style={{ listStyleType: 'none' }} key={extendedMaybe}>
                  {translate(extendedMaybe)}
                </li>
              );
            }
          }
        }

        subsecItemKey = generateKey(false, section, null, subsection, null, ++subsecItem);

        if (!messageExists(subsecItemKey)) {
          content.push(<ul key={subsecItemKey}>{subSecItems}</ul>);
        }
      }

      sectionSubsectionKey = generateKey(false, section, null, ++subsection, 1);
    }

    sectionKey = generateKey(true, ++section);
  }

  return content;
};
