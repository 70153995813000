import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import classNames from 'classnames';

import css from './TransactionPanel.module.css';

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_DECLINED = 'declined';
export const HEADING_CANCELED = 'canceled';
export const HEADING_CANCELED_BY_CUSTOMER = 'canceled-by-customer';
export const HEADING_DELIVERED = 'delivered';
export const HEADING_REVIEWED = 'reviewed';

const HeadingCustomer = props => {
  const { className, message, listingDeletedMaybe } = props;
  return (
    <>
      <h2 className={className}>
        <span className={css.mainTitle}>{message}</span>
      </h2>
      {listingDeletedMaybe}
    </>
  );
};

const HeadingCustomerWithSubtitle = props => {
  const { className, message, subtitleMessage, children, listingDeletedMaybe } = props;
  return (
    <>
      <h2 className={className}>
        <span className={css.mainTitle}>{message}</span>
        {subtitleMessage}
      </h2>
      {children}
      {listingDeletedMaybe}
    </>
  );
};

const HeadingProvider = props => {
  const { className, message, isCustomerBannedMaybe, children } = props;
  return (
    <>
      <h2 className={className}>
        <span className={css.mainTitle}>{message}</span>
      </h2>
      {children}
      {isCustomerBannedMaybe}
    </>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const PanelHeading = props => {
  const {
    className,
    rootClassName,
    panelHeadingState,
    customerName,
    providerName,
    listingDeleted,
    isCustomerBanned,
    transactionRole,
    intl,
  } = props;

  const isCustomer = useMemo(() => transactionRole === 'customer', [transactionRole]);

  const emphasizedText = useMemo(
    () => (
      <span className={css.emphasizedText}>
        <FormattedMessage
          id={
            isCustomer
              ? 'TransactionPanel.anActivityWithProvider'
              : 'TransactionPanel.anActivityWithYou'
          }
          values={{ providerName }}
        />
      </span>
    ),
    [isCustomer, providerName]
  );

  const messages = useMemo(
    () => ({
      orderEnquiredTitle: intl.formatMessage(
        { id: 'TransactionPanel.orderEnquiredTitle' },
        { emphasizedText }
      ),
      saleEnquiredTitle: intl.formatMessage(
        { id: 'TransactionPanel.saleEnquiredTitle' },
        { customerName, emphasizedText }
      ),
      orderPaymentPendingTitle: intl.formatMessage({
        id: 'TransactionPanel.orderPaymentPendingTitle',
      }),
      salePaymentPendingTitle: intl.formatMessage({
        id: 'TransactionPanel.salePaymentPendingTitle',
      }),
      salePaymentPendingInfo: intl.formatMessage(
        { id: 'TransactionPanel.salePaymentPendingInfo' },
        { customerName }
      ),
      orderPaymentExpiredTitle: intl.formatMessage({
        id: 'TransactionPanel.orderPaymentExpiredTitle',
      }),
      salePaymentExpiredTitle: intl.formatMessage({
        id: 'TransactionPanel.salePaymentExpiredTitle',
      }),
      orderPreauthorizedTitle: intl.formatMessage(
        { id: 'TransactionPanel.orderPreauthorizedTitle' },
        { customerName }
      ),
      orderPreauthorizedSubtitle: intl.formatMessage(
        { id: 'TransactionPanel.orderPreauthorizedSubtitle' },
        { emphasizedText }
      ),
      orderPreauthorizedInfo: intl.formatMessage(
        { id: 'TransactionPanel.orderPreauthorizedInfo' },
        { providerName }
      ),
      saleRequestedTitle: intl.formatMessage(
        { id: 'TransactionPanel.saleRequestedTitle' },
        { customerName, emphasizedText }
      ),
      saleRequestedInfo: intl.formatMessage(
        { id: 'TransactionPanel.saleRequestedInfo' },
        { customerName }
      ),
      orderAcceptedTitle: intl.formatMessage(
        { id: 'TransactionPanel.orderAcceptedTitle' },
        { customerName }
      ),
      saleAcceptedTitle: intl.formatMessage(
        { id: 'TransactionPanel.saleAcceptedTitle' },
        { customerName }
      ),
      orderDeclinedTitle: intl.formatMessage(
        { id: 'TransactionPanel.orderDeclinedTitle' },
        { customerName }
      ),
      saleDeclinedTitle: intl.formatMessage(
        { id: 'TransactionPanel.saleDeclinedTitle' },
        { customerName }
      ),
      orderCancelledTitle: intl.formatMessage(
        { id: 'TransactionPanel.orderCancelledTitle' },
        { customerName }
      ),
      saleCancelledTitle: intl.formatMessage(
        { id: 'TransactionPanel.saleCancelledTitle' },
        { customerName }
      ),
      saleCancelledByCustomerTitle: intl.formatMessage(
        { id: 'TransactionPanel.saleCancelledByCustomerTitle' },
        { customerName }
      ),
      orderDeliveredTitle: intl.formatMessage(
        { id: 'TransactionPanel.orderDeliveredTitle' },
        { customerName }
      ),
      saleDeliveredTitle: intl.formatMessage(
        { id: 'TransactionPanel.saleDeliveredTitle' },
        { customerName }
      ),
      orderReviewedTitle: intl.formatMessage(
        { id: 'TransactionPanel.orderReviewedTitle' },
        { emphasizedText, customerName }
      ),
      saleReviewedTitle: intl.formatMessage(
        { id: 'TransactionPanel.saleReviewedTitle' },
        { customerName }
      ),
      deletedListing: intl.formatMessage({ id: 'TransactionPanel.deletedListing' }),
      customerBannedStatus: intl.formatMessage({ id: 'TransactionPanel.customerBannedStatus' }),
    }),
    [customerName, emphasizedText, intl, providerName]
  );

  const titleClasses = useMemo(
    () => classNames(rootClassName || css.transactionStatus, className),
    [className, rootClassName]
  );
  const headingCustomerProps = useMemo(
    () => ({
      className: titleClasses,
      listingDeletedMaybe: listingDeleted && (
        <p className={css.transactionStatus}>{messages.listingDeleted}</p>
      ),
    }),
    [listingDeleted, messages.listingDeleted, titleClasses]
  );
  const headingProviderProps = useMemo(
    () => ({
      className: titleClasses,
      isCustomerBannedMaybe: isCustomerBanned && (
        <p className={css.transactionStatus}>{messages.customerBannedStatus}</p>
      ),
    }),
    [isCustomerBanned, messages.customerBannedStatus, titleClasses]
  );

  switch (panelHeadingState) {
    case HEADING_ENQUIRED:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderEnquiredTitle} />
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.saleEnquiredTitle} />
      );

    case HEADING_PAYMENT_PENDING:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderPaymentPendingTitle} />
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.salePaymentPendingTitle}>
          <p className={css.transactionStatus}>{messages.salePaymentPendingInfo}</p>
        </HeadingProvider>
      );

    case HEADING_PAYMENT_EXPIRED:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderPaymentExpiredTitle} />
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.salePaymentExpiredTitle} />
      );

    case HEADING_REQUESTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          {...headingCustomerProps}
          message={messages.orderPreauthorizedTitle}
          subtitleMessage={messages.orderPreauthorizedSubtitle}
        >
          {!listingDeleted && (
            <p className={css.transactionStatus}>{messages.orderPreauthorizedInfo}</p>
          )}
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.saleRequestedTitle}>
          {!isCustomerBanned && <p className={titleClasses}>{messages.saleRequestedInfo}</p>}
        </HeadingProvider>
      );

    case HEADING_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderAcceptedTitle} />
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.saleAcceptedTitle} />
      );

    case HEADING_DECLINED:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderDeclinedTitle} />
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.saleDeclinedTitle} />
      );

    case HEADING_CANCELED:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderCancelledTitle} />
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.saleCancelledTitle} />
      );

    case HEADING_CANCELED_BY_CUSTOMER:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderCancelledTitle} />
      ) : (
        <HeadingProvider
          {...headingProviderProps}
          message={messages.saleCancelledByCustomerTitle}
        />
      );

    case HEADING_DELIVERED:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderDeliveredTitle} />
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.saleDeliveredTitle} />
      );

    case HEADING_REVIEWED:
      return isCustomer ? (
        <HeadingCustomer {...headingCustomerProps} message={messages.orderReviewedTitle} />
      ) : (
        <HeadingProvider {...headingProviderProps} message={messages.saleReviewedTitle} />
      );

    default:
      console.warn('Unknown state given to panel heading.');
      return null;
  }
};

export default injectIntl(PanelHeading);
