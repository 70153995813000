import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg
        className={className}
        {...rest}
        width="1279.69"
        height="289.62"
        viewBox="0 0 1279.69 289.62"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Livello_2" data-name="Livello 2">
            <path d="M124.8,250a122,122,0,0,1-46-8.8,129.75,129.75,0,0,1-39.47-24.89v-.5a124.1,124.1,0,0,1-28.16-39.22A126.12,126.12,0,0,1,.11,128.82,120.86,120.86,0,0,1,33.8,39.32,123.38,123.38,0,0,1,73.27,11.16,127.42,127.42,0,0,1,120.78.1a120.91,120.91,0,0,1,48.27,7.8,123.32,123.32,0,0,1,41.23,25.89,19.06,19.06,0,0,1,6.29,14.08,19.94,19.94,0,0,1-5.28,14.58,19.1,19.1,0,0,1-14.08,6.29,19.86,19.86,0,0,1-14.58-5.28Q157,39.32,122.29,40.33a96.66,96.66,0,0,0-32.43,7.54A77.51,77.51,0,0,0,63,67Q38.82,92.61,40.33,127.82q1,34.69,26.65,58.83A80.59,80.59,0,0,0,95.39,204.5a85.66,85.66,0,0,0,91.26-21.37,18.8,18.8,0,0,1,13.83-6.29,19.62,19.62,0,0,1,14.33,5.28A18.83,18.83,0,0,1,221.09,196a19.54,19.54,0,0,1-5.28,14.33,120.07,120.07,0,0,1-39.47,28.66A127.08,127.08,0,0,1,128.83,250Z" />
            <path d="M341.62,250a19.71,19.71,0,0,1-20.08-20.08V115.46a116.59,116.59,0,0,1,8.53-44.17,112.68,112.68,0,0,1,25.1-37.66A114.91,114.91,0,0,1,436.5,0a19.73,19.73,0,0,1,20.08,20.08A19.73,19.73,0,0,1,436.5,40.16a71,71,0,0,0-29.12,6,80.78,80.78,0,0,0-23.84,16.07,71.2,71.2,0,0,0-16.07,23.84,75.37,75.37,0,0,0-5.77,29.37V229.92A19.73,19.73,0,0,1,341.62,250Z" />
            <path d="M592.69,6A20.84,20.84,0,0,0,557,20.83V229.17a20.84,20.84,0,1,0,41.67,0V20.83A20.19,20.19,0,0,0,592.69,6Z" />
            <path d="M1259.85,250A19.47,19.47,0,0,1,1240,230.16V115.08a74.73,74.73,0,0,0-22.32-53.32,79.08,79.08,0,0,0-24.31-16.13,75.56,75.56,0,0,0-58.78,0,79.86,79.86,0,0,0-24.06,16.13,74.84,74.84,0,0,0-22.32,53.32c0,.67.08.58.25-.25s.08-.91-.25-.25l-.49,115.58a19.85,19.85,0,1,1-39.69,0V115.08a11.17,11.17,0,0,1,.25-2.23,11.1,11.1,0,0,0,.25-2.23,114.35,114.35,0,0,1,33.73-76.89,113.77,113.77,0,0,1,37.45-25A115.57,115.57,0,0,1,1164.12,0a113.52,113.52,0,0,1,81.35,33.73,114.52,114.52,0,0,1,25,37.2,120.56,120.56,0,0,1,9.17,44.15V230.16A19.47,19.47,0,0,1,1259.85,250Z" />
            <path d="M914.38,195.27h-2.85a5.46,5.46,0,0,1-4.73-2.73l-43.66-75.61a5.47,5.47,0,0,0-9.47,0L851.89,120a5,5,0,0,1-8.66,0l-1.33-2.32a5,5,0,0,0-8.66,0l-29.68,51.4a6,6,0,0,1-10.39,0l-12.07-20.9a9,9,0,0,0-15.59,0l-25.59,44.32a5.46,5.46,0,0,1-4.73,2.73h-2.85a9,9,0,0,1-7.8-13.5l41-71a9,9,0,0,1,15.59,0l12.07,20.9a6,6,0,0,0,10.39,0l23.2-40.19,8.79-15.23,5.06-8.76a9,9,0,0,1,15.58,0l5.06,8.76,8,13.77,53,91.75A9,9,0,0,1,914.38,195.27Z" />
            <path d="M938.36,76.35a121,121,0,0,0-26.75-39.92A124,124,0,0,0,823.61,0a122.35,122.35,0,0,0-48,9.48,126.58,126.58,0,0,0-67,66.87,124.9,124.9,0,0,0,0,95.56A122.73,122.73,0,0,0,730,206c1.8,2.06,3.69,4.08,5.66,6q4.13,4.13,8.53,7.79A123.86,123.86,0,0,0,775.61,239a124.61,124.61,0,0,0,125.25-17.37Q903,220,905,218.23h0q3.39-2.94,6.61-6.15a120.3,120.3,0,0,0,26.75-40.16,125.06,125.06,0,0,0,9.25-47.66A124.46,124.46,0,0,0,938.36,76.35ZM883.11,183.93a83.92,83.92,0,0,1-26.75,18,85.51,85.51,0,0,1-65.5,0,83.92,83.92,0,0,1-26.75-18,85.1,85.1,0,0,1-6.1-6.8q-2.57-3.16-4.82-6.59l-.31-.47a90.68,90.68,0,0,1-7-13,76.07,76.07,0,0,1-2.82-7.4,81.66,81.66,0,0,1-3.93-25.45,80.39,80.39,0,0,1,6.75-32.84,87.78,87.78,0,0,1,18.25-26.83,84.11,84.11,0,0,1,26.75-18.06,85.51,85.51,0,0,1,65.5,0,84.17,84.17,0,0,1,44.75,44.89,83.11,83.11,0,0,1,6.5,32.84,84.6,84.6,0,0,1-3.84,25.64h0c-.77,2.44-1.65,4.84-2.66,7.2A86,86,0,0,1,894,170.43l-.07.11a79.44,79.44,0,0,1-4.77,6.59A83,83,0,0,1,883.11,183.93Z" />
            <path d="M913.72,209.8,905,218.22h0l-68.42,66.06a19,19,0,0,1-26.4,0L733,209.8a19.71,19.71,0,0,1-3-3.77,18.36,18.36,0,0,1-2.61-12.29,18.89,18.89,0,0,1,18.86-16.61H758a85.1,85.1,0,0,0,6.1,6.8,83.92,83.92,0,0,0,26.75,18,85.51,85.51,0,0,0,65.5,0,83.92,83.92,0,0,0,26.75-18,83,83,0,0,0,6.08-6.8h11.33a18.91,18.91,0,0,1,18.87,16.63A18.58,18.58,0,0,1,913.72,209.8Z" />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      {...rest}
      width="747.57"
      height="879.44"
      viewBox="0 0 747.57 879.44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Livello_7" data-name="Livello 7">
          <path d="M373.79,0C167.38,0,0,167.38,0,373.79A372.72,372.72,0,0,0,124.21,652L332.65,862.3a57.84,57.84,0,0,0,82.19,0L623.36,652.11A372.74,372.74,0,0,0,747.57,373.79C747.57,167.38,580.28,0,373.79,0Zm0,62.33c172,0,311.55,139.51,311.55,311.46A309.94,309.94,0,0,1,656,505.91L511.72,256.17,487.8,214.76l-15.2-26.37c-10.38-18-36.49-18-46.86,0l-15.21,26.37-26.46,45.8-69.8,120.88a18.06,18.06,0,0,1-31.29.08l-36.31-62.85a27,27,0,0,0-46.85,0L91.69,505.91A310.38,310.38,0,0,1,62.33,373.79C62.33,201.84,201.84,62.33,373.79,62.33Zm0,623c-101.8,0-192.17-48.88-249-124.4l75-129.75a27,27,0,0,1,46.85,0L283,494.05a18.07,18.07,0,0,0,31.29,0L403.5,339.41a15.1,15.1,0,0,1,26.11,0l4,6.95a15,15,0,0,0,26,0l5.37-9.23a16.42,16.42,0,0,1,28.48,0L622.83,560.94C566,636.55,475.59,685.34,373.79,685.34Z" />
        </g>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
