import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCreditCard.module.css';

const IconCreditCard = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path d="M25.4,4H4.6A2.658,2.658,0,0,0,2.013,6.75L2,23.25A2.669,2.669,0,0,0,4.6,26H25.4A2.669,2.669,0,0,0,28,23.25V6.75A2.669,2.669,0,0,0,25.4,4Zm0,19.25H4.6V15H25.4Zm0-13.75H4.6V6.75H25.4Z" />
    </svg>
  );
};

IconCreditCard.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconCreditCard.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconCreditCard;
