import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';
import { debounce } from 'lodash';

import css from './Reviews.module.css';

const DEBOUNCE_DELAY = 300;

const Review = props => {
  const { review, intl, reviewCardClassName } = props;

  const [ellipsisHeight, setEllipsisHeight] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const reviewParagraph = useRef();
  const toggleBtn = useRef();

  const { attributes, author } = useMemo(() => review, [review]);

  const { createdAt } = useMemo(() => attributes, [attributes]);

  const createdAtString = useMemo(
    () => intl.formatDate(createdAt, { month: 'long', year: 'numeric' }),
    [createdAt, intl]
  );

  const toggleCollapse = useCallback(() => {
    const element = reviewParagraph.current;
    const elementMaxHeight = element.scrollHeight;

    const isCollapsed = element.classList.contains(css.collapsed);

    if (isCollapsed) {
      element.style.height = `${ellipsisHeight}px`;
      setIsCollapsed(false);
    } else {
      element.style.height = `${elementMaxHeight}px`;
      setIsCollapsed(true);
    }

    element.classList.toggle(css.collapsed);
  }, [ellipsisHeight]);

  const onResizeWindow = useCallback(
    (event = null) => {
      if (!reviewParagraph.current) return;

      const element = reviewParagraph.current;
      const elementMinHeight = ellipsisHeight === '' ? element.offsetHeight : ellipsisHeight; // not collapsed element height
      const elementMaxHeight = element.scrollHeight; // collapsed element height (different on different width)

      // close collapsed element on resize window
      event && element.classList.contains(css.collapsed) && toggleCollapse();

      const isCollapsable = elementMinHeight < elementMaxHeight;

      if (isCollapsable) toggleBtn.current.classList.remove(css.noDisplay);
      else toggleBtn.current.classList.add(css.noDisplay);

      // test collapsable after 300ms (equal to css transition duration = 0.3s)
      event && setTimeout(onResizeWindow, 300);
    },
    [ellipsisHeight, toggleCollapse]
  );

  useEffect(() => {
    onResizeWindow();
    setEllipsisHeight(reviewParagraph.current.offsetHeight);

    const debouncedHandleResize = debounce(onResizeWindow, DEBOUNCE_DELAY);
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [onResizeWindow]);

  return (
    <div className={classNames(css.review, reviewCardClassName)}>
      <div className={css.reviewHeader}>
        <div className={css.userInfo}>
          <Avatar className={css.avatar} user={author} />

          <div className={css.reviewInfo}>
            <UserDisplayName className={css.authorName} user={author} intl={intl} />
            <span className={css.reviewDate}>{createdAtString}</span>
          </div>
        </div>

        <span className={css.desktopReviewRatingWrapper}>
          <ReviewRating
            rating={attributes.rating}
            ratingCount={author?.attributes?.profile?.metadata?.ratingCount}
            className={css.desktopReviewRating}
            reviewStarClassName={css.reviewRatingStar}
            isAccented
            hideRatingCount
          />
        </span>
      </div>
      <div>
        <p
          ref={reviewParagraph}
          style={{ height: `${ellipsisHeight}px` }}
          className={classNames(css.reviewContent, css.ellipsedReviewContent)}
        >
          {attributes.content}
        </p>
      </div>
      <button ref={toggleBtn} onClick={toggleCollapse} className={css.continueReadingLabel}>
        {isCollapsed ? (
          <FormattedMessage id="Review.readLess" />
        ) : (
          <FormattedMessage id="Review.readMore" />
        )}
      </button>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, reviewCardClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
            <Review review={r} intl={intl} reviewCardClassName={reviewCardClassName} />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviewCardClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviewCardClassName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
