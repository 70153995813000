import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { USER_TYPE } from '../../constants/userAttributes';

import css from './IconSuccess.module.css';

const iconFill = type => {
  switch (type) {
    case USER_TYPE.BUSINESS:
      return css.fillBusiness;
    case USER_TYPE.EXPERT:
      return css.fillLocal;
    case USER_TYPE.USER:
      return css.fillClient;
    case 'verified':
      return css.fillVerified;
    case 'notverified':
      return css.fillNotVerified;
    case USER_TYPE.PROFESSIONAL:
      return css.fillDefault;
    default:
      return css.fillDefault;
  }
};

const IconSuccess = props => {
  const { rootClassName, className, tagFill } = props;
  const classes = classNames(rootClassName || css.root, className);

  const fillCircleClassName = classNames(
    { [iconFill(tagFill)]: tagFill || tagFill === '' },
    { [css.fillColor]: tagFill === null }
  );

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <circle className={fillCircleClassName} cx="12" cy="12" r="10" />
        <path
          stroke="#FFF"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 8l-5.184 8L8 12.439"
        />
      </g>
    </svg>
  );
};

IconSuccess.defaultProps = {
  rootClassName: null,
  className: null,
  tagFill: null,
};

IconSuccess.propTypes = {
  rootClassName: string,
  className: string,
  tagFill: string,
};

export default IconSuccess;
