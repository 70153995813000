import React from 'react';
import { string, number } from 'prop-types';

const ItalianFlag = props => {
  const { className, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={width}
      viewBox="0 0 512 512"
    >
      <g>
        <path fill="#eee" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
        <path fill="#6da544" d="M0 0h167v512H0z" />
        <path fill="#d80027" d="M345 0h167v512H345z" />
      </g>
    </svg>
  );
};

ItalianFlag.defaultProps = { className: null, width: 22 };
ItalianFlag.propTypes = { className: string, width: number };

export default ItalianFlag;
