import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldSelectableCheckboxItem } from '..';

import css from './FieldSelectableGroup.module.css';

const FieldSelectableCheckboxGroupRenderer = props => {
  const {
    rootClassName,
    className,
    itemsRootClassName,
    itemsClassName,
    fields,
    options,
    label,
    labelClassName,
    itemClassName,
    hasRequiredMark,
    showInput,
    additionalLabel,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const itemsClasses = classNames(itemsRootClassName || css.itemsRoot, itemsClassName);
  const labelClasses = classNames(css.labelWrapper, labelClassName);

  return (
    <div className={classes}>
      {label && (
        <div className={labelClasses}>
          <label>
            {label}
            {hasRequiredMark && '*'}
          </label>
          {additionalLabel}
        </div>
      )}
      <div className={itemsClasses}>
        {options.map(({ value, label, title, imgSrc }) => {
          const fieldId = `${fields.name}.${value}`;
          return (
            <FieldSelectableCheckboxItem
              key={fieldId}
              id={fieldId}
              fields={fields}
              value={value}
              label={label}
              title={title}
              imgSrc={imgSrc}
              className={itemClassName}
              showInput={showInput}
              {...rest}
            />
          );
        })}
      </div>
    </div>
  );
};

FieldSelectableCheckboxGroupRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  itemsRootClassName: null,
  itemsClassName: null,
  hasRequiredMark: false,
  showInput: false,
};

FieldSelectableCheckboxGroupRenderer.propTypes = {
  rootClassName: string,
  className: string,
  itemsRootClassName: string,
  itemsClassName: string,
  label: string,
  additionalLabel: node,
  hasRequiredMark: bool,
  showInput: bool,

  options: arrayOf(
    shape({
      value: string.isRequired,
      imgSrc: string,
      title: string,
      label: string,
    })
  ).isRequired,
};

const FieldSelectableCheckboxGroup = props => (
  <FieldArray component={FieldSelectableCheckboxGroupRenderer} {...props} />
);

FieldSelectableCheckboxGroup.propTypes = {
  name: string.isRequired,
};

export default FieldSelectableCheckboxGroup;
