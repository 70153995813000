import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewUser.module.css';

const IconReviewUser = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
    >
      <path
        d="M41.7,3H7.3A4.294,4.294,0,0,0,3.021,7.3L3,46l8.6-8.6H41.7A4.313,4.313,0,0,0,46,33.1V7.3A4.313,4.313,0,0,0,41.7,3ZM11.6,28.8V23.49L26.392,8.7a1.064,1.064,0,0,1,1.527,0L31.724,12.5a1.064,1.064,0,0,1,0,1.526L16.91,28.8Zm25.8,0H21.275l4.3-4.3H37.4Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

IconReviewUser.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconReviewUser.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconReviewUser;
