import React from 'react';

const IconLuganoregionLogo = props => {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
      <path
        fill="#a11828"
        d="M972.68,314.46c0,1.78-.13,3.56-.13,5.34q0,219.57,0,439.13v5.66H526.9c-1.64,0-3.29.08-4.93.13V314.46ZM784.77,604.06V400.15h-75.2v102H607.83v75H709.72V678.93h177V604.06Z"
      />
      <path
        fill="#a31e2e"
        d="M1445.64,650.47V603.94h15.75v75c-4.77,0-9.53.18-14.26-.13-1.12-.07-2.35-1.62-3.16-2.75q-15.3-21.39-30.5-42.86c-.77-1.07-1.58-2.11-3-4v49.49h-16.1V603.79c5.21,0,10.5-.14,15.77.13,1,.05,2,1.66,2.78,2.73q14.76,20.51,29.47,41.08c.76,1.06,1.58,2.09,2.37,3.13Z"
      />
      <path
        fill="#a21d2d"
        d="M1354,501.68c5,0,9.69-.22,14.32.14,1.41.1,3.08,1.53,4,2.8,9.83,13.53,19.52,27.15,29.26,40.75a10,10,0,0,0,3.22,3.15V501.91h16.08V577c-4.67,0-9.43.17-14.16-.13-1.12-.07-2.37-1.57-3.17-2.7-11-15.37-22-30.79-33.42-46.93v49.48H1354Z"
      />
      <path
        fill="#a31d2d"
        d="M1248.83,549.52h-19.62V535.69h35.85c0,9.28,0,18.31-.06,27.34a3.93,3.93,0,0,1-1.15,2.46c-11.48,11.58-25.38,15.39-41.1,11.82-15.06-3.43-24.9-12.75-28.8-27.85-4.37-16.92,2.46-34.31,16.66-43,14.84-9.05,34.81-7.77,47.23,3.18,2.31,2,4.13,4.61,6.52,7.33l-13.57,7.42c-6.86-8.72-15.71-11.61-26-8-9.83,3.47-14.59,11.21-15.17,21.41-.56,9.75,2.76,17.9,11.46,22.95s17.37,4.13,25.7-1a5.59,5.59,0,0,0,2-3.57A44,44,0,0,0,1248.83,549.52Z"
      />
      <path
        fill="#a21c2c"
        d="M1264.3,619l-13.51,7.34c-9.35-9.62-19-11.85-28.81-6.76-12,6.24-16.4,22.66-9.32,34.94,6.7,11.61,22.12,15.07,33.73,7.56,2.91-1.88,3.11-2.86,2-10.7h-19.29V637.45h35.43c.08,1.17.24,2.35.24,3.53,0,5.76-.61,11.61.17,17.26,1,7.33-2.57,11.36-8,15.07-22.07,15.07-53.62,5.88-62-18.21-6-17.16-.15-36.15,14-45.65,15.08-10.1,36.18-9.15,48.93,2.33C1260.1,613.76,1261.88,616.22,1264.3,619Z"
      />
      <path
        fill="#a21d2d"
        d="M1058.55,603.85c12.72,0,25.08-.46,37.39.13s20.81,8.19,22.55,19c2.11,13.2-2.85,22.1-15.73,27.65,2.76,4.68,5.5,9.32,8.23,14s5.32,9.11,8.39,14.36c-6.29,0-11.73.12-17.15-.12-1,0-2.19-1.36-2.78-2.39-3.85-6.78-7.72-13.57-11.25-20.52-1.5-3-3.24-4.36-6.6-3.92a57.3,57.3,0,0,1-7,.06v26.64h-16ZM1074.7,638c6.38,0,12.53.26,18.64-.09,5.27-.29,8.7-4.37,8.82-9.54.13-5.54-3-9.8-8.49-10.21-6.21-.46-12.49-.11-19-.11Z"
      />
      <path
        fill="#a21b2b"
        d="M1304.59,641.27c.06-22.67,16.44-38.75,39.46-38.72s39.13,16.26,39.1,39.07c0,22.61-16.55,38.86-39.44,38.76S1304.53,664,1304.59,641.27Zm16.6,1.81c.2,5.8,2.18,12.66,8.16,17.66,7.06,5.9,15.17,6.87,23.49,3.39,8.84-3.7,13-11.15,13.59-20.35.68-9.89-2.13-18.59-11.22-23.86C1342.47,612.54,1321.58,618.21,1321.19,643.08Z"
      />
      <path
        fill="#a21b2b"
        d="M1432.31,539.49c0-22.77,16.32-39,39.19-39s39.24,16.33,39.23,39.4c0,22.26-16.65,38.65-39.23,38.65C1448.71,578.55,1432.3,562.2,1432.31,539.49Zm16.53-1.76c-.22,12.17,5.16,21.11,14.05,24.64a22.4,22.4,0,0,0,27.85-9.26c6.32-11,3.82-26.68-5.52-33.6-7.37-5.45-15.53-6.07-23.68-2.3C1452.45,521.42,1449.3,529.52,1448.84,537.73Z"
      />
      <path
        fill="#a31e2e"
        d="M1130.94,603.93h52.85v13.86h-36.51v15.85H1183v14.21h-35.78v17h36.59v13.9h-52.87Z"
      />
      <path
        fill="#a21b2b"
        d="M1286.5,577h-17.58c.53-1.6.92-3,1.44-4.3,8.73-22.58,17.51-45.14,26.17-67.75.91-2.39,2.06-3.42,4.73-3.29,4.65.23,9.34-.09,14,.17a4.82,4.82,0,0,1,3.52,2.28c9.35,23.8,18.54,47.67,27.74,71.52a5.43,5.43,0,0,1,.09,1.46c-5.13,0-10.19.09-15.24-.09-.92,0-1.87-1.06-2.68-1.77-.36-.32-.43-1-.62-1.5-3.63-9.68-3.62-9.68-14.08-9.68-6,0-12.09.19-18.12-.07-3.35-.14-5.29.65-6.12,4.18C1289,571.15,1287.61,574,1286.5,577Zm21.42-60.76c-4.34,12-8.24,22.7-12.19,33.6H1320C1316.05,538.78,1312.21,528.1,1307.92,516.2Z"
      />
      <path
        fill="#a21b2b"
        d="M1165.34,501.94h16.5c0,13.66,0,27.07,0,40.48a86,86,0,0,1-.64,11.47c-1.67,11.92-9.24,20.2-21,23.12a49.37,49.37,0,0,1-24.42-.1c-12.61-3.22-20.69-12.8-21.15-26.57-.53-16-.12-32.06-.12-48.38h16.18c.06,1.36.16,2.68.17,4,0,13.3,0,26.6,0,39.9.06,13.86,10.45,21.39,23.64,17.2,6.89-2.19,10.75-7.83,10.79-16.16.07-13.44,0-26.88,0-40.32Z"
      />
      <path fill="#a21c2c" d="M1074.38,562.78H1106v14h-47.44V501.88h15.82Z" />
      <path fill="#a31f2f" d="M1277.74,603.92h15.63v74.9h-15.63Z" />
    </svg>
  );
};

export default IconLuganoregionLogo;
