import React, { useMemo } from 'react';
import { intlShape } from '../../util/reactIntl';
import { convertNumberToMoney, formatMoney } from '../../util/currency';
import { LINE_ITEM_SEATS, LINE_ITEM_SESSION, propTypes } from '../../util/types';
import { getSessionDatesFromLineItemCode } from '../../util/sessions';
import { BookedByPreview } from '../../components';
import { convertDateToMoment, daysBetween, getDayOfWeekStrings, isSameDay } from '../../util/dates';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './OrderBreakdown.module.css';

const groupBookingsByDates = bookings => {
  if (!bookings || !bookings.length) return {};

  return bookings.reduce((groupedBookings, booking) => {
    const { seats, startsAt, endsAt, status, isOvernight, timezone, endTime } = booking;

    const startDate = convertDateToMoment(startsAt, timezone);
    const endDate = convertDateToMoment(endsAt, timezone);

    const bookedDays = daysBetween(startDate, endDate, true);
    const isSingleDayBooking = bookedDays <= 1;

    const actualSeats = status === 'CANCELLED' ? 0 : seats;

    if (isSingleDayBooking) {
      const key = `${startsAt}${endsAt}`;

      groupedBookings[key] = {
        seats: +actualSeats + (groupedBookings[key]?.seats || 0),
        bookings: [...(groupedBookings[key]?.bookings || []), booking],
      };

      return groupedBookings;
    }

    const [endHours, endMinutes] = endTime.split(':');

    const date = startDate.clone();

    while (date.isSameOrBefore(endDate, 'day')) {
      const clonedDate = date.clone();

      const sessionStart = clonedDate.clone();
      const sessionEnd = isOvernight
        ? clonedDate.clone()
        : clonedDate
            .clone()
            .hours(+endHours)
            .minutes(+endMinutes);

      if (isOvernight) {
        if (isSameDay(sessionEnd, endDate) || !isSameDay(sessionEnd, startDate)) {
          sessionStart.hours(0).minutes(0);
        }

        if (isSameDay(sessionStart, startDate) || !isSameDay(sessionStart, endDate)) {
          sessionEnd
            .add(1, 'day')
            .hours(0)
            .minutes(0);
        } else {
          sessionEnd.hours(+endHours).minutes(+endMinutes);
        }
      }

      const key = `${sessionStart.utc().format()}${sessionEnd.utc().format()}`;

      groupedBookings[key] = {
        seats: +actualSeats + (groupedBookings[key]?.seats || 0),
        bookings: [...(groupedBookings[key]?.bookings || []), booking],
      };

      date.add(1, 'days');
    }

    return groupedBookings;
  }, {});
};

const LineItemSessions = props => {
  const {
    className,
    transaction,
    timeZone,
    intl,
    isEstimated,
    bookings: propBookings = [],
    isProvider,
    savedListingPublicData,
    hidePrice,
    hideTitle,
  } = props;

  const sessionLineItems = transaction.attributes.lineItems.filter(
    item => item.code.includes(LINE_ITEM_SESSION) && !item.reversal
  );

  const weekdays = useMemo(() => getDayOfWeekStrings(), []);

  if (sessionLineItems.length === 0) return null;

  const { quantity: seats = 0 } =
    transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_SEATS && !item.reversal
    ) || {};

  if (isEstimated) {
    const { total: sessionsTotal, currency, quantity } = sessionLineItems.reduce(
      (sessionTotal, lineItem) => {
        const { lineTotal, quantity } = lineItem;

        sessionTotal.total += lineTotal.amount;
        sessionTotal.currency = lineTotal.currency;
        sessionTotal.quantity = quantity;

        return sessionTotal;
      },
      { total: 0, currency: null, quantity: 1 }
    );

    const total = formatMoney(intl, convertNumberToMoney(sessionsTotal, currency), true);

    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          {intl.formatMessage(
            { id: 'BookingBreakdown.sessionsCount' },
            { sessionsCount: sessionLineItems.length / quantity, peopleCount: seats }
          )}
        </span>
        <span className={css.itemValue}>{total}</span>
      </div>
    );
  }

  const { peopleAtMost = 0 } = savedListingPublicData || {};

  const groupedBookings = groupBookingsByDates(propBookings);

  const from = intl.formatMessage({ id: 'General.from' });
  const to = intl.formatMessage({ id: 'General.to' });
  const of = intl.formatMessage({ id: 'General.of' });
  const people = intl.formatMessage({ id: 'General.people' });

  return (
    <>
      {!hideTitle && (
        <div className={css.itemLabel}>{intl.formatMessage({ id: 'General.time' })}</div>
      )}
      <ul className={classNames(css.list, className)}>
        {sessionLineItems
          .sort((l1, l2) => {
            const { startDate: firstStartDate } = getSessionDatesFromLineItemCode(
              l1.code,
              timeZone
            );
            const { startDate: secondStartDate } = getSessionDatesFromLineItemCode(
              l2.code,
              timeZone
            );

            return firstStartDate.toDate() - secondStartDate.toDate();
          })
          .map(lineItem => {
            const { code, lineTotal } = lineItem;

            const {
              startDate,
              endDate,
              startDateString,
              endDateString,
            } = getSessionDatesFromLineItemCode(code, timeZone);

            const dayOfWeek =
              weekdays[startDate.isoWeekday() - 1]?.localizedLong || startDate.format('dddd');

            const startDoWAndDay = `${dayOfWeek} ${startDate.format('DD')}`;

            const startTime = startDate.format('HH:mm');
            const endTime = endDate.format('HH:mm');

            const endTimeFinal = endTime === '00:00' ? '24:00' : endTime;

            const total = formatMoney(intl, lineTotal, true);

            const sessionTime = `${from} ${startTime} ${to} ${endTimeFinal}`.toLocaleLowerCase();

            const key = `${startDateString}${endDateString}`;

            const { seats: bookedSeats = '-', bookings } = groupedBookings[key] || {};

            const bookedSeatsString = `${bookedSeats} ${of} ${peopleAtMost} ${people}`;

            return (
              <li className={classNames(css.lineItem, css.listItem)} key={code}>
                <span className={classNames(css.itemLabel, css.itemLabelSession)}>
                  <span>{`${startDoWAndDay} ${sessionTime}`}</span>
                </span>
                {!hidePrice && (
                  <span
                    className={classNames(css.itemValue, { [css.itemBookedSeats]: isProvider })}
                  >
                    {isProvider ? bookedSeatsString : total}

                    {!!bookings?.length && (
                      <BookedByPreview bookings={bookings} className={css.bookingsPreview} />
                    )}
                  </span>
                )}
              </li>
            );
          })}
      </ul>
    </>
  );
};

LineItemSessions.propTypes = {
  transaction: propTypes.transaction.isRequired,
  timeZone: string.isRequired,
  isEstimated: bool,
  intl: intlShape.isRequired,
};

export default LineItemSessions;
