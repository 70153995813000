import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { IconReviewUser, Modal } from '../../../components';
import ReviewForm from '../ReviewForm/ReviewForm';

import css from './ReviewModal.module.css';
import { useConfiguration } from '../../../context/configurationContext';

const ReviewModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitReview,
    revieweeName,
    reviewSent,
    sendReviewInProgress,
    sendReviewError,
    intl,
  } = props;

  const config = useConfiguration();

  const messages = useMemo(
    () => ({
      title: intl.formatMessage(
        { id: 'ReviewModal.title' },
        { revieweeName: <span className={css.reviewee}>{revieweeName}</span> }
      ),
      description: intl.formatMessage(
        { id: 'ReviewModal.description' },
        { marketplaceName: config.marketplaceName }
      ),
    }),
    [config.marketplaceName, intl, revieweeName]
  );

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <IconReviewUser className={css.modalIcon} />
      <h2 className={css.modalTitle}>{messages.title}</h2>
      <p className={css.modalMessage}>{messages.description}</p>
      <ReviewForm
        onSubmit={onSubmitReview}
        reviewSent={reviewSent}
        sendReviewInProgress={sendReviewInProgress}
        sendReviewError={sendReviewError}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

ReviewModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

ReviewModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
};

export default injectIntl(ReviewModal);
