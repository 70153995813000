import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_ADDITIONAL_PEOPLE, propTypes } from '../../util/types';
import classNames from 'classnames';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

import css from './OrderBreakdown.module.css';

const LineItemAdditionalPerson = props => {
  const { transaction, intl, isEstimated, additionalPersonCharge } = props;

  const additionalPeopleCharge = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_ADDITIONAL_PEOPLE && !item.reversal
  );

  if (!additionalPeopleCharge) return null;

  const { lineTotal } = additionalPeopleCharge;

  const total = formatMoney(intl, lineTotal, true);

  return (
    <div className={css.lineItem}>
      <span className={classNames(css.itemLabel, { [css.itemGray]: !isEstimated })}>
        <FormattedMessage id="BookingBreakdown.additionalPersonCharge" />
        <InfoTooltip
          id="BookingBreakdown.additionalPersonTooltip"
          triggerClassName={css.feeTooltip}
        >
          <FormattedMessage
            id="BookingTimeForm.additionalPersonCharge"
            values={{
              fromPerson: additionalPersonCharge.fromPerson,
              percent: additionalPersonCharge.percent,
            }}
          />
        </InfoTooltip>
      </span>
      <span className={css.itemValue}>+{total}</span>
    </div>
  );
};

LineItemAdditionalPerson.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAdditionalPerson;
