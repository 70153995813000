import React, { useMemo } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import ExternalLink from '../ExternalLink/ExternalLink';

import css from './ProjectPartnerCard.module.css';

const ProjectPartnerCard = props => {
  const { rootClassName, className, partner } = props;
  const { url, logo: Logo, logoClassName } = partner;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return (
    <ExternalLink href={url} className={classes}>
      <Logo className={logoClassName} />
    </ExternalLink>
  );
};

ProjectPartnerCard.defaultProps = {
  rootClassName: null,
  className: null,
  partner: null,
};

ProjectPartnerCard.propTypes = {
  rootClassName: string,
  className: string,
};

export default ProjectPartnerCard;
