import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPaid.module.css';

const IconPaid = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path d="M15,2A13,13,0,1,0,28,15,13,13,0,0,0,15,2Zm1.144,20.488V24.1H13.869V22.423a5.248,5.248,0,0,1-3.926-3.848l2.145-.871c.078.286.754,2.717,3.12,2.717,1.209,0,2.574-.624,2.574-2.093,0-1.248-.91-1.9-2.964-2.639-1.43-.507-4.355-1.339-4.355-4.3a4,4,0,0,1,3.406-3.848V5.9h2.275V7.512a4.453,4.453,0,0,1,3.458,2.9l-2.054.871a2.512,2.512,0,0,0-2.47-1.742c-.91,0-2.353.481-2.353,1.807,0,1.235,1.118,1.7,3.432,2.47,3.12,1.079,3.913,2.665,3.913,4.485C20.07,21.721,16.82,22.371,16.144,22.488Z" />
    </svg>
  );
};

IconPaid.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconPaid.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconPaid;
