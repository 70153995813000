import { useCallback, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { changeInterfaceLang } from '../ducks/ui.duck';
import { LANGUAGE_CODES } from '../constants/locales';

const myHistory =
  typeof window === 'object' ? createBrowserHistory() : { push: () => {}, replace: () => {} };

const defaultHistoryPush = myHistory.push;
const defaultHistoryReplace = myHistory.replace;

const useMyHistory = store => {
  // Used for intercepting a link that requires authorized user,
  // so it can open the login modal
  myHistory.push = useCallback(
    (params, state) => {
      const stateFinal = params.state || state;
      const {
        ui: { interfaceLang },
      } = store.getState();

      const paramsWithLang = getParamsWithLang(params, interfaceLang);

      defaultHistoryPush(paramsWithLang, stateFinal);
    },
    [store]
  );

  myHistory.replace = useCallback(
    (params, state) => {
      const {
        ui: { interfaceLang },
      } = store.getState();

      const paramsWithLang = getParamsWithLang(params, interfaceLang);

      defaultHistoryReplace(paramsWithLang, state);
    },
    [store]
  );

  // Listen for URL change and update Redux interfaceLang based on url param
  useEffect(() => {
    const listener = myHistory.listen(location => {
      const {
        ui: { interfaceLang },
      } = store.getState();

      const { pathname } = location;

      const urlLang = getLocaleFromUrl(pathname);

      if (urlLang !== interfaceLang) {
        store.dispatch(changeInterfaceLang(urlLang));
      }
    });

    return listener;
  }, [store]);

  // Used when no SSR - as server redirects to url with language always - DEVELOPMENT ONLY!!!
  useEffect(() => {
    const {
      ui: { interfaceLang },
    } = store.getState();

    const { hash, pathname, search, state } = myHistory.location;

    const urlLang = getLocaleFromUrl(pathname);

    if (urlLang && urlLang !== interfaceLang) {
      store.dispatch(changeInterfaceLang(urlLang));
    }

    if (!urlLang) {
      myHistory.replace(`/${interfaceLang}${pathname}${search}${hash}`, state);
    }
  }, [store]);

  return myHistory;
};

const getParamsWithLang = (params, interfaceLang) => {
  const isParamsObject = params.pathname;
  const pathname = params.pathname || params;

  const existingLangInUrl = pathname && getLocaleFromUrl(pathname);

  const isSameLang = existingLangInUrl === interfaceLang;

  if (isSameLang) return params;

  const pathWithLang = existingLangInUrl ? pathname : `/${interfaceLang}${pathname}`;

  if (isParamsObject) {
    params.pathname = pathWithLang;

    return params;
  }

  return pathWithLang;
};

const getLocaleFromUrl = pathname =>
  LANGUAGE_CODES.find(languageCode => pathname?.startsWith('/' + languageCode));

export default useMyHistory;
