import React, { useMemo } from 'react';
import { NamedLink, Avatar, UserDisplayName, TransitionMessage } from '..';
import { ensureTransaction } from '../../util/data';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { dateFormatFull } from '../../util/dates';
import { getUserTxRole } from '../../transactions/transaction';
import { txRoleIsProvider } from '../../transactions/transactionProcessBooking';

import css from './MessageCard.module.css';
import { useConfiguration } from '../../context/configurationContext';
import { checkIfEntityForeignPartner } from '../../util/partner';

const getTimeInMessageFormat = sentAt => {
  return dateFormatFull(sentAt).toLowerCase();
};

const MessageCardComponent = props => {
  const { isMessageSeen, message, sentAt, intl, user, isTransition } = props;

  return (
    <div className={classNames(css.card, { [css.noReadMessage]: !isMessageSeen })}>
      <Avatar user={user} className={css.avatar} disableProfileLink />
      <div className={css.info}>
        <UserDisplayName className={css.userName} user={user} intl={intl} hideUserType />
        <span className={css.messageText}>
          {isTransition && '- '}
          {message}
        </span>
        <span className={css.messageTime}>{sentAt}</span>
      </div>
    </div>
  );
};

MessageCardComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const MessageCard = props => {
  const { rootClassName, className, tx, currentUser, intl, ...rest } = props;

  const config = useConfiguration();

  const currentTransaction = ensureTransaction(tx);
  const {
    customer,
    provider,
    attributes: { metadata, protectedData },
  } = currentTransaction;

  const { lastMessage } = metadata || {};
  const { content, sentBy, seenBy, sentAt, type } = lastMessage || {};

  const { partner } = protectedData || {};

  const isForeignTx = checkIfEntityForeignPartner(partner, config);

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);
  const isCurrentUserProvider = txRoleIsProvider(ownRole);
  const currentUserType = isCurrentUserProvider ? 'provider' : 'customer';

  const isCurrentUserSender = currentUserType === sentBy;
  const isCurrentUserSeenMessage = seenBy?.includes(currentUserType);
  const isMessageSeen = isCurrentUserSender || isCurrentUserSeenMessage;

  const sentAtTime = getTimeInMessageFormat(sentAt);
  const isTransition = type === 'transition';

  const showUser = isCurrentUserProvider ? customer : provider;
  const otherUsersName = <UserDisplayName user={showUser} intl={intl} hideUserType />;

  const transition = {
    transition: content,
    by: sentBy,
  };

  const classes = classNames(rootClassName || css.root, className, {
    [css.greyedOut]: isForeignTx,
  });

  const message = isTransition ? (
    <TransitionMessage
      transaction={currentTransaction}
      transition={transition}
      ownRole={ownRole}
      displayName={otherUsersName}
      hasActions={false}
    />
  ) : (
    content
  );

  const namedLinkProps = useMemo(
    () => ({
      name: !isCurrentUserProvider ? 'OrderDetailsPage' : 'SaleDetailsPage',
      params: { id: tx.id?.uuid },
    }),
    [isCurrentUserProvider, tx.id?.uuid]
  );

  return (
    <NamedLink {...namedLinkProps} isForeignLink={isForeignTx} className={classes}>
      <MessageCardComponent
        tx={tx}
        user={showUser}
        message={message}
        sentAt={sentAtTime}
        isTransition={isTransition}
        isMessageSeen={isMessageSeen}
        intl={intl}
        {...rest}
      />
    </NamedLink>
  );
};

export default injectIntl(MessageCard);
