import React from 'react';
import { Field } from 'react-final-form';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './FieldSelectableItem.module.css';

const FieldSelectableCheckboxItem = props => {
  const {
    className,
    id,
    label,
    title,
    imgSrc,
    horizontal,
    icon,
    iconClassName,
    value,
    disabled,
    fields,
    showInput,
  } = props;

  const classes = classNames(
    css.root,
    className,
    { [css.vertical]: !horizontal },
    { [css.horizontal]: !!horizontal },
    { [css.disabled]: disabled }
  );

  const Icon = icon;

  const iconClasses = classNames(
    iconClassName,
    { [css.iconVertical]: !horizontal },
    { [css.iconHorizontal]: !!horizontal }
  );

  const handleOnChange = fields => {
    if (fields.length === 0) {
      fields.push(value);
    } else {
      const index = fields.value.indexOf(value);
      index === -1 ? fields.push(value) : fields.remove(index);
    }
  };

  return (
    <Field name={fields.name}>
      {() => {
        return (
          <div className={classNames({ [css.fieldWithImage]: imgSrc })}>
            <input
              id={id}
              type="checkbox"
              className={classNames(css.input, { [css.visibleInput]: showInput })}
              name={fields.name}
              value={value}
              checked={fields.value?.indexOf(value) > -1}
              disabled={disabled}
              onChange={() => handleOnChange(fields)}
            />
            <label
              className={classNames(classes, {
                [css.selected]: fields.value?.indexOf(value) > -1,
                [css.imageLabel]: imgSrc,
              })}
              htmlFor={id}
            >
              {imgSrc && <img src={imgSrc} alt="" />}
              {icon && (
                <span>
                  <Icon className={iconClasses} />
                </span>
              )}
              {title && (
                <label
                  className={classNames(css.titleText, { [css.disabled]: disabled })}
                  htmlFor={id}
                >
                  {title}
                </label>
              )}
              {label && (
                <label
                  className={classNames(css.labelText, { [css.disabled]: disabled })}
                  htmlFor={id}
                >
                  {label}
                </label>
              )}
            </label>
          </div>
        );
      }}
    </Field>
  );
};

FieldSelectableCheckboxItem.defaultProps = {
  className: null,
  showInput: false,
};

FieldSelectableCheckboxItem.propTypes = {
  className: string,
  id: string.isRequired,
  value: string.isRequired,
  showInput: bool,
};

export default FieldSelectableCheckboxItem;
