import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { FieldSelectableItem, ValidationError } from '../../components';
import classNames from 'classnames';
import { arrayOf, node, oneOfType, shape, string } from 'prop-types';

import css from './FieldSelectableItemStrip.module.css';

const checkIfAdjacentOptionsSelected = (option, leftOption, rightOption, selectedOptions) => {
  const selected = { isLeftSelected: false, isRightSelected: false };

  // If no options selected, return false
  if (!selectedOptions) return selected;

  const isCurrentOptionSelected = selectedOptions.some(opt => opt === option.key);

  if (!isCurrentOptionSelected) return selected;

  // If left of option is selected
  if (selectedOptions.some(opt => opt === leftOption?.key)) selected.isLeftSelected = true;

  //   If right of option is selected
  if (selectedOptions.some(opt => opt === rightOption?.key)) selected.isRightSelected = true;

  return selected;
};

const FieldSelectableItemStripComponent = props => {
  const { className, itemClassName, id, label, fields, options, meta, onChange, disabled } = props;

  const classes = classNames(css.root, className);

  return (
    <fieldset className={classes} disabled={disabled}>
      {label ? <legend>{label}</legend> : null}
      <ul className={css.rowList}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;

          const { isLeftSelected, isRightSelected } = checkIfAdjacentOptionsSelected(
            option,
            options[index - 1],
            options[index + 1],
            fields.value
          );

          return (
            <li key={fieldId} className={css.item}>
              <FieldSelectableItem
                id={fieldId}
                name={fields.name}
                label={option.label}
                value={option.key}
                inputClassName={itemClassName}
                labelClassName={classNames({
                  [css.firstInRowItemSelectedMobile]:
                    (index === 0 || index === 6) && options[index],
                  [css.leftOfItemSelected]: isLeftSelected,
                  [css.lastInRowItemSelectedMobile]:
                    (index === 5 || index === options.length - 1) && options[index],
                  [css.rightOfItemSelected]: isRightSelected,
                })}
                onChange={onChange}
              />
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldSelectableItemStripComponent.defaultProps = {};

FieldSelectableItemStripComponent.propTypes = {
  className: string,
  itemClassName: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: oneOfType([node, string]).isRequired,
    })
  ).isRequired,
};

const FieldSelectableItemStrip = props => (
  <FieldArray component={FieldSelectableItemStripComponent} {...props} />
);

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldSelectableItemStrip.propTypes = {
  name: string.isRequired,
};

export default FieldSelectableItemStrip;
