import defaultConfig from '../config/configDefault';
import { getCookie } from '../util/cookieParser';

export const LOCALE_CODE = {
  // English - United States
  EN_US: 'en-US',
  // German - Germany
  DE_DE: 'de-DE',
  // French - France
  FR_FR: 'fr-FR',
  // Italian - Italy
  IT_IT: 'it-IT',
};

export const LANG_TO_LOCALE = {
  english: LOCALE_CODE.EN_US,
  german: LOCALE_CODE.DE_DE,
  french: LOCALE_CODE.FR_FR,
  italian: LOCALE_CODE.IT_IT,
};

export const LANGUAGE_CODES = Object.values(LOCALE_CODE);

// The language is prepended to every url path
export const LANG_URL_PARAM = `:lang(${LANGUAGE_CODES.join('|')})?`;

export const getLocaleFromUrl = url => {
  const cookieLocale = getCookie('language');
  const preferredLocale = getBrowserLocale();

  const localePriority = cookieLocale || preferredLocale || defaultConfig.localization.locale;

  if (!url && typeof window === 'undefined') return localePriority;

  const locationFinal = url || window.location.pathname;
  const fromUrl = LANGUAGE_CODES.find(locale => locationFinal.includes(locale));

  return fromUrl || localePriority;
};

const getBrowserLocale = () => {
  if (typeof window === 'undefined') return false;

  const navigatorLocale =
    navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

  // Extract the base language code, e.g., "en" from "en-US"
  const baseLang = navigatorLocale.split('-')[0];

  // Check if there is a full match or a base language match in your LANGUAGE_CODES list
  const exactMatch = LANGUAGE_CODES.find(
    code => code.toLowerCase() === navigatorLocale.toLowerCase()
  );
  const baseMatch = LANGUAGE_CODES.find(code =>
    code.toLowerCase().startsWith(baseLang.toLowerCase())
  );

  // Return the exact match, or the base match, or the default locale
  return exactMatch || baseMatch;
};
