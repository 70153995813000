import React, { useMemo } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { useIntl } from 'react-intl';
import defaultLocations from '../../config/configDefaultLocationSearches';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const intl = useIntl();

  const cardContent = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'SectionHowItWorks.card1Title' }),
        content: intl.formatMessage({ id: 'SectionHowItWorks.card1Content' }),
      },
      {
        title: intl.formatMessage({ id: 'SectionHowItWorks.card2Title' }),
        content: intl.formatMessage({ id: 'SectionHowItWorks.card2Content' }),
      },
      {
        title: intl.formatMessage({ id: 'SectionHowItWorks.card3Title' }),
        content: intl.formatMessage({ id: 'SectionHowItWorks.card3Content' }),
      },
    ],
    [intl]
  );

  return (
    <div className={classes}>
      <div className={css.content}>
        <div>
          <h2 className={css.title}>
            <FormattedMessage id="SectionHowItWorks.title" />
          </h2>
          <div className={css.wrapper}>
            {cardContent.map(({ title, content }, i) => (
              <div key={i} className={css.card}>
                <h4>{`${i + 1} - ${title}`}</h4>
                <p>{content}</p>
                {i === 0 ? (
                  <NamedLink
                    name="SearchPage"
                    to={{ search: defaultLocations[0].search }}
                    className={css.btnLike}
                  >
                    <FormattedMessage id="SectionHowItWorks.cta" />
                  </NamedLink>
                ) : null}
              </div>
            ))}
            <NamedLink
              name="SearchPage"
              to={{ search: defaultLocations[0].search }}
              className={css.btnLikeMobile}
            >
              <FormattedMessage id="SectionHowItWorks.cta" />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
