import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFacebook.module.css';

const IconFacebook = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(-37 -148)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(37 148)" />
        <path d="M67.001 165.576a12.5 12.5 0 1 0-14.454 12.349v-8.735h-3.175v-3.614h3.175v-2.754c0-3.133 1.865-4.863 4.721-4.863a19.237 19.237 0 0 1 2.8.244v3.075H58.49a1.807 1.807 0 0 0-2.037 1.952v2.346h3.467l-.554 3.613h-2.912v8.735a12.5 12.5 0 0 0 10.547-12.348Z" />
      </g>
    </svg>
  );
};

IconFacebook.defaultProps = {
  rootClassName: null,
  className: null,
};

IconFacebook.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFacebook;
