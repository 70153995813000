import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPlusUser.module.css';

const IconPlusUser = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(-356 -149)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(356 149)" />
        <path d="M376.477 165.591a3.636 3.636 0 1 0-3.636-3.636 3.635 3.635 0 0 0 3.636 3.636Zm-8.182-1.818v-2.728h-1.818v2.727h-2.727v1.818h2.727v2.728h1.818v-2.727h2.728v-1.818Zm8.182 3.636c-2.427 0-7.273 1.218-7.273 3.636v1.818h14.546v-1.818c0-2.418-4.845-3.636-7.273-3.636Z" />
      </g>
    </svg>
  );
};

IconPlusUser.defaultProps = {
  rootClassName: null,
  className: null,
};

IconPlusUser.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconPlusUser;
