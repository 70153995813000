import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconRoute.module.css';

const IconRoute = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 20 20"
    >
      <path d="M15.25,12.13V6a3,3,0,0,0-6,0v8a1.5,1.5,0,0,1-3,0V7.87A2.491,2.491,0,0,0,5.5,3a2.734,2.734,0,0,0-.5.05,2.575,2.575,0,0,0-.9.38,2.5,2.5,0,0,0,0,4.14,2.575,2.575,0,0,0,.9.38,1.829,1.829,0,0,1-.25-.08V14a3,3,0,0,0,6,0V6a1.5,1.5,0,0,1,3,0v6.13a1.829,1.829,0,0,1,.25-.08,2.45,2.45,0,0,0-.89.38,2.5,2.5,0,0,0,0,4.14,2.607,2.607,0,0,0,.89.38,2.734,2.734,0,0,0,.5.05,2.491,2.491,0,0,0,.75-4.87ZM4.5,5.5a1,1,0,1,1,1,1A1,1,0,0,1,4.5,5.5Zm10,10a1,1,0,1,1,1-1A1,1,0,0,1,14.5,15.5Z" />
    </svg>
  );
};

IconRoute.defaultProps = {
  rootClassName: null,
  className: null,
};

IconRoute.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconRoute;
