/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

import { TX_TRANSITION_ACTOR_CUSTOMER, TX_TRANSITION_ACTOR_PROVIDER } from './transaction';
import {
  getTransitionsToState,
  hasPassedTransition,
  txLastTransition,
  txTransitions,
} from './transactionsUtil';

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',
  REQUEST_PAYMENT_FREE: 'transition/request-payment-free',

  // A customer can also initiate a transaction with an enquiry, and
  // then transition that with a request.
  ENQUIRE: 'transition/enquire',
  REQUEST_PAYMENT_AFTER_ENQUIRY: 'transition/request-payment-after-enquiry',
  REQUEST_PAYMENT_AFTER_ENQUIRY_FREE: 'transition/request-payment-after-enquiry-free',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  CONFIRM_PAYMENT_FREE: 'transition/confirm-payment-free',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  PAYMENT_EXPIRED_STRIPE: 'transition/payment-expired-stripe',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',
  ACCEPT_FREE: 'transition/accept-free',
  DECLINE: 'transition/decline',

  // The backend automatically expire the transaction in 24h.
  EXPIRE: 'transition/expire',

  DECLINED_STRIPE: 'transition/declined-stripe',

  // Admin can also cancel the transition.
  CANCEL_PENDING_PAYMENT: 'transition/cancel-pending-payment',
  CANCEL_PREAUTHORIZED: 'transition/cancel-preauthorized',
  CANCEL_PREAUTHORIZED_BY_CUSTOMER: 'transition/cancel-preauthorized-by-customer',
  CANCEL_ACCEPTED: 'transition/cancel-accepted',
  CANCEL_ACCEPTED_BY_PROVIDER: 'transition/cancel-accepted-by-provider',
  CANCEL_ACCEPTED_BY_CUSTOMER: 'transition/cancel-accepted-by-customer',

  AUTO_REFUND: 'transition/auto-refund',
  MANUAL_REFUND: 'transition/manual-refund',
  FULL_PAYOUT: 'transition/full-payout',

  CANCELED_STRIPE: 'transition/cancelled-stripe',

  // Marks the start of the booking
  START: 'transition/start',

  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',

  // Payout transition
  DELIVER_MANUAL: 'transition/deliver-manual',
  DELIVER_FREE: 'transition/deliver-free',
  PAYOUT_AUTO: 'transition/payout-auto',
  FULL_REFUND_AUTO: 'transition/full-refund-auto',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

export const TRANSITIONS_ARRAY = Object.values(transitions);

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  ENQUIRY: 'enquiry',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  CANCELLED: 'cancelled',
  CANCEL_UNRESOLVED: 'cancel-unresolved',
  CANCEL_RESOLVED: 'cancel-resolved',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  STARTED: 'started',
  COMPLETED: 'completed',
  DELIVERED: 'delivered',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED: 'reviewed',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'main-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.ENQUIRE]: states.ENQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.REQUEST_PAYMENT_FREE]: states.PENDING_PAYMENT,
      },
    },

    [states.ENQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_ENQUIRY]: states.PENDING_PAYMENT,
        [transitions.REQUEST_PAYMENT_AFTER_ENQUIRY_FREE]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CANCEL_PENDING_PAYMENT]: states.CANCELLED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
        [transitions.CONFIRM_PAYMENT_FREE]: states.PREAUTHORIZED,
      },
    },

    [states.PAYMENT_EXPIRED]: {
      on: {
        [transitions.PAYMENT_EXPIRED_STRIPE]: states.PAYMENT_EXPIRED,
      },
    },

    [states.PREAUTHORIZED]: {
      on: {
        [transitions.CANCEL_PREAUTHORIZED]: states.CANCELLED,
        [transitions.CANCEL_PREAUTHORIZED_BY_CUSTOMER]: states.CANCELLED,
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.ACCEPT_FREE]: states.ACCEPTED,
        [transitions.DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.DECLINED,
      },
    },

    [states.DECLINED]: {
      on: {
        [transitions.DECLINED_STRIPE]: states.DECLINED,
      },
    },

    [states.ACCEPTED]: {
      on: {
        [transitions.CANCEL_ACCEPTED]: states.CANCEL_UNRESOLVED,
        [transitions.CANCEL_ACCEPTED_BY_PROVIDER]: states.CANCEL_UNRESOLVED,
        [transitions.CANCEL_ACCEPTED_BY_CUSTOMER]: states.CANCEL_UNRESOLVED,
        [transitions.START]: states.STARTED,
      },
    },

    [states.STARTED]: {
      on: {
        [transitions.COMPLETE]: states.COMPLETED,
      },
    },

    [states.CANCELLED]: {
      on: {
        [transitions.CANCELED_STRIPE]: states.CANCELLED,
      },
    },

    [states.CANCEL_UNRESOLVED]: {
      on: {
        [transitions.AUTO_REFUND]: states.CANCEL_RESOLVED,
        [transitions.MANUAL_REFUND]: states.CANCEL_RESOLVED,
        [transitions.FULL_PAYOUT]: states.CANCEL_RESOLVED,
      },
    },

    [states.CANCEL_RESOLVED]: {},

    [states.COMPLETED]: {
      on: {
        [transitions.DELIVER_MANUAL]: states.DELIVERED,
        [transitions.DELIVER_FREE]: states.DELIVERED,
        [transitions.PAYOUT_AUTO]: states.DELIVERED,
        [transitions.FULL_REFUND_AUTO]: states.DELIVERED,
      },
    },

    [states.DELIVERED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },

    [states.REVIEWED]: { type: 'final' },
  },
};

const getTransitionsToStateFn = state => getTransitionsToState({ graph }, state);

const hasPassedStateFn = state => tx =>
  getTransitionsToStateFn(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const TRANSITIONS_TO_ENQUIRED = getTransitionsToStateFn(states.ENQUIRY);
export const TRANSITIONS_TO_REQUESTED = getTransitionsToStateFn(states.PREAUTHORIZED);
export const TRANSITIONS_TO_ACCEPTED = [
  ...getTransitionsToStateFn(states.ACCEPTED),
  ...getTransitionsToStateFn(states.STARTED),
];
export const TRANSITIONS_TO_DECLINED = getTransitionsToStateFn(states.DECLINED);
export const TRANSITIONS_TO_CANCELED = [
  ...getTransitionsToStateFn(states.CANCELLED),
  ...getTransitionsToStateFn(states.CANCEL_UNRESOLVED),
  ...getTransitionsToStateFn(states.CANCEL_RESOLVED),
];
export const TRANSITIONS_TO_COMPLETED = getTransitionsToStateFn(states.COMPLETED);
export const TRANSITIONS_TO_DELIVERED = getTransitionsToStateFn(states.DELIVERED);
export const TRANSITIONS_TO_REVIEW =
  getTransitionsToStateFn(states.REVIEWED_BY_CUSTOMER) ||
  getTransitionsToStateFn(states.REVIEWED_BY_PROVIDER);
export const TRANSITIONS_TO_REVIEWED = getTransitionsToStateFn(states.REVIEWED);

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.CONFIRM_PAYMENT,
    transitions.CONFIRM_PAYMENT_FREE,
    transitions.CANCEL_PENDING_PAYMENT,
    transitions.CANCEL_PREAUTHORIZED,
    transitions.CANCEL_PREAUTHORIZED_BY_CUSTOMER,
    transitions.ACCEPT,
    transitions.ACCEPT_FREE,
    transitions.DECLINE,
    transitions.EXPIRE,
    transitions.CANCEL_ACCEPTED,
    transitions.CANCEL_ACCEPTED_BY_PROVIDER,
    transitions.CANCEL_ACCEPTED_BY_CUSTOMER,
    transitions.AUTO_REFUND,
    transitions.FULL_PAYOUT,
    transitions.DELIVER_MANUAL,
    transitions.DELIVER_FREE,
    transitions.PAYOUT_AUTO,
    transitions.FULL_REFUND_AUTO,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

export const transitionIsReviewed = transition =>
  getTransitionsToStateFn(states.REVIEWED).includes(transition);

export const hasCustomerCanceled = transition =>
  [transitions.CANCEL_PREAUTHORIZED_BY_CUSTOMER, transitions.CANCEL_ACCEPTED_BY_CUSTOMER].includes(
    transition
  );

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_ENQUIRY].includes(
    transition
  );
};

export const txIsEnquired = tx =>
  getTransitionsToStateFn(states.ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToStateFn(states.PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToStateFn(states.PAYMENT_EXPIRED).includes(txLastTransition(tx));

export const txIsRequested = tx =>
  getTransitionsToStateFn(states.PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToStateFn(states.ACCEPTED).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToStateFn(states.CANCELLED).includes(txLastTransition(tx));

export const txIsCanceledUnresolved = tx =>
  getTransitionsToStateFn(states.CANCEL_UNRESOLVED).includes(txLastTransition(tx));

export const txIsCanceledResolved = tx =>
  getTransitionsToStateFn(states.CANCEL_RESOLVED).includes(txLastTransition(tx));

export const txIsStarted = tx =>
  getTransitionsToStateFn(states.STARTED).includes(txLastTransition(tx));

export const txIsCompleted = tx =>
  getTransitionsToStateFn(states.COMPLETED).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToStateFn(states.DELIVERED).includes(txLastTransition(tx));

export const txHasBeenAccepted = hasPassedStateFn(states.ACCEPTED);
export const txHasBeenCompleted = hasPassedStateFn(states.COMPLETED);
export const txHasBeenDelivered = hasPassedStateFn(states.DELIVERED);

export const txHasDeliveredPayout = tx => hasPassedTransition(transitions.PAYOUT_AUTO, tx);

export const txIsDeclined = tx =>
  getTransitionsToStateFn(states.DECLINED).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToStateFn(states.REVIEWED).includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToStateFn(states.REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToStateFn(states.REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const getReview1Transition = isCustomer =>
  isCustomer ? transitions.REVIEW_1_BY_CUSTOMER : transitions.REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? transitions.REVIEW_2_BY_CUSTOMER : transitions.REVIEW_2_BY_PROVIDER;

const withoutStripeTransitions = [
  transitions.REQUEST_PAYMENT_FREE,
  transitions.REQUEST_PAYMENT_AFTER_ENQUIRY_FREE,
];

export const txIsWithoutStripe = tx =>
  txTransitions(tx).some(({ transition }) => withoutStripeTransitions.includes(transition));

export const txIsCancelResolvedRefunded = tx => txLastTransition(tx) === transitions.AUTO_REFUND;

export const statesNeedingProviderAttention = getTransitionsToStateFn(states.PREAUTHORIZED);

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;
