import React, { useMemo } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ActivityCard, Carousel, Activity } from '..';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './SectionActivities.module.css';
import { useConfiguration } from '../../context/configurationContext';

const SectionActivities = props => {
  const { rootClassName, className, intl, activitiesCount } = props;

  const config = useConfiguration();

  const classes = classNames(rootClassName || css.root, className);

  const activities = useMemo(
    () => findOptionsForSelectFilter('activity', config.search.defaultFilters),
    [config.search.defaultFilters]
  );

  const activitiesCardData = useMemo(
    () =>
      activities.reduce((acc, { key, label }) => {
        return (
          (acc[key] = {
            label: intl.formatMessage({ id: label }),
            icon: <Activity activity={key} hideLabel />,
          }) && acc
        );
      }, []),
    [activities, intl]
  );

  const activityColumns = useMemo(
    () =>
      activitiesCount.reduce((columns, item, index) => {
        return (
          (index % 2 === 0 ? columns.push([item]) : columns[columns.length - 1].push(item)) &&
          columns
        );
      }, []),
    [activitiesCount]
  );

  return (
    <div className={classes}>
      <div className={css.content}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionActivities.title" />
        </h2>

        {activityColumns.length ? (
          <Carousel className={css.carousel} contentClassName={css.carouselContent}>
            {activityColumns.map((activityColumn, index) => {
              return (
                <div className={css.carouselColumn} key={index}>
                  <ActivityCard
                    activity={{
                      key: activityColumn[0].activity,
                      count: activityColumn[0].count,
                      ...activitiesCardData[activityColumn[0].activity],
                    }}
                  />
                  {activityColumn[1] && (
                    <ActivityCard
                      activity={{
                        key: activityColumn[1].activity,
                        count: activityColumn[1].count,
                        ...activitiesCardData[activityColumn[1].activity],
                      }}
                    />
                  )}
                </div>
              );
            })}
          </Carousel>
        ) : null}
      </div>
    </div>
  );
};

SectionActivities.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionActivities.propTypes = {
  rootClassName: string,
  className: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SectionActivities);
