import React from 'react';
import { string } from 'prop-types';

const IconParagliding = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 47 53">
      <path
        d="M46.991-511.867a70.437,70.437,0,0,0-16.746,2.993c-1.685.523-2.187.806-2.679,1.539a4.111,4.111,0,0,0-.22,3.726c.356.691,4.793,7.033,5.3,7.578a2.9,2.9,0,0,0,1.915.9h.492l3.3,4.448c3.056,4.124,3.3,4.479,3.192,4.731-.126.335-.115.4.754,4.029a30.364,30.364,0,0,0,.89,3.276c.167.262.848.628,2.9,1.559l.806.366v6.876l-.837.984a6.68,6.68,0,0,0-.963,1.392,51.607,51.607,0,0,0,.366,8.1,1.734,1.734,0,0,0,3.077.021c.209-.429.241-.691.345-3.548L49-465.984l.9-.921.911-.911.911.911.921.921L52.758-463c.063,1.643.157,3.15.209,3.36a2.042,2.042,0,0,0,1.287,1.2,1.782,1.782,0,0,0,2.03-1.266c.1-.419.335-5.181.335-6.834,0-1.015-.063-1.172-.931-2.208l-.638-.764v-7.211l1.078-.492c2.062-.942,2.5-1.193,2.7-1.591.1-.2.492-1.685.869-3.3.858-3.684.827-3.527.7-3.852-.1-.262.115-.576,3.182-4.71l3.286-4.438.617-.052a2.664,2.664,0,0,0,1.811-.89c.492-.523,4.93-6.866,5.3-7.567a4.11,4.11,0,0,0-.22-3.726c-.492-.733-.994-1.015-2.679-1.539A71.3,71.3,0,0,0,46.991-511.867Zm6.541,14.223c1.1.063,2.606.178,3.349.272,1.277.146,4.459.659,4.532.722.031.031-2.25,8.54-2.5,9.315a.725.725,0,0,1-.5.482,1.449,1.449,0,0,0-.565.419c-.094.136-.534,1.423-.994,2.868l-.827,2.617-1.245.492-1.245.492H48.4l-1.245-.492-1.245-.492-.816-2.606c-.461-1.434-.9-2.721-.994-2.868a1.591,1.591,0,0,0-.576-.429.725.725,0,0,1-.5-.482c-.251-.775-2.533-9.283-2.5-9.315.01-.01.743-.146,1.6-.293A49.053,49.053,0,0,1,53.533-497.644Zm-13.773,2.062c.115.45.659,2.439,1.2,4.427s.973,3.621.963,3.632c-.031.042-5.767-7.745-5.8-7.871-.021-.084.4-.251,1.151-.45.649-.178,1.35-.366,1.549-.429C39.508-496.461,39.518-496.45,39.759-495.582Zm24.072-.492c1.79.471,2.009.544,1.978.68s-5.767,7.9-5.8,7.871c-.01-.01.419-1.643.963-3.632s1.088-3.977,1.2-4.427.272-.806.345-.806S63.172-496.241,63.831-496.073Z"
        transform="translate(-27 512)"
      />
      <path
        d="M226.983-273.608a3.235,3.235,0,0,0,.492,5.976,3.246,3.246,0,0,0,3.9-1.706,2.561,2.561,0,0,0,.241-1.34,2.572,2.572,0,0,0-.3-1.5,2.971,2.971,0,0,0-2.784-1.727A2.239,2.239,0,0,0,226.983-273.608Z"
        transform="translate(-204 298)"
      />
    </svg>
  );
};

IconParagliding.defaultProps = { className: null };
IconParagliding.propTypes = { className: string };

export default IconParagliding;
