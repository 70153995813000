import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPercent.module.css';

const IconPercent = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        d="M4.375,8.75A4.375,4.375,0,1,0,0,4.375,4.373,4.373,0,0,0,4.375,8.75Zm0-6.25A1.875,1.875,0,1,1,2.5,4.375,1.875,1.875,0,0,1,4.375,2.5Zm8.75,8.75A4.375,4.375,0,1,0,17.5,15.625,4.373,4.373,0,0,0,13.125,11.25Zm0,6.25A1.875,1.875,0,1,1,15,15.625,1.875,1.875,0,0,1,13.125,17.5ZM15.324.008l1.234,0a.937.937,0,0,1,.77,1.477L3.023,19.594a.936.936,0,0,1-.766.4l-1.3,0a.938.938,0,0,1-.77-1.477L14.559.406A.936.936,0,0,1,15.324.008Z"
        transform="translate(8.75 7.5)"
      />
    </svg>
  );
};

IconPercent.defaultProps = {
  rootClassName: null,
  className: null,
};

IconPercent.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconPercent;
