import React, { useCallback, useMemo, useState } from 'react';
import { arrayOf, bool, object, shape, string } from 'prop-types';
import { FieldCheckbox } from '..';
import classNames from 'classnames';

import css from './SearchableMultiSelect.module.css';

const getLastInputtedChar = (inputEventValue, formattedSelectedOptions) => {
  const inputEventValueAsArray = inputEventValue.split('');
  const formattedSelectedOptionsAsArray = formattedSelectedOptions.split('');

  // This means that we have pressed the backspace button and the input value is
  // less than the selected options, so we should clear the input
  if (inputEventValueAsArray.length < formattedSelectedOptionsAsArray.length) return '';

  // Find the first char that is different from the second string;
  // That char is the last inputted char
  for (let index = 0; index < inputEventValueAsArray.length; index++)
    if (inputEventValueAsArray[index] !== formattedSelectedOptionsAsArray[index])
      return inputEventValueAsArray[index];

  return '';
};

const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options, noSearchFoundText } = props;

  const noOptions = useMemo(() => options.length === 0, [options.length]);

  return (
    <fieldset className={className} tabIndex="-1">
      <ul className={css.list}>
        {noOptions ? <li className={css.item}> {noSearchFoundText}</li> : null}

        {options.map(option => {
          const fieldId = `${id}.${option.key}`;

          return (
            <li key={fieldId} className={css.item}>
              <span className={css.itemBorder} />
              <FieldCheckbox
                id={fieldId}
                name={name}
                label={option.label}
                value={option.key}
                useMarketplaceColor
              />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const SearchableMultiSelect = props => {
  const {
    className,
    // search input
    inputContainerClassName,
    inputClassName,
    id,
    name,
    label,
    placeholder,
    noSearchFoundText,
    readOnly,
    // multi select options
    optionsClassName,
    options,
    optionsId,
    optionsName,
    selectedOptions,
    tabIndex,
    form,
    // track input active state
    onFocus,
    onBlur,

    isAlwaysOpen,
  } = props;

  const [searchValue, setSearchValue] = useState('');

  const inputContainerClasses = useMemo(
    () => classNames(css.inputContainer, inputContainerClassName),
    [inputContainerClassName]
  );

  const optionsClassNames = useMemo(
    () => classNames(css.options, optionsClassName, { [css.flatOptions]: isAlwaysOpen }),
    [isAlwaysOpen, optionsClassName]
  );

  const filteredOptions = useMemo(
    () => options.filter(({ key }) => key.toLowerCase().includes(searchValue.toLowerCase())),
    [options, searchValue]
  );

  const formattedOptions = useMemo(
    () => selectedOptions.map(option => options?.find(o => o.key === option)?.label).join(', '),
    [options, selectedOptions]
  );

  const handleOnChange = useCallback(
    event => {
      if (selectedOptions.length > 0) {
        const latestChar = getLastInputtedChar(event.target.value, formattedOptions);

        form.change(optionsName, []);
        setSearchValue(latestChar);
      } else setSearchValue(event.target.value);
    },
    [form, formattedOptions, optionsName, selectedOptions.length]
  );

  return (
    <div className={className} tabIndex={tabIndex}>
      <div className={inputContainerClasses}>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          name={name}
          type="text"
          placeholder={placeholder}
          className={inputClassName}
          autoComplete="off"
          value={formattedOptions || searchValue}
          onChange={handleOnChange}
          readOnly={readOnly}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      <div
        className={classNames(css.optionsContainer, { [css.alwaysOpen]: isAlwaysOpen })}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <GroupOfFieldCheckboxes
          options={filteredOptions}
          id={optionsId}
          name={optionsName}
          className={optionsClassNames}
          noSearchFoundText={noSearchFoundText}
        />
      </div>
    </div>
  );
};

SearchableMultiSelect.defaultProps = {
  selectedOptions: [],
  readOnly: false,
  onFocus: () => {},
  onBlur: () => {},
};

SearchableMultiSelect.propTypes = {
  className: string,
  inputContainerClassName: string,
  inputClassName: string,
  optionsClassName: string,

  id: string.isRequired,
  name: string.isRequired,
  label: string,
  placeholder: string,
  readOnly: bool,

  options: arrayOf(shape({ key: string.isRequired, label: string.isRequired })),
  optionsId: string.isRequired,
  optionsName: string.isRequired,
  selectedOptions: arrayOf(string.isRequired),

  form: object.isRequired,
};

export default SearchableMultiSelect;
