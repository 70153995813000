import { convertToDecimal } from './currency';
import { types as sdkTypes } from './sdkLoader';
import { ensureBooking, ensureListing, ensureTransaction, ensureUser } from './data';

const { UUID, Money, LatLng } = sdkTypes;

export const parseImagesToSharetribe = images => {
  if (!images || images.length < 1 || !images[0]) return null;

  return images.map(image => {
    const { _id, attributes, ...rest } = image;
    const { variants, ...restOfAttributes } = attributes;
    const { _id: variantsId, ...restOfVariants } = variants;

    return {
      ...rest,
      id: new UUID(_id),
      attributes: {
        ...restOfAttributes,
        variants: {
          ...restOfVariants,
        },
      },
    };
  });
};

export const parseUserToSharetribe = user => {
  if (!user) return ensureUser({});

  const { _id, profileImage, ...rest } = user;
  const parsedProfileImage = parseImagesToSharetribe([profileImage]);
  const profileImageMaybe = parsedProfileImage ? { profileImage: parsedProfileImage[0] } : {};

  const userToReturn = {
    ...rest,
    id: new UUID(_id),
    type: 'user',
    ...profileImageMaybe,
  };

  return ensureUser(userToReturn);
};

export const parseListingToSharetribe = (listing, user) => {
  if (!listing) return ensureListing({});

  const { _id, attributes, images, ...rest } = listing;
  const { price, geolocation, ...restAttributes } = attributes;

  const listingToReturn = {
    ...rest,
    id: new UUID(_id),
    attributes: {
      ...restAttributes,
      price: price ? new Money(price.amount, price.currency) : null,
      geolocation: geolocation ? new LatLng(geolocation.lat, geolocation.lng) : null,
    },
    author: parseUserToSharetribe(user),
    images: parseImagesToSharetribe(images),
  };

  return ensureListing(listingToReturn);
};

export const parseBookingToSharetribe = booking => {
  if (!booking) return ensureBooking({});

  const { sharetribeId, startsAt, endsAt, state, seats, ...rest } = booking;

  const bookingToReturn = {
    ...rest,
    id: new UUID(sharetribeId),
    type: 'booking',
    attributes: {
      start: new Date(startsAt),
      end: new Date(endsAt),
      displayStart: new Date(startsAt),
      displayEnd: new Date(endsAt),
      state,
      seats,
    },
  };

  return ensureBooking(bookingToReturn);
};

const parseLineItemsToSharetribe = lineItems =>
  lineItems.map(({ lineTotal, unitPrice, quantity, units, ...rest }) => ({
    ...rest,
    lineTotal: lineTotal ? new Money(lineTotal.amount, lineTotal.currency) : null,
    unitPrice: unitPrice ? new Money(unitPrice.amount, unitPrice.currency) : null,
    quantity: quantity ? convertToDecimal(quantity.value) : null,
    units: units ? units.value : null,
  }));

const parseTransitionsToSharetribe = transitions =>
  transitions.map(t => ({ ...t, createdAt: new Date(t.createdAt) }));

// TODO: we may need to update this
export const parseTxToSharetribe = tx => {
  if (!tx) return ensureTransaction({});

  const {
    lastTransition,
    lastTransitionedAt,
    transitions,
    listing,
    seller,
    buyer,
    payinTotal,
    payoutTotal,
    lineItems,
    booking,
    createdAt,
    sharetribeId,
    ...rest
  } = tx;

  const txToReturn = {
    ...rest,
    id: new UUID(sharetribeId),
    type: 'transaction',
    attributes: {
      lastTransition,
      lastTransitionedAt: new Date(lastTransitionedAt),
      createdAt: new Date(createdAt),
      payinTotal: payinTotal ? new Money(payinTotal.amount, payinTotal.currency) : null,
      payoutTotal: payoutTotal ? new Money(payoutTotal.amount, payoutTotal.currency) : null,
      lineItems: lineItems ? parseLineItemsToSharetribe(lineItems) : [],
      transitions: parseTransitionsToSharetribe(transitions),
    },
    listing: parseListingToSharetribe(listing, seller),
    booking: parseBookingToSharetribe(booking),
    customer: parseUserToSharetribe(buyer),
    provider: parseUserToSharetribe(seller),
  };

  return ensureTransaction(txToReturn);
};
