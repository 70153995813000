import React, { Component, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { node, number, oneOfType, string } from 'prop-types';
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';
import { filterNumber, parseNumber } from '../../util/sanitize';

import css from './FieldNumberIncrementerInput.module.css';

const FieldNumberIncrementerInputComponent = props => {
  const {
    className,
    rootClassName,
    inputRootClass,
    descriptionClassName,
    id,
    input,
    icon,
    min,
    max,
    label,
    meta,
    description,
    fieldClassName,
    buttonsDisabled,
    onChange: propsOnChange,
    ...rest
  } = props;

  const [touched, setTouched] = useState(false);

  const { value, onChange: inputOnChange, ...restOfInput } = input;

  const { invalid, error } = meta;

  const hasError = useMemo(() => !!error || !!(touched && invalid && error), [
    error,
    invalid,
    touched,
  ]);

  const fieldMeta = useMemo(() => ({ touched: hasError, error }), [error, hasError]);

  const onChange = useCallback(
    numberOrEvent => {
      // If we have target, then this is the native onChange event
      // else, it's called from our fns
      const value = numberOrEvent?.target ? numberOrEvent.target.value : numberOrEvent;

      if (!value && +value !== 0) return;

      // If we have min or max, we need to
      // keep the value between those two
      let safeNum = +value;

      if (safeNum > max) {
        safeNum = max;
      }

      if (safeNum < min) {
        safeNum = min;
      }

      inputOnChange(safeNum);
      propsOnChange?.(safeNum);
    },
    [inputOnChange, max, min, propsOnChange]
  );

  const inputProps = useMemo(
    () => ({
      id,
      value,
      onChange,
      min,
      max,
      ...restOfInput,
      ...rest,
    }),
    [id, max, min, onChange, rest, restOfInput, value]
  );

  const isSubDisabled = useMemo(() => +value <= +min || buttonsDisabled, [
    buttonsDisabled,
    min,
    value,
  ]);

  const onSubClick = useCallback(() => {
    onChange(+value - 1);

    setTouched(true);
  }, [onChange, value]);

  const isAddDisabled = useMemo(() => +value >= +max || buttonsDisabled, [
    buttonsDisabled,
    max,
    value,
  ]);
  const onAddClick = useCallback(() => {
    onChange(+value + 1);

    setTouched(true);
  }, [onChange, value]);

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);
  const inputClasses = classNames(inputRootClass || css.input);

  return (
    <div className={classes}>
      <div className={classNames(css.field, fieldClassName)}>
        {(label || description) && (
          <div className={css.labelContainer}>
            {label && <label>{label}</label>}
            {description && (
              <div className={classNames(css.description, descriptionClassName)}>{description}</div>
            )}
          </div>
        )}

        <div className={css.incrementerContainer}>
          {icon && <div className={css.additionalIcon}>{icon}</div>}
          <button
            type="button"
            className={css.button}
            disabled={isSubDisabled}
            onClick={onSubClick}
          >
            <span className={classNames(css.icon, { [css.iconDisabled]: isSubDisabled })}>-</span>
          </button>
          <input {...inputProps} className={inputClasses} />
          <button
            type="button"
            className={css.button}
            disabled={isAddDisabled}
            onClick={onAddClick}
          >
            <span className={classNames(css.icon, { [css.iconDisabled]: isAddDisabled })}>+</span>
          </button>
        </div>
      </div>
      <ValidationError className={css.errorMessage} fieldMeta={fieldMeta} />
    </div>
  );
};

FieldNumberIncrementerInputComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

FieldNumberIncrementerInputComponent.propTypes = {
  className: string,
  rootClassName: string,
  descriptionClassName: string,
  label: string,
  description: string,
  icon: node,
  min: oneOfType([string, number]),
  max: oneOfType([string, number]),
};

class FieldNumberIncrementerInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return (
      <Field
        type="number"
        component={FieldNumberIncrementerInputComponent}
        {...this.props}
        parse={parseNumber}
        onKeyPress={filterNumber}
        defaultValue={this.props.defaultValue || 0}
      />
    );
  }
}

export default FieldNumberIncrementerInput;
