import classNames from 'classnames';
import { node, oneOfType, string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ErrorMessage.module.css';

const ErrorMessage = props => (
  <h5 className={classNames(props.rootClassName || css.root, props.className)}>
    {props.errorMessage}
  </h5>
);

ErrorMessage.defaultProps = {
  className: null,
  rootClassName: null,
  errorMessage: <FormattedMessage id="General.error" />,
};

ErrorMessage.propTypes = {
  className: string,
  rootClassName: string,
  errorMessage: oneOfType([string, node]),
};

export default ErrorMessage;
