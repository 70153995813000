import React from 'react';
import { string, number } from 'prop-types';

const GermanFlag = props => {
  const { className, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={width}
      viewBox="0 0 512 512"
    >
      <g>
        <path fill="#ffda44" d="m0 345 256.7-25.5L512 345v167H0z" />
        <path fill="#d80027" d="m0 167 255-23 257 23v178H0z" />
        <path fill="#333" d="M0 0h512v167H0z" />
      </g>
    </svg>
  );
};

GermanFlag.defaultProps = { className: null, width: 22 };
GermanFlag.propTypes = { className: string, width: number };

export default GermanFlag;
