import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconNotification.module.css';

const IconNotification = props => {
  const { className, rootClassName } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="14.625"
      height="13.625"
      viewBox="0 0 14.625 13.625"
    >
      <g transform="translate(0.313 0.313)">
        <ellipse
          id="Oval"
          cx="6.563"
          cy="6.063"
          rx="6.563"
          ry="6.063"
          transform="translate(0.438 0.438)"
          fill="red"
          stroke="red"
          strokeWidth="1.5"
        />
        <g transform="translate(6.781 2.533)">
          <path
            d="M.219,0A.219.219,0,1,0,.438.219.219.219,0,0,0,.219,0h0"
            transform="translate(0 7.729)"
            fill="#fdfdfd"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            d="M0,4.813V0"
            transform="translate(0.219)"
            fill="#fdfdfd"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

IconNotification.defaultProps = { className: null, rootClassName: null };

IconNotification.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconNotification;
