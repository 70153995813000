import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFileSignature.module.css';

const IconFileSignature = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 26"
    >
      <path d="M11.363,22.091a.577.577,0,0,0-.53-.34.568.568,0,0,0-.522.322l-.4.8a.833.833,0,0,1-1.535-.109L7.5,20.135,6.947,21.8a2.249,2.249,0,0,1-2.135,1.538H4.167a.833.833,0,1,1,0-1.667h.645a.584.584,0,0,0,.554-.4l.947-2.846a1.25,1.25,0,0,1,2.372,0l.723,2.169a2.337,2.337,0,0,1,3.437.737.591.591,0,0,0,.487.326V17.377L20,10.758V8.333H12.917a1.254,1.254,0,0,1-1.25-1.25V0H1.25A1.247,1.247,0,0,0,0,1.25V25.417a1.247,1.247,0,0,0,1.25,1.25h17.5A1.247,1.247,0,0,0,20,25.417V23.333l-6.667-.006A2.236,2.236,0,0,1,11.363,22.091ZM20,6.349a1.246,1.246,0,0,0-.365-.88l-5.1-5.1A1.249,1.249,0,0,0,13.651,0h-.318V6.667H20ZM15,18.071v3.6h3.593l8.421-8.478L23.479,9.653,15,18.071ZM29.611,8.715l-1.66-1.66a1.327,1.327,0,0,0-1.876,0L24.657,8.474l3.535,3.535,1.419-1.419A1.326,1.326,0,0,0,29.611,8.715Z" />
    </svg>
  );
};

IconFileSignature.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconFileSignature.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconFileSignature;
