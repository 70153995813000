import React, { useMemo } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconRefresh.module.css';

const IconRefresh = props => {
  const { rootClassName, className } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    rootClassName,
    className,
  ]);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      width="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M23.076,8.939a10.008,10.008,0,1,0,2.6,9.568h-2.6A7.5,7.5,0,1,1,16.009,8.5a7.4,7.4,0,0,1,5.278,2.226L17.26,14.755h8.755V6Z"
        transform="translate(-6 -6)"
      ></path>
    </svg>
  );
};

IconRefresh.defaultProps = {
  rootClassName: null,
  className: null,
};

IconRefresh.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconRefresh;
