import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_DISCOUNT_SESSIONS, propTypes } from '../../util/types';
import classNames from 'classnames';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

import css from './OrderBreakdown.module.css';

const LineItemSessionsDiscount = props => {
  const { transaction, intl, isEstimated, sessionsDiscount } = props;
  const sessionsDiscountLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DISCOUNT_SESSIONS && !item.reversal
  );

  if (!sessionsDiscountLineItem || !sessionsDiscount) return null;

  const { lineTotal } = sessionsDiscountLineItem;

  const total = formatMoney(intl, lineTotal, true);

  return (
    <div className={css.lineItem}>
      <span className={classNames(css.itemLabel, { [css.itemGray]: !isEstimated })}>
        <FormattedMessage id="General.discount" />
        <InfoTooltip id="BookingBreakdown.seasonDiscountTooltip" triggerClassName={css.feeTooltip}>
          <FormattedMessage
            id="BookingTimeForm.seasonDiscount"
            values={{
              fromSession: (sessionsDiscount.fromSession || 0) + 1,
              percent: sessionsDiscount.percent,
            }}
          />
        </InfoTooltip>
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  );
};

LineItemSessionsDiscount.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSessionsDiscount;
