import React, { useMemo } from 'react';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Carousel, ListingCardCompact } from '..';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { useSelector } from 'react-redux';

import css from './SectionListings.module.css';
import { useConfiguration } from '../../context/configurationContext';

const SectionListings = props => {
  const { rootClassName, className, listings } = props;

  const { currentUser } = useSelector(state => state.user);
  const config = useConfiguration();

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  const popularListings = useMemo(
    () =>
      listings.length > 0 && (
        <Carousel columns={5} className={css.carousel} contentClassName={css.carouselContent}>
          {listings.map(l => (
            <ListingCardCompact
              key={l.id.uuid}
              className={css.listingCard}
              listing={l}
              currentUser={currentUser}
              config={config}
            />
          ))}
        </Carousel>
      ),
    [config, currentUser, listings]
  );

  return (
    <div className={classes}>
      <div className={css.listingsContent}>
        {popularListings && (
          <div>
            <h2 className={css.title}>
              <FormattedMessage id="SectionListings.popularListingsTitle" />
            </h2>
            {popularListings}
          </div>
        )}
      </div>
    </div>
  );
};

SectionListings.defaultProps = {
  rootClassName: null,
  className: null,
  listings: [],
};

SectionListings.propTypes = {
  rootClassName: string,
  className: string,
  listings: arrayOf(propTypes.listing),
};

export default SectionListings;
