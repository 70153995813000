import React, { useMemo } from 'react';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { isTransactionsTransitionAlreadyReviewed } from '../../../util/errors';
import { propTypes } from '../../../util/types';
import { required } from '../../../util/validators';

import {
  FieldReviewRating,
  Form,
  PrimaryButton,
  FieldTextInput,
  ErrorMessage,
} from '../../../components';

import css from './ReviewForm.module.css';

const ReviewFormComponent = props => {
  const { rootClassName, className, sendReviewError, intl, ...restOfProps } = props;

  const messages = useMemo(
    () => ({
      reviewRatingLabel: intl.formatMessage({ id: 'ReviewForm.reviewRatingLabel' }),
      reviewRatingRequired: intl.formatMessage({ id: 'ReviewForm.reviewRatingRequired' }),
      reviewContentLabel: intl.formatMessage({ id: 'ReviewForm.reviewContentLabel' }),
      reviewContentPlaceholder: intl.formatMessage({ id: 'ReviewForm.reviewContentPlaceholder' }),
      reviewContentRequired: intl.formatMessage({ id: 'ReviewForm.reviewContentRequired' }),
      reviewSubmitAlreadySent: intl.formatMessage({ id: 'ReviewForm.reviewSubmitAlreadySent' }),
      reviewSubmitFailed: intl.formatMessage({ id: 'ReviewForm.reviewSubmitFailed' }),
      reviewSubmit: intl.formatMessage({ id: 'ReviewForm.reviewSubmit' }),
    }),
    [intl]
  );

  const errorMessageMaybe = useMemo(
    () =>
      sendReviewError && (
        <ErrorMessage
          errorMessage={
            isTransactionsTransitionAlreadyReviewed(sendReviewError)
              ? messages.reviewSubmitAlreadySent
              : messages.reviewSubmitFailed
          }
        />
      ),
    [messages, sendReviewError]
  );

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return (
    <FinalForm
      {...restOfProps}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          formId,
          disabled,
          invalid,
          reviewSent,
          sendReviewInProgress,
        } = fieldRenderProps;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldReviewRating
              id={formId ? `${formId}.starRating` : 'starRating'}
              name="reviewRating"
              label={messages.reviewRatingLabel}
              validate={required(messages.reviewRatingRequired)}
            />

            <FieldTextInput
              type="textarea"
              id={formId ? `${formId}.reviewContent` : 'reviewContent'}
              name="reviewContent"
              label={messages.reviewContentLabel}
              placeholder={messages.reviewContentPlaceholder}
              validate={required(messages.reviewContentRequired)}
            />

            {errorMessageMaybe}

            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={sendReviewInProgress}
              disabled={invalid || disabled || sendReviewInProgress}
              ready={reviewSent}
            >
              {messages.reviewSubmit}
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

ReviewFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendReviewError: null,
};

ReviewFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  reviewSent: bool.isRequired,
  sendReviewError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
};

const ReviewForm = injectIntl(ReviewFormComponent);
ReviewForm.displayName = 'ReviewForm';

export default ReviewForm;
