import React from 'react';
import { IconPointLevel } from '..';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './Difficulty.module.css';

const abilityLevels = {
  veryEasy: 1,
  easy: 2,
  intermediate: 3,
  difficult: 4,
};

const Difficulty = props => {
  const { ability, className, hasIconBackground, showFullLabel } = props;

  if (!ability) return null;

  return (
    <div className={classNames(css.root, className)}>
      <IconPointLevel
        backgroundClassName={classNames({ [css.iconBackground]: hasIconBackground })}
        level={abilityLevels[ability]}
      />
      {showFullLabel ? (
        <FormattedMessage id={`General.${ability}`} />
      ) : (
        <FormattedMessage id={`General.${ability}Selected`} />
      )}
    </div>
  );
};

Difficulty.propTypes = {
  className: string,
  ability: string.isRequired,
  hasIconBackground: bool,
  showFullLabel: bool,
};

export default Difficulty;
