import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCrionTravel.module.css';

const IconCrionTravel = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 20 20"
    >
      <path d="M16.5,8.63V8.5a6.5,6.5,0,0,0-13,0C3.5,12.84,10,18,10,18a27.774,27.774,0,0,0,2.7-2.52c.1.01.2.02.3.02a1.99,1.99,0,1,0-1.62-.82c-.49.51-.97.97-1.38,1.35C8.1,14.3,5,10.97,5,8.5a5,5,0,0,1,10,0v.13L13.31,6.94,12.25,8l3.5,3.5L19.25,8,18.19,6.94Z" />
    </svg>
  );
};

IconCrionTravel.defaultProps = {
  rootClassName: null,
  className: null,
};

IconCrionTravel.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCrionTravel;
