import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconLocalOffer.module.css';

const IconLocalOffer = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path d="M27.233,14.454l-11.7-11.7A2.584,2.584,0,0,0,13.7,2H4.6A2.608,2.608,0,0,0,2,4.6v9.1a2.594,2.594,0,0,0,.767,1.846l11.7,11.7A2.584,2.584,0,0,0,16.3,28a2.542,2.542,0,0,0,1.833-.767l9.1-9.1A2.542,2.542,0,0,0,28,16.3,2.626,2.626,0,0,0,27.233,14.454ZM6.55,8.5A1.95,1.95,0,1,1,8.5,6.55,1.947,1.947,0,0,1,6.55,8.5Z" />
    </svg>
  );
};

IconLocalOffer.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconLocalOffer.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconLocalOffer;
