import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { useSelector } from 'react-redux';
import NamedLink from '../NamedLink/NamedLink';
import { parse } from '../../util/urlHelpers';
import { LOCALE_CODE } from '../../constants/locales';
import { parseSelectFilterOptions } from '../../util/search';

import topLocations from '../../assets/topLocations.json';

import css from './SectionTopLocations.module.css';

const SectionTopLocations = props => {
  const { rootClassName, className } = props;

  const intl = useIntl();

  const { interfaceLang } = useSelector(state => state.ui);

  const classes = classNames(rootClassName || css.root, className);

  const activitiesByLocations = topLocations.reduce((locations, location) => {
    const { country } = location['en-US'];

    return { ...locations, [country]: [...(locations[country] || []), location] };
  }, {});

  return (
    <div className={classes}>
      <div className={css.content}>
        {Object.entries(activitiesByLocations).map((entry, index) => {
          const lang = interfaceLang || LOCALE_CODE.EN_US;

          const [, activities] = entry;

          const { name } = activities[0][lang] || activities[0][lang];

          const splitName = name.split(',');

          const country = splitName[splitName.length - 1];

          return (
            <div className={css.country} key={country + index}>
              <h2 className={css.title}>
                <FormattedMessage id="SectionTopLocations.title" values={{ country }} />
              </h2>
              <ul className={css.locations}>
                {activities.map((location, i) => {
                  const { name, url } = location[lang] || location[lang];

                  const langInUrlIndex = url.indexOf(lang);

                  // +8 for en-US/s?
                  const onlySearch = url.slice(langInUrlIndex + 8);

                  const parsedSearch = parse(onlySearch);

                  const activity = parseSelectFilterOptions(parsedSearch.pub_activity)?.[0];

                  const addressWithoutCountry = parsedSearch.address.split(',');

                  addressWithoutCountry.pop();

                  return (
                    <li key={name + i}>
                      <NamedLink name="SearchPage" to={{ search: `?${onlySearch}` }}>
                        {`${
                          activity ? intl.formatMessage({ id: `General.${activity}` }) : ''
                        } ${addressWithoutCountry.join(', ')}`}
                      </NamedLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

SectionTopLocations.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionTopLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTopLocations;
