import React, { useMemo } from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import { IconSpinner, IconTrashBin, SecondaryButton } from '..';

import css from './AddRemoveList.module.css';

const AddRemoveList = props => {
  const {
    className,
    itemClassName,
    addButtonClassName,
    label,
    labelClassName,
    items,
    onAddItem,
    onRemoveItem,
    addItemMessage,
    showBin,
    loadMoreButton,

    fetchInProgress,
    fetchErrorMessage,

    removingItemInProgress,
    removingItemErrorMessage,
    disabled,
  } = props;

  const itemClasses = useMemo(() => classNames(css.item, itemClassName), [itemClassName]);

  const hasItems = useMemo(() => items.length > 0, [items.length]);

  return (
    <div className={className}>
      {label && <label className={labelClassName}>{label}</label>}
      {hasItems && (
        <ul className={css.itemList}>
          {items.map((item, index) => (
            <li key={item.key} className={itemClasses}>
              {item.content}
              {showBin && (
                <button
                  type="button"
                  onClick={() => onRemoveItem(item, index)}
                  className={css.removeItem}
                  disabled={removingItemInProgress === item.key || disabled || item.disabled}
                >
                  {removingItemInProgress === item.key ? (
                    <IconSpinner className={css.removeItemSpinner} />
                  ) : (
                    <IconTrashBin />
                  )}
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
      {(fetchErrorMessage || removingItemErrorMessage) && (
        <div className={css.error}> {fetchErrorMessage || removingItemErrorMessage} </div>
      )}
      {fetchInProgress && (
        <div className={css.spinner}>
          <IconSpinner />
        </div>
      )}

      {loadMoreButton}
      <SecondaryButton
        type="button"
        className={classNames(css.item, css.addButton, addButtonClassName)}
        onClick={onAddItem}
        disabled={disabled}
      >
        {addItemMessage}
        <span className={css.plus}>+</span>
      </SecondaryButton>
    </div>
  );
};

AddRemoveList.defaultProps = {
  items: [],
  fetchInProgress: false,
  removingItemInProgress: '',
  showBin: true,
};

AddRemoveList.propTypes = {
  className: string,
  itemClassName: string,
  addButtonClassName: string,
  label: oneOfType([string, node]),
  addItemMessage: node,
  items: arrayOf(
    shape({ key: string.isRequired, content: node.isRequired, disabled: bool }).isRequired
  ),
  showBin: bool,
  loadMoreButton: node,
  onRemoveItem: func.isRequired,
  onAddItem: func.isRequired,
  fetchInProgress: bool,
  removingItemInProgress: string,
  fetchErrorMessage: node,
  removingItemErrorMessage: node,
  disabled: bool,
};

export default AddRemoveList;
