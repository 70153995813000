import React, { useMemo } from 'react';
import { any, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { AspectRatioWrapper, Promised } from '../../components';

import css from './ImageFromFile.module.css';

const MAX_WIDTH = 1366;
const MAX_HEIGHT = 768;

const getResizedImageDimensions = (width, height) => {
  if (width > MAX_WIDTH) {
    height *= MAX_WIDTH / width;
    width = MAX_WIDTH;
  }
  if (height > MAX_HEIGHT) {
    width *= MAX_HEIGHT / height;
    height = MAX_HEIGHT;
  }

  return { width, height };
};

const readImage = file =>
  new Promise(async (resolve, reject) => {
    try {
      // Decode the image file into an ImageBitmap object to efficiently work with image data
      const bitmap = await createImageBitmap(file);
      const { width, height } = getResizedImageDimensions(bitmap.width, bitmap.height);

      // Make use of canvas to resolve with the lower resolution image
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      canvas.getContext('2d').drawImage(bitmap, 0, 0, width, height);

      canvas.toBlob(blob => {
        if (blob) {
          const dataURL = URL.createObjectURL(blob);
          return resolve(dataURL);
        }
        reject(new Error('Error creating Blob from canvas'));
      });
    } catch (error) {
      reject(new Error('Error processing image'));
    }
  });

const ImageFromFile = props => {
  const { className, rootClassName, aspectWidth, aspectHeight, file, id, children } = props;
  const classes = classNames(rootClassName || css.root, className);

  const promisedImage = useMemo(() => readImage(file), [file]);

  return (
    <Promised
      key={id}
      promise={promisedImage}
      renderFulfilled={dataURL => {
        return (
          <div className={classes}>
            <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
              <img src={dataURL} alt={file.name} className={css.rootForImage} loading="lazy" />
            </AspectRatioWrapper>
            {children}
          </div>
        );
      }}
      renderRejected={() => (
        <div className={classes}>
          <FormattedMessage id="ImageFromFile.couldNotReadFile" />
        </div>
      )}
    />
  );
};

ImageFromFile.defaultProps = {
  className: null,
  children: null,
  rootClassName: null,
  aspectWidth: 1,
  aspectHeight: 1,
};

ImageFromFile.propTypes = {
  className: string,
  rootClassName: string,
  aspectWidth: number,
  aspectHeight: number,
  file: any.isRequired,
  id: string.isRequired,
  children: node,
};

export default ImageFromFile;
