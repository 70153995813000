import React, { useMemo } from 'react';
import { injectIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { ErrorMessage, SecondaryButton } from '../../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const ActionButtons = props => {
  const {
    className,
    rootClassName,
    neutralInProgress,
    acceptInProgress,
    declineInProgress,
    acceptrror,
    declinerror,
    acceptButtonText,
    declineButtonText,
    neutralButtonText,
    reviewButtonText,
    onReview,
    onAccept,
    onDecline,
    onNeutral,
    intl,
  } = props;

  const messages = useMemo(
    () => ({
      declineSaleFailed: intl.formatMessage({ id: 'TransactionPanel.declineSaleFailed' }),
      acceptSaleFailed: intl.formatMessage({ id: 'TransactionPanel.acceptSaleFailed' }),
    }),
    [intl]
  );

  const acceptErrorMessage = acceptrror && (
    <ErrorMessage errorMessage={messages.acceptSaleFailed} />
  );
  const declineErrorMessage = declinerror && (
    <ErrorMessage errorMessage={messages.declineSaleFailed} />
  );

  const classes = useMemo(() => classNames(rootClassName || css.actionButtons, className), [
    className,
    rootClassName,
  ]);

  const disabled = useMemo(() => acceptInProgress || declineInProgress || neutralInProgress, [
    acceptInProgress,
    declineInProgress,
    neutralInProgress,
  ]);

  return (
    <div className={classes}>
      {acceptErrorMessage}
      {declineErrorMessage}
      <div className={css.actionButtonWrapper}>
        {neutralButtonText && (
          <SecondaryButton
            color="gray"
            className={css.actionButton}
            spinnerClassName={css.spinner}
            inProgress={neutralInProgress}
            disabled={disabled}
            onClick={onNeutral}
          >
            {neutralButtonText}
          </SecondaryButton>
        )}
        {acceptButtonText && (
          <SecondaryButton
            color="green"
            className={css.actionButton}
            spinnerClassName={css.spinner}
            inProgress={acceptInProgress}
            disabled={disabled}
            onClick={onAccept}
          >
            {acceptButtonText}
          </SecondaryButton>
        )}
        {declineButtonText && (
          <SecondaryButton
            color="red"
            className={css.actionButton}
            spinnerClassName={css.spinner}
            inProgress={declineInProgress}
            disabled={disabled}
            onClick={onDecline}
          >
            {declineButtonText}
          </SecondaryButton>
        )}
        {reviewButtonText && (
          <SecondaryButton className={css.actionButton} onClick={onReview}>
            {reviewButtonText}
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

export default injectIntl(ActionButtons);
