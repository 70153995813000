import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.module.css';

const Tab = props => {
  const { className, id, disabled, text, selected, linkProps } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
  });

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const {
    className,
    rootClassName,
    tabsRootClassName,
    tabRootClassName,
    titleRootClassName,
    tabs,
    navTitle,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  const titleClasses = titleRootClassName || css.title;
  const tabsClasses = tabsRootClassName || css.nav;

  return (
    <div className={classNames({ [classes]: navTitle })}>
      {navTitle && <div className={titleClasses}>{navTitle}</div>}
      <nav className={classNames({ [tabsClasses]: navTitle }, { [classes]: !navTitle })}>
        {tabs.map((tab, index) => {
          const id = typeof tab.id === 'string' ? tab.id : `${index}`;
          return <Tab key={id} id={id} className={tabClasses} {...tab} />;
        })}
      </nav>
    </div>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
  titleRootClassName: null,
  tabsRootClassName: null,
  navTitle: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  titleRootClassName: string,
  tabsRootClassName: string,
  navTitle: oneOfType([string, bool]),
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
