import React from 'react';

const IconYoyotennisLogo = props => {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
      <path
        fill="#fefefe"
        d="M1121.56,644.15c0-1.16.09-2.31.13-3.47H1126c-.24-11.17-13.08-14.71-26.26-7v8.6c7.31,6.66,17.38,2.37,22.53,10.09-6.32,7.29-13.12,7.3-18.83.62a51.64,51.64,0,0,0-4-3.78c-2.33,5.74-.22,8.37,3.64,10.4,6.8,3.59,18.72,1.72,24.5-4C1127.81,648.45,1127.81,648.45,1121.56,644.15ZM976.7,661V630.39h-4.45V652.8l-19.33-22.54h-4.79v30.85l4.36-.32V638.28L972.3,661ZM863.4,638.24c9.31,7.31,13.32,18.64,24.73,23.65V630.38h-4.42v22.46c-5.88-7.09-10.47-13-15.54-18.53-2.06-2.24-5.14-3.55-8.68-5.89v32.65l3.91-.2Zm-62.22,4.16H781.86v-8.17h21.2c-.08-1.58-.13-2.57-.2-3.76H777.09v30.2h25.56v-4.43h-21V647h19.53Zm-79.1-12H691.54v4.42h12.11v26.29l6.59-.6V634.24h11.84Zm318.27,30.72V629.9l-3.85.43v30.53Z"
      />
      <path
        fill="#c6d201"
        d="M631.07,423.88c13.17-14.44,28.94-25.75,44.61-37.21a406.63,406.63,0,0,1,63.26-37.77A511.5,511.5,0,0,1,849.56,310c18.26-4.1,36.64-7.85,55.13-10.72,13.1-2,26.46-2.55,39.73-3.28,14.9-.82,29.83-1.61,44.74-1.52,28.37.18,56.59,2.49,84.26,9.17,18.15,4.38,36.4,8.59,54.22,14.09a273,273,0,0,1,88.53,45.94c31.21,24.49,55.73,54.45,67.61,93.35,6.45,21.08,9.22,42.72,7,64.39-3.69,35.34-16.45,67.62-36.86,96.92-12.63,18.13-26.48,35.24-42.71,50.12a425.32,425.32,0,0,1-81.45,58.65c-31.14,17.28-63.53,31.49-97.64,41.38-24.48,7.1-49.35,13-74.31,18.16-14.41,3-29.31,3.75-44,4.89-18.15,1.4-36.36,3.15-54.52,2.92-19.51-.25-39-2.62-58.49-4.06l.09-1.89c2.71,0,5.41.07,8.12,0,15.43-.5,31-.12,46.28-1.75a772.68,772.68,0,0,0,84.13-13.23,600.15,600.15,0,0,0,102.5-32.28,463.28,463.28,0,0,0,108.11-63A313.21,313.21,0,0,0,1186.32,645c23.3-25.15,42.21-53.16,52-86.65,9.41-32,8.38-63.2-5.09-93.87-10.7-24.36-27.48-43.74-47.78-60.69a240.17,240.17,0,0,0-51.39-32.36A340.77,340.77,0,0,0,1050,344.1c-15.19-2.83-30.43-5.57-45.74-7.61-9.64-1.29-19.46-1.17-29.19-1.75-7.67-.46-15.35-1.65-23-1.4-15.86.52-31.71,1.73-47.56,2.77-6.63.43-13.27.9-19.85,1.79-18.37,2.48-37,3.88-55,8.11-28,6.6-55.92,14-83.13,23.2-39.45,13.37-77,31.16-111.48,54.9a10.39,10.39,0,0,1-2.86.89Z"
      />
      <path
        fill="#000"
        d="M751,446.82h33.57c-.56,5.58-1.09,10.9-1.69,16.93,16.7-15.48,36.77-18.46,57.89-18.77s41.26,2.69,58.35,17.09c-.58-5.14-1.12-9.85-1.71-15h34.1c.68,7.43,1.25,15,2.06,22.51,2.25,21.07,6,42.05,19.42,59,12.44,15.71,34.33,16.52,47.45-2.75,8.82-12.94,12.86-27.5,15.35-42.54,1.95-11.76,2.85-23.69,4.28-36.16h32.66v14.77c4.93-2.79,9.1-5.25,13.36-7.55,12-6.47,25-9,38.46-9.28,14.27-.29,28.43-.07,42.23,4.57,23.34,7.85,36.48,24.39,39.83,48.13,1.92,13.64,3.09,27.86,1.44,41.44-3.81,31.2-16.51,51.41-53.94,58-18.7,3.3-37.67,3.45-56.19-1.52-23.15-6.21-36.83-21.49-41.65-45.48-2.27-11.31-2.86-23-4.14-33.8-5.53,5.11-11.23,11.14-17.7,16.18s-13.65,9-21.83,14.34v50H958.75V545.16c-15.64-4.65-27.3-14.91-38.34-27.29-.84,8.13-1.75,15.51-2.33,22.91-1.17,15-6.18,28.72-16.62,39.35a56.38,56.38,0,0,1-35.17,17.06c-13.78,1.26-27.76,2.39-41.5,1.43-20.31-1.41-38.68-8.66-49.58-27.2-4.57-7.75-7.46-16.91-9.18-25.81-2-10.22-1.8-20.85-2.57-31.42-10.68,13.6-23.28,25.36-41.19,31V597H689.92V545.76c-44.55-18-59.2-54.05-61.46-98.7h34c.74,9.25,1,18.67,2.35,27.93,3.13,21.2,7.23,42.19,23.47,58.1,11.25,11,29.79,9,39.66-3.27C739.88,515,744.53,497,747.08,478.5,748.49,468.21,749.66,457.89,751,446.82ZM1154.92,522c-.74-9.42-1.14-18.87-2.3-28.24a55.71,55.71,0,0,0-10.91-27.49c-7.53-9.73-17.81-13.66-30.57-13.69-17.17,0-28.21,8-35.56,22.38-6.19,12.13-7.81,25.56-8,38.76a207.21,207.21,0,0,0,3.12,41.79c3.27,17.48,11.44,32.64,31,36.53,19.41,3.87,38.72-3.21,46.48-25.15C1153.29,552.27,1155.07,537.37,1154.92,522Zm-269.09-3.49c-.91-7.59-1.62-18.8-3.74-29.73-2-10.14-6.18-19.95-13.77-27.06-10-9.33-23-10.74-35.75-8.5A32.86,32.86,0,0,0,810,468c-7.34,11.17-10.42,23.64-11.25,36.12-1.17,17.78-2.2,35.94,2.81,53.49,3.15,11.05,6.47,22,17.41,28.77a43,43,0,0,0,24.36,6.48c15.27-.27,25.84-7.44,32.74-20.6C884.34,556.48,884.82,539.27,885.83,518.49Z"
      />
      <path
        fill="#000"
        d="M1121.56,644.15c6.25,4.3,6.25,4.3,6,11.44-5.78,5.7-17.7,7.57-24.5,4-3.86-2-6-4.66-3.64-10.4a51.64,51.64,0,0,1,4,3.78c5.71,6.68,12.51,6.67,18.83-.62-5.15-7.72-15.22-3.43-22.53-10.09v-8.6c13.18-7.67,26-4.13,26.26,7h-4.31c-1-5.44-5.18-7.36-9.72-7.38-3.53,0-7.63,1.25-7.4,7.58Z"
      />
      <path
        fill="#000"
        d="M1180.76,453.5c-.16-12.23-.16-12.23,6.49-18.89,14.47-1.2,18.53.69,21.19,9.86,1.37,4.71-1.42,12.08-5.66,14.53C1195.82,463,1190.1,464.6,1180.76,453.5Zm7.35,2.34v-15C1182.29,446,1182.28,447.48,1188.11,455.84Zm2.61,1.37h8.74l-7.1-8.87C1191.61,452.39,1191.18,454.74,1190.72,457.21Zm11.89-1.73v-14.1l-1.11.08c-.72,2.52-1.44,5-2.44,8.58Zm-5-10.35-.1-2-6.09-1.33c.21,2.27.32,3.44.5,5.35Zm-6.84-7.47.12,1.18,6.37-.3c0-.29,0-.59,0-.88Z"
      />
      <path
        fill="#000"
        d="M976.7,661h-4.4l-19.81-22.67v22.51l-4.36.32V630.26h4.79l19.33,22.54V630.39h4.45Z"
      />
      <path
        fill="#000"
        d="M863.4,638.24v22.63l-3.91.2V628.42c3.54,2.34,6.62,3.65,8.68,5.89,5.07,5.5,9.66,11.44,15.54,18.53V630.38h4.42v31.51C876.72,656.88,872.71,645.55,863.4,638.24Z"
      />
      <path
        fill="#000"
        d="M801.18,642.4V647H781.65v9.25h21v4.43H777.09v-30.2h25.77c.07,1.19.12,2.18.2,3.76h-21.2v8.17Z"
      />
      <path fill="#000" d="M722.08,630.36v3.88H710.24v26.23l-6.59.6V634.78H691.54v-4.42Z" />
      <path fill="#000" d="M1040.35,661.08l-3.85-.22V630.33l3.85-.43Z" />
      <path
        fill="#fefefe"
        d="M1121.56,644.15l-17-3.27c-.23-6.33,3.87-7.58,7.4-7.58,4.54,0,8.76,1.94,9.72,7.38C1121.65,641.84,1121.61,643,1121.56,644.15Z"
      />
      <path fill="#c6d201" d="M632.21,425l-2.39,1.46L628.59,425l2.48-1.12Z" />
      <path fill="#fefefe" d="M1188.11,455.84c-5.83-8.36-5.82-9.84,0-15Z" />
      <path fill="#fefefe" d="M1190.72,457.21c.46-2.47.89-4.82,1.64-8.87l7.1,8.87Z" />
      <path fill="#fefefe" d="M1202.61,455.48l-3.55-5.44c1-3.55,1.72-6.06,2.44-8.58l1.11-.08Z" />
      <path fill="#fefefe" d="M1197.62,445.13l-5.69,2c-.18-1.91-.29-3.08-.5-5.35l6.09,1.33Z" />
      <path fill="#fefefe" d="M1190.78,437.66h6.48c0,.29,0,.59,0,.88l-6.37.3Z" />
    </svg>
  );
};

export default IconYoyotennisLogo;
