import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { IconArrowHead, ListingLink, PrimaryButton } from '..';
import { propTypes } from '../../util/types';

import css from './EditListingBottomButtons.module.css';

const EditListingBottomButtons = props => {
  const {
    className,
    listing,
    previewActionMsg,
    submitInProgress,
    submitDisabled,
    submitReady,
    saveActionMsg,
    hideSubmitButton,
  } = props;

  const classes = useMemo(() => classNames(css.root, className), [className]);

  return (
    <div className={classes}>
      <ListingLink target="_blank" listing={listing} className={css.previewListingButton}>
        {previewActionMsg}
      </ListingLink>

      {!hideSubmitButton && (
        <PrimaryButton
          className={classNames(css.submitButton, { [css.marginLeft]: !listing?.id })}
          type="submit"
          inProgress={submitInProgress}
          disabled={submitDisabled}
          ready={submitReady}
        >
          {saveActionMsg}
          <IconArrowHead className={css.arrowIcon} size="big" direction="right" />
        </PrimaryButton>
      )}
    </div>
  );
};

EditListingBottomButtons.propTypes = {
  classNames: string,
  listing: propTypes.ownListing,
  previewActionMsg: string.isRequired,
  submitInProgress: bool,
  submitDisabled: bool,
  submitReady: bool,
  saveActionMsg: string.isRequired,
  hideSubmitButton: bool,
};

export default EditListingBottomButtons;
