import React from 'react';
import { string } from 'prop-types';

const IconRockClimbing = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 52 51">
      <path
        d="M2.151-507.821a2.791,2.791,0,0,0-1.532.97c-.664.807-.623-1.022-.623,25.579v24.19l.245.276a.834.834,0,0,0,1.185.092c.184-.194,7.079-12.494,7.835-14,.306-.6.582-1.655,1.777-6.814.776-3.351,1.512-6.333,1.634-6.64.4-.94.735-1.328,3.33-3.821,1.4-1.328,2.717-2.635,2.932-2.891.674-.8.97-1.583,1.359-3.565.562-2.963.644-3.259,1.022-4.015a5.383,5.383,0,0,1,.9-1.257c.3-.286,2.482-1.951,4.852-3.677s4.444-3.269,4.607-3.4a.572.572,0,0,0-.082-1.042C31.305-507.975,2.836-507.975,2.151-507.821Z"
        transform="translate(0 507)"
      />
      <g transform="translate(22 1)">
        <path
          d="M228.112-493.732a1.563,1.563,0,0,0-.94,1.43,72.212,72.212,0,0,0,.94,7.958c.123.245,2.033,2.053,5.925,5.639l.388.368-1.2,5.005c-.664,2.748-1.236,5.026-1.267,5.056s-1.553.031-3.381-.02c-3.79-.1-4.076-.071-4.822.6-.827.746-6.579,7.192-6.875,7.712a1.827,1.827,0,0,0-.3,1.2,2.556,2.556,0,0,0,3.729,2.319c.47-.225.96-.725,3.412-3.442l2.85-3.167,1.338.01c.725.01,1.726.041,2.207.082l.889.061-1.369,1.941c-1.338,1.91-1.675,2.543-1.675,3.156,0,.174.4,2.523.879,5.22.746,4.2.919,4.975,1.154,5.414a2.6,2.6,0,0,0,4.811-.715c.123-.542.153-.347-.766-5.465l-.684-3.769,2.349-3.361c1.3-1.839,2.452-3.514,2.564-3.718.133-.215.695-2.35,1.44-5.394.664-2.768,1.236-5.046,1.256-5.067a15.846,15.846,0,0,1,2.115,2.4c0,.092-.235,1.6-.521,3.371a23.2,23.2,0,0,0-.449,3.555,2.086,2.086,0,0,0,1.144,1.267,1.8,1.8,0,0,0,2.064-.817,35.371,35.371,0,0,0,.838-4.26c.644-3.861.644-3.892.47-4.342A20.288,20.288,0,0,0,244-476.663c-3.116-3.432-3.688-4-4.28-4.331a16.306,16.306,0,0,0-2.39-.725l-1.91-.46-2.033-1.89c-1.655-1.542-2.043-1.961-2.094-2.227-.031-.184-.194-1.614-.358-3.187s-.358-3.024-.409-3.218a2.037,2.037,0,0,0-1.052-1.113A1.718,1.718,0,0,0,228.112-493.732Z"
          transform="translate(-216.588 493.918)"
        />
        <g transform="translate(18 3)">
          <path
            d="M404.378-458.738a4.1,4.1,0,0,0-3.044,4.6,4.016,4.016,0,0,0,3.3,3.269,4.018,4.018,0,0,0,4.25-5.71A4.113,4.113,0,0,0,404.378-458.738Z"
            transform="translate(-401 458)"
          />
        </g>
      </g>
    </svg>
  );
};

IconRockClimbing.defaultProps = { className: null };
IconRockClimbing.propTypes = { className: string };

export default IconRockClimbing;
