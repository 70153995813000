import React from 'react';

const IconNiedeckerLogo = props => {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
      <path
        fill="#000"
        d="M653.27,674.66c-74.3,0-134.68-60.41-134.67-134.75s60.5-134.6,135-134.57c74,0,134.4,60.61,134.31,134.75C787.82,614.39,727.53,674.66,653.27,674.66Zm-1.91-20c64.31.59,116-50,116.57-114.11.53-62.33-49.65-114.13-112.58-115.25a114.7,114.7,0,1,0-4,229.36Z"
      />
      <path
        fill="#000"
        d="M827.69,486.19c6.5,0,12.88,0,19.26.06.54,0,1.21.66,1.58,1.18q14.34,20.32,28.62,40.7,9.23,13.14,18.46,26.29c.34.49.71,1,1.37,1.85v-70h21.28V593.7c-.54,0-1.08.09-1.61.09-5.61,0-11.22,0-16.83,0a2.69,2.69,0,0,1-2.54-1.37Q873.84,559,850.34,525.57c-.34-.49-.71-1-1.37-1.85v70H827.69Z"
      />
      <path
        fill="#000"
        d="M992.09,593.79V486.19h33.2c25,0,45.61,16.74,49.85,41.33,2.34,13.59,2,27.32-5.1,39.82-9.63,17-24.34,26-44,26.41-10.77.23-21.54,0-32.31,0Zm21.34-20.1c4.31,0,8.42.19,12.5,0,13.08-.73,22.32-7.18,27.68-19.09,2.61-5.79,2.52-12,2.26-18.15-.62-15.29-10.75-27.17-25.82-29.6-5.31-.86-10.84-.37-16.27-.48-.09,0-.18.11-.35.22Z"
      />
      <path
        fill="#000"
        d="M1457.47,537.17c3.8,0,7.46.12,11.1,0a40.35,40.35,0,0,0,8.22-1c5.5-1.41,8.75-5.48,9.14-11.14a35.85,35.85,0,0,0-.12-7.47c-.84-5.76-4.34-9.86-10.26-10.46-8.29-.86-16.69-.78-25.22-1.1v87.73h-20.65V486.27c.51,0,.94-.08,1.37-.08,13.11,0,26.21,0,39.32,0a43.82,43.82,0,0,1,20.57,4.7c9.74,5.11,14.83,13.37,16,24.16a48.16,48.16,0,0,1-.77,16.71,29.23,29.23,0,0,1-16.16,20l-1.87.93,20.08,40.94c-.65,0-1.07.1-1.5.1-6.72,0-13.44,0-20.16,0a1.77,1.77,0,0,1-2-1.06q-13.3-27.27-26.7-54.49C1457.74,538,1457.65,537.65,1457.47,537.17Z"
      />
      <path
        fill="#000"
        d="M1117.44,506.2V530h34.84v19h-34.82v24.61h38.91v20h-60.13V486.33h60.31V506.2Z"
      />
      <path
        fill="#000"
        d="M1345.49,486.25h60.34v20h-39.11V530h34.82v19h-34.83v24.61h38.91v20h-60.13Z"
      />
      <path
        fill="#000"
        d="M1252.45,561.88q6.49,7.82,13,15.57c-16.51,18.14-49.11,23.23-72.59,3.45a53.44,53.44,0,0,1-3.63-78c21.84-22.29,56.65-20.57,76.27,0l-13,15.66c-12.87-15.82-35.71-15-48.14-2a34.23,34.23,0,0,0-.11,47.16C1216.35,576.62,1239.34,577.94,1252.45,561.88Z"
      />
      <path
        fill="#000"
        d="M1291.48,540l43.76,53.67c-.76,0-1.23.1-1.7.11-7.72,0-15.44,0-23.16,0a2.73,2.73,0,0,1-2.39-1.11q-21.4-25.83-42.86-51.61c-.28-.34-.53-.7-.86-1.13l10-12q16.82-20.24,33.61-40.48a3.06,3.06,0,0,1,2.65-1.27c7.61.06,15.22,0,22.83,0h2Z"
      />
      <path fill="#000" d="M944.59,486.28h21.13V593.7H944.59Z" />
      <path
        fill="#000"
        d="M1071.89,654.39c-2.78-10.28-5.52-20.36-8.25-30.45l-.34-.05c-.19.65-.39,1.31-.57,2-2.41,9-4.84,18-7.19,27-.31,1.16-.71,1.62-2,1.59-3.56-.07-3.56,0-4.54-3.46q-4.85-16.94-9.68-33.87c-.09-.31-.16-.62-.32-1.23a18.49,18.49,0,0,1,3.36.07c.43.08.92.82,1.07,1.35,2.35,8.14,4.65,16.3,7,24.45a52.6,52.6,0,0,0,2.23,6.66l3.42-12.33,4.89-17.78c.69-2.48.69-2.48,3.32-2.42.33,0,.66,0,1.1.09q4.48,16.24,9,32.47h.31q1.69-5.91,3.38-11.83c1.74-6.12,3.44-12.26,5.2-18.38.81-2.79.13-2.3,3.2-2.36.43,0,.87.06,1.45.1-.19.79-.32,1.48-.52,2.16q-5,17.42-10,34.82c-.3,1.07-.69,1.6-1.9,1.47A36,36,0,0,0,1071.89,654.39Z"
      />
      <path
        fill="#000"
        d="M1147.83,633.91c4.89,2.45,7,5.75,5.64,12.87-.8,4.23-3.88,6.77-9,7.19-5.46.44-11,.4-16.56.58V616.1c.06-.05.15-.17.24-.17,5.2.14,10.42.07,15.6.51,3.72.31,6.68,2.3,7.56,6.21.94,4.15.68,8.1-3.19,10.91C1148,633.62,1148,633.74,1147.83,633.91Zm-16,16.75c3.46,0,6.73.08,10,0a14.16,14.16,0,0,0,3.9-.79c2.93-1,4.11-2.94,4.07-6.47s-1.32-5.69-4.26-6.55c-4.49-1.31-9.1-.63-13.71-.78Zm0-18.54c3.53,0,6.87.25,10.15-.06,4.6-.43,6.25-2.95,5.61-7.57a4.74,4.74,0,0,0-3.92-4.35,25.39,25.39,0,0,0-4.59-.58c-2.41-.1-4.82,0-7.31,0,0,4.16,0,8,0,11.89C1131.74,631.69,1131.78,632,1131.79,632.12Z"
      />
      <path
        fill="#000"
        d="M916.48,647.38V616h3.91v38.14c-2.9.8-3.71.51-5.33-1.89l-20-29.47c-.31-.46-.64-.91-1.21-1.72v33.23h-4V615.86a37.87,37.87,0,0,1,4.54.06,2.83,2.83,0,0,1,1.54,1.27q9.63,14.17,19.2,28.38C915.5,646.06,915.86,646.52,916.48,647.38Z"
      />
      <path
        fill="#000"
        d="M982.08,655.05a20.2,20.2,0,0,1-20.3-20.26c.05-10.59,9.36-19.56,20.34-19.59a19.93,19.93,0,1,1,0,39.85Zm-.17-36.12a16.23,16.23,0,1,0,16.51,15.83A16.44,16.44,0,0,0,981.91,618.93Z"
      />
      <path
        fill="#000"
        d="M1213.05,655.05a20.17,20.17,0,0,1-20.39-20.16c0-10.72,9.34-19.72,20.38-19.69a19.93,19.93,0,1,1,0,39.85Zm-.16-3.67a16.23,16.23,0,1,0-16.2-16.43A16.48,16.48,0,0,0,1212.89,651.38Z"
      />
      <path
        fill="#000"
        d="M1413.38,654.59V615.86c6.64.25,13.27-.68,19.71,1.39,8.72,2.8,13.56,10.24,12.7,19.47-.9,9.64-6.36,15.72-16.23,17.13C1424.28,654.61,1418.86,654.37,1413.38,654.59Zm4-35v31.28c3.88-.19,7.67-.11,11.38-.61,7.9-1,12.26-5.75,13-13.59.77-7.53-2.73-13.39-9.43-15.78C1427.51,619.17,1422.47,619.65,1417.39,619.61Z"
      />
      <path
        fill="#000"
        d="M1355.64,635.13a57.13,57.13,0,0,0,6.19,0,7.7,7.7,0,0,0,6.74-7.32,7.89,7.89,0,0,0-5.66-7.9,12.57,12.57,0,0,0-3.13-.34c-3.09,0-6.19,0-9.45,0v34.78h-3.9V616.11s.14-.17.22-.17c5.1,0,10.22-.2,15.3.12,5.6.35,9.65,4.76,10.23,10.49a11.6,11.6,0,0,1-8,11.78l-1.68.52,9.75,15.2c-2.82,1-4.26.47-5.77-1.88l-9.72-15.1C1356.5,636.57,1356.21,636.07,1355.64,635.13Z"
      />
      <path
        fill="#000"
        d="M1269.49,654.39c.23-.65.36-1.06.53-1.46q7.65-17.86,15.29-35.72c.45-1.06,1-1.53,2.11-1.31s2.63-.74,3.41,1.1q7.79,18.25,15.67,36.47c.08.2.12.41.26.91a31.44,31.44,0,0,1-3.66,0c-.39-.05-.85-.61-1-1-1.55-3.49-3.07-7-4.53-10.52a1.79,1.79,0,0,0-1.94-1.34q-7.48.09-15,0a1.79,1.79,0,0,0-2,1.31c-1.36,3.27-2.9,6.47-4.18,9.77-.57,1.48-1.34,2.12-2.91,1.88A15.49,15.49,0,0,0,1269.49,654.39Zm25.86-16.64c-2.42-5.77-4.75-11.32-7.21-17.2-2.53,5.91-4.9,11.47-7.34,17.2Z"
      />
      <path
        fill="#000"
        d="M1506.85,625.67H1503c-.11-.55-.21-1-.3-1.52a6.62,6.62,0,0,0-8.39-5,6.54,6.54,0,0,0-4.5,8.25,5.29,5.29,0,0,0,3.52,3.7c2.4.84,4.86,1.54,7.24,2.44,5.89,2.25,8.47,7.13,7,13.1-1.35,5.55-6.51,8.92-12.86,8.4a11.26,11.26,0,0,1-10.41-10.52,7,7,0,0,1,.07-1c1,0,1.9,0,2.82,0s1.28.33,1.49,1.28c1.1,5.14,5.22,7.7,10,6.29a7.22,7.22,0,0,0,5.19-6.62,6.06,6.06,0,0,0-3.84-6.71c-2.51-1.14-5.19-1.91-7.79-2.86-5.3-1.94-7.79-6.5-6.49-11.86,1.27-5.2,6.08-8.33,11.82-7.68,5.56.63,9.34,4.59,9.37,9.84A3.27,3.27,0,0,1,1506.85,625.67Z"
      />
      <path
        fill="#000"
        d="M826.45,643.56h4.12c.31,1,.53,2.06.91,3a7.58,7.58,0,0,0,10,4.3,7.44,7.44,0,0,0,4.16-9.76,7.87,7.87,0,0,0-3.42-3.38,56.38,56.38,0,0,0-7.3-2.72c-5.38-1.88-7.86-5.82-7.06-11.25.73-4.89,4.76-8.28,10.19-8.55s9.62,2.78,10.85,7.68a25.33,25.33,0,0,1,.38,2.73h-4c-.12-.53-.24-1.11-.37-1.68a6.64,6.64,0,0,0-8.53-4.77,6.51,6.51,0,0,0-4.3,8.2,5.27,5.27,0,0,0,3.51,3.7c2.25.78,4.53,1.47,6.77,2.28,5.92,2.13,8.7,6.59,7.7,12.28A11.21,11.21,0,0,1,839,655.05c-6.39.13-11-3.28-12.17-9.07C826.66,645.23,826.58,644.47,826.45,643.56Z"
      />
      <path
        fill="#000"
        d="M698.47,545.67V479.12h20.9v108L593.84,481.35c.58-.26,1-.48,1.42-.63,5.66-2,11.34-3.86,17-5.86a2.38,2.38,0,0,1,2.67.53q41,34.56,82,69.07C697.33,544.8,697.75,545.11,698.47,545.67Z"
      />
      <path
        fill="#000"
        d="M712.79,598.73l-2.09.74c-5.4,1.86-10.81,3.69-16.18,5.61a2.63,2.63,0,0,1-3-.54q-33.19-28-66.45-56L609.8,535.69c-.45-.38-.92-.74-1.69-1.34v2.25q0,31.2,0,62.4c0,1.62-.45,2-2,2-5.67-.09-11.33,0-17,0h-1.91v-108Z"
      />
    </svg>
  );
};

export default IconNiedeckerLogo;
