import api from '../../api';

// ================ Action types ================ //

export const SEND_CONTACT_MESSAGE_REQUEST = 'app/ContactPage/SEND_CONTACT_MESSAGE_REQUEST';
export const SEND_CONTACT_MESSAGE_SUCCESS = 'app/ContactPage/SEND_CONTACT_MESSAGE_SUCCESS';
export const SEND_CONTACT_MESSAGE_ERROR = 'app/ContactPage/SEND_CONTACT_MESSAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendContactMessageInProgress: false,
  sendContactMessageError: null,
  sendContactMessageDone: false,
};

export default function contactPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_CONTACT_MESSAGE_REQUEST:
      return {
        ...state,
        sendContactMessageInProgress: true,
        sendContactMessageError: null,
        sendContactMessageDone: false,
      };
    case SEND_CONTACT_MESSAGE_SUCCESS:
      return {
        ...state,
        sendContactMessageInProgress: false,
        sendContactMessageError: null,
        sendContactMessageDone: true,
      };
    case SEND_CONTACT_MESSAGE_ERROR:
      return {
        ...state,
        sendContactMessageInProgress: false,
        sendContactMessageError: payload,
        sendContactMessageDone: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sendContactMessageRequest = () => ({
  type: SEND_CONTACT_MESSAGE_REQUEST,
});

export const sendContactMessageSuccess = () => ({
  type: SEND_CONTACT_MESSAGE_SUCCESS,
});

export const sendContactMessageError = e => ({
  type: SEND_CONTACT_MESSAGE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const sendContactMessage = data => dispatch => {
  dispatch(sendContactMessageRequest());

  return api.contactUs
    .contact(data)
    .then(() => dispatch(sendContactMessageSuccess()))
    .catch(e => {
      dispatch(sendContactMessageError(e));
      throw e;
    });
};
