import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.module.css';

const IconCalendar = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(20285 11808)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(-20285 -11808)" />
        <path d="M-20276.25-11782.375a1.875 1.875 0 0 0 1.875 1.875h13.75a1.875 1.875 0 0 0 1.875-1.875V-11793h-17.5Zm12.5-7.656a.47.47 0 0 1 .469-.469h1.563a.47.47 0 0 1 .469.469v1.563a.47.47 0 0 1-.469.469h-1.563a.47.47 0 0 1-.469-.469Zm0 5a.47.47 0 0 1 .469-.469h1.563a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469h-1.563a.47.47 0 0 1-.469-.469Zm-5-5a.47.47 0 0 1 .469-.469h1.562a.47.47 0 0 1 .469.469v1.563a.47.47 0 0 1-.469.469h-1.562a.47.47 0 0 1-.469-.469Zm0 5a.47.47 0 0 1 .469-.469h1.562a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469h-1.562a.47.47 0 0 1-.469-.469Zm-5-5a.47.47 0 0 1 .469-.469h1.562a.47.47 0 0 1 .469.469v1.563a.47.47 0 0 1-.469.469h-1.562a.47.47 0 0 1-.469-.469Zm0 5a.47.47 0 0 1 .469-.469h1.562a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469h-1.562a.47.47 0 0 1-.469-.469Zm13.125-12.969h-1.875v-1.875a.627.627 0 0 0-.625-.625h-1.25a.627.627 0 0 0-.625.625v1.875h-5v-1.875a.627.627 0 0 0-.625-.625h-1.25a.627.627 0 0 0-.625.625v1.875h-1.875a1.875 1.875 0 0 0-1.875 1.875v1.875h17.5v-1.875a1.875 1.875 0 0 0-1.875-1.875Z" />
      </g>
    </svg>
  );
};

IconCalendar.defaultProps = {
  rootClassName: null,
  className: null,
};

IconCalendar.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCalendar;
