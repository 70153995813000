import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconReviewMountain } from '../../components';
import { REVIEW_RATINGS } from '../../util/types';
import { FormattedMessage } from 'react-intl';

import css from './ReviewRating.module.css';

const ReviewRating = props => {
  const {
    className,
    rootClassName,
    reviewStarClassName,
    rating = 0,
    ratingCount = 0,
    isAccented,
    hideRatingCount,
    showStars = true,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <span className={classes} title={`${rating}/5`}>
      {showStars ? REVIEW_RATINGS.map(star => (
        <IconReviewMountain
          key={`star-${star}`}
          className={reviewStarClassName}
          isFilled={star <= rating}
        />
        ))
      : <IconReviewMountain
          className={reviewStarClassName}
          isFilled={true}
        />
      }
      <h5
        className={classNames(css.ratingCount, {
          [css.ratingCountAccented]: isAccented,
          [css.ratingCountNoStars]: !showStars,
        })}
      >
        {rating || showStars ? (
          <span> {rating?.toFixed(1)}/5 </span>
        ) : (
          <FormattedMessage id="General.new" />
        )}
        <span>
          {!hideRatingCount && `(${ratingCount} `}
          {!hideRatingCount && <FormattedMessage id="General.reviews" />}
          {!hideRatingCount && `)`}
        </span>

      </h5>
    </span>
  );
};

ReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
};

const { string, bool, number } = PropTypes;

ReviewRating.propTypes = {
  rating: number,
  ratingCount: number,
  isAccented: bool,
  reviewStartClassName: string,
  rootClassName: string,
  className: string,
  hideRatingCount: bool,
  showStars: bool,
};

export default ReviewRating;
