import React from 'react';

const IconGiorgioRoccaLogo = props => {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
      <path
        fill="#000"
        d="M518.44,790C380.08,790,268,678,268.5,539.24,269,397.85,384.76,288.53,521.37,290,655.77,291.48,768.52,400.86,768.65,540,768.78,678.14,656.8,790,518.44,790ZM305.62,539.71c0,117.68,95.28,213.08,212.85,213.08,116.84,0,211.54-93.64,213.18-209.63C733.35,423.7,636.84,327,518.67,327A212.51,212.51,0,0,0,305.62,539.71Z"
      />
      <path
        fill="#000"
        d="M1427.65,509.77c-14.08,0-24.05-9.59-24.05-23.19s10.34-23.8,24.18-23.85c13.65-.06,24.06,10,24.29,23.34S1441.6,509.74,1427.65,509.77Zm.38-9.49c7.63,0,13.23-5.95,13.14-13.85a13.81,13.81,0,0,0-13.78-13.95c-7.52.09-13.14,6.09-13.08,14S1420.19,500.34,1428,500.28Z"
      />
      <path
        fill="#000"
        d="M1230.07,486.5c0-13.5,10.58-23.77,24.37-23.77s24.25,10.26,24.11,23.88c-.14,13.26-10.54,23.13-24.4,23.16S1230,500,1230.07,486.5Zm24.23,13.78c7.75.06,13.35-5.56,13.48-13.52a13.77,13.77,0,0,0-13.45-14.27c-7.6-.08-13.28,5.71-13.35,13.64C1240.9,494.31,1246.47,500.23,1254.3,500.28Z"
      />
      <path
        fill="#000"
        d="M1009.45,509.77c-13.62-.06-23.65-9.94-23.61-23.27s10.59-23.77,24.38-23.77,24.23,10.27,24.1,23.89C1034.19,500,1023.65,509.84,1009.45,509.77Zm.88-9.49c7.67,0,13.3-5.9,13.22-13.78a13.8,13.8,0,0,0-13.72-14c-7.57,0-13.11,5.94-13.08,13.91C996.78,494.54,1002.43,500.32,1010.33,500.28Z"
      />
      <path
        fill="#000"
        d="M1164.14,469.26l-6,7.33c-2.8-1.3-5.22-2.79-7.86-3.58a12.73,12.73,0,0,0-15.23,6.25c-3,5.37-2,13.24,2.29,17.4,4,3.94,12,5.21,16.54,2,1.57-1.12,1.9-4,3.22-7.05H1146v-8.89h19.84c0,6.33.08,12.39-.08,18.45a4.44,4.44,0,0,1-1.85,2.78c-8.12,5.93-17.11,7.59-26.65,4.45-10.58-3.48-16.15-13.73-14.44-25.67a23.4,23.4,0,0,1,22-19.93C1151.94,462.43,1158.6,464,1164.14,469.26Z"
      />
      <path
        fill="#000"
        d="M1370.6,493.17c3.55,5.28,6.83,10.17,10.71,15.93-4.14,0-7.35.19-10.51-.11-1.06-.09-2.24-1.24-3-2.22a47.85,47.85,0,0,1-5.1-7.84c-2.18-4.67-5.62-5.57-10.67-4.19v14.12h-9.65V464c8.17,0,16.35-.51,24.42.16,6.55.55,11.4,4.37,12.57,11.37s-.86,12.63-7.14,16.42C1371.8,492.21,1371.37,492.6,1370.6,493.17ZM1352,485.68c4,0,7.73.31,11.35-.09,3.43-.38,5.7-2.56,5.54-6.31-.15-3.49-2.36-5.46-5.65-5.73-3.63-.29-7.31-.07-11.24-.07Z"
      />
      <path
        fill="#000"
        d="M914.58,476.14c-4.33-1.33-8-3.08-11.87-3.51-5.79-.64-10.41,3-12.52,8.75a14.57,14.57,0,0,0,4.28,16.18c3.84,3.13,11.8,4,15.5,1.05,1.51-1.21,1.67-4.09,2.7-6.93H902.26v-8.93H921.8c0,6.29.07,12.61-.08,18.93,0,.79-1.08,1.72-1.87,2.29-7.84,5.7-16.54,7.36-25.82,4.68-11-3.18-17-13.68-15.31-26,1.52-10.91,11.15-19.48,22.53-19.92,6.89-.26,13.26,1.27,18.9,6.43C918.09,471.75,916.1,474.23,914.58,476.14Z"
      />
      <path
        fill="#000"
        d="M1089.48,493.1l10.84,16c-4.11,0-7.19.18-10.23-.09a5.08,5.08,0,0,1-3.1-2,53.73,53.73,0,0,1-5.13-7.82c-2.19-4.33-5.25-6.23-10.57-4.45v14h-10V463.22c9.05.47,18.23.4,27.24,1.62,5.76.78,9.09,5.43,9.92,11.28s-.68,10.92-5.55,14.65C1092,491.49,1091,492.09,1089.48,493.1Zm-18.85-7.43c4.22,0,7.94.3,11.58-.08,3.46-.37,5.69-2.57,5.55-6.3-.14-3.49-2.34-5.48-5.64-5.74-3.75-.3-7.55-.07-11.49-.07Z"
      />
      <path
        fill="#000"
        d="M1488.57,616.2h-8.8V592.56l-1-.41-10.59,15.76-9.85-14.82-.87,0v23.33h-9.37v-40c7.18-2.16,9.33-1.4,13.12,4.63,2.21,3.51,4.44,7,7.14,11.26,2.43-3.82,5-6.92,6.53-10.47,2.74-6.44,7.5-7.13,13.7-5.72Z"
      />
      <path
        fill="#000"
        d="M1651.5,509.07a32.82,32.82,0,0,0-3.8.2c-5,.86-8.27-.78-9.28-6-.6-3.11-2.46-4.46-5.71-4a16.25,16.25,0,0,1-4.46,0c-6.71-.85-12.4-.21-13.81,8.1-.13.77-1.88,1.68-3,1.8a78.06,78.06,0,0,1-8,.08c.56-1.56.93-2.79,1.43-4,5.44-12.87,11-25.69,16.29-38.65,1.34-3.31,3.5-3.4,6.3-3.34s5-.18,6.36,3.24c5.3,13.09,11,26,16.49,39C1650.77,506.63,1651.06,507.76,1651.5,509.07Zm-24.12-32.78-5.45,13.57h10.9Z"
      />
      <path
        fill="#000"
        d="M1333.15,616.37v-41c7.76.42,15.43,0,22.73,1.43,9.74,1.93,15.69,11.35,14.78,21.07-.93,10-8.57,17.62-19.18,18.47C1345.59,616.76,1339.63,616.37,1333.15,616.37Zm9.25-8.68c6.63.22,12.92,1.11,16.87-5.27a13,13,0,0,0-.59-13.9c-4.27-5.49-10.18-5.06-16.28-4.64Z"
      />
      <path
        fill="#000"
        d="M1575.24,495.34l6.26,6c-4.86,8-16.86,10.42-25.71,7.7a23.32,23.32,0,0,1-16-21.46c-.35-10.78,5.56-19.87,15.21-23.37s20.74-1,26.3,6.07l-6,6.83c-11.94-5.95-17.57-5.72-22.32.79a15.1,15.1,0,0,0,1.26,18.54C1559.45,501.91,1566.35,501.59,1575.24,495.34Z"
      />
      <path
        fill="#000"
        d="M1517.65,470.14l-6.23,7.05c-11.12-6.09-16.8-6-21.74.1a14.91,14.91,0,0,0,.48,18.9c5,5.59,11.27,5.42,21.34-.64l6.16,6.08c-7.82,10.2-24,11.44-34,2.78-9.75-8.48-10.31-25-1.17-34.51S1508.44,460.22,1517.65,470.14Z"
      />
      <path
        fill="#000"
        d="M1042.61,600c-7.52,3.67-3.48,10.5-5.17,16h-9V598.18c0-6,.22-11.93-.08-17.88-.18-3.8,1.18-5.16,4.83-4.66,1.44.2,2.91.17,4.78.26v17.24c4.47-4.85,8.55-8.56,11.73-12.93,4-5.53,9.11-4.92,15.31-4.08l-16,16.79,16.74,23c-7,1.43-12.2.92-15.63-5.62C1048.17,606.56,1045.12,603.39,1042.61,600Z"
      />
      <path
        fill="#000"
        d="M1394,575.94h30.26v7.61h-20.9v7.84h18.15v8.28h-18.16v8.21h21.08v7.94H1394Z"
      />
      <path
        fill="#000"
        d="M1150.89,616.74c5.89-14.06,11.21-26.94,16.71-39.74,1.14-2.65,8.7-2.59,9.86.06,5.58,12.76,11,25.6,16.47,38.44-7.27,2.33-9.15,1.63-11.69-4.78-1-2.46-2.25-3.56-4.89-3.25a20.17,20.17,0,0,1-4.91,0c-5.51-.7-9.39.3-11.25,6.66C1159.81,618.84,1155.08,615.52,1150.89,616.74Zm26.8-18-5-11.69-4.38,11.69Z"
      />
      <path
        fill="#000"
        d="M1311.11,616.45a34.21,34.21,0,0,0-4.73,0c-3.54.56-5.71-.74-6.44-4.31-.79-3.87-3.12-5.37-7-4.7a12.84,12.84,0,0,1-4,0c-5.34-.77-8.82.61-10.45,6.48-1.42,5.14-6.19,1.08-9.81,2,.44-1.29.82-2.62,1.35-3.88,4.62-11.11,9.34-22.18,13.85-33.33,1-2.55,2.29-3.79,5.19-3.55,2.66.22,5-.21,6.37,3.22,4.59,11.44,9.59,22.72,14.41,34.07C1310.27,613.56,1310.54,614.69,1311.11,616.45Zm-16.76-17.81c-1.57-3.93-2.95-7.37-4.7-11.77-1.74,4.48-3.09,8-4.56,11.77Z"
      />
      <path
        fill="#000"
        d="M973.11,610.31l4.58-5.53c5,1.36,9.33,2.87,13.76,3.52,1.48.22,3.28-1.76,4.94-2.73-1.29-1.39-2.32-3.33-3.91-4.06-3.07-1.43-6.52-2-9.71-3.25-5.7-2.18-7.87-5.5-7.66-11.18.19-5.21,3.24-9.17,8.39-10.91,6.77-2.29,15.22-.48,21.09,4.73l-4.2,6.44a37.9,37.9,0,0,0-5.24-2.54,18.28,18.28,0,0,0-6.48-1.12c-1.27.09-3.4,1.68-3.41,2.61a5.21,5.21,0,0,0,2.51,3.93c2.66,1.24,5.71,1.62,8.52,2.56,8,2.67,11.11,6.84,10.23,13.49-.82,6.27-5.58,10.42-13.32,10.8A28.32,28.32,0,0,1,973.11,610.31Z"
      />
      <path
        fill="#000"
        d="M1249,581.25l-5.25,6.33c-9.31-5.33-15-5.33-19.4-.12a13.37,13.37,0,0,0,0,16.87c4.42,5.14,10.19,5,19.52-.62l4.91,5.95c-7,8.47-22.62,9.49-30.91,1.7s-8.32-22.86,0-31S1241.13,572.49,1249,581.25Z"
      />
      <path
        fill="#000"
        d="M1529.51,591.58l5.78-9.69c4.33-7.29,4.35-7.31,13.83-5.58-3.11,4.91-5.82,9.81-9.14,14.25-4.73,6.35-7.42,13.08-6.22,21.11a23.94,23.94,0,0,1-.21,4.54h-9c0-4.23.21-8.5-.09-12.73a15.46,15.46,0,0,0-1.94-6.6c-4.09-6.86-8.49-13.54-12.85-20.4,8.36-2.19,10-1.54,14,5.26C1525.47,584.81,1527.31,587.87,1529.51,591.58Z"
      />
      <path fill="#000" d="M1193.67,463.84h9.57V509h-9.57Z" />
      <path fill="#000" d="M949.49,463.8H959V509h-9.48Z" />
      <path fill="#000" d="M1096.12,616.24h-8.74V576.06h8.74Z" />
      <path
        fill="#000"
        d="M610.73,690.51V673.09c0-7.9.15-15.81-.07-23.7a8.49,8.49,0,0,1,3.27-7.27c11.3-9.94,23.65-26.34,28.32-37.37l-68-64.61v6.36q0,78,.1,156.06c0,4-1,5.82-5,6.65-10.74,2.2-21.4,4.8-32.75,7.39V364.44c93.41,7.71,161.81,93,158.48,175.62H630.36c1.27,1.5,1.88,2.39,2.65,3.12,17,16.26,34.09,32.47,51,48.85,1.17,1.13,2,3.8,1.53,5.24q-20.22,58.15-71.86,91.82A30.47,30.47,0,0,1,610.73,690.51Zm42-188.5c-9.29-40.07-44.62-77.47-78.23-89.84V502Z"
      />
      <path
        fill="#000"
        d="M498.87,364.64v36.64c-35.91,5.49-65.63,22.05-88.64,50s-33.59,60.38-31.73,96.57C381.64,609,424.11,652.54,461,667.15V502.46h37.78V715.18c-66.86-4-151.7-64-156.87-165.79C336.5,442.68,420.83,370.92,498.87,364.64Z"
      />
    </svg>
  );
};

export default IconGiorgioRoccaLogo;
