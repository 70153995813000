import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import FieldNumberIncrementerInput from '../FieldNumberIncrementerInput/FieldNumberIncrementerInput';
import FilterPopup from '../../containers/SearchPage/FilterPopup/FilterPopup';
import FilterPlain from '../../containers/SearchPage/FilterPlain/FilterPlain';

import css from './NumberIncrementFilter.module.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class NumberIncrementFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;
    this.shortKeywordTimeout = null;
    this.mobileInputRef = React.createRef();

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortKeywordTimeout);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      popupClassName,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const popupSizeClasses = popupClassName || css.popupSize;

    const urlParam = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[urlParam];
    const parsedInitialValues = hasInitialValues && initialValues[urlParam].split(',')[0];
    const otherInitialValues = Object.entries(initialValues).reduce((validParams, entry) => {
      const [paramName, paramValue] = entry;
      return { ...validParams, [paramName]: paramValue || 0 };
    }, {});

    const labelText = intl.formatMessage({ id: label });

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'NumberIncrementFilter.labelSelected' },
          { labelText, count: parsedInitialValues }
        )
      : labelText;

    const labelForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'NumberIncrementFilterPlainForm.labelSelected' },
          { labelText, count: parsedInitialValues }
        )
      : labelText;

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: parsedInitialValues || undefined, ...otherInitialValues };

    const handleSubmit = values => {
      const paramEntries = values && Object.entries(values);

      const mainValue = paramEntries?.reduce((prev, entry) => {
        const [paramName, paramValue] = entry;
        const parsedPrev = +`${prev}`.replace(',', '');
        const parsedParamValue = +`${paramValue}`.replace(',', '');
        return options.find(o => o.key === paramName) ? parsedPrev + parsedParamValue : 0;
      }, 0);

      // Used for the UI
      const otherParams = Object.assign(
        ...options.map(o => ({ [o.key]: (values && values[o.key]) || 0 }))
      );

      onSubmit({ [urlParam]: mainValue ? `${mainValue},` : null, ...otherParams });
    };

    // Uncontrolled input needs to be cleared through the reference to DOM element.
    const handleClear = () => {
      if (this.mobileInputRef && this.mobileInputRef.current) {
        this.mobileInputRef.current.value = '';
      }
    };

    const content = options.map((option, i) => (
      <FieldNumberIncrementerInput
        className={css.numberIncrementerInput}
        fieldClassName={css.fieldNumberIncrementerInput}
        key={option.key}
        id={option.key}
        name={option.key}
        label={intl.formatMessage({ id: option.label })}
        min="0"
      />
    ));

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        paddingClasses={popupSizeClasses}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        {content}
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        onClear={handleClear}
        initialValues={namedInitialValues}
        {...rest}
      >
        {content}
      </FilterPlain>
    );
  }
}

NumberIncrementFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

NumberIncrementFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(NumberIncrementFilter);
