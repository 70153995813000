import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';

import css from './SectionTraveler.module.css';

const SectionTraveler = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.content}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionTraveler.title" />
        </h2>

        <div className={css.card}>
          <h3 className={css.cardTitle}>
            <FormattedMessage id="SectionTraveler.cardTitle" />
          </h3>
          <p className={css.cardText}>
            <FormattedMessage id="SectionTraveler.cardContent" />
          </p>
          <NamedLink
            className={css.cta}
            name="HelpCenterPage"
            params={{
              tab: 'searching-and-booking',
              subtab: 'what-can-i-find',
            }}
          >
            <FormattedMessage id="General.knowMore" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionTraveler.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionTraveler.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTraveler;
