import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconItems.module.css';

const IconItems = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(20285 11808)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(-20285 -11808)" />
        <path d="M-20274.375-11786.125h-2.5a.625.625 0 0 0-.625.625v2.5a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625Zm0-12.5h-2.5a.625.625 0 0 0-.625.625v2.5a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625Zm0 6.25h-2.5a.625.625 0 0 0-.625.625v2.5a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625Zm16.25 6.875h-12.5a.625.625 0 0 0-.625.625v1.25a.625.625 0 0 0 .625.625h12.5a.625.625 0 0 0 .625-.625v-1.25a.625.625 0 0 0-.625-.625Zm0-12.5h-12.5a.625.625 0 0 0-.625.625v1.25a.625.625 0 0 0 .625.625h12.5a.625.625 0 0 0 .625-.625v-1.25a.625.625 0 0 0-.625-.625Zm0 6.25h-12.5a.625.625 0 0 0-.625.625v1.25a.625.625 0 0 0 .625.625h12.5a.625.625 0 0 0 .625-.625v-1.25a.625.625 0 0 0-.625-.625Z" />
      </g>
    </svg>
  );
};

IconItems.defaultProps = {
  rootClassName: null,
  className: null,
};

IconItems.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconItems;
