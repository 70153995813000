import React, { useMemo } from 'react';
import { string, oneOf } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { DATE_TYPE_CRION_DATE, propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import {
  NamedLink,
  ResponsiveImage,
  BookingTimeInfo,
  ListingTitle,
  UserDisplayName,
} from '../../components';
import { BOOKING_STATE_TYPE } from '../../constants/listingAttributes';
import Avatar from '../Avatar/Avatar';
import { txIsRequested } from '../../transactions/transactionProcessBooking';

import css from './BookedListingCard.module.css';
import { useConfiguration } from '../../context/configurationContext';
import { checkIfEntityForeignPartner } from '../../util/partner';

const bookingStateStyle = type => {
  switch (type) {
    case BOOKING_STATE_TYPE.ACCEPTED:
      return css.successBg;
    case BOOKING_STATE_TYPE.DECLINED:
      return css.negativeBg;
    case BOOKING_STATE_TYPE.PENDING:
      return css.attentionBg;
    case BOOKING_STATE_TYPE.CANCELED:
      return css.failColor;
    case BOOKING_STATE_TYPE.COMPLETED:
      return css.successColor;
    case BOOKING_STATE_TYPE.REVIEWED:
      return css.marketplaceBg;
    case BOOKING_STATE_TYPE.REQUESTED:
      return css.attentionBg;
    default:
      break;
  }
};

const ListingImage = props => <ResponsiveImage {...props} />;
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const BookedListingCardComponent = props => {
  const { imageWrapperClassName, intl, tx, type, unitType, stateData, renderSizes } = props;
  const { provider, customer, listing } = tx;
  const { stateType, state } = stateData;
  const isOrder = type === 'order';

  const imageWrapperClasses = useMemo(() => classNames(css.imageWrapper, imageWrapperClassName), [
    imageWrapperClassName,
  ]);

  const otherUser = isOrder ? provider : customer;

  const currentListing = useMemo(() => ensureListing(listing), [listing]);
  const { attributes, images } = currentListing;
  const { title: listingTitle = '', deleted, publicData, availabilityPlan } = attributes;

  const { activity, activityLocation, city } = publicData || {};
  const firstImage = useMemo(() => (images?.length > 0 ? images[0] : null), [images]);

  const timeZone = availabilityPlan ? availabilityPlan.timezone : 'Etc/UTC';

  const isSaleNotification = !isOrder && txIsRequested(tx);
  const notificationLabel = isSaleNotification ? (
    <div className={css.notificationLabel}>
      {intl
        .formatMessage({
          id: 'General.new',
        })
        .toLowerCase()}
    </div>
  ) : null;

  const avatar = (
    <Avatar
      user={otherUser}
      className={classNames(css.avatar, css.avatarPlace)}
      disableProfileLink
    />
  );

  return (
    <>
      <div className={imageWrapperClasses}>
        <LazyImage
          rootClassName={css.rootForImage}
          alt={listingTitle || ' '}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x']}
          sizes={renderSizes}
        />
        <div className={css.mobileAvatar}>{avatar}</div>
      </div>

      <div className={css.mainContent}>
        <div className={css.titleContainer}>
          <div className={classNames(css.title, { [css.deletedListing]: deleted })}>
            {!deleted ? (
              <ListingTitle listing={currentListing} />
            ) : (
              <FormattedMessage id={`General.deletedListing`} />
            )}
          </div>
          <div className={css.desktopAvatar}>{avatar}</div>
        </div>

        {!deleted && (
          <div className={css.info}>
            {city || activityLocation?.split(' ')[0]}
            <div className={css.activity}>
              <FormattedMessage id={`General.${activity}`} />
            </div>
            <div className={css.authorContent}>
              <UserDisplayName className={css.authorName} user={otherUser} intl={intl} />
              <div className={classNames(css.type, bookingStateStyle(stateType))}>{state}</div>
            </div>
            <div className={css.date}>
              <BookingTimeInfo
                isOrder={isOrder}
                intl={intl}
                tx={tx}
                unitType={unitType}
                dateType={DATE_TYPE_CRION_DATE}
                timeZone={timeZone}
              />
            </div>
          </div>
        )}
      </div>

      <div className={css.notificationLabelWrapper}>{notificationLabel}</div>
    </>
  );
};

BookedListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  imageWrapperClassName: null,
  renderSizes: null,
};

BookedListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  imageWrapperClassName: string,

  listing: propTypes.listing,
  unitType: propTypes.bookingUnitType.isRequired,
  tx: propTypes.transaction.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  renderSizes: string,

  intl: intlShape.isRequired,
};

const BookedListingCard = props => {
  const { rootClassName, className, tx, type, currentUser, ...rest } = props;

  const config = useConfiguration();

  const isOrder = type === 'order';
  const namedLinkProps = useMemo(
    () => ({
      name: isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage',
      params: { id: tx.id.uuid },
    }),
    [isOrder, tx.id.uuid]
  );

  const { partner } = tx?.attributes?.protectedData || {};

  const isForeignTx = checkIfEntityForeignPartner(partner, config);

  const classes = classNames(rootClassName || css.root, className, {
    [css.greyedOut]: isForeignTx,
  });

  return (
    <NamedLink {...namedLinkProps} isForeignLink={isForeignTx} className={classes}>
      <BookedListingCardComponent tx={tx} type={type} {...rest} />
    </NamedLink>
  );
};
BookedListingCard.displayName = 'BookedListingCard';
export default injectIntl(BookedListingCard);
