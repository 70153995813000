import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import Carousel from '../Carousel/Carousel';
import NamedLink from '../NamedLink/NamedLink';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { DISCIPLINES_TO_SLIDE } from './DisciplineSlider.helper';
import classNames from 'classnames';
import { stringify } from '../../util/urlHelpers';
import defaultLocations from '../../config/configDefaultLocationSearches';

import css from './DisciplineSlider.module.css';

// eslint-disable-next-line jsx-a11y/alt-text
const LazyImage = lazyLoadWithDimensions(props => <img {...props} />);

const DisciplineSlider = props => {
  const { className } = props;

  return (
    <Carousel
      className={classNames(css.root, className)}
      contentClassName={css.carouselContent}
      columns={6}
    >
      {DISCIPLINES_TO_SLIDE.map(discipline => (
        <NamedLink
          key={discipline.key}
          name="SearchPage"
          to={{
            search: stringify({
              ...defaultLocations[0].predictionPlace,
              ...discipline.queryParams,
            }),
          }}
          className={css.card}
        >
          <div className={css.imageWrapper}>
            <div className={css.aspectWrapper}>
              <LazyImage
                src={discipline.srcImg}
                alt={discipline.key}
                className={css.disciplineImage}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <FormattedMessage id={`General.${discipline.key}`} />
          </div>
        </NamedLink>
      ))}
    </Carousel>
  );
};

export default DisciplineSlider;
