import React, { useMemo } from 'react';
import { element, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';

import css from './ActivityCard.module.css';

const ActivityCard = props => {
  const {
    rootClassName,
    className,
    activity: { label, count, icon, key },
  } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    rootClassName,
    className,
  ]);
  const searchQuery = useMemo(() => `pub_activity=has_any%3A${key}`, [key]);

  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={classes}>
      <div className={css.iconWrapper}>{icon}</div>
      <div className={css.info}>
        <div className={css.label}>{label}</div>
        <div className={css.listing}>
          <FormattedMessage id="ActivityCard.listingCount" values={{ count }} />
        </div>
      </div>
    </NamedLink>
  );
};

ActivityCard.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityCard.propTypes = {
  rootClassName: string,
  className: string,

  activity: shape({
    key: string,
    label: string,
    numOfListings: number,
    icon: element,
  }),
};

export default ActivityCard;
