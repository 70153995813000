import { ALL_LISTING_TYPES, LISTING_TYPE } from './listingAttributes';

export const USER_TYPE = {
  BUSINESS: 'certifiedBusiness',
  PROFESSIONAL: 'certifiedProfessional',
  EXPERT: 'localExpert',
  USER: 'crionUser',
};

export const USER_TYPE_ALLOWED_LISTINGS = {
  [USER_TYPE.BUSINESS]: ALL_LISTING_TYPES,
  [USER_TYPE.PROFESSIONAL]: ALL_LISTING_TYPES,
  [USER_TYPE.EXPERT]: [LISTING_TYPE.EXPERIENCE, LISTING_TYPE.FREE],
  [USER_TYPE.USER]: [LISTING_TYPE.FREE],
};
