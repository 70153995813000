import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from '../../util/reactIntl';
import { generateContentFromTranslationMessages } from '../../util/contentGenerator';
import ExternalLink from '../ExternalLink/ExternalLink';

import css from './TermsAndConditions.module.css';

const crionEmail = (
  <ExternalLink key="emailCrion" title="Email Crion" href="mailto:help@crion.org">
    help@crion.org
  </ExternalLink>
);

const translationsWithValues = [
  {
    key: 'TermsAndConditions.section0paragraph1',
    values: {
      link1: (
        <ExternalLink key="linkToCrionOrg" title="Crion" href="https://www.crion.org">
          www.crion.org
        </ExternalLink>
      ),
      link2: (
        <ExternalLink key="linkToCrionCh" title="Crion" href="http://www.crion.ch">
          www.crion.ch
        </ExternalLink>
      ),
    },
  },
  {
    key: 'TermsAndConditions.section9paragraph4',
    values: {
      email: crionEmail,
    },
  },
  {
    key: 'TermsAndConditions.section10subsection3paragraph1',
    values: {
      email: crionEmail,
    },
  },
  {
    key: 'TermsAndConditions.section12paragraph3',
    values: {
      email: crionEmail,
    },
  },
];

const TermsAndConditions = props => {
  const { rootClassName, className, intl } = props;

  const termsAndConditions = useMemo(
    () =>
      generateContentFromTranslationMessages('TermsAndConditions', intl, translationsWithValues),
    [intl]
  );

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return <div className={classes}>{termsAndConditions}</div>;
};

TermsAndConditions.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsAndConditions.propTypes = {
  rootClassName: string,
  className: string,
};

export default injectIntl(TermsAndConditions);
