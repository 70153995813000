import React, { useEffect } from 'react';
import { bool, object, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './CookiePolicy.module.css';

const CookiePolicy = props => {
  const { rootClassName, className, text, isWidget } = props;

  const classes = classNames(rootClassName || css.root, { [css.widget]: isWidget }, className);

  useEffect(() => {
    const id = 'iubenda-js';

    const scriptExists = document.getElementById(id);
    if (scriptExists) {
      return;
    }

    const mewIubendsScript = document.createElement('script');
    mewIubendsScript.id = id;
    mewIubendsScript.src = 'https://cdn.iubenda.com/iubenda.js';

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(mewIubendsScript, firstScript);

    return () => {
      const iubendsScript = document.getElementById(id);
      if (iubendsScript) {
        iubendsScript.parentNode.removeChild(iubendsScript);
      }
    };
  }, []);

  return (
    <div className={classes}>
      <a
        href="https://www.iubenda.com/privacy-policy/17351107/cookie-policy"
        className={`iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe ${!isWidget &&
          'iub-body-embed'}`}
        style={{ opacity: isWidget ? 1 : 0 }}
        title="Cookie Policy "
      >
        {text}
      </a>
    </div>
  );
};

CookiePolicy.defaultProps = {
  rootClassName: null,
  className: null,
  isWidget: false,
  text: <FormattedMessage id="General.cookiePolicy" />,
};

CookiePolicy.propTypes = {
  rootClassName: string,
  className: string,
  isWidget: bool,
  text: oneOfType([string, object]),
};

export default CookiePolicy;
