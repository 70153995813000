import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconVerifiedUser.module.css';

const IconVerifiedUser = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path d="M15,1,3,6.091v7.636A15.964,15.964,0,0,0,15,29,15.964,15.964,0,0,0,27,13.727V6.091ZM12.333,21.364,7,16.273l1.88-1.795,3.453,3.284L21.12,9.375,23,11.182Z" />
    </svg>
  );
};

IconVerifiedUser.defaultProps = {
  rootClassName: null,
  className: null,
  sizes: null,
};

IconVerifiedUser.propTypes = {
  rootClassName: string,
  className: string,
  sizes: string,
};

export default IconVerifiedUser;
