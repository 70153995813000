/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

import { ACTIVITY, LISTING_SEASONALITY, LISTING_TYPE } from '../constants/listingAttributes';
import { USER_TYPE } from '../constants/userAttributes';
import { PARTNER_KEYS } from '../util/partner';

export const CONFIGURATION_OPTION_STATE = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'people',
    scope: 'public',
    schemaType: 'long',
    filterConfig: {
      indexForSearch: true,
      label: 'General.people',
      group: 'primary',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'General.people',
      isDetail: true,
    },
  },
  {
    key: 'children',
    scope: 'none',
    schemaType: 'long',
    group: 'primary',
    filterConfig: {
      indexForSearch: true,
      label: 'General.children',
      group: 'primary',
    },
    showConfig: {
      label: 'General.children',
      isDetail: true,
    },
  },
  {
    key: 'adults',
    scope: 'none',
    schemaType: 'long',
    group: 'primary',
    filterConfig: {
      indexForSearch: true,
      label: 'General.adultsWithAge',
      group: 'primary',
    },
    showConfig: {
      label: 'General.adultsWithAge',
      isDetail: true,
    },
  },
  {
    key: 'activity',
    schemaType: 'enum',
    enumOptions: [
      { option: ACTIVITY.SKIING, label: 'General.skiing' },
      { option: ACTIVITY.SNOWBOARDING, label: 'General.snowboarding' },
      { option: ACTIVITY.HIKING, label: 'General.hiking' },
      { option: ACTIVITY.RUNNING, label: 'General.running' },
      { option: ACTIVITY.HORSE_RIDING, label: 'General.horseRiding' },
      { option: ACTIVITY.BIKING, label: 'General.biking' },
      { option: ACTIVITY.CLIMBING, label: 'General.climbing' },
      { option: ACTIVITY.SLEDGING, label: 'General.sledging' },
      { option: ACTIVITY.RIVER_SPORTS, label: 'General.riverSports' },
      { option: ACTIVITY.LAKE_AND_SEA_SPORTS, label: 'General.lakeAndSeaSports' },
      { option: ACTIVITY.AIR_SPORTS, label: 'General.airSports' },
      {
        option: ACTIVITY.COURT_SPORTS,
        label: 'General.courtSports',
        showForPartners: [PARTNER_KEYS.GENOVA],
      },
      { option: ACTIVITY.OTHER_EXPERIENCES, label: 'General.otherExperiences' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'General.activity',
      group: 'primary',
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'General.activity',
      isDetail: true,
    },
  },
  {
    key: 'discipline',
    schemaType: 'enum',
    // Discipline options depends of selected activity but we need
    // this options here to validate the filter options in SeachPage.helpers.js
    enumOptions: [
      // Skiing
      {
        option: 'alpineSkiing',
        label: 'General.alpineSkiing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'freerideSkiing',
        label: 'General.freerideSkiing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'freestyleSkiing',
        label: 'General.freestyleSkiing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'skiTouring',
        label: 'General.skiTouring',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'telemarkSkiing',
        label: 'General.telemarkSkiing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'crossCountrySkiing',
        label: 'General.crossCountrySkiing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'heliSkiing',
        label: 'General.heliSkiing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'grassSkiing',
        label: 'General.grassSkiing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'avalancheTraining',
        label: 'General.avalancheTraining',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON],
      },
      // Snowboarding
      {
        option: 'onPisteSnowboarding',
        label: 'General.onPisteSnowboarding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'freerideSnowboarding',
        label: 'General.freerideSnowboarding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'freestyleSnowboarding',
        label: 'General.freestyleSnowboarding',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'heliBoarding',
        label: 'General.heliBoarding',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'splitBoarding',
        label: 'General.splitBoarding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      // Hiking
      {
        option: 'mountainHiking',
        label: 'General.mountainHiking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'snowShoeing',
        label: 'General.snowShoeing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      // Running
      {
        option: 'trailRunning',
        label: 'General.trailRunning',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      // Horse riding
      {
        option: 'horseRiding',
        label: 'General.horseRiding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      // Biking
      {
        option: 'mountainBiking',
        label: 'General.mountainBiking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'roadCycling',
        label: 'General.roadCycling',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'eBiking',
        label: 'General.eBiking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'fatBiking',
        label: 'General.fatBiking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      // Climbing
      {
        option: 'mountaineering',
        label: 'General.mountaineering',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'rockClimbing',
        label: 'General.rockClimbing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'viaFerrata',
        label: 'General.viaFerrata',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'bouldering',
        label: 'General.bouldering',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON],
      },
      {
        option: 'iceClimbing',
        label: 'General.iceClimbing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON],
      },
      // Sledding
      {
        option: 'tobogganing',
        label: 'General.tobogganing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'huskySledding',
        label: 'General.huskySledding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'snowMobiling',
        label: 'General.snowMobiling',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'horseCarriageSledding',
        label: 'General.horseCarriageSledding',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      // River sports
      {
        option: 'rafting',
        label: 'General.rafting',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'canyoning',
        label: 'General.canyoning',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      // Lake & Sea sports
      {
        option: 'sailing',
        label: 'General.sailing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'kayaking',
        label: 'General.kayaking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'standUpPaddle',
        label: 'General.standUpPaddle',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'boatCruise',
        label: 'General.boatCruise',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'fishing',
        label: 'General.fishing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      // Air sports
      {
        option: 'paragliding',
        label: 'General.paragliding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'bungeeJumping',
        label: 'General.bungeeJumping',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'skydiving',
        label: 'General.skydiving',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'kiting',
        label: 'General.kiting',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        typeTags: [],
        seasonalityTags: [],
      },
      {
        option: 'tandemSpeedFlying',
        label: 'General.tandemSpeedFlying',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      // Other experiences
      {
        option: 'guidedWalking',
        label: 'General.guidedWalking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'culinaryTasting',
        label: 'General.culinaryTasting',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'northernLights',
        label: 'General.northernLights',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'yoga',
        label: 'General.yoga',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      // Court sports
      {
        option: 'tennis',
        label: 'General.tennis',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON],
        showForPartners: [PARTNER_KEYS.GENOVA],
      },
      {
        option: 'padel',
        label: 'General.padel',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON],
        showForPartners: [PARTNER_KEYS.GENOVA],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'General.discipline',
      group: 'primary',
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'General.discipline',
      isDetail: true,
    },
  },
  {
    key: 'type',
    schemaType: 'enum',
    enumOptions: [
      {
        option: LISTING_TYPE.LESSON,
        label: 'General.lesson',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
      },
      {
        option: LISTING_TYPE.TOUR,
        label: 'General.tour',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
      },
      {
        option: LISTING_TYPE.EXPERIENCE,
        label: 'General.experience',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
      },
      {
        option: LISTING_TYPE.FREE,
        label: 'General.freeSharing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'General.type',
      group: 'primary',
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'General.type',
      isDetail: true,
    },
  },
  {
    key: 'ability',
    schemaType: 'enum',
    enumOptions: [
      { option: 'veryEasy', label: 'General.veryEasy', selectedLabel: 'General.veryEasySelected' },
      { option: 'easy', label: 'General.easy', selectedLabel: 'General.easySelected' },
      {
        option: 'intermediate',
        label: 'General.intermediate',
        selectedLabel: 'General.intermediateSelected',
      },
      {
        option: 'difficult',
        label: 'General.difficult',
        selectedLabel: 'General.difficultSelected',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'General.ability',
      group: 'primary',
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'General.ability',
      isDetail: true,
    },
  },
  {
    key: 'languages',
    schemaType: 'enum',
    enumOptions: [
      { option: 'english', label: 'English' },
      { option: 'french', label: 'Français' },
      { option: 'german', label: 'Deutsch' },
      { option: 'italian', label: 'Italiano' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'General.languages',
      group: 'primary',
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'General.languages',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.SKIING,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'alpineSkiing',
        label: 'General.alpineSkiing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'freerideSkiing',
        label: 'General.freerideSkiing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'freestyleSkiing',
        label: 'General.freestyleSkiing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'skiTouring',
        label: 'General.skiTouring',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'telemarkSkiing',
        label: 'General.telemarkSkiing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'crossCountrySkiing',
        label: 'General.crossCountrySkiing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'heliSkiing',
        label: 'General.heliSkiing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'grassSkiing',
        label: 'General.grassSkiing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'avalancheTraining',
        label: 'General.avalancheTraining',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Skiing',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Skiing',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.SNOWBOARDING,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'onPisteSnowboarding',
        label: 'General.onPisteSnowboarding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'freerideSnowboarding',
        label: 'General.freerideSnowboarding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'onPisteSnowboarding',
        label: 'General.onPisteSnowboarding',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'freestyleSnowboarding',
        label: 'General.freestyleSnowboarding',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'heliBoarding',
        label: 'General.heliBoarding',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'splitBoarding',
        label: 'General.splitBoarding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Snowboarding',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Snowboarding',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.HIKING,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'mountainHiking',
        label: 'General.mountainHiking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'snowShoeing',
        label: 'General.snowShoeing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.TOUR],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Hiking',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Hiking',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.RUNNING,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'trailRunning',
        label: 'General.trailRunning',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Running',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Running',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.HORSE_RIDING,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'horseRiding',
        label: 'General.horseRiding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Horse riding',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Horse riding',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.BIKING,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'mountainBiking',
        label: 'General.mountainBiking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'roadCycling',
        label: 'General.roadCycling',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'eBiking',
        label: 'General.eBiking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'fatBiking',
        label: 'General.fatBiking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.TOUR],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Biking',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Biking',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.CLIMBING,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'mountaineering',
        label: 'General.mountaineering',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'rockClimbing',
        label: 'General.rockClimbing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'viaFerrata',
        label: 'General.viaFerrata',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'bouldering',
        label: 'General.bouldering',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON],
      },
      {
        option: 'iceClimbing',
        label: 'General.iceClimbing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Climbing',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Climbing',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.SLEDGING,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'tobogganing',
        label: 'General.tobogganing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'huskySledding',
        label: 'General.huskySledding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'snowMobiling',
        label: 'General.snowMobiling',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'horseCarriageSledding',
        label: 'General.horseCarriageSledding',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Sledging',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Sledging',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.RIVER_SPORTS,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'rafting',
        label: 'General.rafting',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'canyoning',
        label: 'General.canyoning',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'River sports',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'River sports',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.LAKE_AND_SEA_SPORTS,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'sailing',
        label: 'General.sailing',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'kayaking',
        label: 'General.kayaking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'standUpPaddle',
        label: 'General.standUpPaddle',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
      {
        option: 'boatCruise',
        label: 'General.boatCruise',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'fishing',
        label: 'General.fishing',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Lake & sea sports',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Lake & sea sports',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.AIR_SPORTS,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'paragliding',
        label: 'General.paragliding',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'bungeeJumping',
        label: 'General.bungeeJumping',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'skydiving',
        label: 'General.skydiving',
        state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        seasonalityTags: [],
        typeTags: [],
      },
      {
        option: 'kiting',
        label: 'General.kiting',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        typeTags: [],
        seasonalityTags: [],
      },
      {
        option: 'tandemSpeedFlying',
        label: 'General.tandemSpeedFlying',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [],
        typeTags: [],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Air sports',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Air sports',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.OTHER_EXPERIENCES,
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'guidedWalking',
        label: 'General.guidedWalking',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'culinaryTasting',
        label: 'General.culinaryTasting',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'northernLights',
        label: 'General.northernLights',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER],
        typeTags: [LISTING_TYPE.TOUR],
      },
      {
        option: 'yoga',
        label: 'General.yoga',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Other experiences',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Other experiences',
      isDetail: true,
    },
  },
  {
    key: ACTIVITY.COURT_SPORTS,
    schemaType: 'enum',
    showForPartners: [PARTNER_KEYS.GENOVA],
    enumOptions: [
      {
        option: 'tennis',
        label: 'General.tennis',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON],
      },
      {
        option: 'padel',
        label: 'General.padel',
        state: CONFIGURATION_OPTION_STATE.ACTIVE,
        seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
        typeTags: [LISTING_TYPE.LESSON],
      },
    ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    filterConfig: {
      indexForSearch: true,
      label: 'Court sports',
      group: 'primary',
      filterType: 'SelectSingleFilter',
    },
    showConfig: {
      label: 'Court sports',
      isDetail: true,
    },
  },
  {
    key: 'provider',
    schemaType: 'enum',
    enumOptions: [
      {
        option: USER_TYPE.BUSINESS,
        label: 'General.certifiedBusiness',
        selectedLabel: 'General.certifiedBusinessSelected',
      },
      {
        option: USER_TYPE.PROFESSIONAL,
        label: 'General.certifiedProfessional',
        selectedLabel: 'General.certifiedProfessionalSelected',
      },
      {
        option: USER_TYPE.EXPERT,
        label: 'General.localExpert',
        selectedLabel: 'General.localExpertSelected',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'General.provider',
      group: 'primary',
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'General.provider',
      isDetail: true,
    },
  },
  {
    key: 'groupType',
    schemaType: 'enum',
    enumOptions: [
      { option: 'private', label: 'General.private' },
      { option: 'group', label: 'General.group' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'General.session',
      group: 'primary',
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'General.session',
      isDetail: true,
    },
  },
  {
    key: 'services',
    schemaType: 'enum',
    enumOptions: [
      { option: 'gear', label: 'General.gear' },
      { option: 'food', label: 'General.food' },
      { option: 'tickets', label: 'General.tickets' },
      { option: 'overnightStay', label: 'General.overnightStay' },
      { option: 'drinks', label: 'General.drinks' },
      { option: 'transportation', label: 'General.transportation' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Services',
      group: 'secondary',
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'Services',
      isDetail: true,
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
