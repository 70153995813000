export const ACTIVITY = 'activity';
export const PRICING = 'pricing';
export const DESCRIPTION = 'description';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [ACTIVITY, PRICING, DESCRIPTION];

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
// Note 3: in FTW-hourly template we don't use the POLICY tab so it's commented out.
// If you want to add a free text field to your listings you can enable the POLICY tab
export const TABS = [ACTIVITY, PRICING, DESCRIPTION];
