export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }

  addUserContext(userData) {
    console.log('Analytics add user context:', getTrackingListingData(userData));
  }

  trackSignUp() {
    console.log('Analytics sign up');
  }

  trackListingView(listing) {
    console.log('Analytics listing view:', getTrackingListingData(listing));
  }

  trackBookingInitiated(listing, price, currency) {
    console.log('Analytics booking initiated:', getTrackingListingData(listing), price, currency);
  }

  trackBookingFinalized(listing, transactionId, price, currency) {
    console.log(
      'Analytics booking finalized:',
      getTrackingListingData(listing),
      transactionId,
      price,
      currency
    );
  }

  trackHostContact(listing, transactionId) {
    console.log('Analytics host contact:', getTrackingListingData(listing), transactionId);
  }
}

export class GoogleTagManagerHandler {
  static createHandler = (log = false) => {
    const isWindowDefined = typeof window !== 'undefined';

    this._dataLayer = isWindowDefined && process.env.REACT_APP_GTM_ID && window.dataLayer;
    this._log = log;
    this._userData = null;
  };

  static pushToDataLayer = eventData => {
    if (this._log) {
      console.log('DataLayer updated: ', eventData);
    }

    if (this._dataLayer) {
      try {
        this._dataLayer.push({
          userData: this._userData,
          ...eventData,
        });
      } catch (err) {
        console.error('Failed adding event');
      }
    }
  };

  static addUserContext = user => {
    this._userData = getTrackingUserData(user);
  };

  static trackPageView = (url, prevUrl) => {
    GoogleTagManagerHandler.pushToDataLayer({
      event: 'Pageview',
      pagePath: url,
      previousPagePath: prevUrl,
    });
  };

  static trackSignUp = (signUpMethod = 'email') => {
    GoogleTagManagerHandler.pushToDataLayer({
      event: 'SignUp',
      signUpMethod,
    });
  };

  static trackListingView = listing => {
    GoogleTagManagerHandler.pushToDataLayer({
      event: 'ListingView',
      ...getTrackingListingData(listing),
    });
  };

  static trackBookingInitiated = (listing, price, currency) => {
    GoogleTagManagerHandler.pushToDataLayer({
      event: 'BookingInitiated',
      price: price ? price / 100 : 0,
      currency,
      ...getTrackingListingData(listing),
    });
  };

  static trackBookingFinalized = (listing, transactionId, price, currency) => {
    GoogleTagManagerHandler.pushToDataLayer({
      event: 'BookingFinalized',
      price: price ? price / 100 : 0,
      currency,
      transactionData: {
        id: transactionId,
      },
      ...getTrackingListingData(listing),
    });
  };

  static trackHostContact = (listing, transactionId) => {
    GoogleTagManagerHandler.pushToDataLayer({
      event: 'HostContact',
      ...getTrackingListingData(listing),
      transactionData: {
        id: transactionId,
      },
    });
  };
}

function getTrackingUserData(user) {
  if (!user) {
    return null;
  }

  const { id, attributes } = user || {};

  const { profile, email, emailVerified } = attributes || {};

  const { firstName, lastName, publicData, protectedData, privateData } = profile || {};

  const { gender } = publicData || {};
  const { phoneNumber } = protectedData || {};
  const { birthdate } = privateData || {};

  return {
    id: id?.uuid,
    firstName,
    lastName,
    gender,
    email,
    phoneNumber,
    emailVerified,
    birthDate: birthdate,
  };
}

function getTrackingListingData(listing) {
  if (!listing) {
    return { listingData: null };
  }

  const { attributes: { publicData, metadata, title, price } = {}, author } = listing || {};

  const ensurePublicData = publicData || {};
  const ensureMetadata = metadata || {};

  const { id = {} } = author || {};

  const { amount, currency } = price || {};

  return {
    listingData: {
      id: listing.id?.uuid,
      hostId: id.uuid,
      title: title,
      type: ensurePublicData.type,
      ability: ensurePublicData.ability,
      activity: ensurePublicData.activity,
      discipline: ensurePublicData.discipline,
      groupType: ensurePublicData.groupType,
      languages: ensurePublicData.languages,
      duration: ensurePublicData.duration,
      ratingCount: ensureMetadata.ratingCount,
      averageRating: ensureMetadata.averageRating,
      price: {
        amount: amount ? amount / 100 : 0,
        currency,
      },
    },
  };
}
