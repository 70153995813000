import React from 'react';
import { string } from 'prop-types';

const IconOtherExperiences = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 -960 960 960">
      <path d="m280-40 112-564-72 28v136h-80v-188l202-86q14-6 29.5-7t29.5 4q14 5 26.5 14t20.5 23l40 64q26 42 70.5 69T760-520v80q-70 0-125-29t-94-74l-25 123 84 80v300h-80v-260l-84-64-72 324h-84Zm260-700q-33 0-56.5-23.5T460-820q0-33 23.5-56.5T540-900q33 0 56.5 23.5T620-820q0 33-23.5 56.5T540-740Z" />
    </svg>
  );
};

IconOtherExperiences.defaultProps = { className: null };
IconOtherExperiences.propTypes = { className: string };

export default IconOtherExperiences;
