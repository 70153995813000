import React, { useCallback, useMemo, useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import {
  Activity,
  IconSuccess,
  IconRefresh,
  IconTrashBin,
  ModalContainer,
  SecondaryButton,
  Difficulty,
} from '..';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import AddActivityForm from '../../forms/AddActivityForm/AddActivityForm';

import css from './ProfileActivities.module.css';
import { useConfiguration } from '../../context/configurationContext';

const ProfileActivities = props => {
  const {
    intl,
    activities = {},
    certificates = [],
    isEditMode,
    className,
    onUpdateProfile,
    onClearFormErrors,
    submitInProgress,
    submitError,
  } = props;

  const config = useConfiguration();

  const [activityModalOpen, setActivityModalOpen] = useState(null);

  const addActivityTitle = useMemo(
    () => <FormattedMessage id="ProfileSettingsPage.addActivity" />,
    []
  );

  const abilityOptions = useMemo(
    () => findOptionsForSelectFilter('ability', config.search.defaultFilters),
    [config.search.defaultFilters]
  );

  const activityOptions = useMemo(
    () =>
      findOptionsForSelectFilter('activity', config.search.defaultFilters).map(
        ({ key, label }) => ({
          key,
          label: intl.formatMessage({ id: label }),
        })
      ),
    [config.search.defaultFilters, intl]
  );

  const isActivityModalOpen = useMemo(() => !!activityModalOpen, [activityModalOpen]);

  const activityKeys = useMemo(() => Object.keys(activities), [activities]);

  const hasAddedActivities = useMemo(() => activityKeys.length > 0, [activityKeys.length]);

  const getActivityDisciplines = useCallback(
    activity => findOptionsForSelectFilter(activity, config.search.defaultFilters),
    [config.search.defaultFilters]
  );

  const findCertificateForDiscipline = useCallback(
    discipline => certificates.find(cert => cert.discipline === discipline),
    [certificates]
  );

  const handleSubmitAddActivity = useCallback(
    values => {
      const { activity, discipline, ability, certificate } = values;

      const certificateMaybe = certificate ? { certificate: certificate[0], discipline } : null;

      const disciplineCertificateToDelete =
        !certificate && findCertificateForDiscipline(discipline) ? discipline : null;

      const addValue = {
        discipline,
        ability,
      };

      const profileData = { publicData: { activities: { ...activities } } };

      if (!profileData.publicData.activities[activity])
        profileData.publicData.activities[activity] = [];

      const activityData = profileData.publicData.activities[activity];

      const isDisciplineUsed = activityData.findIndex(
        activityInfo => activityInfo.discipline === discipline
      );

      if (isDisciplineUsed !== -1) activityData[isDisciplineUsed] = addValue;
      else activityData.push(addValue);

      onUpdateProfile(profileData, certificateMaybe, disciplineCertificateToDelete);
    },
    [activities, findCertificateForDiscipline, onUpdateProfile]
  );

  const removeDiscipline = useCallback(
    async data => {
      const { activity, disciplineIndex } = data;

      const profileData = { publicData: { activities: {} } };

      const activityData = { ...activities };
      const disciplineRemoved = activityData[activity].splice(disciplineIndex, 1);

      const { discipline } = disciplineRemoved[0];

      profileData.publicData.activities = { ...activityData };

      if (activityData[activity].length === 0) {
        const filteredActivities = Object.keys(activityData).reduce((acc, activityName) => {
          if (activityName !== activity) acc[activityName] = activityData[activityName];
          return acc;
        }, {});

        profileData.publicData.activities = { ...filteredActivities };
      }

      onUpdateProfile(profileData, null, discipline);
    },
    [activities, onUpdateProfile]
  );

  const openActivityModal = useCallback(
    (data = null) => {
      const uploadedCertificate = findCertificateForDiscipline(data?.discipline);

      const certificate = uploadedCertificate
        ? [{ name: uploadedCertificate.name, url: uploadedCertificate.url }]
        : null;

      const initialValues = data
        ? {
            ...data,
            certificate,
          }
        : {};

      setActivityModalOpen(initialValues);
    },
    [findCertificateForDiscipline]
  );

  const onCloseActivityModal = useCallback(() => {
    setActivityModalOpen(null);
    onClearFormErrors();
  }, [onClearFormErrors]);

  return (
    <div className={classNames(css.root, className)}>
      <label>
        <FormattedMessage id="General.activities" />
      </label>

      {!hasAddedActivities && (
        <FormattedMessage
          id="General.noItems"
          values={{ items: intl.formatMessage({ id: 'General.activities' }) }}
        />
      )}

      {activityKeys?.map(activity => (
        <div key={activity} className={css.activityContainer}>
          <div className={css.activityType}>
            <Activity activity={activity} hasIconBackground />
          </div>

          {activities[activity].map((activityProp, disIndex) => {
            const { ability, discipline } = activityProp;

            const certificate = findCertificateForDiscipline(discipline);

            const hasUploadedCertificate = !!certificate;

            const { isVerified } = certificate || {};

            return (
              <div key={discipline} className={css.disciplineWrapper}>
                <div className={css.leftSection}>
                  <div className={css.disciplineNameWrapper}>
                    <span className={css.certificateIconWrapper}>
                      {hasUploadedCertificate && (
                        <IconSuccess tagFill={isVerified ? 'default' : 'notverified'} />
                      )}
                    </span>
                    <FormattedMessage id={`General.${discipline}`} />
                  </div>

                  <div className={css.disciplineAbilityWrapper}>
                    <Difficulty ability={ability} hasIconBackground />
                  </div>
                </div>
                {isEditMode && (
                  <div className={css.disciplineButtonsWrapper}>
                    <button
                      type="button"
                      className={css.openModal}
                      onClick={() => openActivityModal({ activity, ability, discipline })}
                    >
                      <IconRefresh />
                    </button>
                    <button
                      type="button"
                      className={css.openModal}
                      onClick={() => removeDiscipline({ activity, disciplineIndex: disIndex })}
                    >
                      <IconTrashBin />
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}

      {isEditMode && submitError}
      {isEditMode && (
        <SecondaryButton
          type="button"
          className={classNames(css.addButton, css.formMargins)}
          onClick={() => openActivityModal()}
        >
          <FormattedMessage id="ProfileSettingsForm.add" />
          <span className={css.addButtonPlus}>+</span>
        </SecondaryButton>
      )}

      {isEditMode && (
        <ModalContainer
          id="AddActivityModal"
          title={addActivityTitle}
          isModalOpen={isActivityModalOpen}
          onClose={onCloseActivityModal}
        >
          {isActivityModalOpen && (
            <AddActivityForm
              updateInProgress={submitInProgress}
              updateError={submitError}
              initialValues={activityModalOpen}
              abilityOptions={abilityOptions}
              activityOptions={activityOptions}
              getActivityDisciplines={getActivityDisciplines}
              onClearFormErrors={onClearFormErrors}
              onSubmit={handleSubmitAddActivity}
            />
          )}
        </ModalContainer>
      )}
    </div>
  );
};

ProfileActivities.propTypes = {
  intl: intlShape.isRequired,
  className: string,
  activities: object.isRequired,
  certificates: arrayOf(object.isRequired).isRequired,
  isEditMode: bool,
  onUpdateProfile: func,
  onClearFormErrors: func,
  submitInProgress: bool,
  submitError: object,
};

export default ProfileActivities;
