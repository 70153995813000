import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_HIGH_SEASON, LINE_ITEM_LOW_SEASON, propTypes } from '../../util/types';
import classNames from 'classnames';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

import css from './OrderBreakdown.module.css';

const LineItemSeason = props => {
  const { transaction, intl, isEstimated, seasonalCharge } = props;

  const seasonLineItems = transaction.attributes.lineItems.filter(
    item =>
      (item.code === LINE_ITEM_HIGH_SEASON || item.code === LINE_ITEM_LOW_SEASON) && !item.reversal
  );

  if (seasonLineItems.length === 0) return null;

  return seasonLineItems.map(lineItem => {
    const { code, lineTotal } = lineItem;

    const total = formatMoney(intl, lineTotal, true);

    const isHighSeason = code === LINE_ITEM_HIGH_SEASON;

    const translationKey = isHighSeason ? 'General.highSeason' : 'General.lowSeason';
    const tooltipKey = isHighSeason
      ? 'BookingTimeForm.highSeasonCharge'
      : 'BookingTimeForm.lowSeasonDiscount';

    return (
      <div className={css.lineItem} key={code}>
        <span className={classNames(css.itemLabel, { [css.itemGray]: !isEstimated })}>
          <FormattedMessage id={translationKey} />
          <InfoTooltip id={tooltipKey} triggerClassName={css.feeTooltip}>
            <FormattedMessage
              id={tooltipKey}
              values={{
                percent: isHighSeason ? seasonalCharge.highSeason : seasonalCharge.lowSeason,
              }}
            />
          </InfoTooltip>
        </span>
        <span className={css.itemValue}>{isHighSeason ? `+${total}` : total}</span>
      </div>
    );
  });
};

LineItemSeason.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSeason;
