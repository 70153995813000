import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCancel.module.css';

const IconCancel = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path d="M15 0a15 15 0 1 0 15 15A14.986 14.986 0 0 0 15 0Zm7.5 20.385L20.385 22.5 15 17.115 9.615 22.5 7.5 20.385 12.885 15 7.5 9.615 9.615 7.5 15 12.885 20.385 7.5 22.5 9.615 17.115 15Z" />
    </svg>
  );
};

IconCancel.defaultProps = {
  rootClassName: null,
  className: null,
};

IconCancel.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCancel;
