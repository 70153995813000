import React from 'react';
import { string } from 'prop-types';

const IconBiking = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 -5 45 41">
      <path d="M28.125,6.75A3.375,3.375,0,1,0,24.75,3.375,3.375,3.375,0,0,0,28.125,6.75Zm-.281,8.508a2.243,2.243,0,0,0,1.406.492h4.5a2.25,2.25,0,0,0,0-4.5H30.039L25.031,7.242a2.246,2.246,0,0,0-2.869.048l-7.875,6.75a2.25,2.25,0,0,0,.217,3.58l5.747,3.833v7.8a2.25,2.25,0,0,0,4.5,0v-9a2.25,2.25,0,0,0-1-1.871L20.84,16.44l4.1-3.51ZM36,18a9,9,0,1,0,9,9A9,9,0,0,0,36,18Zm0,13.5A4.5,4.5,0,1,1,40.5,27,4.5,4.5,0,0,1,36,31.5ZM9,18a9,9,0,1,0,9,9A9,9,0,0,0,9,18ZM9,31.5A4.5,4.5,0,1,1,13.5,27,4.5,4.5,0,0,1,9,31.5Z" />
    </svg>
  );
};

IconBiking.defaultProps = { className: null };
IconBiking.propTypes = { className: string };

export default IconBiking;
