import { denormalisedEntities, denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import listings from '../../api/listings';
import { parseListingToSharetribe } from '../../util/sharetribeParser';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
} from '../../util/types';

const RESULT_PAGE_SIZE = 10;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  listings: [],
  pagination: {},
  queryInProgress: false,
  queryListingsError: null,
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
};

const updateListingAttributes = (state, listingId) => {
  const newListings = state.listings.filter(listing => listing.id.uuid !== listingId.uuid);

  return {
    ...state,
    listings: newListings,
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        queryListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      const { pagination, listings: newListings } = payload;
      return {
        ...state,
        listings: pagination.page > 1 ? [...state.listings, ...newListings] : [...newListings],
        pagination: { ...pagination },
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.listingId),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.listingId),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = listingId => ({
  type: OPEN_LISTING_SUCCESS,
  payload: { listingId },
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = listingId => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: { listingId },
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryOwnListings = params => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const { tab, userId, page = 1, perPage = RESULT_PAGE_SIZE } = params;

  const listingStates = {
    published: { 'attributes.state': { $eq: LISTING_STATE_PUBLISHED } },
    unpublished: {
      'attributes.state': { $in: [LISTING_STATE_CLOSED, LISTING_STATE_PENDING_APPROVAL] },
    },
    drafted: { 'attributes.state': { $eq: LISTING_STATE_DRAFT } },
  };

  const listingState = listingStates[tab];

  const newParams = {
    ...listingState,
    authorId: userId,
    $page: page,
    $limit: perPage,
    $sort: '-createdAt',
  };

  return listings
    .fetchListings(newParams)
    .then(res => {
      const { items, ...paginationProps } = res.data;

      const pagination = {
        ...paginationProps,
        perPage,
        page: paginationProps.currentPage,
      };
      const listings = items.map(listing => parseListingToSharetribe(listing, listing.author));
      dispatch(queryListingsSuccess({ listings, pagination }));
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const querySavedListings = params => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const { ids, page = 1, perPage = RESULT_PAGE_SIZE } = params;
  const totalItems = ids.length;
  const totalPages = Math.ceil(totalItems / perPage);

  const startItemsIndex = (page - 1) * perPage;
  const endItemIndex = startItemsIndex + perPage;

  const itemsIds = ids.slice(startItemsIndex, endItemIndex);

  return sdk.listings
    .query({
      ids: itemsIds,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    })
    .then(response => {
      const pagination = {
        page,
        perPage,
        totalPages,
        totalItems,
      };

      const listings = denormalisedResponseEntities(response);

      dispatch(queryListingsSuccess({ listings, pagination }));
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return listings
    .closeListing(listingId.uuid)
    .then(response => {
      dispatch(closeListingSuccess(listingId));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(e));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return listings
    .openListing(listingId.uuid)
    .then(response => {
      dispatch(openListingSuccess(listingId));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(e));
    });
};

export const queryListings = params => (dispatch, getState, sdk) => {
  const { currentUser, tab, ...restProps } = params;
  const { savedListings = [] } = currentUser.attributes.profile.privateData;

  if (tab !== 'saved')
    dispatch(queryOwnListings({ userId: currentUser.id.uuid, tab, ...restProps }));
  else dispatch(querySavedListings({ ids: savedListings, ...restProps }));
};
