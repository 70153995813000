import React, { useCallback, useMemo, useState } from 'react';
import { object, string } from 'prop-types';
import { IconArrowHead, ValidationError, OutsideClickHandler } from '..';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import {
  numOfdaysInMonth,
  getMonthStrings,
  dateIsAfter,
  parseDateFromISO8601,
  stringifyDateToISO8601,
  dateFormat,
} from '../../util/dates';

import css from './DateSelect.module.css';

const MIN_YEAR = 1900;
const TODAY = new Date();

const dateToday = {
  day: TODAY.getDate(),
  month: TODAY.getMonth(),
  year: TODAY.getFullYear(),
};

const isDateGreaterThanCurrent = date => {
  return dateIsAfter(
    stringifyDateToISO8601(date),
    [stringifyDateToISO8601(dateToday), 'day'],
    false
  );
};

const DateSelectComponent = props => {
  const {
    className,
    labelClassName,
    inputContainerClassName,
    id,
    label,
    placeholder,
    input,
    meta,
  } = props;

  const [selectOpen, setSelectOpen] = useState(false);

  const { value: inputValue, onChange, onFocus, onBlur } = useMemo(() => input, [input]);

  const months = useMemo(() => getMonthStrings(), []);

  const dateValues = useMemo(() => {
    if (!inputValue) return dateToday;

    const date = parseDateFromISO8601(inputValue);
    return {
      day: +date.getDate(),
      month: +date.getMonth(),
      year: +date.getFullYear(),
    };
  }, [inputValue]);

  const disabledButton = useMemo(() => {
    const maxYear = dateValues.year === dateToday.year;
    const minYear = dateValues.year === MIN_YEAR;
    const maxManth = maxYear && dateValues.month === dateToday.month;
    const maxDay = maxManth && dateValues.day === dateToday.day;

    return {
      year: { max: maxYear, min: minYear },
      month: { max: maxManth },
      day: { max: maxDay },
    };
  }, [dateValues.year, dateValues.month, dateValues.day]);

  const { valid, invalid, touched, error } = meta;
  // Error message and error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error };

  const classes = classNames(css.root, className);

  const inputContainerClasses = useMemo(
    () =>
      classNames(
        css.inputContainer,
        { [css.selectSuccess]: dateValues && valid },
        { [css.selectError]: hasError },
        inputContainerClassName
      ),
    [inputContainerClassName, hasError, valid, dateValues]
  );

  const formattedDate = useMemo(() => dateFormat(dateValues), [dateValues]);

  const setNewDate = useCallback(
    date => {
      onChange(stringifyDateToISO8601(date)); // 'YYYY-MM-DD'
    },
    [onChange]
  );

  const increment = useCallback(
    value => () => {
      let newDate = { ...dateValues };

      switch (value) {
        case 'year':
          newDate.year++;
          break;
        case 'month':
          if (newDate.month === 11) newDate.month = 0;
          else newDate.month++;
          break;
        case 'day':
          if (numOfdaysInMonth(inputValue) === newDate.day) newDate.day = 1;
          else newDate.day++;
          break;
        default:
          break;
      }

      // test max days in month
      const maxDayInMonth = numOfdaysInMonth(stringifyDateToISO8601({ ...newDate, day: 1 }));
      if (maxDayInMonth < newDate.day) newDate.day = maxDayInMonth;

      // test max date
      if (isDateGreaterThanCurrent(newDate)) newDate = { ...dateToday };

      setNewDate(newDate);
    },
    [inputValue, setNewDate, dateValues]
  );

  const decrement = useCallback(
    value => () => {
      let newDate = { ...dateValues };

      switch (value) {
        case 'year':
          newDate.year--;
          break;
        case 'month':
          if (dateValues.month === 0) newDate.month = 11;
          else newDate.month--;
          break;
        case 'day':
          if (dateValues.day === 1) newDate.day = numOfdaysInMonth(inputValue);
          else newDate.day--;
          break;
        default:
          break;
      }

      // test max days in month
      const maxDayInMonth = numOfdaysInMonth(stringifyDateToISO8601({ ...newDate, day: 1 }));
      if (maxDayInMonth < newDate.day) newDate.day = maxDayInMonth;

      //test max date
      if (isDateGreaterThanCurrent(newDate)) newDate = { ...dateToday };

      setNewDate(newDate);
    },
    [inputValue, setNewDate, dateValues]
  );

  return (
    <OutsideClickHandler className={classes} onOutsideClick={() => setSelectOpen(false)}>
      {label && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}

      <button
        type="button"
        className={inputContainerClasses}
        id={id}
        onClick={() => {
          setSelectOpen(isOpen => !isOpen);

          onFocus();
        }}
      >
        {inputValue ? formattedDate : placeholder}
      </button>

      <div
        className={classNames(css.optionsContainer, { [css.optionsShown]: selectOpen })}
        onBlur={() => onBlur()}
      >
        <div className={css.options}>
          <div className={css.row}>
            <button
              onClick={decrement('year')}
              className={css.arrowButton}
              type="button"
              disabled={disabledButton.year.min}
            >
              <IconArrowHead size="small" direction="left" />
            </button>
            <span>{dateValues.year}</span>
            <button
              onClick={increment('year')}
              className={css.arrowButton}
              type="button"
              disabled={disabledButton.year.max}
            >
              <IconArrowHead size="small" direction="right" />
            </button>
          </div>
          <div className={css.row}>
            <button onClick={decrement('month')} className={css.arrowButton} type="button">
              <IconArrowHead size="small" direction="left" />
            </button>
            {months[dateValues.month].localizedLong}
            <button
              onClick={increment('month')}
              className={css.arrowButton}
              type="button"
              disabled={disabledButton.month.max}
            >
              <IconArrowHead size="small" direction="right" />
            </button>
          </div>
          <div className={css.row}>
            <button onClick={decrement('day')} className={css.arrowButton} type="button">
              <IconArrowHead size="small" direction="left" />
            </button>
            <span>{dateValues.day}</span>
            <button
              onClick={increment('day')}
              className={css.arrowButton}
              type="button"
              disabled={disabledButton.day.max}
            >
              <IconArrowHead size="small" direction="right" />
            </button>
          </div>
        </div>
      </div>

      <ValidationError fieldMeta={fieldMeta} />
    </OutsideClickHandler>
  );
};

DateSelectComponent.propTypes = {
  className: string,
  inputContainerClassName: string,
  labelClassName: string,
  optionsClassName: string,

  id: string.isRequired,
  label: string,
  placeholder: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
};

const DateSelect = props => <Field component={DateSelectComponent} {...props} />;

export default DateSelect;
