import httpClient from '../httpClient';

const uploadImages = (file, options) => {
  const formData = new FormData();

  formData.append('files', file, file.name);

  if (options) {
    formData.append('options', JSON.stringify(options));
  }

  return httpClient.post('images/upload', formData);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadImages,
};
