export function getCookie(cname) {
  if (typeof document === 'undefined') return null;
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function deleteCookie(cname) {
  if (typeof document === 'undefined') return;
  document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function setCookie(cname, cvalue, exdays = 365 * 2) {
  if (typeof document === 'undefined') return;

  const expires = exdays
    ? `expires=${new Date(Date.now() + exdays * 24 * 60 * 60 * 1000).toUTCString()};`
    : '';
  document.cookie = cname + '=' + cvalue + ';' + expires + 'path=/';
}
