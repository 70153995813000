import React from 'react';
import { convertDateToMoment } from '../../util/dates';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { LINE_ITEM_NIGHT, LINE_ITEM_UNITS, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const BookingPeriod = props => {
  const { startDate, endDate, timeZone } = props;

  const dateFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  return (
    <div className={css.bookingPeriod}>
      <div className={css.itemLabel}>
        <FormattedMessage id="General.date" />
      </div>
      <div className={css.bookingPeriodSectionRight}>
        <div className={css.itemValue}>
          <FormattedMessage id="General.from" />
          <span className={css.dayInfo}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        </div>

        <div className={css.itemValue}>
          <FormattedMessage id="General.to" />
          <span className={css.dayInfo}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        </div>
      </div>
    </div>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, timeZone } = props;

  if (!booking) {
    return null;
  }
  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isUnit = unitType === LINE_ITEM_UNITS;
  const endDay =
    isUnit || isNightly
      ? localEndDateRaw
      : convertDateToMoment(localEndDateRaw).subtract(1, 'days');

  return (
    <div className={css.lineItem}>
      <BookingPeriod startDate={localStartDate} endDate={endDay} timeZone={timeZone} />
    </div>
  );
};

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
};

export default LineItemBookingPeriod;
