import React, { useMemo } from 'react';
import { InlineTextButton } from '../Button/Button';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import * as log from '../../util/log';
import { bool, func, node, object, oneOfType, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { createListingTitle } from '../../util/listingHelpers';
import { ensureListing } from '../../util/data';
import {
  transitions,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  txRoleIsCustomer,
  txRoleIsProvider,
} from '../../transactions/transactionProcessBooking';
import { useConfiguration } from '../../context/configurationContext';

const TransitionMessage = props => {
  const {
    transaction,
    transition,
    ownRole,
    displayName,
    onOpenReviewModal,
    hasActions,
    intl,
  } = props;
  const config = useConfiguration();
  const { by: transitionedBy, transition: transitionName } = transition;
  const isOwnTransition = transitionedBy === ownRole;

  const currentListing = useMemo(() => ensureListing(transaction.listing), [transaction.listing]);

  const { deleted, publicData } = useMemo(() => currentListing.attributes, [
    currentListing.attributes,
  ]);

  const { discipline, ability, type, duration, groupType } = useMemo(() => publicData || {}, [
    publicData,
  ]);

  const deletedListing = useMemo(() => intl.formatMessage({ id: 'ActivityFeed.deletedListing' }), [
    intl,
  ]);

  const listingTitle = useMemo(
    () =>
      deleted
        ? deletedListing
        : createListingTitle(intl, { discipline, ability, type, duration, groupType }),
    [deleted, deletedListing, intl, discipline, ability, type, duration, groupType]
  );

  const messages = useMemo(
    () => ({
      ownTransitionRequest: intl.formatMessage(
        { id: 'ActivityFeed.ownTransitionRequest' },
        { displayName }
      ),
      transitionRequest: intl.formatMessage(
        { id: 'ActivityFeed.transitionRequest' },
        { displayName, listingTitle }
      ),
      transitionCancelPendingPayment: intl.formatMessage({
        id: 'ActivityFeed.transitionCancelPendingPayment',
      }),
      transitionCancelPreauhorized: intl.formatMessage({
        id: 'ActivityFeed.transitionCancelPreauhorized',
      }),
      ownTransitionCancel: intl.formatMessage({ id: 'ActivityFeed.ownTransitionCancel' }),
      transitionCancel: intl.formatMessage(
        { id: 'ActivityFeed.transitionCancel' },
        { displayName }
      ),
      ownTransitionAccept: intl.formatMessage({ id: 'ActivityFeed.ownTransitionAccept' }),
      transitionAccept: intl.formatMessage(
        { id: 'ActivityFeed.transitionAccept' },
        { displayName }
      ),
      ownTransitionDecline: intl.formatMessage({ id: 'ActivityFeed.ownTransitionDecline' }),
      transitionDecline: intl.formatMessage(
        { id: 'ActivityFeed.transitionDecline' },
        { displayName }
      ),
      ownTransitionExpire: intl.formatMessage({ id: 'ActivityFeed.ownTransitionExpire' }),
      ownTransitionComplete: intl.formatMessage(
        { id: 'ActivityFeed.ownTransitionComplete' },
        { marketplaceName: config.marketplaceName }
      ),
      transitionComplete: intl.formatMessage({ id: 'ActivityFeed.transitionComplete' }),
      transitionExpire: intl.formatMessage(
        { id: 'ActivityFeed.transitionExpire' },
        { displayName }
      ),
      transitionAutoRefund: intl.formatMessage({ id: 'ActivityFeed.transitionAutoRefund' }),
      transitionFullPayout: intl.formatMessage({ id: 'ActivityFeed.transitionFullPayout' }),
      ownTransitionReview: intl.formatMessage(
        { id: 'ActivityFeed.ownTransitionReview' },
        { displayName }
      ),
      leaveAReview: intl.formatMessage({ id: 'ActivityFeed.leaveAReview' }, { displayName }),
      leaveAReviewSecond: intl.formatMessage(
        { id: 'ActivityFeed.leaveAReviewSecond' },
        { displayName }
      ),
      transitionReview: intl.formatMessage({ id: 'ActivityFeed.transitionReview' }),
    }),
    [config.marketplaceName, displayName, intl, listingTitle]
  );

  const messageToReturn = useMemo(() => {
    switch (transitionName) {
      case transitions.ENQUIRE:
        return isOwnTransition ? (
          <FormattedMessage id="ActivityFeed.ownTransitionEnquire" values={{ displayName }} />
        ) : (
          <FormattedMessage id="ActivityFeed.transitionEnquire" values={{ displayName }} />
        );

      case transitions.CONFIRM_PAYMENT:
      case transitions.CONFIRM_PAYMENT_FREE:
        return isOwnTransition ? messages.ownTransitionRequest : messages.transitionRequest;

      case transitions.CANCEL_PENDING_PAYMENT:
        return messages.transitionCancelPendingPayment;

      case transitions.CANCEL_PREAUTHORIZED:
      case transitions.CANCEL_ACCEPTED:
        return messages.transitionCancelPreauhorized;

      case transitions.CANCEL_PREAUTHORIZED_BY_CUSTOMER:
      case transitions.CANCEL_ACCEPTED_BY_CUSTOMER:
        return txRoleIsProvider(ownRole) ? messages.transitionCancel : messages.ownTransitionCancel;

      case transitions.CANCEL_ACCEPTED_BY_PROVIDER:
        return txRoleIsProvider(ownRole) ? messages.ownTransitionCancel : messages.transitionCancel;

      case transitions.AUTO_REFUND:
        return messages.transitionAutoRefund;

      case transitions.FULL_PAYOUT:
        return messages.transitionFullPayout;

      case transitions.ACCEPT:
      case transitions.ACCEPT_FREE:
        return isOwnTransition ? messages.ownTransitionAccept : messages.transitionAccept;

      case transitions.DECLINE:
        return isOwnTransition ? messages.ownTransitionDecline : messages.transitionDecline;

      case transitions.EXPIRE:
        return txRoleIsProvider(ownRole) ? messages.ownTransitionExpire : messages.transitionExpire;

      case transitions.COMPLETE:
        return txRoleIsProvider(ownRole)
          ? messages.ownTransitionComplete
          : messages.transitionComplete;

      case transitions.DELIVER_MANUAL:
      case transitions.DELIVER_FREE:
      case transitions.PAYOUT_AUTO:
      case transitions.FULL_REFUND_AUTO:
        // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
        const reviewPeriodJustStarted = txIsDelivered(transaction);
        const reviewAsFirstLink = hasActions ? (
          <InlineTextButton onClick={onOpenReviewModal}>{messages.leaveAReview}</InlineTextButton>
        ) : (
          messages.leaveAReview
        );
        return (
          <FormattedMessage
            id="ActivityFeed.transitionComplete"
            values={{ reviewLink: reviewPeriodJustStarted && reviewAsFirstLink }}
          />
        );

      case transitions.REVIEW_1_BY_PROVIDER:
      case transitions.REVIEW_1_BY_CUSTOMER:
        if (isOwnTransition) return messages.ownTransitionReview;
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const isCustomer = txRoleIsCustomer(ownRole);
        const userHasLeftAReview = txIsInFirstReviewBy(transaction, isCustomer);

        const reviewAsSecondLink = hasActions ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            {messages.leaveAReviewSecond}
          </InlineTextButton>
        ) : (
          messages.leaveAReviewSecond
        );

        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{
              displayName,
              reviewLink: !(reviewPeriodIsOver || userHasLeftAReview) && reviewAsSecondLink,
            }}
          />
        );
      case transitions.REVIEW_2_BY_PROVIDER:
      case transitions.REVIEW_2_BY_CUSTOMER:
        if (isOwnTransition) return messages.ownTransitionReview;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );

      default:
        log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
          transitionType: transition,
        });
        return '';
    }
  }, [
    displayName,
    isOwnTransition,
    messages,
    onOpenReviewModal,
    ownRole,
    transaction,
    transition,
    transitionName,
    hasActions,
  ]);

  return messageToReturn;
};

TransitionMessage.defaultProps = {
  hasActions: true,
};

TransitionMessage.propTypes = {
  hasActions: bool,
  transaction: propTypes.transaction.isRequired,
  transition: object.isRequired,
  ownRole: string.isRequired,
  onOpenReviewModal: func,
  displayName: oneOfType([string, node]).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(TransitionMessage);
