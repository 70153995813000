import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BigCalendar } from '..';
import { fetchBookings, fetchSessions } from '../../ducks/Bookings.duck';
import { propTypes } from '../../util/types';
import {
  addRemoveDaysFromDate,
  convertDateToMoment,
  getMonthStartInTimeZone,
  isSameDay,
  nextMonthFn,
  resetToStartOfDay,
  stringifyDateToISO8601,
} from '../../util/dates';
import { createEventFromBooking } from '../../util/sessions';

const TODAY = new Date();
const START_OF_MONTH = getMonthStartInTimeZone(TODAY);
const END_OF_MONTH = nextMonthFn(START_OF_MONTH);

// When we get to the page, the first view is the month view
// so we want to fetch this range [startOfMonth - 7 days, endOfMonth + 7 days].
// +- 7 days, because we see days from other months
const INITIAL_START_PARAM = addRemoveDaysFromDate(START_OF_MONTH, null, 7, true);
const INITIAL_END_PARAM = addRemoveDaysFromDate(END_OF_MONTH, null, 7);

const parseSessionsToAvailableDays = rawSessions => {
  const parsedSessions = rawSessions.reduce((sessions, session) => {
    const { startsAt, endsAt, endTime, timezone, isOvernight } = session;

    const endDate = convertDateToMoment(endsAt, timezone);

    const date = convertDateToMoment(startsAt, timezone);

    while (date.isSameOrBefore(endDate, 'day')) {
      const dateString = stringifyDateToISO8601(date, timezone);

      if (!sessions[dateString]) {
        sessions[dateString] = {};
      }

      const clonedDate = date.clone();

      const isDateDifferentFromStart = !isSameDay(
        convertDateToMoment(startsAt, timezone),
        clonedDate,
        timezone
      );

      // If its overnight and we are after the startDate,
      // we should set the start hours to 00:00 because
      // overnight will take the full hours from start to end
      if (isOvernight && isDateDifferentFromStart) {
        clonedDate.startOf('day');
      }

      while (
        clonedDate.format('HH:mm') <
          (isOvernight && !isSameDay(clonedDate, endDate) ? '24:00' : endTime) &&
        isSameDay(clonedDate, date, timezone)
      ) {
        const timeString = clonedDate.format('HH:mm');

        sessions[dateString][timeString] = true;

        clonedDate.add(30, 'minute');
      }

      date.add(1, 'day');
    }

    return sessions;
  }, {});

  return parsedSessions;
};

const BookingsCalendar = props => {
  const { listingId, className } = props;

  const dispatch = useDispatch();
  const {
    bookings,
    fetchBookingsInProgress,
    fetchBookingsError,

    sessions,
    fetchSessionsInProgress,
    fetchSessionsError,
  } = useSelector(({ Bookings }) => Bookings);

  const calendarEvents = useMemo(() => bookings?.map(createEventFromBooking), [bookings]);

  const availableDays = useMemo(() => parseSessionsToAvailableDays(sessions), [sessions]);

  const fetchEventsInProgress = useMemo(() => fetchBookingsInProgress || fetchSessionsInProgress, [
    fetchBookingsInProgress,
    fetchSessionsInProgress,
  ]);
  const fetchEventsError = useMemo(() => fetchBookingsError || fetchSessionsError, [
    fetchBookingsError,
    fetchSessionsError,
  ]);

  const handleRangeChange = useCallback(
    datesRange => {
      // Depending on the view, the calendar provides either an array or an object
      const { start, end } = Array.isArray(datesRange)
        ? { start: datesRange[0], end: datesRange[datesRange.length - 1] }
        : datesRange;

      const dayAfterEnd = resetToStartOfDay(end, null, 1);

      dispatch(fetchSessions(listingId, start, dayAfterEnd));
      dispatch(fetchBookings(listingId, start, dayAfterEnd));
    },
    [dispatch, listingId]
  );

  useEffect(() => {
    dispatch(fetchSessions(listingId, INITIAL_START_PARAM, INITIAL_END_PARAM));
    dispatch(fetchBookings(listingId, INITIAL_START_PARAM, INITIAL_END_PARAM));
  }, [dispatch, listingId]);

  return (
    <BigCalendar
      className={className}
      events={calendarEvents}
      onRangeChange={handleRangeChange}
      fetchEventsInProgress={fetchEventsInProgress}
      fetchEventsError={fetchEventsError}
      availableDays={availableDays}
    />
  );
};

BookingsCalendar.propTypes = {
  listingId: propTypes.uuid,
};

export default BookingsCalendar;
