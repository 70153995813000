import React, { useMemo } from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import { dateIsAfter, stringifyDateToISO8601 } from '../../util/dates';

import css from './CalendarDateCellWrapper.module.css';

const TODAY = new Date();

const CalendarDateCellWrapper = props => {
  const { label, date, datesWithEvents = {}, availableDays = {} } = props;

  const isDateBeforeToday = useMemo(() => dateIsAfter(TODAY, [date, 'day'], false), [date]);

  const dateString = useMemo(() => stringifyDateToISO8601(date), [date]);

  const hasBooking = useMemo(() => datesWithEvents[dateString], [dateString, datesWithEvents]);

  const hasSession = useMemo(() => availableDays[dateString], [availableDays, dateString]);

  return (
    <div
      className={classNames(css.cellWrapper, {
        [css.hasBooking]: hasBooking,
        [css.hasSession]: hasSession && !hasBooking,
        [css.disabled]: isDateBeforeToday,
      })}
    >
      {label}
    </div>
  );
};

CalendarDateCellWrapper.propTypes = {
  label: string.isRequired,
  datesWithEvents: object.isRequired,
};

export default CalendarDateCellWrapper;
