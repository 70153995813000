import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { InlineTextButton, ModalWithoutIntl, SecondaryButton } from '../../components';

import css from './Confirm.module.css';

const ConfirmModal = ({
  id = 'ConfirmModal',
  title,
  messages = [],
  okText = 'OK',
  cancelText = 'Cancel',
  onConfirm,
  type,
  onClose,
  showCloseButton = true,
  titleClassName,
  isWarning,
  isConfirmation,
  confirmInProgress,
  confirmError,
  showSecondaryActionButton = false,
  secondaryActionText,
  onSecondaryAction,
  ...restOfProps
}) => {
  return (
    <ModalWithoutIntl id={id} isOpen usePortal onClose={onClose} {...restOfProps}>
      {title && <h2 className={classNames(css.title, titleClassName)}>{title}</h2>}
      {messages && (
        <div className={css.messages}>
          {messages.map((message, i) => (
            <p key={`${id}.message${i}`} className={css.modalMessage}>
              {message}
            </p>
          ))}
        </div>
      )}
      {confirmError && <h5 className={css.error}>{confirmError}</h5>}
      <div className={css.buttons}>
        {showSecondaryActionButton && (
          <InlineTextButton
            onClick={() => {
              onSecondaryAction();
              onClose();
            }}
            className={css.button}
          >
            {secondaryActionText}
          </InlineTextButton>
        )}
        {showCloseButton && (
          <InlineTextButton onClick={onClose} className={css.button}>
            {cancelText}
          </InlineTextButton>
        )}
        <SecondaryButton
          color={isWarning ? 'red' : isConfirmation ? 'green' : 'black'}
          onClick={onConfirm}
          inProgress={confirmInProgress}
          disabled={confirmInProgress}
        >
          {okText}
        </SecondaryButton>
      </div>
    </ModalWithoutIntl>
  );
};

const confirm = async config => {
  const div = document.createElement('div');
  const portalRoot = document.getElementById('portal-root');
  portalRoot.appendChild(div);

  const { onConfirm, onClose, isAsyncConfirm, ...rest } = config;

  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(div);
    portalRoot.removeChild(div);
  };

  return new Promise(resolve => {
    ReactDOM.render(
      <ConfirmModal
        onClose={e => {
          onClose?.(e);
          cleanup();
          resolve(false);
        }}
        onConfirm={() => {
          if (isAsyncConfirm)
            return onConfirm().then(() => {
              cleanup();
              resolve(true);
            });

          onConfirm?.();
          cleanup();
          resolve(true);
        }}
        {...rest}
      />,
      div
    );
  });
};

export default confirm;
