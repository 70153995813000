import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';

import css from './SectionExperience.module.css';
import { useConfiguration } from '../../context/configurationContext';

const SectionExperience = props => {
  const { rootClassName, className } = props;

  const config = useConfiguration();

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.content}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionExperience.title" />
        </h2>

        <div className={css.card}>
          <p className={css.cardText}>
            <FormattedMessage id="SectionExperience.cardContent" />
          </p>
          <ExternalLink href={config.tailoredActivitiesFormLink} className={css.cta}>
            <FormattedMessage id="ContactPage.title" />
          </ExternalLink>
        </div>
      </div>
    </div>
  );
};

SectionExperience.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionExperience.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionExperience;
