import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { string } from 'prop-types';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { Field } from 'react-final-form';
import { useConfiguration } from '../../context/configurationContext';
import DateRangeController from './DateRangeController';
import {
  convertDateToMoment,
  dateIsAfter,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
} from '../../util/dates';
import IconArrowHead from '../IconArrowHead/IconArrowHead';
import { dayCountAvailableForBooking } from '../../config/configStripe';

// MAX_TIME_SLOTS_RANGE is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const MAX_TIME_SLOTS_RANGE = dayCountAvailableForBooking;

const TODAY = new Date();

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const Next = props => {
  const { currentMonth, timeZone } = props;

  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : (
    <IconArrowHead direction="right" />
  );
};
const Prev = props => {
  const { currentMonth, timeZone } = props;

  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <IconArrowHead direction="left" /> : null;
};

const DateRangeControllerComponent = props => {
  const { input, controllerRef, timeZone, onComponentMount, onComponentUnmount, ...rest } = props;
  const { type, checked, ...restOfInput } = input;

  const inputStartMonth = useMemo(
    () =>
      input?.value?.startDate ? getMonthStartInTimeZone(input.value.startDate, timeZone) : null,
    [input?.value?.startDate, timeZone]
  );

  const [currentMonth, setCurrentMonth] = useState(
    inputStartMonth || getMonthStartInTimeZone(TODAY, timeZone)
  );

  const onMonthClick = useCallback(
    monthFn => {
      setCurrentMonth(oldMonth => monthFn(oldMonth, timeZone));
    },
    [timeZone]
  );

  useEffect(() => {
    onComponentMount?.();
    return () => {
      onComponentUnmount?.();
    };
  }, [onComponentMount, onComponentUnmount]);

  return (
    <DateRangeController
      ref={controllerRef}
      onPrevMonthClick={() => onMonthClick(prevMonthFn)}
      onNextMonthClick={() => onMonthClick(nextMonthFn)}
      navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
      navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
      timeZone={timeZone}
      {...restOfInput}
      {...rest}
    />
  );
};

const FieldDateRangeController = props => {
  const config = useConfiguration();
  const { isOutsideRange, firstDayOfWeek, ...rest } = props;

  // Outside range -><- today ... today+available days -1 -><- outside range
  const defaultIsOutSideRange = day => {
    const endOfRange = config.stripe?.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, convertDateToMoment()) ||
      !isInclusivelyBeforeDay(day, convertDateToMoment().add(endOfRange, 'days'))
    );
  };
  const defaultFirstDayOfWeek = config.localization.firstDayOfWeek;

  return (
    <Field
      component={DateRangeControllerComponent}
      isOutsideRange={isOutsideRange || defaultIsOutSideRange}
      firstDayOfWeek={firstDayOfWeek || defaultFirstDayOfWeek}
      {...rest}
    />
  );
};

FieldDateRangeController.defaultProps = {
  rootClassName: null,
  className: null,
};

FieldDateRangeController.propTypes = {
  rootClassName: string,
  className: string,
};

export default FieldDateRangeController;
