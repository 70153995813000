import React from 'react';
import classNames from 'classnames';
import { ensureListing } from '../../../util/data';
import { OrderBreakdown } from '../../../components';
import { LINE_ITEM_UNITS } from '../../../util/types';
import BookingDetails from '../../../components/OrderBreakdown/BookingDetails';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    listing,
    savedListingPublicData,
    bookings,
  } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const { sessionsDiscount, seasonalCharge, additionalPersonCharge } =
    listingAttributes.publicData || {};

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  return loaded ? (
    <div className={classes}>
      <OrderBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={LINE_ITEM_UNITS}
        transaction={transaction}
        booking={transaction.booking}
        timeZone={timeZone}
        listing={listing}
        savedListingPublicData={savedListingPublicData}
        bookings={bookings}
        sessionsDiscount={savedListingPublicData?.sessionsDiscount || sessionsDiscount}
        seasonalCharge={savedListingPublicData?.seasonalCharge || seasonalCharge}
        additionalPersonCharge={
          savedListingPublicData?.additionalPersonCharge || additionalPersonCharge
        }
      />
    </div>
  ) : (
    <BookingDetails listing={listing} savedListingPublicData={savedListingPublicData} />
  );
};

export default BreakdownMaybe;
