import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconLanguages.module.css';

const IconLanguages = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(0 -389)">
        <circle cx="17.5" cy="17.5" r="17.5" transform="translate(0 389)" />
        <path d="M12.753 405.881c-.109-.378-.244-1.038-.244-1.038h-.015s-.134.659-.244 1.038l-.35 1.169h1.194ZM27.25 401.5H18.5v10h8.75a.748.748 0 0 0 .75-.75v-8.5a.748.748 0 0 0-.75-.75Zm-.75 3.75a.376.376 0 0 1-.375.375h-.356a5.6 5.6 0 0 1-1.334 2.184 9.688 9.688 0 0 0 .816.563.376.376 0 0 1 .128.506l-.247.434a.376.376 0 0 1-.522.134 10.873 10.873 0 0 1-1.106-.778 11.021 11.021 0 0 1-1.104.782.376.376 0 0 1-.522-.134l-.247-.434a.375.375 0 0 1 .131-.506 8.915 8.915 0 0 0 .816-.562 7.251 7.251 0 0 1-.656-.8.374.374 0 0 1 .116-.534l.2-.122.228-.134a.378.378 0 0 1 .5.106 5.959 5.959 0 0 0 .544.653 4.78 4.78 0 0 0 .938-1.35h-3.573a.376.376 0 0 1-.375-.383v-.5a.376.376 0 0 1 .375-.375h2v-.5a.376.376 0 0 1 .375-.375h.5a.376.376 0 0 1 .375.375v.5h2a.376.376 0 0 1 .375.375Zm-18.5-3v8.5a.748.748 0 0 0 .75.75h8.75v-10H8.75a.748.748 0 0 0-.75.75Zm1.841 6.753 1.8-5.284a.374.374 0 0 1 .356-.253h1.012a.381.381 0 0 1 .356.253l1.8 5.284a.376.376 0 0 1-.356.5h-.718a.375.375 0 0 1-.359-.269l-.294-1h-1.882l-.284.994a.378.378 0 0 1-.359.272H10.2a.377.377 0 0 1-.356-.5Z" />
      </g>
    </svg>
  );
};

IconLanguages.defaultProps = {
  rootClassName: null,
  className: null,
};

IconLanguages.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLanguages;
