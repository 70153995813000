import React from 'react';
import { string } from 'prop-types';

const IconHorseRiding = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 256 256">
      <path d="M100,38.1c9.6,0,17.4-7.8,17.4-17.4c0-9.6-7.8-17.4-17.4-17.4c-9.6,0-17.4,7.8-17.4,17.4 C82.7,30.3,90.4,38.1,100,38.1z" />
      <path d="M229.3,88.2l-18.4-3.5L200.1,106c1.7,0.4,3.5,0.6,5.4,0.6C217,106.6,226.6,98.8,229.3,88.2z M2.8,124.2 c0-14.1,11.4-25.6,25.6-25.6h54V59.9c0-9.6,7.8-17.4,17.4-17.4s17.4,7.8,17.4,17.4v6l25.7,6.6c4.5,1,7.4,5.5,6.3,10 c-1,4.6-5.5,7.4-10.1,6.3l-22-5.2v15H143l70.8-73.2l-2.1,19l34.9,23.2c5.7,3.3,7.8,10.7,4.5,16.5c-2.6,4.6-7.7,6.8-12.6,5.9 l-2.8-0.5c-3.4,13.6-15.6,23.6-30.2,23.6c-2.9,0-5.7-0.4-8.3-1.1l-26.4,54v74c0,6.3-5.2,11.5-11.6,11.5c-6.3,0-11.5-5.2-11.5-11.5 v-75.7H89.5L79.1,182l21.8,52c3.2,5.6,1.2,12.6-4.3,15.7c-5.6,3.2-12.6,1.2-15.8-4.3l-25.5-60v-19.1L44,184.2v55.7 c0,6.3-5.1,11.5-11.5,11.5c-6.3,0-11.6-5.2-11.6-11.5v-64.3l9.5-14.9v-50.5L21,124.7v42H2.8V124.2z" />
    </svg>
  );
};

IconHorseRiding.defaultProps = { className: null };
IconHorseRiding.propTypes = { className: string };

export default IconHorseRiding;
