import httpClient from '../httpClient';

const fetchListingSessions = (listingId, params) => {
  const reqUrl = listingId ? `by-listing/${listingId}` : 'by-user';

  return httpClient.get(`listing-sessions/${reqUrl}`, { params });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { fetchListingSessions };
