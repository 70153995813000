import React, { useCallback, useMemo } from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldSelectableItem.module.css';

const FieldSelectableItem = props => {
  const { className, inputClassName, labelClassName, id, label, onChange, ...rest } = props;

  const classes = useMemo(() => classNames(css.root, className), [className]);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = useCallback(
    (input, event) => {
      const { name, value, onBlur, onChange: inputOnChange } = input;
      inputOnChange(event);
      onChange?.({ name, value, checked: event.target.checked });

      onBlur(event);
    },
    [onChange]
  );

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          return (
            <input
              id={id}
              className={classNames(css.input, inputClassName)}
              {...input}
              onChange={event => handleOnChange(input, event)}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={classNames(css.label, labelClassName)}>
        {label}
      </label>
    </span>
  );
};

FieldSelectableItem.defaultProps = {
  className: null,
  inputClassName: null,
  labelClassName: null,
  label: null,
};

FieldSelectableItem.propTypes = {
  className: string,
  inputClassName: string,
  labelClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldSelectableItem;
