import React, { memo, useMemo } from 'react';
import { dateIsBefore, isValidDate } from '../../util/dates';
import IconPercent from '../IconPercent/IconPercent';
import IconSuccess from '../IconSuccess/IconSuccess';
import { injectIntl } from '../../util/reactIntl';
import moment from 'moment';
import classNames from 'classnames';

import css from './PromotionCard.module.css';

const PromotionCard = memo(props => {
  const { rootClassName, className, promotion, intl } = props;

  const { code, isVerified, validUntil } = promotion;

  const hasExpired = useMemo(
    () =>
      isValidDate(validUntil, 'DD-MM-YYYY') &&
      dateIsBefore(moment(validUntil, 'DD-MM-YYYY'), moment(), true),
    [validUntil]
  );

  const messages = useMemo(
    () => ({
      expired: intl.formatMessage({ id: 'PromotionCard.expired' }, { code }),
      valid: intl.formatMessage({ id: 'PromotionCard.valid' }, { code }),
      validUntil: intl.formatMessage({ id: 'PromotionCard.validUntil' }, { code, validUntil }),
    }),
    [code, intl, validUntil]
  );

  const classes = useMemo(
    () => classNames(rootClassName || css.root, { [css.expired]: hasExpired }, className),
    [className, hasExpired, rootClassName]
  );

  return (
    <div className={classes}>
      <IconPercent className={css.percentIcon} />

      <span className={css.promotion}>
        {!isVerified
          ? code
          : !hasExpired
          ? validUntil && isValidDate(validUntil, 'DD-MM-YYYY')
            ? messages.validUntil
            : messages.valid
          : messages.expired}
      </span>

      <IconSuccess tagFill={isVerified ? 'default' : 'notverified'} className={css.verifiedIcon} />
    </div>
  );
});

export default injectIntl(PromotionCard);
