import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { IconClose, NamedLink, Button } from '../../components';

import css from './CloseButton.module.css';

const CloseButtonComponent = props => {
  const {
    rootClassName,
    className,
    textClassName,
    iconClassName,
    text,
    linkTo,
    intl,
    ...rest
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);

  const textClasses = classNames(css.closeText, textClassName);
  const iconClasses = classNames(css.closeIcon, iconClassName);

  const textFormat = text || intl.formatMessage({ id: 'General.close' });

  return (
    <>
      {linkTo ? (
        <NamedLink {...rest} name={linkTo} className={classes}>
          <span className={textClasses}>{textFormat}</span>
          <IconClose rootClassName={iconClasses} />
        </NamedLink>
      ) : (
        <Button {...rest} rootClassName={classes}>
          <span className={textClasses}>{textFormat}</span>
          <IconClose rootClassName={css.closeIcon} />
        </Button>
      )}
    </>
  );
};

CloseButtonComponent.defaultProps = {
  rootClassName: null,
  className: null,
  textClassName: null,
  iconClassName: null,
  text: null,
  linkTo: null,
};

CloseButtonComponent.propTypes = {
  rootClassName: string,
  className: string,
  textClassName: string,
  iconClassName: string,
  text: string,
  linkTo: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CloseButton = compose(injectIntl)(CloseButtonComponent);

export default CloseButton;
