import React from 'react';
import { string } from 'prop-types';

const IconRunning = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 -960 960 960">
      <path d="M520-40v-240l-84-80-40 176-276-56 16-80 192 40 64-324-72 28v136h-80v-188l158-68q35-15 51.5-19.5T480-720q21 0 39 11t29 29l40 64q26 42 70.5 69T760-520v80q-66 0-123.5-27.5T540-540l-24 120 84 80v300h-80Zm20-700q-33 0-56.5-23.5T460-820q0-33 23.5-56.5T540-900q33 0 56.5 23.5T620-820q0 33-23.5 56.5T540-740Z" />
    </svg>
  );
};

IconRunning.defaultProps = { className: null };
IconRunning.propTypes = { className: string };

export default IconRunning;
