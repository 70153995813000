import { PARTNER_KEYS } from '../../../util/partner';

const SUGGESTED_LOCATIONS = [
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Grisons, Switzerland',
      url:
        '/en-US/s?address=Grisons%2C%20Switzerland&bounds=47.065118%2C10.492061%2C46.16917%2C8.651067&origin=46.854747%2C9.5264904',
      country: 'Switzerland',
    },
    'fr-FR': {
      name: 'canton des Grisons, Suisse',
      country: 'Switzerland',
      url:
        '/fr-FR/s?address=canton%20des%20Grisons%2C%20Suisse&bounds=47.065118%2C10.492061%2C46.16917%2C8.651067&origin=46.854747%2C9.5264904',
    },
    'it-IT': {
      name: 'Grigioni, Svizzera',
      country: 'Switzerland',
      url:
        '/it-IT/s?address=Grigioni%2C%20Svizzera&bounds=47.065118%2C10.492061%2C46.16917%2C8.651067&origin=46.854747%2C9.5264904',
    },
    'de-DE': {
      name: 'Kanton Graubünden, Schweiz',
      country: 'Switzerland',
      url:
        '/de-DE/s?address=Kanton%20Graubünden%2C%20Schweiz&bounds=47.065118%2C10.492061%2C46.16917%2C8.651067&origin=46.854747%2C9.5264904',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Aosta Valley, Italy',
      url:
        '/en-US/s?address=Aosta%20Valley%2C%20Italy&bounds=45.987697%2C7.939505%2C45.466853%2C6.801653&origin=45.7370885%2C7.3196649',
      country: 'Italy',
    },
    'fr-FR': {
      name: "Vallée d'Aoste, Italie",
      country: 'Italy',
      url:
        '/fr-FR/s?address=Vallée%20d%27Aoste%2C%20Italie&bounds=45.987697%2C7.939505%2C45.466853%2C6.801653&origin=45.7370885%2C7.3196649',
    },
    'it-IT': {
      name: "Valle d'Aosta, Italia",
      country: 'Italy',
      url:
        '/it-IT/s?address=Valle%20d%27Aosta%2C%20Italia&bounds=45.987697%2C7.939505%2C45.466853%2C6.801653&origin=45.7370885%2C7.3196649',
    },
    'de-DE': {
      name: 'Aostatal, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Aostatal%2C%20Italien&bounds=45.987697%2C7.939505%2C45.466853%2C6.801653&origin=45.7370885%2C7.3196649',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Valais, Switzerland',
      url:
        '/en-US/s?address=Valais%2C%20Switzerland&bounds=46.654027%2C8.478564%2C45.858185%2C6.770579&origin=46.2311749%2C7.3588795',
      country: 'Switzerland',
    },
    'fr-FR': {
      name: 'canton du Valais, Suisse',
      country: 'Switzerland',
      url:
        '/fr-FR/s?address=canton%20du%20Valais%2C%20Suisse&bounds=46.654027%2C8.478564%2C45.858185%2C6.770579&origin=46.2311749%2C7.3588795',
    },
    'it-IT': {
      name: 'Vallese, Svizzera',
      country: 'Switzerland',
      url:
        '/it-IT/s?address=Vallese%2C%20Svizzera&bounds=46.654027%2C8.478564%2C45.858185%2C6.770579&origin=46.2311749%2C7.3588795',
    },
    'de-DE': {
      name: 'Wallis, Schweiz',
      country: 'Switzerland',
      url:
        '/de-DE/s?address=Wallis%2C%20Schweiz&bounds=46.654027%2C8.478564%2C45.858185%2C6.770579&origin=46.2311749%2C7.3588795',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Sondrio, Italy',
      url:
        '/en-US/s?address=Sondrio%2C%20Italy&bounds=46.635351%2C10.63227%2C46.011133%2C9.247002&origin=46.1713553%2C9.8734274',
      country: 'Italy',
    },
    'fr-FR': {
      name: 'Sondrio, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=Sondrio%2C%20Italie&bounds=46.635351%2C10.63227%2C46.011133%2C9.247002&origin=46.1713553%2C9.8734274',
    },
    'it-IT': {
      name: 'provincia di Sondrio, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=provincia%20di%20Sondrio%2C%20Italia&bounds=46.635351%2C10.63227%2C46.011133%2C9.247002&origin=46.1713553%2C9.8734274',
    },
    'de-DE': {
      name: 'Provinz Sondrio, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Provinz%20Sondrio%2C%20Italien&bounds=46.635351%2C10.63227%2C46.011133%2C9.247002&origin=46.1713553%2C9.8734274',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Troms, Norway',
      url:
        '/en-US/s?address=Troms%2C%20Norway&bounds=70.3483176%2C22.894471%2C68.356014%2C15.592542&origin=69.35511%2C19.585912',
      country: 'Norway',
    },
    'fr-FR': {
      name: 'Troms, Norvège',
      country: 'Norway',
      url:
        '/fr-FR/s?address=Troms%2C%20Norvège&bounds=70.3483176%2C22.894471%2C68.356014%2C15.592542&origin=69.35511%2C19.585912',
    },
    'it-IT': {
      name: 'Troms, Norvegia',
      country: 'Norway',
      url:
        '/it-IT/s?address=Troms%2C%20Norvegia&bounds=70.3483176%2C22.894471%2C68.356014%2C15.592542&origin=69.35511%2C19.585912',
    },
    'de-DE': {
      name: 'Troms, Norwegen',
      country: 'Norway',
      url:
        '/de-DE/s?address=Troms%2C%20Norwegen&bounds=70.3483176%2C22.894471%2C68.356014%2C15.592542&origin=69.35511%2C19.585912',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Cuneo, Italy',
      url:
        '/en-US/s?address=Cuneo%2C%20Italy&bounds=44.856606%2C8.268725%2C44.060119%2C6.853499&origin=44.389633%2C7.5479007',
      country: 'Italy',
    },
    'fr-FR': {
      name: 'Coni, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=Coni%2C%20Italie&bounds=44.856606%2C8.268725%2C44.060119%2C6.853499&origin=44.389633%2C7.5479007',
    },
    'it-IT': {
      name: 'provincia di Cuneo, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=provincia%20di%20Cuneo%2C%20Italia&bounds=44.856606%2C8.268725%2C44.060119%2C6.853499&origin=44.389633%2C7.5479007',
    },
    'de-DE': {
      name: 'Provinz Cuneo, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Provinz%20Cuneo%2C%20Italien&bounds=44.856606%2C8.268725%2C44.060119%2C6.853499&origin=44.389633%2C7.5479007',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Haute-Savoie, France',
      url:
        '/en-US/s?address=Haute-Savoie%2C%20France&bounds=46.468133%2C7.044471%2C45.681768%2C5.805425&origin=45.8992348%2C6.1288847',
      country: 'France',
    },
    'fr-FR': {
      name: 'Haute-Savoie, France',
      country: 'France',
      url:
        '/fr-FR/s?address=Haute-Savoie%2C%20France&bounds=46.468133%2C7.044471%2C45.681768%2C5.805425&origin=45.8992348%2C6.1288847',
    },
    'it-IT': {
      name: 'Alta Savoia, Francia',
      country: 'France',
      url:
        '/it-IT/s?address=Alta%20Savoia%2C%20Francia&bounds=46.468133%2C7.044471%2C45.681768%2C5.805425&origin=45.8992348%2C6.1288847',
    },
    'de-DE': {
      name: 'Département Haute-Savoie, Frankreich',
      country: 'France',
      url:
        '/de-DE/s?address=Département%20Haute-Savoie%2C%20Frankreich&bounds=46.468133%2C7.044471%2C45.681768%2C5.805425&origin=45.8992348%2C6.1288847',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Tyrol, Austria',
      url:
        '/en-US/s?address=Tyrol%2C%20Austria&bounds=47.742958%2C12.965998%2C46.651456%2C10.097828&origin=47.2654296%2C11.3927685',
      country: 'Austria',
    },
    'fr-FR': {
      name: 'Tyrol, Autriche',
      country: 'Austria',
      url:
        '/fr-FR/s?address=Tyrol%2C%20Autriche&bounds=47.742958%2C12.965998%2C46.651456%2C10.097828&origin=47.2654296%2C11.3927685',
    },
    'it-IT': {
      name: 'Tirolo, Austria',
      country: 'Austria',
      url:
        '/it-IT/s?address=Tirolo%2C%20Austria&bounds=47.742958%2C12.965998%2C46.651456%2C10.097828&origin=47.2654296%2C11.3927685',
    },
    'de-DE': {
      name: 'Tirol, Österreich',
      country: 'Austria',
      url:
        '/de-DE/s?address=Tirol%2C%20Österreich&bounds=47.742958%2C12.965998%2C46.651456%2C10.097828&origin=47.2654296%2C11.3927685',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Bern, Switzerland',
      url:
        '/en-US/s?address=Bern%2C%20Switzerland&bounds=47.34532%2C8.455166%2C46.326528%2C6.8615&origin=46.9482713%2C7.4514512',
      country: 'Switzerland',
    },
    'fr-FR': {
      name: 'canton de Berne, Suisse',
      country: 'Switzerland',
      url:
        '/fr-FR/s?address=canton%20de%20Berne%2C%20Suisse&bounds=47.34532%2C8.455166%2C46.326528%2C6.8615&origin=46.9482713%2C7.4514512',
    },
    'it-IT': {
      name: 'Canton Berna, Svizzera',
      country: 'Switzerland',
      url:
        '/it-IT/s?address=Canton%20Berna%2C%20Svizzera&bounds=47.34532%2C8.455166%2C46.326528%2C6.8615&origin=46.9482713%2C7.4514512',
    },
    'de-DE': {
      name: 'Kanton Bern, Schweiz',
      country: 'Switzerland',
      url:
        '/de-DE/s?address=Kanton%20Bern%2C%20Schweiz&bounds=47.34532%2C8.455166%2C46.326528%2C6.8615&origin=46.9482713%2C7.4514512',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Ticino, Switzerland',
      url:
        '/en-US/s?address=Ticino%2C%20Switzerland&bounds=46.632484%2C9.159679%2C45.817986%2C8.382138&origin=46.1920538%2C9.0205888',
      country: 'Switzerland',
    },
    'fr-FR': {
      name: 'canton du Tessin, Suisse',
      country: 'Switzerland',
      url:
        '/fr-FR/s?address=canton%20du%20Tessin%2C%20Suisse&bounds=46.632484%2C9.159679%2C45.817986%2C8.382138&origin=46.1920538%2C9.0205888',
    },
    'it-IT': {
      name: 'Canton Ticino, Svizzera',
      country: 'Switzerland',
      url:
        '/it-IT/s?address=Canton%20Ticino%2C%20Svizzera&bounds=46.632484%2C9.159679%2C45.817986%2C8.382138&origin=46.1920538%2C9.0205888',
    },
    'de-DE': {
      name: 'Kanton Tessin, Schweiz',
      country: 'Switzerland',
      url:
        '/de-DE/s?address=Kanton%20Tessin%2C%20Schweiz&bounds=46.632484%2C9.159679%2C45.817986%2C8.382138&origin=46.1920538%2C9.0205888',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Brescia, Italy',
      url:
        '/en-US/s?address=Brescia%2C%20Italy&bounds=46.35479%2C10.84015%2C45.203939%2C9.836039&origin=45.5398022%2C10.2200214',
      country: 'Italy',
    },
    'fr-FR': {
      name: 'Brescia, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=Brescia%2C%20Italie&bounds=46.35479%2C10.84015%2C45.203939%2C9.836039&origin=45.5398022%2C10.2200214',
    },
    'it-IT': {
      name: 'provincia di Brescia, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=provincia%20di%20Brescia%2C%20Italia&bounds=46.35479%2C10.84015%2C45.203939%2C9.836039&origin=45.5398022%2C10.2200214',
    },
    'de-DE': {
      name: 'Provinz Brescia, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Provinz%20Brescia%2C%20Italien&bounds=46.35479%2C10.84015%2C45.203939%2C9.836039&origin=45.5398022%2C10.2200214',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Obwalden, Switzerland',
      url:
        '/en-US/s?address=Obwalden%2C%20Switzerland&bounds=46.980364%2C8.506856%2C46.753169%2C8.042264&origin=46.8956729%2C8.2461492',
      country: 'Switzerland',
    },
    'fr-FR': {
      name: "canton d'Obwald, Suisse",
      country: 'Switzerland',
      url:
        '/fr-FR/s?address=canton%20d%27Obwald%2C%20Suisse&bounds=46.980364%2C8.506856%2C46.753169%2C8.042264&origin=46.8956729%2C8.2461492',
    },
    'it-IT': {
      name: 'Obvaldo, Svizzera',
      country: 'Switzerland',
      url:
        '/it-IT/s?address=Obvaldo%2C%20Svizzera&bounds=46.980364%2C8.506856%2C46.753169%2C8.042264&origin=46.8956729%2C8.2461492',
    },
    'de-DE': {
      name: 'Kanton Obwalden, Schweiz',
      country: 'Switzerland',
      url:
        '/de-DE/s?address=Kanton%20Obwalden%2C%20Schweiz&bounds=46.980364%2C8.506856%2C46.753169%2C8.042264&origin=46.8956729%2C8.2461492',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Vestland, Norway',
      url:
        '/en-US/s?address=Vestland%2C%20Norway&bounds=62.2661477%2C8.322052%2C59.47542%2C4.4014523&origin=60.694458%2C6.106089',
      country: 'Norway',
    },
    'fr-FR': {
      name: 'Vestland, Norvège',
      country: 'Norway',
      url:
        '/fr-FR/s?address=Vestland%2C%20Norvège&bounds=62.2661477%2C8.322052%2C59.47542%2C4.4014523&origin=60.694458%2C6.106089',
    },
    'it-IT': {
      name: 'Vestland, Norvegia',
      country: 'Norway',
      url:
        '/it-IT/s?address=Vestland%2C%20Norvegia&bounds=62.2661477%2C8.322052%2C59.47542%2C4.4014523&origin=60.694458%2C6.106089',
    },
    'de-DE': {
      name: 'Vestland, Norwegen',
      country: 'Norway',
      url:
        '/de-DE/s?address=Vestland%2C%20Norwegen&bounds=62.2661477%2C8.322052%2C59.47542%2C4.4014523&origin=60.694458%2C6.106089',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Møre og Romsdal, Norway',
      url:
        '/en-US/s?address=Møre%20og%20Romsdal%2C%20Norway&bounds=63.5779482%2C9.364828%2C61.923344%2C5.1752821&origin=62.7377239%2C7.1590265',
      country: 'Norway',
    },
    'fr-FR': {
      name: 'Comté de Møre og Romsdal, Norvège',
      country: 'Norway',
      url:
        '/fr-FR/s?address=Comté%20de%20Møre%20og%20Romsdal%2C%20Norvège&bounds=63.5779482%2C9.364828%2C61.923344%2C5.1752821&origin=62.7377239%2C7.1590265',
    },
    'it-IT': {
      name: 'Comune della Contea di Møre og Romsdal, Norvegia',
      country: 'Norway',
      url:
        '/it-IT/s?address=Comune%20della%20Contea%20di%20Møre%20og%20Romsdal%2C%20Norvegia&bounds=63.5779482%2C9.364828%2C61.923344%2C5.1752821&origin=62.7377239%2C7.1590265',
    },
    'de-DE': {
      name: 'Provinz Møre og Romsdal, Norwegen',
      country: 'Norway',
      url:
        '/de-DE/s?address=Provinz%20Møre%20og%20Romsdal%2C%20Norwegen&bounds=63.5779482%2C9.364828%2C61.923344%2C5.1752821&origin=62.7377239%2C7.1590265',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Aargau, Switzerland',
      url:
        '/en-US/s?address=Aargau%2C%20Switzerland&bounds=47.621047%2C8.455111%2C47.137478%2C7.713561&origin=47.3927146%2C8.0444448',
      country: 'Switzerland',
    },
    'fr-FR': {
      name: "canton d'Argovie, Suisse",
      country: 'Switzerland',
      url:
        '/fr-FR/s?address=canton%20d%27Argovie%2C%20Suisse&bounds=47.621047%2C8.455111%2C47.137478%2C7.713561&origin=47.3927146%2C8.0444448',
    },
    'it-IT': {
      name: 'Argovia, Svizzera',
      country: 'Switzerland',
      url:
        '/it-IT/s?address=Argovia%2C%20Svizzera&bounds=47.621047%2C8.455111%2C47.137478%2C7.713561&origin=47.3927146%2C8.0444448',
    },
    'de-DE': {
      name: 'Aargau, Schweiz',
      country: 'Switzerland',
      url:
        '/de-DE/s?address=Aargau%2C%20Schweiz&bounds=47.621047%2C8.455111%2C47.137478%2C7.713561&origin=47.3927146%2C8.0444448',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Lecco, Italy',
      url:
        '/en-US/s?address=Lecco%2C%20Italy&bounds=46.157413%2C9.540952%2C45.649601%2C9.243307&origin=45.8553764%2C9.389605',
      country: 'Italy',
    },
    'fr-FR': {
      name: 'Lecco, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=Lecco%2C%20Italie&bounds=46.157413%2C9.540952%2C45.649601%2C9.243307&origin=45.8553764%2C9.389605',
    },
    'it-IT': {
      name: 'provincia di Lecco, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=provincia%20di%20Lecco%2C%20Italia&bounds=46.157413%2C9.540952%2C45.649601%2C9.243307&origin=45.8553764%2C9.389605',
    },
    'de-DE': {
      name: 'Provinz Lecco, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Provinz%20Lecco%2C%20Italien&bounds=46.157413%2C9.540952%2C45.649601%2C9.243307&origin=45.8553764%2C9.389605',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Vercelli, Italy',
      url:
        '/en-US/s?address=Vercelli%2C%20Italy&bounds=45.95046%2C8.581189%2C45.159585%2C7.861374&origin=45.3251557%2C8.4227666',
      country: 'Italy',
    },
    'fr-FR': {
      name: 'Verceil, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=Verceil%2C%20Italie&bounds=45.95046%2C8.581189%2C45.159585%2C7.861374&origin=45.3251557%2C8.4227666',
    },
    'it-IT': {
      name: 'provincia di Vercelli, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=provincia%20di%20Vercelli%2C%20Italia&bounds=45.95046%2C8.581189%2C45.159585%2C7.861374&origin=45.3251557%2C8.4227666',
    },
    'de-DE': {
      name: 'Provinz Vercelli, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Provinz%20Vercelli%2C%20Italien&bounds=45.95046%2C8.581189%2C45.159585%2C7.861374&origin=45.3251557%2C8.4227666',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Hautes-Alpes, France',
      url:
        '/en-US/s?address=Hautes-Alpes%2C%20France&bounds=45.126848%2C7.07778%2C44.18648%2C5.418385&origin=44.5612032%2C6.0820639',
      country: 'France',
    },
    'fr-FR': {
      name: 'Hautes-Alpes, France',
      country: 'France',
      url:
        '/fr-FR/s?address=Hautes-Alpes%2C%20France&bounds=45.126848%2C7.07778%2C44.18648%2C5.418385&origin=44.5612032%2C6.0820639',
    },
    'it-IT': {
      name: 'Alte Alpi, Francia',
      country: 'France',
      url:
        '/it-IT/s?address=Alte%20Alpi%2C%20Francia&bounds=45.126848%2C7.07778%2C44.18648%2C5.418385&origin=44.5612032%2C6.0820639',
    },
    'de-DE': {
      name: 'Département Hautes-Alpes, Frankreich',
      country: 'France',
      url:
        '/de-DE/s?address=Département%20Hautes-Alpes%2C%20Frankreich&bounds=45.126848%2C7.07778%2C44.18648%2C5.418385&origin=44.5612032%2C6.0820639',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Savoie, France',
      url:
        '/en-US/s?address=Savoie%2C%20France&bounds=45.93846%2C7.185755%2C45.051721%2C5.622165&origin=45.5662672%2C5.9203636',
      country: 'France',
    },
    'fr-FR': {
      name: 'Savoie, France',
      country: 'France',
      url:
        '/fr-FR/s?address=Savoie%2C%20France&bounds=45.93846%2C7.185755%2C45.051721%2C5.622165&origin=45.5662672%2C5.9203636',
    },
    'it-IT': {
      name: 'Savoia, Francia',
      country: 'France',
      url:
        '/it-IT/s?address=Savoia%2C%20Francia&bounds=45.93846%2C7.185755%2C45.051721%2C5.622165&origin=45.5662672%2C5.9203636',
    },
    'de-DE': {
      name: 'Département Savoie, Frankreich',
      country: 'France',
      url:
        '/de-DE/s?address=Département%20Savoie%2C%20Frankreich&bounds=45.93846%2C7.185755%2C45.051721%2C5.622165&origin=45.5662672%2C5.9203636',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Navarre, Spain',
      url:
        '/en-US/s?address=Navarre%2C%20Spain&bounds=43.31465%2C-0.724584%2C41.909896%2C-2.500083&origin=42.8182536%2C-1.6440304',
      country: 'Spain',
    },
    'fr-FR': {
      name: 'Navarre, Espagne',
      country: 'Spain',
      url:
        '/fr-FR/s?address=Navarre%2C%20Espagne&bounds=43.31465%2C-0.724584%2C41.909896%2C-2.500083&origin=42.8182536%2C-1.6440304',
    },
    'it-IT': {
      name: 'Navarra, Spagna',
      country: 'Spain',
      url:
        '/it-IT/s?address=Navarra%2C%20Spagna&bounds=43.31465%2C-0.724584%2C41.909896%2C-2.500083&origin=42.8182536%2C-1.6440304',
    },
    'de-DE': {
      name: 'Navarra, Spanien',
      country: 'Spain',
      url:
        '/de-DE/s?address=Navarra%2C%20Spanien&bounds=43.31465%2C-0.724584%2C41.909896%2C-2.500083&origin=42.8182536%2C-1.6440304',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Alicante, Spain',
      url:
        '/en-US/s?address=Alicante%2C%20Spain&bounds=38.945799%2C0.3302188%2C37.843867%2C-1.094162&origin=38.3436365%2C-0.4881708',
      country: 'Spain',
    },
    'fr-FR': {
      name: 'Alicante, Espagne',
      country: 'Spain',
      url:
        '/fr-FR/s?address=Alicante%2C%20Espagne&bounds=38.945799%2C0.3302188%2C37.843867%2C-1.094162&origin=38.3436365%2C-0.4881708',
    },
    'it-IT': {
      name: 'Alicante, Spagna',
      country: 'Spain',
      url:
        '/it-IT/s?address=Alicante%2C%20Spagna&bounds=38.945799%2C0.3302188%2C37.843867%2C-1.094162&origin=38.3436365%2C-0.4881708',
    },
    'de-DE': {
      name: 'Alicante, Spanien',
      country: 'Spain',
      url:
        '/de-DE/s?address=Alicante%2C%20Spanien&bounds=38.945799%2C0.3302188%2C37.843867%2C-1.094162&origin=38.3436365%2C-0.4881708',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Gipuzkoa, Spain',
      url:
        '/en-US/s?address=Gipuzkoa%2C%20Spain&bounds=43.4529189%2C-1.729369%2C42.895245%2C-2.602684&origin=43.3224219%2C-1.9838889',
      country: 'Spain',
    },
    'fr-FR': {
      name: 'Guipuscoa, Espagne',
      country: 'Spain',
      url:
        '/fr-FR/s?address=Guipuscoa%2C%20Espagne&bounds=43.4529189%2C-1.729369%2C42.895245%2C-2.602684&origin=43.3224219%2C-1.9838889',
    },
    'it-IT': {
      name: 'Gipuzkoa, Spagna',
      country: 'Spain',
      url:
        '/it-IT/s?address=Gipuzkoa%2C%20Spagna&bounds=43.4529189%2C-1.729369%2C42.895245%2C-2.602684&origin=43.3224219%2C-1.9838889',
    },
    'de-DE': {
      name: 'Gipuzkoa, Spanien',
      country: 'Spain',
      url:
        '/de-DE/s?address=Gipuzkoa%2C%20Spanien&bounds=43.4529189%2C-1.729369%2C42.895245%2C-2.602684&origin=43.3224219%2C-1.9838889',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Vaud, Switzerland',
      url:
        '/en-US/s?address=Vaud%2C%20Switzerland&bounds=46.990581%2C7.249075%2C46.187127%2C6.063857&origin=46.5247936%2C6.6358089',
      country: 'Switzerland',
    },
    'fr-FR': {
      name: 'canton de Vaud, Suisse',
      country: 'Switzerland',
      url:
        '/fr-FR/s?address=canton%20de%20Vaud%2C%20Suisse&bounds=46.990581%2C7.249075%2C46.187127%2C6.063857&origin=46.5247936%2C6.6358089',
    },
    'it-IT': {
      name: 'Vaud, Svizzera',
      country: 'Switzerland',
      url:
        '/it-IT/s?address=Vaud%2C%20Svizzera&bounds=46.990581%2C7.249075%2C46.187127%2C6.063857&origin=46.5247936%2C6.6358089',
    },
    'de-DE': {
      name: 'Kanton Waadt, Schweiz',
      country: 'Switzerland',
      url:
        '/de-DE/s?address=Kanton%20Waadt%2C%20Schweiz&bounds=46.990581%2C7.249075%2C46.187127%2C6.063857&origin=46.5247936%2C6.6358089',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Salzburg, Austria',
      url:
        '/en-US/s?address=Salzburg%2C%20Austria&bounds=48.041466%2C13.996124%2C46.943856%2C12.076097&origin=47.7981346%2C13.0464806',
      country: 'Austria',
    },
    'fr-FR': {
      name: 'Salzbourg, Autriche',
      country: 'Austria',
      url:
        '/fr-FR/s?address=Salzbourg%2C%20Autriche&bounds=48.041466%2C13.996124%2C46.943856%2C12.076097&origin=47.7981346%2C13.0464806',
    },
    'it-IT': {
      name: 'Salisburghese, Austria',
      country: 'Austria',
      url:
        '/it-IT/s?address=Salisburghese%2C%20Austria&bounds=48.041466%2C13.996124%2C46.943856%2C12.076097&origin=47.7981346%2C13.0464806',
    },
    'de-DE': {
      name: 'Salzburg, Österreich',
      country: 'Austria',
      url:
        '/de-DE/s?address=Salzburg%2C%20Österreich&bounds=48.041466%2C13.996124%2C46.943856%2C12.076097&origin=47.7981346%2C13.0464806',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Trentino, Italy',
      url:
        '/en-US/s?address=Trentino%2C%20Italy&bounds=46.532764%2C11.96268%2C45.673063%2C10.452133&origin=46.0664228%2C11.1257601',
      country: 'Italy',
    },
    'fr-FR': {
      name: 'province autonome de Trente, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=province%20autonome%20de%20Trente%2C%20Italie&bounds=46.532764%2C11.96268%2C45.673063%2C10.452133&origin=46.0664228%2C11.1257601',
    },
    'it-IT': {
      name: 'provincia autonoma di Trento, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=provincia%20autonoma%20di%20Trento%2C%20Italia&bounds=46.532764%2C11.96268%2C45.673063%2C10.452133&origin=46.0664228%2C11.1257601',
    },
    'de-DE': {
      name: 'Trentino, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Trentino%2C%20Italien&bounds=46.532764%2C11.96268%2C45.673063%2C10.452133&origin=46.0664228%2C11.1257601',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Upper Austria, Austria',
      url:
        '/en-US/s?address=Upper%20Austria%2C%20Austria&bounds=48.772567%2C14.992169%2C47.461112%2C12.74908&origin=48.3059078%2C14.286198',
      country: 'Austria',
    },
    'fr-FR': {
      name: 'Haute-Autriche, Autriche',
      country: 'Austria',
      url:
        '/fr-FR/s?address=Haute-Autriche%2C%20Autriche&bounds=48.772567%2C14.992169%2C47.461112%2C12.74908&origin=48.3059078%2C14.286198',
    },
    'it-IT': {
      name: 'Alta Austria, Austria',
      country: 'Austria',
      url:
        '/it-IT/s?address=Alta%20Austria%2C%20Austria&bounds=48.772567%2C14.992169%2C47.461112%2C12.74908&origin=48.3059078%2C14.286198',
    },
    'de-DE': {
      name: 'Oberösterreich, Österreich',
      country: 'Austria',
      url:
        '/de-DE/s?address=Oberösterreich%2C%20Österreich&bounds=48.772567%2C14.992169%2C47.461112%2C12.74908&origin=48.3059078%2C14.286198',
    },
  },
  {
    showForPartners: [PARTNER_KEYS.HELLY_HANSEN],
    'en-US': {
      name: 'Huesca, Spain',
      url:
        '/en-US/s?address=Huesca%2C%20Spain&bounds=42.924493%2C0.771307%2C41.347805%2C-0.934168&origin=42.1382539%2C-0.4080901',
      country: 'Spain',
    },
    'fr-FR': {
      name: 'province de Huesca, Espagne',
      country: 'Spain',
      url:
        '/fr-FR/s?address=province%20de%20Huesca%2C%20Espagne&bounds=42.924493%2C0.771307%2C41.347805%2C-0.934168&origin=42.1382539%2C-0.4080901',
    },
    'it-IT': {
      name: 'provincia di Huesca, Spagna',
      country: 'Spain',
      url:
        '/it-IT/s?address=provincia%20di%20Huesca%2C%20Spagna&bounds=42.924493%2C0.771307%2C41.347805%2C-0.934168&origin=42.1382539%2C-0.4080901',
    },
    'de-DE': {
      name: 'Provinz Huesca, Spanien',
      country: 'Spain',
      url:
        '/de-DE/s?address=Provinz%20Huesca%2C%20Spanien&bounds=42.924493%2C0.771307%2C41.347805%2C-0.934168&origin=42.1382539%2C-0.4080901',
    },
  },
  {
    isHiddenOnCrion: true,
    showForPartners: [PARTNER_KEYS.GENOVA],
    'en-US': {
      name: 'Genoa, Italy',
      country: 'Italy',
      url:
        '/en-US/s?address=Genoa%2C%20Italy&bounds=44.676443%2C9.57456%2C44.147848%2C8.571554&origin=44.40726%2C8.933862',
    },
    'fr-FR': {
      name: 'ville métropolitaine de Gênes, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=ville%20métropolitaine%20de%20Gênes%2C%20Italie&bounds=44.676443%2C9.57456%2C44.147848%2C8.571554&origin=44.40726%2C8.933862',
    },
    'it-IT': {
      name: 'città metropolitana di Genova, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=città%20metropolitana%20di%20Genova%2C%20Italia&bounds=44.676443%2C9.57456%2C44.147848%2C8.571554&origin=44.40726%2C8.933862',
    },
    'de-DE': {
      name: 'Metropolitanstadt Genua, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Metropolitanstadt%20Genua%2C%20Italien&bounds=44.676443%2C9.57456%2C44.147848%2C8.571554&origin=44.40726%2C8.933862',
    },
  },
  {
    isHiddenOnCrion: true,
    showForPartners: [PARTNER_KEYS.GENOVA],
    'en-US': {
      name: 'Savona, Italy',
      country: 'Italy',
      url:
        '/en-US/s?address=Savona%2C%20Italy&bounds=44.528747%2C8.676252%2C43.872069%2C7.977437&origin=44.308025%2C8.481032',
    },
    'fr-FR': {
      name: 'Savone, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=Savone%2C%20Italie&bounds=44.528747%2C8.676252%2C43.872069%2C7.977437&origin=44.308025%2C8.481032',
    },
    'it-IT': {
      name: 'Savona, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=Savona%2C%20Italia&bounds=44.528747%2C8.676252%2C43.872069%2C7.977437&origin=44.308025%2C8.481032',
    },
    'de-DE': {
      name: 'Savona, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Savona%2C%20Italien&bounds=44.528747%2C8.676252%2C43.872069%2C7.977437&origin=44.308025%2C8.481032',
    },
  },
  {
    isHiddenOnCrion: true,
    showForPartners: [PARTNER_KEYS.GENOVA],
    'en-US': {
      name: 'La Spezia, Italy',
      country: 'Italy',
      url:
        '/en-US/s?address=La%20Spezia%2C%20Italy&bounds=44.439827%2C10.07102%2C43.959019%2C9.465738&origin=44.103387%2C9.821702',
    },
    'fr-FR': {
      name: 'La Spezia, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=La%20Spezia%2C%20Italie&bounds=44.439827%2C10.07102%2C43.959019%2C9.465738&origin=44.103387%2C9.821702',
    },
    'it-IT': {
      name: 'provincia della Spezia, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=provincia%20della%20Spezia%2C%20Italia&bounds=44.439827%2C10.07102%2C43.959019%2C9.465738&origin=44.103387%2C9.821702',
    },
    'de-DE': {
      name: 'Provinz La Spezia, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Provinz%20La%20Spezia%2C%20Italien&bounds=44.439827%2C10.07102%2C43.959019%2C9.465738&origin=44.103387%2C9.821702',
    },
  },
  {
    isHiddenOnCrion: true,
    showForPartners: [PARTNER_KEYS.GENOVA],
    'en-US': {
      name: 'Imperia, Italy',
      country: 'Italy',
      url:
        '/en-US/s?address=Imperia%2C%20Italy&bounds=44.140882%2C8.19152%2C43.722427%2C7.495123&origin=43.886296%2C8.026711',
    },
    'fr-FR': {
      name: 'Imperia, Italie',
      country: 'Italy',
      url:
        '/fr-FR/s?address=Imperia%2C%20Italie&bounds=44.140882%2C8.19152%2C43.722427%2C7.495123&origin=43.886296%2C8.026711',
    },
    'it-IT': {
      name: 'provincia di Imperia, Italia',
      country: 'Italy',
      url:
        '/it-IT/s?address=provincia%20di%20Imperia%2C%20Italia&bounds=44.140882%2C8.19152%2C43.722427%2C7.495123&origin=43.886296%2C8.026711',
    },
    'de-DE': {
      name: 'Provinz Imperia, Italien',
      country: 'Italy',
      url:
        '/de-DE/s?address=Provinz%20Imperia%2C%20Italien&bounds=44.140882%2C8.19152%2C43.722427%2C7.495123&origin=43.886296%2C8.026711',
    },
  },
];

export default SUGGESTED_LOCATIONS;
