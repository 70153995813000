import React from 'react';
import { string } from 'prop-types';

const IconClimbing = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 34.561 34.559">
      <path
        d="M4.631,15.516a3.722,3.722,0,0,0-.374,1.628l.139,8.73-3.028,6.5a1.968,1.968,0,0,0,3.454,1.887l3.331-7.1a2.064,2.064,0,0,0,.156-.528l.018-.268-.026-5.4,5.118,2.226.827,5.275a1.972,1.972,0,0,0,3.871-.129,2.279,2.279,0,0,0,.008-.537L17.117,21.43a1.989,1.989,0,0,0-1.1-1.413l-4.575-2.025,2.913-5.05L15.8,14.771a1.505,1.505,0,0,0,.556.39l5.507,1.6a1.547,1.547,0,0,0,.914-2.944l-.078-.026-4.784-1.4-3.6-4.3a3.651,3.651,0,0,0-2.1-1.11,3.593,3.593,0,0,0-3.58,1.655Zm15.8,12.516-.348,2.616-9.472,2.295L10.7,34.56H33.8a1.473,1.473,0,0,0,1.48-1.471l-.026-31.01-3.165-.736L30.27,9.5,27.23,10.769l-2.7,7.518L27.1,24.039l-.721,2.208ZM14,6.593a2.937,2.937,0,1,0-2.949-2.936A2.945,2.945,0,0,0,14,6.593Zm-5.867.459a.835.835,0,0,0-.313-1.143L6.388,5.077a.853.853,0,0,0-1.157.312L.83,12.952A.837.837,0,0,0,1.143,14.1l1.436.824a.842.842,0,0,0,1.148-.3Z"
        transform="translate(-0.72 -0.72)"
      />
    </svg>
  );
};

IconClimbing.defaultProps = { className: null };
IconClimbing.propTypes = { className: string };

export default IconClimbing;
