import React, { useCallback, useMemo } from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import {
  InlineTextButton,
  ReviewRating,
  UserDisplayName,
  TransitionMessage,
} from '../../../components';
import { dateFormatFull } from '../../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../../util/data';
import { propTypes } from '../../../util/types';
import { getUserTxRole } from '../../../transactions/transaction';
import {
  isCustomerReview,
  isProviderReview,
  isRelevantPastTransition,
  transitionIsReviewed,
  txRoleIsProvider,
} from '../../../transactions/transactionProcessBooking';

import css from './ActivityFeed.module.css';

const Message = props => {
  const { message, isOwnMessage } = props;

  const classes = useMemo(() => classNames(css.message, { [css.ownMessage]: isOwnMessage }), [
    isOwnMessage,
  ]);

  const messageContent = useMemo(
    () => (
      <p className={classNames(css.messageContent, { [css.ownMessageContent]: isOwnMessage })}>
        {message.attributes.content}
      </p>
    ),
    [isOwnMessage, message.attributes.content]
  );
  const messageDate = useMemo(
    () => (
      <p className={classNames(css.messageDate, { [css.ownMessageDate]: isOwnMessage })}>
        {dateFormatFull(message.attributes.createdAt)}
      </p>
    ),
    [isOwnMessage, message.attributes.createdAt]
  );

  return (
    <div className={classes}>
      <div className={css.messagecontentWrapper}>{messageContent}</div>
      {messageDate}
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  isOwnMessage: bool,
};

const Review = props => {
  const { content, rating } = props;

  return (
    <>
      <p className={css.reviewContent}>{content}</p>
      <ReviewRating
        reviewStarClassName={css.reviewStar}
        rating={rating}
        hideRatingCount
        isAccented
      />
    </>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const reviewByAuthorId = (transaction, userId) =>
  transaction.reviews.filter(r => !r.attributes.deleted && r.author.id.uuid === userId.uuid)[0];

const Transition = props => {
  const {
    transition,
    currentTransaction,
    currentCustomer,
    currentProvider,
    currentUser,
    intl,
    onOpenReviewModal,
  } = props;

  const deletedReviewContent = useMemo(
    () => intl.formatMessage({ id: 'ActivityFeed.deletedReviewContent' }),
    [intl]
  );

  const ownRole = useMemo(() => getUserTxRole(currentUser.id, currentTransaction), [
    currentTransaction,
    currentUser.id,
  ]);

  const displayName = useMemo(
    () => (
      <UserDisplayName
        user={txRoleIsProvider(ownRole) ? currentCustomer : currentProvider}
        hideUserType
      />
    ),
    [currentCustomer, currentProvider, ownRole]
  );

  const transitionMessage = useMemo(
    () => (
      <TransitionMessage
        transaction={currentTransaction}
        transition={transition}
        ownRole={ownRole}
        displayName={displayName}
        onOpenReviewModal={onOpenReviewModal}
      />
    ),
    [currentTransaction, transition, ownRole, displayName, onOpenReviewModal]
  );

  const transitionDate = useMemo(() => dateFormatFull(transition.createdAt), [
    transition.createdAt,
  ]);

  const reviewComponentMaybe = useMemo(() => {
    const { lastTransition } = currentTransaction.attributes || {};
    if (transitionIsReviewed(lastTransition)) {
      if (isCustomerReview(transition)) {
        const review = reviewByAuthorId(currentTransaction, currentCustomer.id);
        return (
          <Review
            content={review?.attributes?.content || deletedReviewContent}
            rating={review?.attributes?.rating}
          />
        );
      } else if (isProviderReview(transition)) {
        const review = reviewByAuthorId(currentTransaction, currentProvider.id);
        return (
          <Review
            content={review?.attributes?.content || deletedReviewContent}
            rating={review?.attributes?.rating}
          />
        );
      }
    }
    return null;
  }, [
    currentTransaction,
    transition,
    currentCustomer.id,
    deletedReviewContent,
    currentProvider.id,
  ]);

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>-</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{transitionDate}</p>
        {reviewComponentMaybe}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  currentTransaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => (
  <div className={css.transition}>
    <div className={css.bullet}>
      <p className={css.transitionContent}>-</p>
    </div>
    <div>
      <p className={css.transitionContent} />
      <p className={css.transitionDate} />
    </div>
  </div>
);

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;

  const currentTransaction = useMemo(() => ensureTransaction(transaction), [transaction]);
  const currentCustomer = useMemo(() => ensureUser(currentTransaction.customer), [
    currentTransaction.customer,
  ]);
  const currentProvider = useMemo(() => ensureUser(currentTransaction.provider), [
    currentTransaction.provider,
  ]);
  const currentListing = useMemo(() => ensureListing(currentTransaction.listing), [
    currentTransaction.listing,
  ]);

  const transitionsAvailable = useMemo(
    () => !!(currentUser?.id && currentCustomer.id && currentProvider.id && currentListing.id),
    [currentCustomer.id, currentListing.id, currentProvider.id, currentUser]
  );

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  // combine messages and transaction transitions
  const { transitions = [] } = currentTransaction.attributes || {};
  const items = useMemo(
    () => organizedItems(messages, transitions, hasOlderMessages || fetchMessagesInProgress),
    [fetchMessagesInProgress, hasOlderMessages, messages, transitions]
  );

  const messageListItem = useCallback(
    message => (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.item}>
        <Message
          message={message}
          isOwnMessage={message.sender.id?.uuid === currentUser.id?.uuid}
        />
      </li>
    ),
    [currentUser.id?.uuid]
  );

  const transitionListItem = useCallback(
    transition =>
      isRelevantPastTransition(transition.transition) && (
        <li key={transition.transition} className={css.item}>
          {transitionsAvailable ? (
            <Transition
              transition={transition}
              currentTransaction={currentTransaction}
              currentCustomer={currentCustomer}
              currentProvider={currentProvider}
              currentUser={currentUser}
              intl={intl}
              onOpenReviewModal={onOpenReviewModal}
            />
          ) : (
            <EmptyTransition />
          )}
        </li>
      ),
    [
      transitionsAvailable,
      currentTransaction,
      currentCustomer,
      currentProvider,
      currentUser,
      intl,
      onOpenReviewModal,
    ]
  );

  return (
    <ul className={classes}>
      {hasOlderMessages && (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      )}
      {items.map(item => (isMessage(item) ? messageListItem(item) : transitionListItem(item)))}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
