import React, { useMemo } from 'react';
import { array, func, instanceOf, object, string } from 'prop-types';
import { Navigate, Views } from 'react-big-calendar';
import classNames from 'classnames';
import IconArrowHead from '../IconArrowHead/IconArrowHead';
import { SecondaryButton } from '../Button/Button';

import css from './CalendarToolbar.module.css';

const getHeadersByViews = (currentDate, localizer) => {
  const startOfWeek = localizer.startOf(currentDate, 'week');
  const endOfWeek = localizer.endOf(currentDate, 'week');

  const weekRange = localizer.range(startOfWeek, endOfWeek);

  const headers = weekRange.reduce(
    (dateStrings, date) => {
      dateStrings[Views.MONTH].push({
        desktop: localizer.format(date, 'dddd'),
        mobile: localizer.format(date, 'ddd'),
      });

      dateStrings[Views.WEEK].push({
        desktop: localizer.format(date, 'ddd DD/MM'),
        mobile: localizer.format(date, 'ddd DD'),
      });

      return dateStrings;
    },
    {
      [Views.MONTH]: [],
      [Views.WEEK]: [],
    }
  );

  headers[Views.DAY] = [{ desktop: localizer.format(currentDate, 'dddd DD/MM') }];

  return headers;
};

const CalendarToolbar = props => {
  const { date, localizer, onNavigate, onView, view, views } = props;

  const { messages } = localizer;

  const headers = useMemo(() => getHeadersByViews(date, localizer), [date, localizer]);

  const headersToShow = useMemo(() => headers[view], [headers, view]);

  const isMonthView = useMemo(() => view === Views.MONTH, [view]);
  const isWeekView = useMemo(() => view === Views.WEEK, [view]);

  const isDayView = useMemo(() => !(isMonthView || isWeekView), [isMonthView, isWeekView]);

  const currentMonth = useMemo(() => localizer.format(date, 'MMMM'), [date, localizer]);

  const prevButton = useMemo(
    () => (
      <button
        type="button"
        // In the month view, the prev (down) arrow navigates forwards
        onClick={() => onNavigate(isMonthView ? Navigate.NEXT : Navigate.PREVIOUS)}
        aria-label={messages.previous}
        className={css.navButton}
      >
        <IconArrowHead direction="left" />
      </button>
    ),
    [isMonthView, messages.previous, onNavigate]
  );

  const nextButton = useMemo(
    () => (
      <button
        type="button"
        // In the month view, the next (up) arrow navigates backwards
        onClick={() => onNavigate(isMonthView ? Navigate.PREVIOUS : Navigate.NEXT)}
        aria-label={messages.next}
        className={css.navButton}
      >
        <IconArrowHead direction="right" />
      </button>
    ),
    [isMonthView, messages.next, onNavigate]
  );

  return (
    <div className={css.toolbar}>
      <div className={classNames(css.viewButtonGroup)}>
        {views.map(name => (
          <SecondaryButton
            type="button"
            color="black"
            key={name}
            className={classNames(css.viewButton, { [css.viewButtonActive]: view === name })}
            onClick={() => onView(name)}
          >
            {messages[name]}
          </SecondaryButton>
        ))}
      </div>

      {isWeekView && date && <h4 className={css.selectedMonth}> {currentMonth} </h4>}
      <div
        className={classNames(css.headers, {
          [css.headersMonth]: isMonthView,
          [css.headersWeek]: isWeekView,
        })}
      >
        {!isMonthView && (
          <div
            className={classNames(css.prevNavButton, {
              [css.staticNavButton]: isDayView,
              [css.paddingLeft]: isWeekView,
            })}
          >
            {prevButton}
          </div>
        )}

        {headersToShow.map(header => {
          const { desktop, mobile } = header;

          const [mobileFirstPart, mobileSecondPart] = mobile ? mobile.split(' ') : [];

          return (
            <div key={desktop} className={css.header}>
              <div className={classNames({ [css.desktopHeader]: !!mobile })}>{desktop}</div>
              {mobile ? (
                <div className={css.mobileHeader}>
                  <span> {mobileFirstPart} </span>
                  {mobileSecondPart ? <span>{mobileSecondPart}</span> : null}
                </div>
              ) : null}
            </div>
          );
        })}

        {!isMonthView && (
          <div className={classNames(css.nextNavButton, { [css.staticNavButton]: isDayView })}>
            {nextButton}
          </div>
        )}
      </div>

      {isMonthView && (
        <div className={css.verticalSideToolbar}>
          <div className={css.verticalButtons}>
            {prevButton}
            <span>{currentMonth}</span>
            {nextButton}
          </div>
        </div>
      )}
    </div>
  );
};

CalendarToolbar.propTypes = {
  date: instanceOf(Date),
  label: string,
  localizer: object,
  messages: object,
  onNavigate: func,
  onView: func,
  view: string,
  views: array,
};

export default CalendarToolbar;
