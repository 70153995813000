import React, { useMemo } from 'react';
import { number, string } from 'prop-types';
import classNames from 'classnames';
import { rangeRight } from 'lodash';

import css from './IconPointLevel.module.css';

const circleXYr = { cx: '3.5', cy: '3.5', r: '3.5' };

const IconPointLevel = props => {
  const { rootClassName, className, backgroundClassName, level } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  const circles = useMemo(
    () =>
      rangeRight(4).map((n, i) => {
        const transformMaybe = n > 0 ? { transform: `translate(0, ${8 * n})` } : {};

        return (
          <circle
            key={`point${i}`}
            className={classNames(css.empty, { [css.filled]: level > i })}
            {...circleXYr}
            {...transformMaybe}
          />
        );
      }),
    [level]
  );

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g transform="translate(-712 -149)">
        <circle
          className={backgroundClassName}
          cx="17.5"
          cy="17.5"
          r="17.5"
          transform="translate(712 149)"
        />
        <g transform="translate(726.625 151)">{circles}</g>
      </g>
    </svg>
  );
};

IconPointLevel.defaultProps = {
  rootClassName: null,
  className: null,
  backgroundClassName: null,
  level: 0,
};

IconPointLevel.propTypes = {
  rootClassName: string,
  backgroundClassName: string,
  className: string,
  level: number,
};

export default IconPointLevel;
