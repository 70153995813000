import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTrashBin.module.css';

const IconTrashBin = props => {
  const { className } = props;
  const classes = classNames(css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        data-name="Icon awesome-trash-alt"
        d="M1.25 18.125A1.875 1.875 0 0 0 3.125 20h11.25a1.875 1.875 0 0 0 1.875-1.875V5h-15Zm10.625-10a.625.625 0 1 1 1.25 0v8.75a.625.625 0 1 1-1.25 0Zm-3.75 0a.625.625 0 0 1 1.25 0v8.75a.625.625 0 0 1-1.25 0Zm-3.75 0a.625.625 0 1 1 1.25 0v8.75a.625.625 0 1 1-1.25 0Zm12.5-6.875h-4.688L11.82.52a.938.938 0 0 0-.84-.52H6.516a.927.927 0 0 0-.836.52l-.367.73H.625A.625.625 0 0 0 0 1.875v1.25a.625.625 0 0 0 .625.625h16.25a.625.625 0 0 0 .625-.625v-1.25a.625.625 0 0 0-.625-.625Z"
      />
    </svg>
  );
};

IconTrashBin.defaultProps = {
  className: null,
};

const { string } = PropTypes;

IconTrashBin.propTypes = {
  className: string,
};

export default IconTrashBin;
