import httpClient from '../httpClient';

const fetchListings = (params, partnerKey) => {
  const headers = partnerKey ? { headers: { 'x-partner-key': partnerKey } } : {};

  return httpClient.get(`listings`, { params, ...headers });
};

const fetchActivitiesCount = () => {
  return httpClient.get('listings/activities/count');
};

const updateListing = (listingId, data) => {
  return httpClient.patch(`listings/${listingId}`, data);
};

const openListing = listingId => {
  return httpClient.post(`listings/${listingId}/open`);
};

const closeListing = listingId => {
  return httpClient.post(`listings/${listingId}/close`);
};

const requestListingDeletion = listingId => {
  return httpClient.post(`listings/${listingId}/request-deletion`);
};

const getListingPeriods = listingId => {
  return httpClient.get(`listings/${listingId}/periods`, {
    params: {
      $sort: 'startsAt',
    },
  });
};

const updateListingPeriods = (listingId, periods) => {
  return httpClient.put(`listings/${listingId}/periods`, { periods });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchListings,
  updateListing,
  fetchActivitiesCount,
  openListing,
  closeListing,
  requestListingDeletion,
  getListingPeriods,
  updateListingPeriods,
};
