import users from '../../api/users';

// ================ Action types ================ //

export const FETCH_EXCEPTIONS_REQUEST = 'app/CalendarPage/FETCH_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS = 'app/CalendarPage/FETCH_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR = 'app/CalendarPage/FETCH_EXCEPTIONS_ERROR';

export const DELETE_EXCEPTION_REQUEST = 'app/CalendarPage/DELETE_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS = 'app/CalendarPage/DELETE_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR = 'app/CalendarPage/DELETE_EXCEPTION_ERROR';

export const ADD_EXCEPTION_REQUEST = 'app/CalendarPage/ADD_EXCEPTION_REQUEST';
export const ADD_EXCEPTION_SUCCESS = 'app/CalendarPage/ADD_EXCEPTION_SUCCESS';
export const ADD_EXCEPTION_ERROR = 'app/CalendarPage/ADD_EXCEPTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  exceptions: [],
  exceptionsPagination: null,
  fetchExceptionsInProgress: false,
  fetchExceptionsError: null,

  deleteExceptionInProgress: null,
  deleteExceptionError: null,

  addExceptionInProgress: false,
  addExceptionError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_EXCEPTIONS_REQUEST:
      return { ...state, fetchExceptionsInProgress: true, fetchExceptionsError: null };
    case FETCH_EXCEPTIONS_SUCCESS: {
      const { availabilityExceptions, pagination } = payload;

      const exceptions =
        pagination.page > 1
          ? [...state.exceptions, ...availabilityExceptions]
          : availabilityExceptions;

      return {
        ...state,
        fetchExceptionsInProgress: false,
        exceptions,
        exceptionsPagination: pagination,
      };
    }
    case FETCH_EXCEPTIONS_ERROR:
      return { ...state, fetchExceptionsInProgress: false, fetchExceptionsError: payload };

    case DELETE_EXCEPTION_REQUEST:
      return { ...state, deleteExceptionInProgress: payload, deleteExceptionError: null };
    case DELETE_EXCEPTION_SUCCESS:
      return {
        ...state,
        deleteExceptionInProgress: null,
        exceptions: state.exceptions.filter(e => e._id !== payload),
      };
    case DELETE_EXCEPTION_ERROR:
      return { ...state, deleteExceptionInProgress: null, deleteExceptionError: payload };

    case ADD_EXCEPTION_REQUEST:
      return { ...state, addExceptionInProgress: true, addExceptionError: null };
    case ADD_EXCEPTION_SUCCESS:
      const exceptions = [payload, ...state.exceptions].sort(
        (e1, e2) => new Date(e1.startsAt) - new Date(e2.startsAt)
      );

      return {
        ...state,
        addExceptionInProgress: false,
        exceptions,
      };
    case ADD_EXCEPTION_ERROR:
      return { ...state, addExceptionInProgress: false, addExceptionError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchExceptionsRequest = () => ({ type: FETCH_EXCEPTIONS_REQUEST });
const fetchExceptionsSuccess = payload => ({ type: FETCH_EXCEPTIONS_SUCCESS, payload });
const fetchExceptionsError = e => ({
  type: FETCH_EXCEPTIONS_ERROR,
  error: true,
  payload: e,
});

const deleteExceptionRequest = payload => ({ type: DELETE_EXCEPTION_REQUEST, payload });
const deleteExceptionSuccess = payload => ({ type: DELETE_EXCEPTION_SUCCESS, payload });
const deleteExceptionError = e => ({
  type: DELETE_EXCEPTION_ERROR,
  error: true,
  payload: e,
});

const addExceptionRequest = () => ({ type: ADD_EXCEPTION_REQUEST });
const addExceptionSuccess = payload => ({ type: ADD_EXCEPTION_SUCCESS, payload });
const addExceptionError = e => ({
  type: ADD_EXCEPTION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchExceptions = params => dispatch => {
  dispatch(fetchExceptionsRequest());

  const { page = 1 } = params || {};

  const queryParams = {
    $page: page,
    $limit: 10,
    $sort: '-startsAt',
  };

  return users
    .getAvailabilityExceptions(queryParams)
    .then(res => {
      const { items, currentPage, totalItems, totalPages } = res.data;

      const data = {
        availabilityExceptions: items,
        pagination: {
          page: currentPage,
          totalItems,
          totalPages,
        },
      };

      dispatch(fetchExceptionsSuccess(data));

      return res;
    })
    .catch(e => {
      dispatch(fetchExceptionsError(e));
    });
};

export const deleteException = exceptionId => dispatch => {
  dispatch(deleteExceptionRequest(exceptionId));

  return users
    .deleteAvailabilityException(exceptionId)
    .then(() => {
      dispatch(deleteExceptionSuccess(exceptionId));
    })
    .catch(e => {
      dispatch(deleteExceptionError(e));
    });
};

export const addException = (startsAt, endsAt) => dispatch => {
  dispatch(addExceptionRequest());

  return users
    .addAvailabilityException(startsAt, endsAt)
    .then(res => {
      dispatch(addExceptionSuccess(res.data.availabilityException));
    })
    .catch(e => {
      dispatch(addExceptionError(e));
    });
};

export const loadData = () => {
  return fetchExceptions();
};
