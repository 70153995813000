//////////////////////////////////////////////////////////////////////
// Configurations related to search                                 //
// Note: some search experience is also on defaultMapsConfig        //
// and defaultListingConfig.js is responsible public data filtering //
//////////////////////////////////////////////////////////////////////

import { ACTIVITY, LISTING_SEASONALITY, LISTING_TYPE } from '../constants/listingAttributes';
import { USER_TYPE } from '../constants/userAttributes';
import { PARTNER_KEYS } from '../util/partner';
import { CONFIGURATION_OPTION_STATE } from './configListing';

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

// Main search used in Topbar.
// This can be either 'keywords' or 'location'.
export const mainSearch = {
  searchType: 'location',
};

/**
 * Configuration for default filters.
 * These are custom configs for each filter.
 * Common properties: key, schemaType, and label.
 * Note: the order of default filters is defined in util/configHelpers.js
 */

export const dateRangeFilter = {
  schemaType: 'dates',
  // Should the entire date range be available, or just part of it
  // Note: Since we don't enforce location search for dates filtering,
  //       we don't use API's 'time-full' in actual queries. It would require time zone info.
  availability: 'time-full', // time-partial
  // Options: day/night. This affects counting and whether single day picking is possible.
  dateRangeMode: 'day',
};

export const priceFilter = {
  schemaType: 'price',
  // Note: unlike most prices this is not handled in subunits
  min: 0,
  max: 1000,
  step: 5,
};
// // This is not in use by default.
// // Needs more thinking how it should work together with main search.
// export const keywordsFilter = {
//   key: 'keywords',
//   schemaType: 'text',
// }

export const filters = [
  {
    id: 'price',
    label: 'PriceFilter.label',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    showOnSearchPage: true,
    config: {
      min: 0,
      max: 2000,
      step: 5,
    },
  },
  {
    id: 'people',
    label: 'General.people',
    type: 'NumberIncrementFilter',
    group: 'primary',
    queryParamNames: [
      'pub_people',
      // Option keys must be present here just for the UI
      'adults',
      'children',
    ],
    showOnSearchPage: true,
    config: {
      searchMode: 'has_any',

      options: [
        { key: 'adults', label: 'General.adultsWithAge' },
        { key: 'children', label: 'General.children' },
      ],
    },
  },
  {
    id: 'activity',
    label: 'General.activity',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_activity'],
    showOnSearchPage: false,
    config: {
      searchMode: 'has_any',

      options: [
        { key: ACTIVITY.SKIING, label: 'General.skiing' },
        { key: ACTIVITY.SNOWBOARDING, label: 'General.snowboarding' },
        { key: ACTIVITY.HIKING, label: 'General.hiking' },
        { key: ACTIVITY.RUNNING, label: 'General.running' },
        { key: ACTIVITY.HORSE_RIDING, label: 'General.horseRiding' },
        { key: ACTIVITY.BIKING, label: 'General.biking' },
        { key: ACTIVITY.CLIMBING, label: 'General.climbing' },
        { key: ACTIVITY.SLEDGING, label: 'General.sledging' },
        { key: ACTIVITY.RIVER_SPORTS, label: 'General.riverSports' },
        { key: ACTIVITY.LAKE_AND_SEA_SPORTS, label: 'General.lakeAndSeaSports' },
        { key: ACTIVITY.AIR_SPORTS, label: 'General.airSports' },
        {
          key: ACTIVITY.COURT_SPORTS,
          label: 'General.courtSports',
          showForPartners: [PARTNER_KEYS.GENOVA],
        },
        { key: ACTIVITY.OTHER_EXPERIENCES, label: 'General.otherExperiences' },
      ],
    },
  },
  {
    id: 'discipline',
    label: 'General.discipline',
    type: 'SelectMultipleFilter',
    group: 'primary',
    // We don't want to show discipline filter before activity is selected
    showOnSearchPage: false,

    queryParamNames: ['pub_discipline'],
    config: {
      searchMode: 'has_any',

      // Discipline options depends of selected activity but we need
      // this options here to validate the filter options in SeachPage.helpers.js
      // Current states: active (usable on the marketplace) | archived (hidden from the marketplace)
      options: [
        // Skiing
        {
          key: 'alpineSkiing',
          label: 'General.alpineSkiing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'freerideSkiing',
          label: 'General.freerideSkiing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'freestyleSkiing',
          label: 'General.freestyleSkiing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'skiTouring',
          label: 'General.skiTouring',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'telemarkSkiing',
          label: 'General.telemarkSkiing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'crossCountrySkiing',
          label: 'General.crossCountrySkiing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'heliSkiing',
          label: 'General.heliSkiing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'grassSkiing',
          label: 'General.grassSkiing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        // Snowboarding
        {
          key: 'onPisteSnowboarding',
          label: 'General.onPisteSnowboarding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'freerideSnowboarding',
          label: 'General.freerideSnowboarding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'freestyleSnowboarding',
          label: 'General.freestyleSnowboarding',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'heliBoarding',
          label: 'General.heliBoarding',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'splitBoarding',
          label: 'General.splitBoarding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        // Hiking
        {
          key: 'mountainHiking',
          label: 'General.mountainHiking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'snowShoeing',
          label: 'General.snowShoeing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        // Running
        {
          key: 'trailRunning',
          label: 'General.trailRunning',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        // Horse riding
        {
          key: 'horseRiding',
          label: 'General.horseRiding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        // Biking
        {
          key: 'mountainBiking',
          label: 'General.mountainBiking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'roadCycling',
          label: 'General.roadCycling',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'eBiking',
          label: 'General.eBiking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'fatBiking',
          label: 'General.fatBiking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        // Climbing
        {
          key: 'mountaineering',
          label: 'General.mountaineering',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'rockClimbing',
          label: 'General.rockClimbing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'viaFerrata',
          label: 'General.viaFerrata',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'bouldering',
          label: 'General.bouldering',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON],
        },
        {
          key: 'iceClimbing',
          label: 'General.iceClimbing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON],
        },
        // Sledging
        {
          key: 'tobogganing',
          label: 'General.tobogganing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'huskySledding',
          label: 'General.huskySledding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'snowMobiling',
          label: 'General.snowMobiling',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'horseCarriageSledding',
          label: 'General.horseCarriageSledding',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        // River sports
        {
          key: 'rafting',
          label: 'General.rafting',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'canyoning',
          label: 'General.canyoning',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        // Lake & Sea sports
        {
          key: 'sailing',
          label: 'General.sailing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'kayaking',
          label: 'General.kayaking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'standUpPaddle',
          label: 'General.standUpPaddle',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'boatCruise',
          label: 'General.boatCruise',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'fishing',
          label: 'General.fishing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        // Air sports
        {
          key: 'paragliding',
          label: 'General.paragliding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'bungeeJumping',
          label: 'General.bungeeJumping',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'skydiving',
          label: 'General.skydiving',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'kiting',
          label: 'General.kiting',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          typeTags: [],
          seasonalityTags: [],
        },
        {
          key: 'tandemSpeedFlying',
          label: 'General.tandemSpeedFlying',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        // Other experiences
        {
          key: 'avalancheTraining',
          label: 'General.avalancheTraining',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON],
        },
        {
          key: 'guidedWalking',
          label: 'General.guidedWalking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'culinaryTasting',
          label: 'General.culinaryTasting',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'northernLights',
          label: 'General.northernLights',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'yoga',
          label: 'General.yoga',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        // Court sports
        {
          key: 'tennis',
          label: 'General.tennis',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON],
          showForPartners: [PARTNER_KEYS.GENOVA],
        },
        {
          key: 'padel',
          label: 'General.padel',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON],
          showForPartners: [PARTNER_KEYS.GENOVA],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    showOnSearchPage: false,
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    showOnSearchPage: false,
    config: {},
  },
  {
    id: 'certificate',
    label: 'Certificate',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_certificate'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: '200h', label: 'Registered yoga teacher 200h' },
        { key: '500h', label: 'Registered yoga teacher 500h' },
      ],
    },
  },
  {
    id: 'type',
    label: 'General.type',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_type'],
    config: {
      searchMode: 'has_any',

      options: [
        {
          key: LISTING_TYPE.LESSON,
          label: 'General.lesson',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
        },
        { key: LISTING_TYPE.TOUR, label: 'General.tour', state: CONFIGURATION_OPTION_STATE.ACTIVE },
        {
          key: LISTING_TYPE.EXPERIENCE,
          label: 'General.experience',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        },
        {
          key: LISTING_TYPE.FREE,
          label: 'General.freeSharing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: 'ability',
    label: 'General.ability',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_ability'],
    showOnSearchPage: true,
    config: {
      searchMode: 'has_any',

      options: [
        {
          key: 'veryEasy',
          label: 'General.veryEasy',
          selectedLabel: 'General.veryEasySelected',
        },
        {
          key: 'easy',
          label: 'General.easy',
          selectedLabel: 'General.easySelected',
        },
        {
          key: 'intermediate',
          label: 'General.intermediate',
          selectedLabel: 'General.intermediateSelected',
        },
        {
          key: 'difficult',
          label: 'General.difficult',
          selectedLabel: 'General.difficultSelected',
        },
      ],
    },
  },
  {
    id: 'languages',
    label: 'General.languages',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_languages'],
    config: {
      searchMode: 'has_any',
      areOptionsTranslated: true,

      options: [
        { key: 'english', label: 'English' },
        { key: 'french', label: 'Français' },
        { key: 'german', label: 'Deutsch' },
        { key: 'italian', label: 'Italiano' },
      ],
    },
  },
  {
    id: ACTIVITY.SKIING,
    label: 'Skiing',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_skiing'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'alpineSkiing',
          label: 'General.alpineSkiing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'freerideSkiing',
          label: 'General.freerideSkiing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'freestyleSkiing',
          label: 'General.freestyleSkiing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'skiTouring',
          label: 'General.skiTouring',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'telemarkSkiing',
          label: 'General.telemarkSkiing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'crossCountrySkiing',
          label: 'General.crossCountrySkiing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'heliSkiing',
          label: 'General.heliSkiing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'grassSkiing',
          label: 'General.grassSkiing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.SNOWBOARDING,
    label: 'Snowboarding',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_snowboarding'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'onPisteSnowboarding',
          label: 'General.onPisteSnowboarding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'freerideSnowboarding',
          label: 'General.freerideSnowboarding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'freestyleSnowboarding',
          label: 'General.freestyleSnowboarding',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'heliBoarding',
          label: 'General.heliBoarding',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'splitBoarding',
          label: 'General.splitBoarding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.HIKING,
    label: 'Hiking',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_hiking'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'mountainHiking',
          label: 'General.mountainHiking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'snowShoeing',
          label: 'General.snowShoeing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.TOUR],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.RUNNING,
    label: 'Running',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_running'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'trailRunning',
          label: 'General.trailRunning',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.HORSE_RIDING,
    label: 'Horse riding',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_horseRiding'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'horseRiding',
          label: 'General.horseRiding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.BIKING,
    label: 'Biking',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_biking'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'mountainBiking',
          label: 'General.mountainBiking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'roadCycling',
          label: 'General.roadCycling',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'eBiking',
          label: 'General.eBiking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'fatBiking',
          label: 'General.fatBiking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.TOUR],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.CLIMBING,
    label: 'Climbing',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_climbing'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'mountaineering',
          label: 'General.mountaineering',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'rockClimbing',
          label: 'General.rockClimbing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'viaFerrata',
          label: 'General.viaFerrata',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'bouldering',
          label: 'General.bouldering',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON],
        },
        {
          key: 'iceClimbing',
          label: 'General.iceClimbing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.SLEDGING,
    label: 'Sledging',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_sledging'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'tobogganing',
          label: 'General.tobogganing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'huskySledding',
          label: 'General.huskySledding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'snowMobiling',
          label: 'General.snowMobiling',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'horseCarriageSledding',
          label: 'General.horseCarriageSledding',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.RIVER_SPORTS,
    label: 'River sports',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_riverSports'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'rafting',
          label: 'General.rafting',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'canyoning',
          label: 'General.canyoning',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.LAKE_AND_SEA_SPORTS,
    label: 'Lake & sea sports',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_lakeAndSeaSports'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'sailing',
          label: 'General.sailing',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'kayaking',
          label: 'General.kayaking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'standUpPaddle',
          label: 'General.standUpPaddle',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'boatCruise',
          label: 'General.boatCruise',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'fishing',
          label: 'General.fishing',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.AIR_SPORTS,
    label: 'Air sports',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_airSports'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'paragliding',
          label: 'General.paragliding',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
        {
          key: 'bungeeJumping',
          label: 'General.bungeeJumping',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'skydiving',
          label: 'General.skydiving',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
        {
          key: 'kiting',
          label: 'General.kiting',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          typeTags: [],
          seasonalityTags: [],
        },
        {
          key: 'tandemSpeedFlying',
          label: 'General.tandemSpeedFlying',
          state: CONFIGURATION_OPTION_STATE.ARCHIVED,
          seasonalityTags: [],
          typeTags: [],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.OTHER_EXPERIENCES,
    label: 'Other experiences',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_otherExperiences'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'avalancheTraining',
          label: 'General.avalancheTraining',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.LESSON],
        },
        {
          key: 'guidedWalking',
          label: 'General.guidedWalking',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'culinaryTasting',
          label: 'General.culinaryTasting',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'northernLights',
          label: 'General.northernLights',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER],
          typeTags: [LISTING_TYPE.TOUR],
        },
        {
          key: 'yoga',
          label: 'General.yoga',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON, LISTING_TYPE.TOUR],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: ACTIVITY.COURT_SPORTS,
    label: 'Court sports',
    type: 'SelectSingleFilter',
    group: 'primary',
    showForPartners: [PARTNER_KEYS.GENOVA],
    queryParamNames: ['pub_courtSports'],
    showOnSearchPage: false,
    config: {
      options: [
        {
          key: 'tennis',
          label: 'General.tennis',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON],
        },
        {
          key: 'padel',
          label: 'General.padel',
          state: CONFIGURATION_OPTION_STATE.ACTIVE,
          seasonalityTags: [LISTING_SEASONALITY.WINTER, LISTING_SEASONALITY.SUMMER],
          typeTags: [LISTING_TYPE.LESSON],
        },
      ].filter(o => o.state === CONFIGURATION_OPTION_STATE.ACTIVE),
    },
  },
  {
    id: 'provider',
    label: 'General.provider',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_provider'],
    config: {
      searchMode: 'has_any',

      options: [
        {
          key: USER_TYPE.BUSINESS,
          label: 'General.certifiedBusiness',
          selectedLabel: 'General.certifiedBusinessSelected',
        },
        {
          key: USER_TYPE.PROFESSIONAL,
          label: 'General.certifiedProfessional',
          selectedLabel: 'General.certifiedProfessionalSelected',
        },
        {
          key: USER_TYPE.EXPERT,
          label: 'General.localExpert',
          selectedLabel: 'General.localExpertSelected',
        },
      ],
    },
  },
  {
    id: 'group',
    label: 'General.session',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_groupType'],
    showOnSearchPage: true,
    config: {
      searchMode: 'has_any',

      options: [
        { key: 'private', label: 'General.private' },
        { key: 'group', label: 'General.group' },
      ],
    },
  },
  {
    id: 'services',
    label: 'Services',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_services'],
    config: {
      searchMode: 'has_any',

      options: [
        { key: 'gear', label: 'General.gear' },
        { key: 'food', label: 'General.food' },
        { key: 'tickets', label: 'General.tickets' },
        { key: 'overnightStay', label: 'General.overnightStay' },
        { key: 'drinks', label: 'General.drinks' },
        { key: 'transportation', label: 'General.transportation' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Marketplace API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keywords' to this list.
  conflictingFilters: [],

  options: [
    { key: '-attributes.createdAt', label: 'General.newest' },
    { key: 'attributes.createdAt', label: 'General.oldest' },
    { key: 'attributes.price.amount', label: 'General.lowestPrice' },
    { key: '-attributes.price.amount', label: 'General.highestPrice' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
