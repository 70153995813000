import React, { useMemo } from 'react';
import { injectIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { ErrorMessage } from '../../../components';
import ActivityFeed from '../ActivityFeed/ActivityFeed';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    chatmessages,
    oldestMessagePageFetched,
    onShowMoreMessages,
    onOpenReviewModal,
    totalMessagePages,
    intl,
  } = props;

  const txTransitions = currentTransaction.attributes.transitions || [];

  const showFeed = useMemo(
    () =>
      chatmessages?.length > 0 ||
      txTransitions?.length > 0 ||
      initialMessageFailed ||
      fetchMessagesError,
    [chatmessages?.length, fetchMessagesError, initialMessageFailed, txTransitions?.length]
  );

  const hasOlderMessages = useMemo(
    () => totalMessagePages > oldestMessagePageFetched && !fetchMessagesInProgress,
    [fetchMessagesInProgress, oldestMessagePageFetched, totalMessagePages]
  );

  const messages = useMemo(
    () => ({
      activityHeading: intl.formatMessage({ id: 'TransactionPanel.activityHeading' }),
      initialMessageFailed: intl.formatMessage({ id: 'TransactionPanel.initialMessageFailed' }),
      messageLoadingFailed: intl.formatMessage({ id: 'TransactionPanel.messageLoadingFailed' }),
    }),
    [intl]
  );

  const classes = useMemo(() => classNames(rootClassName || css.feedContainer, className), [
    className,
    rootClassName,
  ]);

  return (
    showFeed && (
      <div className={classes}>
        <h3 className={css.feedHeading}>{messages.activityHeading}</h3>
        {initialMessageFailed && <ErrorMessage errorMessage={messages.initialMessageFailed} />}
        {fetchMessagesError && <ErrorMessage errorMessage={messages.messageLoadingFailed} />}
        <ActivityFeed
          messages={chatmessages}
          transaction={currentTransaction}
          currentUser={currentUser}
          hasOlderMessages={hasOlderMessages}
          onOpenReviewModal={onOpenReviewModal}
          onShowOlderMessages={onShowMoreMessages}
          fetchMessagesInProgress={fetchMessagesInProgress}
        />
      </div>
    )
  );
};

export default injectIntl(FeedSection);
